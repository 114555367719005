import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { AddedTopicActions } from 'app/store/added-topics/added-topics.action';
import { AppState } from 'app/store/app.state';
import { BookingActions } from 'app/store/booking';
import { InvoiceActions } from 'app/store/invoice';
import { PatientActions } from 'app/store/patient';
import { PaymentActions } from 'app/store/payment/payment.action';
import { ReferrerActions } from 'app/store/referrer';
import { ReferrerContactMethodActions } from 'app/store/referrer-contact-method';
import { ScanCodeActions } from 'app/store/scan-code/scan-code.action';
import { TemplateActions } from 'app/store/template/template';
import { formatISO } from 'date-fns';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { UserActions } from '../../user/user/user.action';
import { ReportAccessEventActions } from '../access-event/access-event.action';
import { AuditEventActions } from '../audit-event/audit-event.action';
import { DocumentActions } from '../document/document.action';
import { FollowupTaskActions } from '../followup-task/followup-task.action';
import { SendEventActions } from '../send-event/send-event.action';
import { TodoActions } from '../todo/todo.action';
import { TopicActions } from '../topic/topic.action';
import { UrgentNoteActions } from '../urgent-note/urgent-note.action';
import { VoiceNoteActions } from '../voice-note/voice-note.action';
import { ReportActions, ReportBatchActions } from './report.action';
import { fromReport } from './report.selector';
import { NextReportBody, ReportHttpService, ReportSearchBody } from './report.service';

@Injectable()
export class ReportEffect {
  constructor(
    private service: ReportHttpService,
    private store: Store<AppState>,
    private messageService: MessageService,
  ) {}

  find(reportId: number) {
    return this.service.find(reportId).pipe(
      this.messageService.handleHttpErrorPipe,
      map(
        ({
          report,
          topics,
          templates,
          patient,
          referrer,
          access_events,
          send_events,
          todos,
          audit_events,
          users,
          voice_notes,
          urgent_notes,
          scan_code,
          followup_tasks,
          referrer_contact_methods,
        }) => {
          const action = ReportBatchActions.findReportSuccess({
            actions: {
              findReportSuccess: ReportActions.upsertOne({ report }),
              findTopicSuccess: TopicActions.upsertMany({ topics }),
              findTemplateSuccess: TemplateActions.findManySuccess({ templates }),
              findTodoSuccess: TodoActions.findManySuccess({ todos }),
              findTodoAuditEventSuccess: AuditEventActions.findManySuccess({ auditEvents: audit_events }),
              findUserSuccess: UserActions.findAllSuccess({ users }),
              findVoiceNoteSuccess: VoiceNoteActions.findManySuccess({ voice_notes }),
              findUrgentNoteSuccess: UrgentNoteActions.findManySuccess({ urgentNotes: urgent_notes }),
              findAccessEventSuccess: ReportAccessEventActions.findManySuccess({ accessEvents: access_events }),
              findSendEventSuccess: SendEventActions.findManySuccess({ sendEvents: send_events }),
              findFollowupTaskSuccess: FollowupTaskActions.findManySuccess({
                followupTasks: followup_tasks,
              }),
              findReferrerContactMethodSuccess: ReferrerContactMethodActions.findManySuccess({
                referrer_contact_methods,
              }),
            },
          });
          if (patient) {
            action.actions.findPatientSuccess = PatientActions.findSuccess({ patient });
          }
          if (referrer) {
            action.actions.findReferrerSuccess = ReferrerActions.findSuccess({ referrer });
          }
          if (scan_code) {
            action.actions.findScanCodeSuccess = ScanCodeActions.findSuccess({ scanCode: scan_code });
          }
          return action;
        },
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findReportText(reportId: number) {
    return this.service.findReportText(reportId).pipe(
      this.messageService.handleHttpErrorPipe,
      map((report) => ReportActions.findTextSuccess({ report })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  findRelatedReports(reportId: number, topicId: number) {
    return this.store.select(fromReport.selectRelatedReportsLoaded(reportId)).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findRelatedReports(reportId, topicId)),
      this.messageService.handleHttpErrorPipe,
      map((response) => {
        return ReportBatchActions.findRelatedReportsSuccessAction({
          actions: {
            partialUpdateReportSuccess: ReportActions.updateOne({
              update: {
                id: reportId,
                // This is one of the rare places adapter.updateOne() is used. The API doesn't include this field. Only
                // populated on the client after this request.
                changes: {
                  related_reports: response.reports.map((r) => r.id),
                },
              },
            }),
            findReportSuccess: ReportActions.upsertMany({
              reports: response.reports,
            }),
            loadedRelatedReports: ReportActions.loadedRelatedReports({
              report_id: reportId,
            }),
            findTopicSuccess: TopicActions.upsertMany({ topics: response.topics }),
            findTemplateSuccess: TemplateActions.findManySuccess({
              templates: response.templates,
            }),
            findDocumentSuccess: DocumentActions.upsertMany({
              documents: response.documents,
            }),
            findBookingSuccess: BookingActions.findManySuccess({ bookings: response.bookings }),
            findAddedTopicSuccess: AddedTopicActions.findAllSuccess({ added_topics: response.added_topics }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  search(queryBody: ReportSearchBody, params: HttpParams) {
    return this.service.search(queryBody, params).pipe(
      this.messageService.handleHttpErrorPipe,
      map(
        ({
          count,
          reports,
          topics,
          patients,
          referrers,
          scan_codes,
          bookings,
          todos,
          voice_notes,
          access_events,
          urgent_notes,
          send_events,
          invoices,
          audit_events,
          users,
          payments,
          followup_tasks,
          referrer_contact_methods,
        }) => {
          return ReportBatchActions.findAllReportSuccess({
            count: count,
            reportIds: reports.map((report) => report.id),
            actions: {
              findReportSuccess: ReportActions.upsertMany({ reports }),
              findPatientSuccess: PatientActions.findManySuccess({ patients }),
              findReferrerSuccess: ReferrerActions.findManySuccess({ referrers }),
              findTopicSuccess: TopicActions.upsertMany({ topics }),
              findTodoSuccess: TodoActions.findManySuccess({ todos }),
              findVoiceNoteSuccess: VoiceNoteActions.findManySuccess({ voice_notes }),
              findUrgentNoteSuccess: UrgentNoteActions.findManySuccess({ urgentNotes: urgent_notes }),
              findAccessEventSuccess: ReportAccessEventActions.findManySuccess({ accessEvents: access_events }),
              findSendEventSuccess: SendEventActions.findManySuccess({ sendEvents: send_events }),
              findScanCodeSuccess: ScanCodeActions.findManySuccess({ scanCodes: scan_codes }),
              findBookingSuccess: BookingActions.findManySuccess({ bookings }),
              findInvoiceSuccess: InvoiceActions.findManySuccess({ invoices }),
              findAuditEventSuccess: AuditEventActions.findManySuccess({ auditEvents: audit_events }),
              findUserSuccess: UserActions.findAllSuccess({ users }),
              findPaymentSuccess: PaymentActions.findManySuccess({ payments: payments }),
              findFollowupTaskSuccess: FollowupTaskActions.findManySuccess({
                followupTasks: followup_tasks,
              }),
              findReferrerContactMethodSuccess: ReferrerContactMethodActions.findManySuccess({
                referrer_contact_methods,
              }),
            },
          });
        },
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  patientSearch(patient_id: number, date?: string) {
    return this.service.patientSearch(patient_id, date).pipe(
      this.messageService.handleHttpErrorPipe,
      map(({ reports, topics, scan_codes }) => {
        return ReportBatchActions.findPatientReportsSuccess({
          actions: {
            findReportSuccess: ReportActions.upsertMany({ reports }),
            findTopicSuccess: TopicActions.upsertMany({ topics }),
            findScanCodeSuccess: ScanCodeActions.findManySuccess({ scanCodes: scan_codes }),
          },
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(data: Partial<RR.Report>) {
    return this.service.create(data).pipe(
      this.messageService.handleHttpErrorPipe,
      map((report: RR.Report) => {
        return ReportActions.createSuccess({
          report,
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(reportId: number, changes: Partial<RR.Report>) {
    return this.service.update(reportId, changes).pipe(
      this.messageService.handleHttpErrorPipe,
      map(({ report, referrer, patient, booking }) => {
        const action = ReportBatchActions.updateSuccessBatchAction({
          actions: {
            updateReportSuccess: ReportActions.upsertOne({
              report,
            }),
          },
        });
        if (patient) {
          action.actions.updatePatientSuccess = PatientActions.updateSuccess({ patient });
        }
        if (referrer) {
          action.actions.updateReferrerSuccess = ReferrerActions.updateSuccess({ referrer });
        }
        if (booking) {
          action.actions.updateBookingSuccess = BookingActions.updateSuccess({ booking });
        }

        return action;
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  markSuperUrgent(reportId: number, superUrgent: boolean) {
    const updateData: Partial<RR.Report> = {
      super_urgent: superUrgent,
    };

    // set due_date if the new status is true
    if (superUrgent) {
      const dt: Date = new Date();
      updateData.due_date = formatISO(dt);
    }

    return this.update(reportId, updateData);
  }

  updateAccession(reportId: number, accessionNumber: string) {
    return this.service.updateAccession(reportId, accessionNumber).pipe(
      this.messageService.handleHttpErrorPipe,
      map((response) => {
        return ReportActions.upsertOne({
          report: response,
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  getImageUrls(reportId: number) {
    return this.service.getImageUrls(reportId).pipe(
      this.messageService.handleHttpErrorPipe,
      map((response) => {
        return ReportActions.upsertOne({
          report: response,
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  sendToVoyager(reportId: number) {
    return this.service.sendToVoyager(reportId).pipe(
      this.messageService.handleHttpErrorPipe,
      map((response) => {
        return ReportActions.upsertOne({
          report: response,
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(reportId: number, password: string) {
    return this.service.delete(reportId, password).pipe(
      this.messageService.handleHttpErrorPipe,
      map((report) => {
        // Delete report only update deleted field
        return ReportActions.upsertOne({
          report,
        });
      }),
      tap((action) => this.store.dispatch(action)),
    );
  }

  restoreDeletedReport(reportId: number) {
    return this.service.restoreDeletedReport(reportId).pipe(
      this.messageService.handleHttpErrorPipe,
      map((report) => ReportActions.upsertOne({ report })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  nextReport(data: NextReportBody) {
    return this.service.nextReport(data).pipe(
      this.messageService.handleHttpErrorPipe,
      map(
        ({
          reports,
          topics,
          patients,
          next_report_id,
          next_report_same_referrer_id,
          next_report_same_scan_code_id,
          next_report_xr_id,
          next_report_mr_id,
          next_report_ct_id,
          next_report_us_id,
        }) => {
          return ReportBatchActions.findNextReportsSuccess({
            next_report_id,
            next_report_same_referrer_id,
            next_report_same_scan_code_id,
            next_report_xr_id,
            next_report_mr_id,
            next_report_ct_id,
            next_report_us_id,
            actions: {
              findReportSuccess: ReportActions.upsertMany({ reports }),
              findTopicSuccess: TopicActions.upsertMany({ topics }),
              findPatientSuccess: PatientActions.findManySuccess({ patients }),
            },
          });
        },
      ),
      tap((action) => this.store.dispatch(action)),
    );
  }

  availableForms(reportId: number) {
    return this.service.availableForms(reportId).pipe(
      this.messageService.handleHttpErrorPipe,
      // TODO: dispatch action and update the store?
    );
  }
}
