<div
  class="d-flex flex-column flex-grow-1"
  *ngIf="{
    topic: topic$ | async,
    report: report$ | async,
    selectedSection: selectedSection$ | async,
    selectedSubsection: selectedSubsection$ | async,
    selectedElement: selectedElement$ | async,
  } as data"
>
  <div class="d-flex flex-column bg-light ps-0 pe-0">
    <div class="d-flex">
      <div class="col-3 d-flex flex-column">
        <h3 class="mb-0">Section</h3>
        <div ngbDropdown class="d-inline-block" data-cy="section-dropdown">
          <button class="btn btn-outline-primary w-100 truncate" id="dropdownSection" ngbDropdownToggle>
            <ng-container *ngIf="data.selectedSection">
              {{ data.selectedSection.title | uppercase }}
            </ng-container>
          </button>
          <div ngbDropdownMenu>
            <button
              ngbDropdownItem
              *ngFor="let section of sections"
              [disabled]="!(isTemplateLoaded() | async)"
              class="truncate"
              [class.text-info]="section === data.selectedSection"
              [class.fw-bold]="section === data.selectedSection"
              (click)="selectSection(section)"
              [title]="section.title"
            >
              {{ section.short_title | uppercase }}
              <i *ngIf="!(isTemplateLoaded() | async)" class="fa fa-spinner fa-spin text-secondary"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="col-4 d-flex flex-column ps-0">
        <div class="d-flex">
          <h3 class="mb-0">Subsection</h3>
          <button
            role="button"
            class="btn p-0"
            permission
            [permission-only]="['template_manage']"
            (click)="openSubsectionEditModal()"
            data-cy="subsection-edit"
          >
            <span class="fa fa-edit"></span>
          </button>
        </div>
        <div ngbDropdown class="d-inline-block" *ngIf="data.topic && data.selectedSection">
          <!-- eslint-disable @angular-eslint/template/no-any -->
          <button
            class="btn btn-outline-primary w-100 truncate"
            id="dropdownSubsection"
            ngbDropdownToggle
            rr-subsection-breadcrumb
            [topic]="data.topic"
            [subsection]="$any(data.selectedSubsection)"
            [section]="data.selectedSection"
          ></button>
          <!-- eslint-enable @angular-eslint/template/no-any -->
          <div ngbDropdownMenu data-cy="dropdownSubsectionMenu" class="w-100 truncate dropdown-scroll">
            <button
              *ngFor="let sub of dropdownSubsections$ | async"
              (click)="selectSubsection(sub)"
              class="truncate"
              [class.text-info]="sub.id === data.selectedSubsection?.id"
              [class.fw-bold]="sub.id === data.selectedSubsection?.id"
              rr-subsection-breadcrumb
              ngbDropdownItem
              [topic]="data.topic"
              [subsection]="sub"
              [section]="data.selectedSection"
            ></button>
          </div>
        </div>
      </div>

      <div class="col-5 d-flex flex-column ps-0">
        <div class="d-flex">
          <h3 class="mb-0">Element</h3>
        </div>

        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-outline-primary w-100 truncate" id="dropdownElement" ngbDropdownToggle>
            <ng-container *ngIf="data.selectedElement">
              {{ getStatementSetName(data.selectedElement.statement_set_id) | async }}
            </ng-container>
          </button>
          <div ngbDropdownMenu data-cy="dropdownElementMenu" class="w-100 truncate dropdown-scroll">
            <button
              ngbDropdownItem
              *ngFor="let ss of dropdownStatementSets$ | async"
              class="truncate"
              [class.text-info]="ss?.id === data.selectedElement?.statement_set_id"
              [class.fw-bold]="ss?.id === data.selectedElement?.statement_set_id"
              (click)="selectElementByStatementSetId(ss.id)"
              [title]="ss?.name ? ss?.name : 'NO NAME'"
            >
              <ng-container *ngIf="ss">
                {{ getStatementSetName(ss.id) | async }}
              </ng-container>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="(selectedSubsection$ | async)?.region_set_id !== null"
      class="p-1 rounded text-light bg-secondary mt-2 d-flex align-items-center"
    >
      <div ngbDropdown id="region-dropdown">
        <button ngbDropdownToggle rrTooltip="REGION_DROPDOWN" class="btn btn-light ms-1">
          <strong>{{ activeRegion ? activeRegion.name : 'No region selected' }}</strong>
        </button>
        <div class="btn-group ms-1">
          <button
            class="btn btn-light"
            rrTooltip="PREVIOUS_REGION"
            [disabled]="!previousRegionChoice"
            (click)="previousRegion()"
          >
            <span class="fa fa-chevron-up"></span>
          </button>
          <button class="btn btn-light" rrTooltip="NEXT_REGION" [disabled]="!nextRegionChoice" (click)="nextRegion()">
            <span class="fa fa-chevron-down"></span>
          </button>
        </div>
        <button
          *ngIf="activeRegion"
          permission
          [permission-only]="['report_manage']"
          (click)="editLandmark({ region_id: activeRegion.id, landmark_label_id: activeRegion.landmark_label_id })"
          class="btn btn-sm btn-info ms-1"
          rrTooltip="EDIT_LANDMARK"
        >
          <span class="fa fa-map-marker-alt"></span> Landmark Label
          <ng-container *ngIf="activeRegion.landmark_label_id">
            (<rr-landmark-label-headline
              *ngIf="activeRegion.landmark_label_id | storeSelectPipe: selectLandmarkLabel | async as landmarkLabel"
              [landmarkLabel]="landmarkLabel"
            ></rr-landmark-label-headline
            >)
          </ng-container>
        </button>
        <div ngbDropdownMenu *ngIf="regions$ | async as regions">
          <div *ngFor="let region of regions" class="d-flex align-items-center">
            <input
              type="checkbox"
              id="check-region-{{ region.id }}"
              [checked]="checkSelectedRegion(region.id)"
              (click)="addOrRemoveRegion(region.id)"
              class="ms-2"
            />
            <label
              for="check-region-{{ region.id }}"
              *ngIf="!checkSelectedRegion(region.id)"
              class="text-muted form-check-label ms-3 py-1"
              >{{ region.name }}</label
            >

            <button
              class="dropdown-item ps-0 ms-3 py-1"
              *ngIf="checkSelectedRegion(region.id)"
              (click)="selectRegion(region.id)"
              [class.text-info]="region === activeRegion"
              [class.fw-bold]="region === activeRegion"
            >
              {{ region.name }}
            </button>
          </div>
        </div>
      </div>
      <div style="margin-left: auto" *ngIf="activeRegionChoice" rrTooltip="COPY_FROM_REGION_DROPDOWN">
        <div #dropdown="ngbDropdown" ngbDropdown placement="bottom-right" class="btn-group">
          <button
            role="button"
            *ngIf="previousRegionChoice"
            class="btn btn-light"
            (click)="copyFromRegion(previousRegionChoice, activeRegionChoice, $event); $event.stopPropagation()"
            [disabled]="copyingRegion"
          >
            Copy from
            <strong>{{ previousRegionChoice.region_name }}</strong>
          </button>
          <button ngbDropdownToggle class="btn btn-light" (click)="$event.stopPropagation()">
            <span *ngIf="!previousRegionChoice">Copy from</span>
          </button>
          <div ngbDropdownMenu class="w-100">
            <span *ngFor="let copyRegion of region_choices">
              <button
                *ngIf="copyRegion.id !== activeRegionChoice.id"
                class="dropdown-item"
                (click)="copyFromRegion(copyRegion, activeRegionChoice, $event); dropdown.close()"
              >
                {{ copyRegion.region_name }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- *ngIf="elements.length > 0 && (!selectedSubsection?.region_set_id || activeRegion) && selectedElement" -->
  <div
    *ngIf="data.selectedElement && data.selectedSubsection && data.selectedSection && data.report"
    class="d-flex flex-column flex-grow-1"
  >
    <rr-element
      [report]="data.report"
      [element]="data.selectedElement"
      [region]="activeRegion"
      [subsection]="data.selectedSubsection"
      [section]="data.selectedSection"
      [parent]="'EDITOR'"
      class="d-flex flex-column flex-grow-1"
    ></rr-element>
  </div>
</div>
