import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { CheckboxComponent } from 'app/shared/components/checkbox/checkbox.component';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { referrerTypeMap } from 'app/shared/utils/shared.utils';
import { Observable, Subscription } from 'rxjs';

import { RegistrationService } from '../../services/registration.service';
import { ReferrerFormEditComponent } from './referrer-form-edit/referrer-form-edit.component';
import { ReferrerFormSearchComponent } from './referrer-form-search/referrer-form-search.component';
import { ReferrerFormViewComponent } from './referrer-form-view/referrer-form-view.component';

export const REFERRER_FORM_ID = 'referrer-form';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReferrerFormEditComponent,
    ReferrerFormSearchComponent,
    ReferrerFormViewComponent,
    SharedModule,
    CheckboxComponent,
  ],
  selector: 'rr-referrer-form',
  templateUrl: './referrer-form.component.html',
  styleUrls: ['./referrer-form.component.scss'],
  host: {
    id: REFERRER_FORM_ID,
  },
})
export class ReferrerFormComponent implements OnDestroy, OnInit {
  @ViewChild(ReferrerFormEditComponent) referrerFormEdit: ReferrerFormEditComponent;
  @ViewChild(ReferrerFormSearchComponent) referrerFormSearchComponent: ReferrerFormSearchComponent;

  @Input() @BindObservable() referrer: RR.Referrer | undefined;
  referrer$: Observable<RR.Referrer | undefined>;
  @Input() reportId?: number | undefined;
  @Input() parent: 'REPORT' | 'REFERRER' | 'BOOKING' = 'REPORT'; // Parent component (report registration or referrer page)
  @Input() noReferrer: boolean;

  fromBooking$: Observable<RR.Booking | undefined>;

  removedReferrer: RR.Referrer | undefined = undefined;
  subscription: Subscription = new Subscription();
  formStatus: string;
  /**
   * search: show the search input. create patient button.
   * view: non editable preview of the patient. edit and remove patient buttons.
   * edit: show the form. if there's a patient, edit patient. if there's no patient, create patient.
   */
  viewMode: 'search' | 'view' | 'edit' | 'create' = 'view';
  referrerTypes = referrerTypeMap;

  constructor(
    private modalService: NgbModal,
    private registrationService: RegistrationService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.referrer$.subscribe((referrer) => {
        if (referrer) {
          // Do not back to view mode if editing referrer because we might want to edit preferred contacts multiple times.
          if (this.viewMode !== 'edit') {
            this.viewMode = 'view';
          }
        } else {
          this.viewMode = 'search';
        }
      }),
    );

    this.fromBooking$ = this.registrationService.fromBooking$;
  }

  setFormStatus(status: string) {
    this.formStatus = status;
  }

  selectReferrer($event: { referrer: RR.Referrer; created: boolean }) {
    const { referrer } = $event;
    this.subscription.add(
      this.registrationService.selectReferrer(referrer).subscribe(() => {
        this.viewMode = 'view';
      }),
    );
  }

  editReferrer() {
    this.viewMode = 'edit';
  }

  removeReferrer(referrer: RR.Referrer) {
    this.removedReferrer = referrer;
    this.subscription.add(this.registrationService.selectReferrer(null).subscribe());
    this.viewMode = 'search';
    this.resetForm();
  }

  undoRemoval(removedReferrer: RR.Referrer) {
    this.subscription.add(this.registrationService.selectReferrer(removedReferrer).subscribe());
    this.removedReferrer = undefined;
    this.viewMode = 'view';
  }

  cancel() {
    this.referrerFormEdit.form.markAsPristine();
    if (this.referrer) {
      this.viewMode = 'view';
    } else {
      this.viewMode = 'search';
    }
  }

  createNewReferrer() {
    this.viewMode = 'create';
    if (this.referrer) this.resetForm();
  }
  deleteReferrerReport() {
    const modalRef = ConfirmMessageModalComponent.open({
      modalService: this.modalService,
      header: 'Confirm',
      message: `Are you sure you want to delete ${
        (this.referrer?.physician_given_name ?? '') + ' ' + (this.referrer?.physician_family_name ?? '')
      }  from report?`,
      btnConfirmText: 'Yes',
      secondBtnOptionText: 'Cancel',
    });
    modalRef.result.then(
      () => {
        if (this.reportId) {
          this.subscription.add(this.registrationService.selectReferrer(null).subscribe());
          this.removedReferrer = undefined;
          this.viewMode = 'search';
          this.resetForm();
        }
      },
      () => {
        /* do nothing */
      },
    );
  }

  resetForm() {
    this.referrerFormEdit.form.reset();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
