import { createEntityAdapter, Dictionary } from '@ngrx/entity';
import { createSelector } from '@ngrx/store';
import { AppState } from 'app/store/app.state';
import { fromBookingRate } from 'app/store/booking-rate/booking-rate.selector';

import { fromCompanyRole } from '../company-role/company-role.selector';

const adapter = createEntityAdapter<RR.User>();
const selectFeature = (state: AppState) => state.user;

// The @ngrx/entity adapter provides a number of selectors for us
const { selectIds, selectEntities, selectAll } = adapter.getSelectors(selectFeature);

/**
 * Select a single user given the Id
 *
 * @params: userId: The identifier of the user in the database.
 */
const selectUser = (userId: number) => createSelector(selectEntities, (users: Dictionary<RR.User>) => users[userId]);

const selectActiveUsers = createSelector(selectAll, (users) => users.filter((user) => user.active));

const selectCompanyRoles = (userId: number) =>
  createSelector(
    selectUser(userId),
    fromCompanyRole.selectEntities,
    (user: RR.User | undefined, companyRoles: Dictionary<RR.CompanyRole>) => {
      return (
        user?.company_roles
          .map((companyRoleId) => companyRoles[companyRoleId])
          .filter((companyRole): companyRole is RR.CompanyRole => companyRole !== undefined) ?? []
      );
    },
  );

const selectUserLoaded = (userId: number) => createSelector(selectFeature, ({ usersLoaded }) => !!usersLoaded[userId]);

const selectAllUserLoaded = createSelector(selectFeature, (state) => state.allUserLoaded);

const selectBookingRates = (userId: number) =>
  createSelector(
    selectUser(userId),
    fromBookingRate.selectEntities,
    (user, bookingRates) =>
      user?.booking_rate_ids.map((br) => bookingRates[br]).filter((obj): obj is RR.BookingRate => !!obj) ?? [],
  );

export const fromUser = {
  selectIds,
  selectEntities,
  selectAll,
  selectUser,
  selectActiveUsers,
  selectCompanyRoles,
  selectUserLoaded,
  selectAllUserLoaded,
  selectBookingRates,
};
