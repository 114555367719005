import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { DefaultAttributeActions } from '../template/default-attribute/default-attribute.action';
import { ElementActions } from '../template/element/element.action';
import { RegionActions } from '../template/region/region.action';
import { SectionActions } from '../template/section/section.action';
import { StatementCoincidenceActions } from '../template/statement-coincidence/statement-coincidence.action';
import { StatementSetActions } from '../template/statement-set/statement-set.action';
import { StatementActions } from '../template/statement/statement.action';
import { SubsectionActions } from '../template/subsection/subsection.action';
import { TemplateBatchActions } from '../template/template/template.action';
import { TextObjectActions } from '../template/text-object/text-object.action';
import { CorrelatedSearchActions } from './correlated-statement-search.action';
import { CorrelatedResponse, CorrelatedSearchHttpService } from './correlated-statement-search.service';

@Injectable()
export class CorrelatedSearchEffect {
  constructor(
    private store: Store<AppState>,
    private service: CorrelatedSearchHttpService,
  ) {}

  // Same response for all three  api's
  handleCorrelatedSearchResponse(response: CorrelatedResponse) {
    const {
      suggested_statements,
      sections,
      subsections,
      regions,
      elements,
      statement_sets,
      statements,
      dividers,
      text_objects,
      statement_coincidences,
      default_attributes,
    } = response;

    return CorrelatedSearchActions.findCorrelatedSuccess({
      suggested_statements,
      actions: {
        findTemplateDataSuccess: TemplateBatchActions.findTemplateDataSuccess({
          actions: {
            sectionAddMany: SectionActions.addMany({ sections: sections }),
            subsectionAddMany: SubsectionActions.addMany({ subsections: subsections }),
            regionAddMany: RegionActions.addMany({ regions: regions }),
            elementAddMany: ElementActions.addMany({ elements: elements }),
            statementSetAddMany: StatementSetActions.addMany({ statementSets: statement_sets }),
            statementAddMany: StatementActions.addMany({ statements: statements }),
            dividerAddMany: StatementActions.addMany({ statements: dividers }),
            textObjectAddMany: TextObjectActions.upsertMany({ textObjects: text_objects }),
            statementCoincidenceAddMany: StatementCoincidenceActions.addMany({
              statementCoincidences: statement_coincidences,
            }),
          },
        }),
        findDefaultAttributeSuccess: DefaultAttributeActions.findAllSuccess({
          defaultAttributes: default_attributes,
        }),
      },
    });
  }

  correlatedSearch(topicId: number, statementId: number) {
    return this.service.correlatedSearch(topicId, statementId).pipe(
      // commented because error handled in parent
      // this.message.handleHttpErrorPipe,
      map((response) => this.handleCorrelatedSearchResponse(response)),
      tap((action) => this.store.dispatch(action)),
    );
  }

  reportCorrelatedSearch(reportId: number) {
    return this.service.reportCorrelatedSearch(reportId).pipe(
      // commented because error handled in parent
      // this.message.handleHttpErrorPipe,
      map((response) => this.handleCorrelatedSearchResponse(response)),
      tap((action) => this.store.dispatch(action)),
    );
  }

  statisticalStatementsPrediction(topicId: number) {
    return this.service.statisticalStatementsPrediction(topicId).pipe(
      // commented because error handled in parent
      // this.message.handleHttpErrorPipe,
      map((response) => this.handleCorrelatedSearchResponse(response)),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
