<div class="d-flex align-items-center mb-2" *ngIf="parent === 'REGISTRATION'">
  <h3>Search for an existing patient or add a new one</h3>
  <button
    class="ms-auto btn btn-outline-primary btn-sm"
    (click)="createNewPatient()"
    rrTooltip="REGISTRATION_CREATE_NEW_PATIENT"
  >
    + Create New Patient
  </button>
  <button
    *ngIf="removedPatient && !patient"
    class="btn btn-primary btn-sm ms-1"
    (click)="undoRemoval(removedPatient)"
    rrTooltip="REGISTRATION_UNDO_REMOVAL"
  >
    Undo Removal
  </button>
</div>

<!-- Hidden used so form state isn't lost when switching viewMode state -->
<rr-patient-form-search
  [hidden]="!(viewMode === 'search')"
  [bookingPatient]="bookingPatient"
  (onSelectPatient)="selectPatient($event)"
  [parent]="parent"
></rr-patient-form-search>

<div class="mb-2">
  <div class="badge bg-danger me-2" *ngIf="expiredMedicare && viewMode === 'view'">Expired Medicare</div>
  <div class="badge bg-warning" *ngIf="!formValid && viewMode === 'view'">Missing Details</div>
</div>

<rr-patient-form-view
  *ngIf="patient && viewMode === 'view'"
  [invalid]="!formValid"
  [patient]="patient"
  (onClear)="removePatient(patient)"
>
  <ng-container buttons>
    <!-- "buttons" marks the projected content -->
    <button type="button" class="btn btn-outline-primary" (click)="editPatient()" rrTooltip="REGISTRAION_EDIT_PATIENT">
      Edit Patient
    </button>
  </ng-container>
</rr-patient-form-view>

<rr-patient-form-edit
  *ngIf="viewMode === 'edit' || viewMode === 'create'"
  [viewMode]="viewMode"
  [parent]="parent"
  [patient]="patient"
  [bookingPatient]="bookingPatient"
  [searchFormPatientDetails]="searchFormPatientDetails"
  (onCancel)="cancel()"
  (onRemove)="patient && removePatient(patient)"
  (onChange)="selectPatient($event)"
></rr-patient-form-edit>
