<div class="modal-header">
  <h4 class="modal-title">Pending statements require your attention</h4>
  <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p>
    These statements are required. If you choose not to include one, justify the reason why by clicking the justify
    button.
  </p>

  <ng-container *ngFor="let incompleteMandatoryStatement of incompleteMandatoryStatements">
    <ng-container
      *ngIf="
        incompleteMandatoryStatement.statement_id &&
        (incompleteMandatoryStatement.statement_id | storeSelectPipe: selectStatement | async) as statement
      "
    >
      <div class="d-flex align-items-center gap-2">
        <rr-recommended-statement
          (click)="selectChoice(statement.id)"
          [statement]="statement"
          [topic]="topic"
        ></rr-recommended-statement>
        <ng-container>
          <button
            *ngIf="
              (incompleteMandatoryStatement.id | storeSelectPipe: selectJustification | async) === undefined &&
              mandatoryStatement[statement.id]
            "
            role="button"
            class="btn btn-danger"
            rrTooltip="JUSTIFY_MANDATORY_STATEMENT"
            (click)="mandatoryStatementService.openJustificationModal(topic, incompleteMandatoryStatement)"
          >
            Justify
          </button>
          <button
            role="button"
            *ngIf="incompleteMandatoryStatement.id | storeSelectPipe: selectJustification | async as justification"
            class="btn btn-danger"
            rrTooltip="DELETE_MANDATORY_STATEMENT_JUSTIFICATION"
            (click)="mandatoryStatementService.deleteJustification(justification)"
          >
            Delete Justification
          </button>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.dismiss()">Close</button>
</div>
