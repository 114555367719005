<div *ngIf="(loading$ | async) && selectedTopics.length === 0" class="d-flex justify-content-center loading mt-2">
  <i class="fa fa-spinner fa-2x fa-spin text-secondary mb-3"></i>
</div>

<div class="d-flex flex-column mb-1 w-100 flex-auto" *ngIf="topic">
  <!--Top action buttons-->
  <div class="d-flex bg-light rounded p-1">
    <div class="ms-1">
      <h3 *ngIf="(loading$ | async) === false" class="align-self-center mb-0">
        {{ selectedTopics.length }} {{ selectedTopics.length > 1 ? 'topics' : 'topic' }} selected.
      </h3>
      <div *ngIf="metric && metric.totalCurrent > 0" rrTooltip="PREFILL_METRIC_1">
        {{ metric.intersection }} / {{ metric.totalCurrent }} =
        {{ (metric.intersection / metric.totalCurrent) * 100 | number: '1.0-0' }}%
      </div>
      <div *ngIf="metric && metric.totalPrefills > 0" rrTooltip="PREFILL_METRIC_2">
        {{ metric.intersection }} / {{ metric.totalPrefills }} =
        {{ (metric.intersection / metric.totalPrefills) * 100 | number: '1.0-0' }}%
      </div>

      <rr-checkbox [checked]="hideExcludedTags" (change)="toggleHideExcludedTags()"> Hide excluded groups </rr-checkbox>
      <div *ngIf="prefillService.exactMatchOpen$ | async" class="d-flex justify-content-center">
        <ng-container *ngIf="prefillService.isShowB3ResultsButtonVisible$ | async">
          <button
            *ngIf="prefillService.isExactMatchResultsLoading$ | async; else showResultsButton"
            class="btn btn-warning text-nowrap"
            disabled
          >
            Loading B3 results...
          </button>
          <ng-template #showResultsButton>
            <button
              class="btn btn-warning text-nowrap flash"
              (click)="showExactMatchResults()"
              rrTooltip="SHOW_B3_RESULTS"
            >
              Show B3 Results
            </button>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <div class="d-flex flex-column ms-auto my-1">
      <div class="d-flex justify-content-end">
        <button
          title="Show prefill tutorial"
          class="btn btn-sm btn-outline-dark me-1"
          role="button"
          (click)="openPrefillTutorialModal()"
        >
          <i class="fa fa-question"></i>
        </button>
        <button
          class="btn btn-sm btn-outline-dark"
          id="copy-all"
          (click)="copyAll(false)"
          role="button"
          rrTooltip="COPY_ALL"
          [disabled]="selectedTopics.length === 0"
        >
          <span class="fa fa-exchange-alt"></span> Copy all statements
        </button>
        <button
          class="btn btn-sm btn-outline-dark ms-2"
          id="copy-all-keep-numbers"
          (click)="copyAll(true)"
          role="button"
          rrTooltip="COPY_ALL_KEEP_NUMBERS"
          [disabled]="selectedTopics.length === 0"
        >
          <span class="fa fa-exchange-alt"></span> Copy all & keep numbers
        </button>
      </div>
      <div class="d-flex justify-content-end mt-1">
        <ng-container *ngIf="topic.title_option_id">
          <button
            class="btn btn-sm btn-outline-dark text-nowrap"
            rrTooltip="PREFILL_TITLE_SENTENCES"
            [disabled]="processingPrefillTitle"
            (click)="prefillSentences('TITLE')"
          >
            <span class="fa fa-file-import"></span> Prefill Title (T)
          </button>

          <button
            (click)="prefillSentences('PREDICTION')"
            [disabled]="processingPrefillTitle"
            class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
            rrTooltip="PREFILL_PREDICTION_SENTENCES"
          >
            <span class="fa fa-file-import"></span> Prefill Prediction (P)
          </button>
        </ng-container>
      </div>
      <div class="d-flex justify-content-end mt-1">
        <button
          title="Jump to the bottom of Prefill"
          class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
          role="button"
          (click)="prefillScrollTo('BOTTOM')"
        >
          <i class="fa fa-arrow-alt-circle-down"></i> End
        </button>

        <button
          title="Jump to the top of Prefill"
          class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
          role="button"
          (click)="prefillScrollTo('TOP')"
        >
          <i class="fa fa-home"></i> Home
        </button>
        <button
          title="Jump to the previous Subsection of Prefill"
          class="btn btn-sm btn-outline-dark ms-1"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'up', data: '[data-subsection]' })"
        >
          <i class="fa fa-arrow-up"></i> Previous Subsection
        </button>
        <button
          title="Jump to the next Subsection of Prefill"
          class="btn btn-sm btn-outline-dark ms-1 text-nowrap"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'down', data: '[data-subsection]' })"
        >
          <i class="fa fa-arrow-down"></i> Next Subsection
        </button>
      </div>
      <div class="d-flex justify-content-end mt-1 gap-1">
        <button
          title="Jump to the previous section of Prefill"
          class="btn btn-sm btn-outline-dark"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'up', data: '[data-section]' })"
        >
          <i class="fa fa-arrow-up"></i> Previous Section
        </button>
        <button
          title="Jump to the next section of Prefill"
          class="btn btn-sm btn-outline-dark text-nowrap"
          role="button"
          (click)="scrollToNextSectionOrSubsection({ direction: 'down', data: '[data-section]' })"
        >
          <i class="fa fa-arrow-down"></i> Next Section
        </button>
        <button
          class="btn btn-primary"
          rrTooltip="CORRELATED_SENTENCE_SEARCH"
          (click)="impressionAndCommentsPrediction()"
        >
          Statistical Statements Prediction
        </button>
      </div>
      <div class="mt-1 align-self-end">
        <button
          class="btn btn-primary"
          *ngIf="incompleteMandatoryStatements && incompleteMandatoryStatements.length > 0"
          (click)="mandatoryStatementService.openMandatoryStatementModal(incompleteMandatoryStatements)"
        >
          Pending Statements
          <span class="badge rounded-pill bg-danger me-1">{{ incompleteMandatoryStatements.length }} </span>
        </button>
      </div>
    </div>
  </div>

  <!--Merge choices from all reports, display in template order-->
  <div
    #scrollContainer
    id="PREFILL_COPY_SCROLL"
    class="p-2 d-flex flex-column flex-basis-0 flex-grow-1 overflow-auto"
    *ngIf="selectedTopics.length > 0"
  >
    <!--Loading spinner-->
    <div *ngIf="loading$ | async" class="d-flex justify-content-center loading mt-2">
      <i class="fa fa-spinner fa-2x fa-spin text-secondary"></i>
    </div>
    <!--Key findings-->
    <div *ngIf="keyFindings.length">
      <div class="item-hover-parent">
        <label
          title="Click to copy all key findings in prefill to the report"
          class="text-primary"
          (click)="copyAllKeyFindingChoices()"
        >
          <h3><strong>Key Findings</strong></h3>
        </label>
        <button class="btn btn-sm btn-light item-hover ms-1" (click)="copyAllKeyFindingChoices()">
          <i class="fas fa-exchange-alt"></i> Copy key findings
        </button>
      </div>
      <div class="d-flex flex-column gap-1">
        <rr-prefill-copy-choice
          *ngFor="let choice of keyFindings; trackBy: trackById"
          [statementChoice]="choice"
          [element]="undefined"
          [type]="'keyfinding'"
          [topic]="topic"
          [suggestionStatementIds]="suggestionStatementIds"
          [currentContextChoices]="currentContextChoices"
          [prefillContextChoices]="prefillContextChoices"
          [notepadStatements]="notepadStatements"
          (onClick)="copyChoices({ choiceIds: [choice.id], keepNumbers: false, choice: choice })"
        ></rr-prefill-copy-choice>
      </div>
    </div>

    <hr class="my-3" />

    <!--Sections-->
    <div class="ps-0" *ngFor="let section of usedSections" [attr.data-cy]="'prefill-section-' + section.type">
      <div
        *ngIf="
          (section.type !== 'history' && section.type !== 'technique') ||
          !hideHistoryTechnique ||
          checkSectionInUse(section)
        "
      >
        <div class="item-hover-parent">
          <label
            title="Click to copy all statements in this section to the report"
            (click)="copySectionChoices(section)"
          >
            <!-- Highlight section title when jumping between sections -->
            <h3
              class="text-primary"
              [attr.data-section]="section.id"
              #sectionElement
              [class.highlighted]="sectionElement === sectionOrSubsectionEl"
            >
              <strong>
                {{ section.title }}
              </strong>
            </h3>
          </label>
          <button class="btn btn-sm btn-light item-hover ms-1" (click)="copySectionChoices(section)">
            <i class="fas fa-exchange-alt"></i> Copy section
          </button>
          <div class="d-flex align-items-center gap-1">
            <rr-auto-select-groups-button
              [sectionType]="section.type"
              [topicId]="topic.id"
              [parent]="'PREFILL'"
            ></rr-auto-select-groups-button>
          </div>
        </div>

        <!--Clone sentences-->
        <div
          *ngIf="
            (section.type === 'comment' && cloneComments.length > 0) ||
            (section.type === 'impression_recommendations' && cloneImpressions.length > 0)
          "
        >
          <div class="item-hover-parent">
            <i class="fa fa-book me-1"></i>
            <label class="subsection-text fw-bold">
              {{ section.type === 'comment' ? 'CLONED COMMENTS' : 'CLONED I&R' }}
            </label>
          </div>
          <div
            *ngIf="section.type === 'comment' ? cloneComments : cloneImpressions as clones"
            class="d-flex flex-column gap-1"
          >
            <rr-prefill-copy-choice
              *ngFor="let statementChoice of clones; trackBy: trackById"
              [type]="'clone'"
              [statementChoice]="statementChoice"
              [element]="undefined"
              [topic]="topic"
              [suggestionStatementIds]="suggestionStatementIds"
              [currentContextChoices]="currentContextChoices"
              [prefillContextChoices]="prefillContextChoices"
              [notepadStatements]="notepadStatements"
              (onClick)="copyChoices({ choiceIds: [statementChoice.id], keepNumbers: false, choice: statementChoice })"
            ></rr-prefill-copy-choice>
          </div>
        </div>

        <!--Subsections-->
        <div *ngFor="let subsection of getSubsections(section); trackBy: trackById">
          <div class="subsection text-dark mb-4">
            <div *ngIf="!subsection.region_set_id">
              <div class="item-hover-parent">
                <i class="fa fa-book me-1"></i>
                <!-- Highlight subsection title when jumping between subsection -->
                <label
                  title="Click to copy all statements in this subsection to the report"
                  class="subsection-text fw-bold"
                  (click)="copySubsectionChoices(subsection)"
                  [attr.data-subsection]="subsection.id"
                  #subsectionElement
                  [class.highlighted]="subsectionElement === sectionOrSubsectionEl"
                >
                  {{ subsection.name || 'UNNAMED SUBSECTION' }}
                </label>
                <button class="btn btn-sm btn-light item-hover ms-1" (click)="copySubsectionChoices(subsection)">
                  <i class="fas fa-exchange-alt"></i> Copy subsection
                </button>
                <button
                  *ngIf="(mlLandmarkTemplate$ | async) && subsection.landmark_label_id"
                  class="btn btn-sm btn-light item-hover ms-1"
                  (click)="filterLandmark(subsection.landmark_label_id)"
                >
                  <i class="fas fa-search"></i> Search {{ subsection.landmark_label_id }}
                </button>
              </div>

              <div>
                <rr-prefill-copy-element
                  *ngFor="let element of getSubsectionElements(subsection.id)"
                  [element]="element"
                  [region]="undefined"
                  [subsection]="subsection"
                  [section]="section"
                  [topic]="topic"
                  [usedStatements]="usedStatements"
                  [notepadStatements]="notepadStatements"
                  [prefillContextChoices]="prefillContextChoices"
                  [currentContextChoices]="currentContextChoices"
                  [exclusiveTagMap]="exclusiveTagMap"
                  [usedDividers]="usedDividers"
                  [statementGroupIds]="statementGroupIds"
                  [statementGroupTags]="statementGroupTags"
                  [suggestionStatementIds]="suggestionStatementIds"
                  [hideExcludedTags]="hideExcludedTags"
                  (onCopyChoices)="copyChoices($event)"
                ></rr-prefill-copy-element>
              </div>
            </div>
            <!--Show region inside subsection-->
            <div *ngIf="subsection.region_set_id">
              <div class="region" *ngFor="let region of getRegions(subsection.region_set_id) | async">
                <div class="no-checkbox item-hover-parent fw-bold" *ngIf="checkExistRegionChoice(region.id)">
                  <i class="fa fa-registered me-1"></i>
                  <label
                    title="Click to copy all statements in this region to the report"
                    (click)="copyRegionChoices(region)"
                    [attr.data-region]="region.id"
                  >
                    {{ region.name }}
                  </label>
                  <button class="btn btn-sm btn-light item-hover ms-1" (click)="copyRegionChoices(region)">
                    <i class="fas fa-exchange-alt"></i> Copy region
                  </button>

                  <button
                    *ngIf="(mlLandmarkTemplate$ | async) && region.landmark_label_id"
                    class="btn btn-sm btn-light item-hover ms-1"
                    (click)="filterLandmark(region.landmark_label_id)"
                  >
                    <i class="fas fa-search"></i> Search
                    <rr-landmark-label-headline
                      *ngIf="region.landmark_label_id | storeSelectPipe: selectLandmarkLabel | async as landmarkLabel"
                      [landmarkLabel]="landmarkLabel"
                    ></rr-landmark-label-headline>
                  </button>
                </div>

                <div *ngFor="let element of getSubsectionElements(subsection.id)">
                  <rr-prefill-copy-element
                    *ngIf="checkElementInRegionIsUsed(element, region)"
                    [element]="element"
                    [region]="region"
                    [subsection]="subsection"
                    [section]="section"
                    [topic]="topic"
                    [usedStatements]="usedStatements"
                    [notepadStatements]="notepadStatements"
                    [prefillContextChoices]="prefillContextChoices"
                    [currentContextChoices]="currentContextChoices"
                    [exclusiveTagMap]="exclusiveTagMap"
                    [usedDividers]="usedDividers"
                    [statementGroupIds]="statementGroupIds"
                    [statementGroupTags]="statementGroupTags"
                    [suggestionStatementIds]="suggestionStatementIds"
                    [hideExcludedTags]="hideExcludedTags"
                    (onCopyChoices)="copyChoices($event)"
                  ></rr-prefill-copy-element>
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr class="my-3" />
      </div>
    </div>
  </div>
</div>
