import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { TextObjectChoiceEffect } from 'app/store/report/text-object-choice';
import { attributeKeyString, fromDefaultAttribute } from 'app/store/template/default-attribute';
import {
  CreateTextObjectDate,
  CreateTextObjectLiteral,
  CreateTextObjectNumber,
  CreateTextObjectSet,
  fromStatement,
  PatchTextObject,
  StatementEffect,
} from 'app/store/template/statement';
import { fromStatementSet } from 'app/store/template/statement-set';
import { Subscription } from 'rxjs';
import { switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

interface PostStatementCreatedData {
  options: { focus: boolean; dismissModal: boolean };
  newStatement: RR.Statement;
  mode: 'CREATE' | 'INSERT';
}
// import * as statementEffects from 'app/store/effects/statement';
import { VoiceRecognitionComponent } from '../../../shared/components/voice-recognition/voice-recognition/voice-recognition.component';
import { SharedModule } from '../../../shared/shared.module';
import {
  CreateAndCloneStatementData,
  EditType,
  StatementEditComponentOnSubmit,
  StatementEditComponent,
} from '../statement-edit/statement-edit.component';
import { StatementMovingComponent } from '../statement-edit/statement-moving/statement-moving.component';
import { StatementPreviewComponent } from '../statement-edit/statement-preview/statement-preview.component';
import { StatementDeleteModalComponent } from './statement-delete-modal.component';

@Component({
  templateUrl: './statement-edit-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    StatementPreviewComponent,
    RouterLink,
    SharedModule,
    StatementMovingComponent,
    VoiceRecognitionComponent,
    StatementEditComponent,
  ],
})
@LifecycleLogger
export class StatementEditModalComponent implements OnInit, OnDestroy {
  @Input() statement_id: number;
  @Input() region: RR.Region | undefined;
  @Input() topic: RR.Topic;
  @Input() subsection: RR.Subsection;
  @Input() element: RR.Element;
  @Input() editType: EditType;
  @Input() choice: RR.StatementChoice;
  @Input() selectedStatements: number[];
  @Input() currentStatementIndex = 0;
  @Input() source: 'EDITOR' | 'PREFILL' | 'NOTEPAD' = 'EDITOR';
  statement: RR.Statement;
  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private templateService: TemplateService,
    private modalService: NgbModal,
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
    private editorService: EditorService,
    private store: Store<AppState>,
    private statementEffect: StatementEffect,
    private messageService: MessageService,
    private textObjectChoiceEffect: TextObjectChoiceEffect,
  ) {}

  ngOnInit() {
    this.initSubscriptions();
    this.subscription.add(this.statementEffect.findInStatementBuilder().subscribe());
  }

  get region_id() {
    return this.region ? this.region.id : undefined;
  }

  initSubscriptions() {
    this.subscription.unsubscribe();
    this.subscription = new Subscription();
    if (
      this.editType === 'editStatement' ||
      this.editType === 'moveMultipleStatement' ||
      this.editType === 'editNotepadStatement'
    ) {
      this.subscription.add(
        this.templateService.getStatement(this.statement_id).subscribe((statement) => {
          this.statement = statement;
          this.cd.markForCheck();
        }),
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  editStatement(data: StatementEditComponentOnSubmit) {
    this.statementEffect
      .update(this.statement_id, data.statement)
      .pipe(switchMap(() => this.statementEffect.patchTextObjects(this.statement_id, data.textObjects)))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe({
        next: () => {
          if (data.focus) {
            const region_id = this.region ? this.region.id : undefined;
            this.editorService.publishFocus({
              statement_id: this.statement_id,
              element_id: this.element.id,
              region_id,
              target: this.source,
            });
          }
          if (data.dismissModal) {
            this.activeModal.dismiss();
          }

          this.messageService.add({
            title: 'Success',
            type: 'success',
            message: 'Edit statement successfully.',
          });
        },
      });
  }

  removeStatement() {
    // Focus on previous or next statement after removing a statement
    let neighbourId: number | undefined = undefined;
    this.subscription.add(
      this.store
        .select(fromStatementSet.selectStatementNeighbourId(this.element.statement_set_id, this.statement.id))
        .pipe(
          take(1),
          tap((neighbour) => {
            neighbourId = neighbour;
          }),
          switchMap(() => this.statementEffect.delete(this.statement.id)),
        )
        .subscribe(() => {
          if (neighbourId) {
            this.editorService.publishFocus({ statement_id: neighbourId, target: this.source });
          }
          this.activeModal.dismiss();
        }),
    );
  }

  promptDeleteStatement() {
    const modalRef = this.modalService.open(StatementDeleteModalComponent);
    const componentInstance: StatementDeleteModalComponent = modalRef.componentInstance;
    componentInstance.statement = this.statement;
    componentInstance.topic = this.topic;
    modalRef.result.then(
      () => {
        this.removeStatement();
      },
      () => {
        /* dismissed */
      },
    );
  }

  insertStatementBefore(data: StatementEditComponentOnSubmit) {
    this.subscription.add(
      this.templateService
        .insertStatement(
          {
            statement: {
              ...data.statement,
              statement_set_id: this.element.statement_set_id,
            },
            textObjects: data.textObjects,
          },
          this.statement_id,
        )
        .subscribe({
          next: (successAction) => {
            const newStatement = successAction.actions.statementCreateSuccessAction.statement;
            this.postStatementCreatedSuccess({
              options: { focus: data.focus, dismissModal: data.dismissModal },
              newStatement,
              mode: 'INSERT',
            });
          },
        }),
    );
  }

  createStatement(data: StatementEditComponentOnSubmit) {
    this.subscription.add(
      this.statementEffect
        .createWithTextObjects({
          statement: {
            ...data.statement,
            statement_set_id: this.element.statement_set_id,
          },
          textObjects: data.textObjects,
        })
        .subscribe({
          next: (action) => {
            const { actions } = action;
            const newStatement = actions.statementCreateSuccessAction.statement;
            this.postStatementCreatedSuccess({
              options: { focus: data.focus, dismissModal: data.dismissModal },
              newStatement,
              mode: 'CREATE',
            });
          },
        }),
    );
  }

  postStatementCreatedSuccess(data: PostStatementCreatedData) {
    if (data.options.focus) {
      this.focusCreatedStatement(data.newStatement);
    }

    this.messageService.add({
      title: 'Success',
      type: 'success',
      message: `${data.mode === 'CREATE' ? 'Create' : 'Insert'} statement successfully.`,
    });

    if (data.options.dismissModal) {
      this.activeModal.dismiss();
    } else {
      // Switch to edit mode
      this.statement_id = data.newStatement.id;
      if (this.editType === 'insertNotepadStatement' || this.editType === 'createNotepadStatement') {
        this.editType = 'editNotepadStatement';
      } else {
        this.editType = 'editStatement';
      }
      this.initSubscriptions();
      this.cd.detectChanges();
    }
  }

  focusCreatedStatement(statement: RR.Statement) {
    // It is convenient to highlight the just created statement on the screen. So you can then choose it, move it, etc.
    this.editorService.publishFocus({
      statement_id: statement.id,
      // If source is PREFILL or EDITOR, we just need to focus on new created statement. Don't send element_id again
      // because it will trigger LoadElement event for the editor
      element_id: this.source === 'NOTEPAD' ? this.element.id : undefined,
      target: this.source,
    });
  }

  removeChoice() {
    this.reportService.removeStatementChoice(this.choice);
    this.activeModal.dismiss();
  }

  editChoice(text: string) {
    // Report specific choices don't have attributes. So this only needs to update the first TextObjectChoice.
    this.subscription.add(
      this.textObjectChoiceEffect
        .update(this.choice.text_object_choices[0], {
          text: text.charAt(0).toUpperCase() + text.slice(1),
        })
        .subscribe(() => {
          this.activeModal.dismiss();
        }),
    );
  }

  /**
   * Approving a choice is equivalent to creating a statement
   */
  approveChoice(text: string) {
    this.statementEffect
      .approveChoice(this.choice.id, text)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((action) => {
        this.activeModal.dismiss();
        const successAction = action.actions.statementCreateSuccessAction;
        const statement = successAction.statement;
        const modalRef = StatementEditModalComponent.open(this.modalService, {
          windowClass: 'statement-edit-modal',
        });
        const componentInstance = modalRef.componentInstance;
        componentInstance.statement_id = statement.id;
        componentInstance.region = this.region;
        componentInstance.topic = this.topic;
        componentInstance.subsection = this.subsection;
        componentInstance.element = this.element;
        componentInstance.editType = 'editStatement';
        // Focus it so doesn't get filtered in the Element
        this.editorService.publishFocus({
          statement_id: action.actions.statementCreateSuccessAction.statement.id,
          statement_choice_id: this.choice.id,
          target: this.source,
        });
      });
  }

  cloneTextObjects({
    textObjects,
    defaultAttributeMap,
  }: {
    textObjects: RR.TextObject[];
    defaultAttributeMap?: Record<string, RR.DefaultAttribute>;
  }) {
    const patchTextObjects: PatchTextObject[] = textObjects.map((textObject) => {
      if (textObject.type === 'set') {
        // Use the same DefaultAttribute as the original TextObject
        let defaultAttribute: RR.DefaultAttribute | undefined;
        if (defaultAttributeMap) {
          defaultAttribute =
            defaultAttributeMap[attributeKeyString(this.topic.template_id, textObject.id, this.region_id || null)];
        }
        const a: CreateTextObjectSet = {
          type: 'set',
          attribute_set_id: textObject.attribute_set_id,
          default_option_id: defaultAttribute?.default_option_id ?? undefined,
          shortlist: [],
        };
        return a;
      } else if (textObject.type === 'number') {
        const a: CreateTextObjectNumber = {
          type: 'number',
          formula: textObject.formula,
        };
        return a;
      } else if (textObject.type === 'date') {
        const a: CreateTextObjectDate = {
          type: 'date',
        };
        return a;
      } else {
        const a: CreateTextObjectLiteral = {
          type: 'literal',
          value: textObject.value,
        };
        return a;
      }
    });
    return patchTextObjects;
  }

  clone() {
    this.subscription.add(
      this.store
        .select(fromDefaultAttribute.selectChoiceMap)
        .pipe(withLatestFrom(this.store.select(fromStatement.selectTextObjects(this.statement.id))), take(1))
        .pipe(
          switchMap(([defaultAttributeMap, textObjects]) => {
            const newTextObjects = this.cloneTextObjects({ textObjects, defaultAttributeMap });
            return this.templateService.insertStatement(
              {
                statement: {
                  statement_set_id: this.statement.statement_set_id,
                },
                textObjects: newTextObjects,
              },
              this.statement_id,
            );
          }),
        )
        .subscribe((action) => {
          this.statement_id = action.actions.statementCreateSuccessAction.statement.id;
          this.initSubscriptions();
          this.cd.markForCheck();

          this.messageService.add({
            title: 'Success',
            type: 'success',
            message: 'Clone statement successfully.',
          });

          // Publish focus new cloned statement so that the element won't filter it out
          this.editorService.publishFocus({ statement_id: this.statement_id, target: this.source });
        }),
    );
  }

  createAndClone(_data: CreateAndCloneStatementData) {
    // TODO(slatement)
    // const statement: Partial<Statement> = {
    //   ...data.statement,
    //   statement_set_id: this.element.statement_set_id,
    // };
    // let obs$;
    // if (this.editType === 'insertStatement' || this.editType === 'insertNotepadStatement') {
    //   // Insert statement before another statement, then clone it
    //   obs$ = this.templateService.insertStatement(statement, this.statement_id).pipe(
    //     take(1),
    //     map((action) => action.actions.statementCreateSuccessAction.statement),
    //   );
    // } else {
    //   // Create a new statement then clone it
    //   obs$ = this.statementEffect.create({ statement }).pipe(
    //     take(1),
    //     map((action) => action.actions.statementCreateSuccessAction.statement),
    //   );
    // }
    // this.subscription.add(
    //   obs$.subscribe((newStatement) => {
    //     this.statement_id = newStatement.id;
    //     if (this.editType === 'insertNotepadStatement' || this.editType === 'createNotepadStatement') {
    //       this.editType = 'editNotepadStatement';
    //     } else {
    //       this.editType = 'editStatement';
    //     }
    //     this.initSubscriptions();
    //     this.cd.detectChanges();
    //     this.clone();
    //   }),
    // );
  }

  copyToTemplate() {
    // TODO(slatement): passed the text, so this was never working properly with attributes
  }

  /**
   *
   * @param optionsOverride when passed `{ windowClass: 'statement-edit-modal' }` the modal layout stretches vertically.
   * @returns
   */
  static open(modalService: NgbModal, optionsOverride: NgbModalOptions = {}) {
    const modalRef = modalService.open(StatementEditModalComponent, {
      size: 'xl',
      centered: true,
      backdrop: 'static',
      scrollable: true,
      ...optionsOverride,
    });
    const componentInstance: StatementEditModalComponent = modalRef.componentInstance;
    return { componentInstance, modalRef };
  }

  onMoveCompleted() {
    if (this.editType !== 'moveMultipleStatement') return;

    this.currentStatementIndex++;
    if (this.currentStatementIndex >= this.selectedStatements.length) {
      this.editorService.toggleEditMode(false);
      this.activeModal.dismiss();
      return;
    }
  }

  openToInsertMode() {
    this.activeModal.close({
      editType: 'insertStatement',
      firstStatement_id: this.statement.id,
      selectedStatements: this.selectedStatements,
    });
  }

  openToEditMode() {
    this.activeModal.close({
      editType: 'editStatement',
    });
  }
}
