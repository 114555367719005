<div *ngIf="viewMode === 'edit'" class="d-flex bg-danger rounded p-2 my-2 align-items-center">
  <h4 class="text-white mb-0"><strong>Caution!</strong> You are editing this referrer's record across all reports.</h4>
  <button class="btn btn-light ms-auto" (click)="cancel()" rrTooltip="REGISTRATION_CANCEL_EDIT">Cancel</button>
</div>
<div permission [permission-only]="['view']">
  <div class="text-success d-flex" *ngIf="!referrer && similarReferrers.length > 0">
    <span>Similar referrers found, click to see suggestions</span>
    <button
      class="btn btn-info ms-auto"
      [ngbPopover]="suggestionContent"
      #suggestionPopover="ngbPopover"
      popoverClass="suggestion-popover"
      placement="bottom"
      container="body"
    >
      Show suggestions
    </button>
  </div>
  <ng-template #suggestionContent>
    <div *ngIf="!!similarReferrers?.length">
      <h5>
        <strong>Referrer Suggestion</strong>
      </h5>
      <h6>
        <strong class="text-danger">Selecting a suggestion will overwrite current referrer</strong>
      </h6>
      <h6>
        <strong class="text-danger">To find previous referrer, remove and search again</strong>
      </h6>
      <div class="list-group border border-danger">
        <div
          [ngbPopover]="popoverReferrer"
          popoverTitle="Referrer Details"
          [openDelay]="300"
          popoverClass="referrer-popover"
          placement="bottom-left"
          triggers="mouseenter:mouseleave"
          role="button"
          *ngFor="let ref of similarReferrers"
          class="list-group-item list-group-item-action"
          (click)="emitReferrer(ref)"
        >
          <div>
            <strong><rr-referrer-name [referrerId]="ref.id" [strong]="false"></rr-referrer-name></strong>
          </div>
          <div *ngIf="ref.service">Service: {{ ref.service }}</div>
          <div *ngIf="ref.medicare_provider_number">Provider No: {{ ref.medicare_provider_number }}</div>
          <div *ngIf="ref.phone_work || ref.phone_mobile || ref.fax || ref.email">
            Contact: {{ ref.phone_work || ref.phone_mobile || ref.fax || ref.email }}
          </div>
          <div *ngIf="ref.city || ref.zip">Suburb: {{ ref.city ?? '' }} {{ ref.zip ?? '' }}</div>

          <ng-template #popoverReferrer>
            <rr-referrer-popover [referrer]="ref"></rr-referrer-popover>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
  <form [formGroup]="form" class="mt-2 bootstrap-validate">
    <div class="grid">
      <div class="form-group mb-2 g-col-2">
        <label class="control-label">Title</label>
        <rr-title-case-input formControlName="title"></rr-title-case-input>
      </div>
      <div class="form-group mb-2 g-col-5">
        <label class="control-label">Given Name <span class="text-danger fa fa-asterisk fa-xs"></span></label>
        <rr-title-case-input formControlName="physician_given_name"></rr-title-case-input>

        <span class="invalid-feedback" *ngIf="form.controls.physician_given_name.errors?.required">
          Given name is required
        </span>
        <span class="invalid-feedback" *ngIf="form.controls.physician_given_name.errors?.serverError">
          {{ form.controls.physician_given_name.errors?.serverError }}
        </span>
      </div>
      <div class="form-group mb-2 g-col-5">
        <label class="control-label">Family Name <span class="text-danger fa fa-asterisk fa-xs"></span></label>
        <rr-title-case-input formControlName="physician_family_name"> </rr-title-case-input>
        <span class="invalid-feedback" *ngIf="form.controls.physician_family_name.errors?.required">
          Family name is required
        </span>
        <span class="invalid-feedback" *ngIf="form.controls.physician_family_name.errors?.serverError">
          {{ form.controls.physician_family_name.errors?.serverError }}
        </span>
      </div>
    </div>

    <div class="grid">
      <div class="form-group mb-2 g-col-6">
        <label class="control-label"
          >Provider Number (Admission ID) <span class="text-danger fa fa-asterisk fa-xs"></span
        ></label>
        <input maxlength="8" formControlName="medicare_provider_number" class="form-control" />
        <span class="invalid-feedback" *ngIf="form.controls.medicare_provider_number.errors?.required">
          Medicare provider number is required
        </span>
        <span
          class="invalid-feedback"
          *ngIf="
            !form.controls.medicare_provider_number.errors?.serverError &&
            form.controls.medicare_provider_number.invalid
          "
        >
          Medicare number requires 10 digits
        </span>
        <span class="invalid-feedback" *ngIf="form.controls.medicare_provider_number.errors?.serverError">
          {{ form.controls.medicare_provider_number.errors?.serverError }}
        </span>
      </div>
      <div class="form-group mb-2 g-col-6">
        <label class="control-label">Preferred Contact Method</label>

        <div class="d-flex flex-wrap">
          <div class="form-check ms-0">
            <input
              id="sms"
              class="form-check-input"
              type="checkbox"
              value="SMS"
              (change)="onPreferredContactCheck('SMS', $event)"
              [checked]="form.controls.preferred_contacts.value | includes: 'SMS'"
            />
            <label for="sms" class="form-check-label"> SMS </label>
          </div>

          <div class="form-check ms-2">
            <input
              id="email"
              class="form-check-input"
              type="checkbox"
              value="EMAIL"
              (change)="onPreferredContactCheck('EMAIL', $event)"
              [checked]="form.controls.preferred_contacts.value | includes: 'EMAIL'"
            />
            <label for="email" class="form-check-label"> Email </label>
          </div>

          <div class="form-check ms-3">
            <input
              id="fax"
              class="form-check-input"
              type="checkbox"
              value="FAX"
              (change)="onPreferredContactCheck('FAX', $event)"
              [checked]="form.controls.preferred_contacts.value | includes: 'FAX'"
            />
            <label for="fax" class="form-check-label"> Fax </label>
          </div>

          <div class="form-check ms-3">
            <input
              id="md"
              class="form-check-input"
              type="checkbox"
              value="MEDICAL_OBJECTS"
              (change)="onPreferredContactCheck('MEDICAL_OBJECTS', $event)"
              [checked]="form.controls.preferred_contacts.value | includes: 'MEDICAL_OBJECTS'"
            />
            <label for="md" class="form-check-label"> Medical Objects </label>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="form-group mb-2 g-col-6">
        <label class="control-label">
          Referrer Type
          <i class="text-danger fa fa-asterisk fa-xs me-1"></i>
        </label>
        <select class="form-select" formControlName="referrer_type">
          <option [value]="null" selected disabled>Please select</option>
          <option *ngFor="let type of referrerTypes" [value]="type.value">
            {{ type.name }}
          </option>
        </select>
        <span class="invalid-feedback" *ngIf="form.controls.referrer_type.errors?.required">
          Referrer type is required
        </span>
      </div>

      <div class="d-flex mb-2 align-items-end g-col-6">
        <rr-software-preferences *ngIf="referrer" [referrer]="referrer"></rr-software-preferences>
      </div>
    </div>

    <div class="grid">
      <div class="form-group mb-2 g-col-3 pe-0 m-0">
        <label class="control-label">Mobile Phone</label>
        <rr-phone-fax-input
          [invalid]="form.errors?.phoneOrEmailRequired"
          [formControl]="form.controls.phone_mobile"
        ></rr-phone-fax-input>

        <span class="invalid-feedback" *ngIf="form.controls.phone_mobile.errors?.pattern">
          Please enter 10 digit phone number
        </span>
      </div>
      <div class="form-group mb-2 g-col-3 ps-2 pe-0 m-0">
        <label class="control-label">Work Phone</label>
        <rr-phone-fax-input
          [invalid]="form.errors?.phoneOrEmailRequired"
          [formControl]="form.controls.phone_work"
        ></rr-phone-fax-input>
        <span class="invalid-feedback" *ngIf="form.controls.phone_work.errors?.pattern">
          Please enter 10 digit phone number
        </span>
      </div>
      <div class="form-group mb-2 g-col-3 ps-2 pe-0 m-0">
        <label class="control-label">Fax</label>
        <rr-phone-fax-input
          [invalid]="form.errors?.phoneOrEmailRequired"
          [formControl]="form.controls.fax"
        ></rr-phone-fax-input>
        <span class="invalid-feedback" *ngIf="form.controls.fax.errors?.pattern">
          Please enter 10 digit fax number
        </span>
      </div>
      <div class="form-group mb-2 g-col-3 ps-2 m-0">
        <label class="control-label">
          Email
          <div class="text-info ms-2 align-items-center d-inline-flex">
            <i class="text-info fa fa-asterisk fa-xs me-1"></i>
            <small>One contact required</small>
          </div>
        </label>
        <input formControlName="email" class="form-control" />
        <span class="invalid-feedback" *ngIf="form.controls.email.errors?.pattern"> Email is invalid </span>
      </div>
    </div>
    <div
      class="text-danger small mt-1"
      *ngIf="
        (form.controls.phone_mobile.touched ||
          form.controls.phone_work.touched ||
          form.controls.fax.touched ||
          form.controls.email.touched) &&
        form.errors?.phoneOrEmailRequired
      "
    >
      At least one phone or email has to be provided
    </div>

    <div class="mt-3">
      <div class="form-group mb-2 col">
        <label class="control-label">Medical Clinic Name</label>
        <input formControlName="service" class="form-control" />
      </div>

      <div class="form-group mb-2 col">
        <label class="control-label">Address</label>
        <input formControlName="address" class="form-control" />
      </div>
    </div>

    <div class="grid">
      <div class="form-group mb-2 g-col-6">
        <label class="control-label">City</label>
        <input formControlName="city" class="form-control" />
      </div>
      <div class="form-group mb-2 g-col-4">
        <label class="control-label">State</label>
        <input formControlName="state" class="form-control" />
      </div>
      <div class="form-group mb-2 g-col-2">
        <label class="control-label">Postcode</label>
        <input formControlName="zip" class="form-control" />

        <span class="invalid-feedback" *ngIf="form.controls.zip.errors?.pattern"> Postcode is invalid </span>
      </div>
    </div>

    <div class="form-group mb-2">
      <label class="control-label">Collater Notes</label>
      <textarea rows="4" formControlName="collater_notes" class="form-control"> </textarea>
    </div>

    <div class="d-flex justify-content-center">
      <button class="btn btn-primary" (click)="submitReferrer()" [disabled]="!form.dirty">
        <ng-container *ngIf="viewMode === 'create'; else updateReferrer">Create New Referrer</ng-container>
        <ng-template #updateReferrer>Update Referrer</ng-template>
      </button>

      <button class="btn btn-light ms-2" (click)="cancel()">Cancel</button>
    </div>
  </form>
</div>
