<form [formGroup]="searchForm">
  <div class="form-group mb-2 input-group no-shrink">
    <div class="input-group-text">
      <span class="fa fa-search"></span>
    </div>
    <input
      class="form-control col-sm-9"
      type="text"
      [formControl]="searchText"
      placeholder="Search by referrer name or provider number"
      [ngbTypeahead]="suggestReferrers"
      [resultTemplate]="rt"
      (focus)="focus$.next(searchText.value)"
      (selectItem)="selectReferrer($event)"
    />
  </div>

  <ng-template #rt let-r="result" let-t="term">
    <div *ngIf="r.physician_given_name || r.physician_family_name">
      <ngb-highlight
        class="me-1"
        [result]="r.physician_given_name + ' ' + r.physician_family_name"
        [term]="t"
      ></ngb-highlight>
    </div>
    <div *ngIf="r.service">
      <span>Service: </span>
      <ngb-highlight class="me-1" [result]="r.service" [term]="t"></ngb-highlight>
    </div>
    <div *ngIf="r.medicare_provider_number">
      <span>Provider No: </span>
      <ngb-highlight [result]="r.medicare_provider_number" [term]="t"></ngb-highlight>
    </div>
    <div *ngIf="r.phone_work || r.phone_mobile || r.fax || r.email">
      <span>Phone/Email: </span>
      <ngb-highlight [result]="r.phone_work || r.phone_mobile || r.fax || r.email || 'N/A'" [term]="t"></ngb-highlight>
    </div>
    <div *ngIf="r.city">
      <span>Suburb: </span>
      <ngb-highlight [result]="r.city ?? '' + r.zip ?? ''" [term]="t"></ngb-highlight>
    </div>
  </ng-template>
</form>
