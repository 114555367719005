import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BindObservable, filterDefined } from 'app/app.utils';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { fromBookingCode } from 'app/store/booking-code';
import { fromSite } from 'app/store/site';
import { fromUser, UserEffect } from 'app/store/user/user';
import { distinctUntilChanged, map, Observable, Subscription, switchMap } from 'rxjs';

import { BookingCodeHeadlineComponent } from '../../booking-code-headline/booking-code-headline.component';

/**
 * Used in both Booking and Registration
 */
@Component({
  selector: 'rr-booking-metadata',
  templateUrl: './booking-metadata.component.html',
  styleUrls: ['./booking-metadata.component.css'],
  standalone: true,
  imports: [CommonModule, BookingCodeHeadlineComponent, StoreSelectPipe],
})
export class BookingMetadataComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() booking: Partial<RR.Booking>;
  booking$: Observable<Partial<RR.Booking>>;
  @Input() parent?: 'REVIEW' | undefined;
  @Input() selectedDate: Date | null;
  @Input() displayBookingNotes = false;

  subscription = new Subscription();

  selectBookingCode = fromBookingCode.selectBookingCode;
  selectSite = fromSite.selectSite;
  selectUser = fromUser.selectUser;

  constructor(private userEffect: UserEffect) {}

  ngOnInit(): void {
    const userId$ = this.booking$.pipe(
      map((booking) => booking.rr_user_id),
      distinctUntilChanged(),
      filterDefined(),
    );

    this.subscription.add(userId$.pipe(switchMap((userId) => this.userEffect.findById(userId))).subscribe());
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
