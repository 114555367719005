<button
  class="btn text-nowrap w-100 {{ btnClass }}"
  [ngClass]="{
    'w-100': expand,
    'btn-sm': small,
  }"
  (click)="openNotesModal()"
  [ngbTooltip]="noteContent"
  [rrAutoFocus]="autoFocus"
  [openDelay]="350"
  tooltipClass="admin-notes"
  rrTooltip="OPEN_NOTES"
  rrTrack
>
  Notes (N)
  <span *ngIf="hasNotes">
    <span *ngIf="hasNotes.urgentNotes" class="badge bg-danger">{{ hasNotes.urgentNotes }}</span>
    <span *ngIf="hasNotes.todos" class="badge bg-info">{{ hasNotes.todos }}</span>
    <span *ngIf="hasNotes.studyNotes" class="badge bg-warning">{{ hasNotes.studyNotes }}</span>
    <span *ngIf="hasNotes.voiceNotes" class="badge bg-success">{{ hasNotes.voiceNotes }}</span>
    <span *ngIf="hasNotes.minorNotes" class="badge bg-secondary">{{ hasNotes.minorNotes }}</span>
    <span *ngIf="hasNotes.bookingNotes" class="badge bg-black">{{ hasNotes.bookingNotes }}</span>
  </span>
</button>
<div class="mt-2" *ngIf="inlineNotesPreview">
  <div class="truncate-notes" *ngFor="let todo of todos$ | async">
    <i class="fa fa-notes-medical me-1" [ngClass]="todo.resolved ? 'text-success' : 'text-info'"></i>
    <rr-note-text
      [ngClass]="{ 'font-italic': !todo.todo }"
      style="white-space: pre-line"
      [note]="todo.todo || 'Noted'"
    ></rr-note-text>
  </div>
  <div class="truncate-notes" *ngFor="let note of urgentNotes$ | async">
    <i class="fas fa-clipboard text-danger me-1"></i>
    <rr-note-text style="white-space: pre-line" [note]="note.urgent_notes"></rr-note-text>
  </div>
  <div class="truncate-notes" *ngIf="report.study_notes">
    <i class="fas fa-sticky-note text-warning me-1"></i>
    <rr-note-text style="white-space: pre-line" [note]="report.study_notes"></rr-note-text>
  </div>
  <div class="truncate-notes" *ngIf="report.patient_note">
    <i class="fas fa-sticky-note text-primary me-1"></i>
    <rr-note-text style="white-space: pre-line" [note]="report.patient_note"></rr-note-text>
  </div>
</div>

<ng-template #noteContent>
  <ng-container *ngIf="todosSlice$ | async as todos">
    <ng-container *ngIf="todos.length > 0; else noNotes">
      <div class="d-flex flex-column">
        <div class="mb-2" *ngFor="let todo of todos; let i = index">
          <i class="fa fa-notes-medical me-1" [ngClass]="todo.resolved ? 'text-success' : 'text-info'"></i>
          <rr-note-text
            [ngClass]="{ 'font-italic': !todo.todo }"
            style="white-space: pre-line"
            [note]="todo.todo || 'Noted'"
          ></rr-note-text>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #noNotes>
    <div>No admin notes</div>
  </ng-template>
</ng-template>
