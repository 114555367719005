<div class="modal-header sticky-top bg-white">
  <h5 class="modal-title">Distribute Report (R)</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body p-1">
  <div class="alert alert-warning mt-2" *ngIf="recentlyEditedWarning$ | async">
    <div class="d-flex">
      <div class="fa fa-lg fa-exclamation-triangle text-warning mt-1"></div>
      <div class="ms-2">
        <strong>This study was recently edited, do not release without confirmation from the radiologist.</strong>
      </div>
    </div>
  </div>

  <div class="alert alert-warning mt-2" *ngIf="hasMismatchBetweenStudyDates$ | async">
    <div class="d-flex">
      <div class="fa fa-lg fa-exclamation-triangle text-warning mt-1"></div>
      <div class="ms-2">
        <strong>There is a mismatch between the study date on radreport and voyager.</strong>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row gx-4">
      <div class="d-flex flex-column max-height col-4 border-end mb-2">
        <div class="mb-1" *ngIf="patient">
          <rr-patient-info [patientId]="patient.id" [editPatient]="true"></rr-patient-info>
        </div>
        <div class="d-flex flex-wrap align-items-center gap-1">
          <button
            *ngIf="!(parent === 'WORKLIST')"
            class="btn btn-sm btn-info"
            (click)="openRegistrationModal()"
            rrTooltip="OPEN_REGISTRATION_MODAL"
            role="button"
          >
            <span>Registration (Q)</span>
          </button>
          <ng-container *ngIf="report$ | async as report">
            <rr-document-attachment-button
              [bookingId]="report.booking_id"
              [size]="'sm'"
              [btnClassName]="'btn-info'"
              [btnSuccessClass]="'btn-success'"
              [dismissActiveModal]="false"
            ></rr-document-attachment-button>
          </ng-container>
          <rr-report-notes-button [report_id]="report.id"></rr-report-notes-button>
          <button
            role="button"
            class="btn btn-sm btn-info"
            (click)="openFavouriteModal()"
            rrTooltip="NAME_FAVOURITE"
            [class.btn-warning]="favourite"
          >
            {{ favourite ? 'Update Favourites (A)' : 'Add Favourites (A)' }}
          </button>
          <button
            role="button"
            class="btn btn-sm btn-info"
            (click)="togglePrefill()"
            rrTrack
            rrTooltip="SIDE_PREFILL_TOGGLE"
          >
            Back to Prefill (B)
          </button>
          <button
            role="button"
            class="btn btn-sm btn-info"
            (click)="openSendFeedback()"
            rrTrack
            rrTooltip="SEND_FEEDBACK"
          >
            <span>Give Feedback (G) </span>
          </button>
          <button
            *ngIf="patient"
            role="button"
            class="btn btn-sm btn-warning"
            [ngbPopover]="popoverContent"
            #popover="ngbPopover"
            placement="right"
            container="body"
            popoverClass="contact-info-popover"
          >
            Contact Info
          </button>
          <!-- doctor-collowup -->
          <button
            rrTooltip="ADD_DOCTOR_FOLLOWUP"
            type="button"
            class="btn btn-sm btn-info"
            *ngIf="!hasFollowup"
            (click)="openFollowupModal()"
          >
            <span>Follow-up</span>
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            (click)="deleteLink()"
            *ngIf="hasFollowup"
            rrTooltip="DELETE_DOCTOR_FOLLOWUP"
          >
            <span>Delete Follow-up</span>
          </button>
          <ng-container *ngIf="hasSonographerSignature">
            <button
              *ngIf="!showTechnicianSignature"
              class="btn btn-sm btn-info"
              (click)="toggleShowTechnicianSignature()"
              rrTooltip="UNEXCLUDE_TECHNICIAN_SIGNATURE"
            >
              Show Technician Signature
            </button>
            <button
              *ngIf="showTechnicianSignature"
              class="btn btn-sm btn-danger"
              (click)="toggleShowTechnicianSignature()"
              rrTooltip="EXCLUDE_TECHNICIAN_SIGNATURE"
            >
              Delete Technician Signature
            </button>
          </ng-container>
          <rr-same-patient-reports
            *ngIf="activeTopic"
            [autoFocus]="sentToPatient"
            [reportId]="report.id"
            [topicId]="activeTopic.id"
          ></rr-same-patient-reports>
          <button rrTooltip="NEXT_REPORT_MODAL" (click)="openNextReportModal()" class="btn btn-outline-primary">
            Next Case
          </button>
        </div>

        <!-- Modal template -->
        <ng-template #modalTemplate let-modal>
          <div class="modal-header">
            <h5 class="modal-title">Recommend a Follow-up Appointment</h5>
            <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
          </div>
          <div class="modal-body">
            <rr-patient-info [patientId]="patient.id"></rr-patient-info>
            <div class="form-group mt-3">
              <label for="modalitySelect">Select Scan Type:</label>
              <select class="form-control" id="modalitySelect" name="modalitySelect" [formControl]="selectedScanType">
                <option [value]="'CT'">CT (CAT Scan)</option>
                <option [value]="'XR'">XR (X-Ray, Digital Radiography)</option>
                <option [value]="'US'">US (Ultrasound scan)</option>
                <option [value]="'MR'">MRI (Magnetic Resonance Imaging)</option>
              </select>
            </div>
            <div class="form-group mt-3">
              <label>Customisable Template (Click to Edit)</label>
              <textarea
                placeholder="Please enter a doctor followup message..."
                class="form-control textarea"
                [formControl]="doctorFollowUpText"
              ></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" (click)="createLink()" [disabled]="isLoading">
              <span *ngIf="!isLoading">Create followup</span>
              <span *ngIf="isLoading">Loading...</span>
            </button>
          </div>
        </ng-template>

        <ng-template #popoverContent>
          <div>
            <!-- Patient Info -->
            <ng-container *ngIf="patient">
              <h4 class="mb-0">Patient</h4>
              <div class="border bg-light p-1 rounded">
                <ng-container
                  *ngFor="
                    let contact of [
                      { label: 'mobile', value: patient.phone_mobile },
                      { label: 'work', value: patient.phone_work },
                      { label: 'home', value: patient.phone_home },
                      { label: 'email', value: patient.email },
                    ]
                  "
                >
                  <div *ngIf="contact.value" class="text-nowrap">
                    <strong>{{ contact.label }}:</strong>
                    <span>
                      {{ contact.value }}
                      <button
                        (click)="copy(contact.value)"
                        class="btn btn-sm p-0 border-none"
                        role="button"
                        title="Copy"
                      >
                        <span class="fa fa-copy"></span>
                      </button>
                    </span>
                  </div>
                </ng-container>
              </div>
            </ng-container>

            <!-- Referrer Info -->
            <ng-container *ngIf="referrer">
              <h4 class="mb-0 mt-2">Referrer</h4>
              <div class="border bg-light p-1 rounded">
                <div class="text-nowrap" *ngIf="referrer.contact_method_ids.length > 0">
                  <strong>Preferred contact method: </strong>
                  <rr-referrer-preferred-contacts [referrer_id]="referrer.id"></rr-referrer-preferred-contacts>
                </div>
                <ng-container
                  *ngFor="
                    let contact of [
                      { label: 'mobile', value: referrer.phone_mobile },
                      { label: 'work', value: referrer.phone_work },
                      { label: 'fax', value: referrer.fax },
                      { label: 'email', value: referrer.email },
                    ]
                  "
                >
                  <div *ngIf="contact.value" class="text-nowrap">
                    <strong>{{ contact.label }}: </strong>
                    <span>
                      {{ contact.value }}
                      <button
                        (click)="copy(contact.value)"
                        class="btn btn-sm p-0 border-none"
                        role="button"
                        title="Copy"
                      >
                        <span class="fa fa-copy fa-sm"></span>
                      </button>
                    </span>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </ng-template>

        <form class="bootstrap-validate" (ngSubmit)="onSubmit()" [formGroup]="infoForm">
          <div
            class="d-flex flex-column alert alert-warning mt-2"
            *ngIf="!(isReportReadyToSend() | async) && !skipWarning"
          >
            <h5>
              <span class="fa fa-lg fa-exclamation-triangle text-warning"></span>
              Warning:
            </h5>
            <span class="mb-1">
              Cannot send the report by Email or Fax because it has not been finalised in RadReport. Please enter Fax
              number or Email address in 'CC To' fields so that the report can be sent out when it is completed. If the
              report has already been verified in Voyager, or if you still want to send it by Email or Fax for some
              reasons, click this button to skip warning
            </span>
            <button class="btn btn-sm btn-outline-danger" rrAutoFocus (click)="skipWarningClicked()">
              Skip warning
            </button>
          </div>
          <div class="alert alert-secondary mt-1" *ngIf="(referrer$ | async)?.collater_notes as collaterNotes">
            <div class="d-flex flex-column">
              <h5>Collater Notes (N):</h5>
              <div class="ms-1" [title]="collaterNotes">
                {{ collaterNotes }}
              </div>
            </div>
          </div>
          <div class="alert alert-secondary pb-0 mt-1" *ngIf="ccFromNotes.length > 0">
            <div class="d-flex flex-column">
              <h5>CCs from Notes (N):</h5>
              <div class="d-flex flex-column">
                <ng-container *ngFor="let item of ccFromNotes">
                  <ul class="mb-1">
                    <li>{{ item }}</li>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="alert alert-info pb-0 mt-1" *ngIf="emails.length > 0">
            <div class="d-flex flex-column">
              <h5>CC Extracted Emails:</h5>
              <div class="d-flex flex-column">
                <ng-container *ngFor="let item of emails">
                  <ul class="mb-1">
                    <li>
                      <div class="d-flex align-items-center">
                        {{ item }}
                        <button
                          *ngIf="item"
                          (click)="selectEmail(item)"
                          class="btn btn-sm btn-outline-secondary py-0 ms-auto"
                        >
                          Select
                        </button>
                      </div>
                    </li>
                  </ul>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-template #rt let-r="result" let-t="term">
            <div *ngIf="r.fax_number">
              <ngb-highlight class="me-1" [result]="r.fax_number" [term]="t"></ngb-highlight>
            </div>
            <div *ngIf="r.email">
              <ngb-highlight class="me-1" [result]="r.email" [term]="t"></ngb-highlight>
            </div>
            <div *ngIf="r.number">
              <ngb-highlight [result]="r.number" [term]="t"></ngb-highlight>
            </div>
            <div *ngIf="r.type">
              <ngb-highlight [result]="r.type" [term]="t"></ngb-highlight>
            </div>
            <ngb-highlight class="me-1" [result]="r.referring_physician" [term]="t"></ngb-highlight>
            <ngb-highlight [result]="r.referring_service" [term]="t"></ngb-highlight>
          </ng-template>

          <div class="mt-2">
            <label class="control-label mb-0"><strong>SMS Secure Link</strong></label>
            <div class="input-group">
              <input
                type="tel"
                autocomplete="off"
                class="form-control form-control-sm"
                [ngbTypeahead]="suggestPhoneNumbers"
                [resultTemplate]="rt"
                formControlName="phone"
                #phone
                (click)="numberClick$.next(phone.value)"
                (focus)="numberFocus$.next(phone.value)"
                (selectItem)="onSelectTypeaheadPhone('phone', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.phone.value, 'SMS') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  rrTooltip="DISTRIBUTE_LINK_TO_IMAGE"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  (click)="openSendReport('phone')"
                  [disabled]="!(isReportReadyToSend() | async) && !skipWarning"
                >
                  <ng-container>
                    <span class="fa fa-sms"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>
              </ng-container>
            </div>
            <span
              class="text-danger error-text d-block"
              *ngIf="infoForm.controls['phone'].errors?.maxlength || infoForm.controls['phone'].errors?.minlength"
            >
              Must be a 10 digit number
            </span>
            <span class="text-danger error-text d-block" *ngIf="infoForm.controls['phone'].errors?.pattern">
              Must be numbers
            </span>
          </div>

          <div class="mt-2">
            <label class="control-label mb-0"><strong>CC To SMS</strong></label>
            <div class="input-group">
              <input
                type="tel"
                autocomplete="off"
                class="form-control form-control-sm"
                [ngbTypeahead]="suggestCCPhoneNumbers"
                [resultTemplate]="rt"
                formControlName="cc_to_sms"
                #ccToSMS
                (click)="ccNumberClick$.next(ccToSMS.value)"
                (focus)="ccNumberFocus$.next(ccToSMS.value)"
                (selectItem)="onSelectTypeaheadPhone('cc_to_sms', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.cc_to_sms.value, 'SMS') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  rrTooltip="DISTRIBUTE_LINK_TO_IMAGE"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  (click)="openSendReport('cc_to_sms')"
                  [disabled]="!(isReportReadyToSend() | async) && !skipWarning"
                >
                  <ng-container>
                    <span class="fa fa-sms"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>
              </ng-container>
            </div>
            <span
              class="text-danger error-text d-block"
              *ngIf="
                infoForm.controls['cc_to_sms'].errors?.maxlength || infoForm.controls['cc_to_sms'].errors?.minlength
              "
            >
              Must be a 10 digit number
            </span>
            <span class="text-danger error-text d-block" *ngIf="infoForm.controls['cc_to_sms'].errors?.pattern">
              Must be numbers
            </span>
          </div>

          <div class="mt-2">
            <label class="control-label mb-0"><strong>Fax</strong></label>
            <div class="input-group">
              <input
                type="text"
                class="form-control form-control-sm"
                [ngbTypeahead]="suggestFaxNumbers"
                [resultTemplate]="rt"
                formControlName="fax"
                #fax
                (click)="click$.next(fax.value)"
                (focus)="focus$.next(fax.value)"
                (selectItem)="onSelectTypeaheadFax('fax', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.fax.value, 'FAX') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  (click)="openSendReport('fax')"
                  rrTooltip="DISTRIBUTE_REPORT_TEXT_TO_FAX"
                  [disabled]="!(isReportReadyToSend() | async) && !skipWarning"
                  [rrAutoFocus]="!hasMedicalObject && infoForm.controls.fax.value"
                >
                  <ng-container>
                    <span class="fa fa-fax"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>
              </ng-container>
            </div>
          </div>

          <div class="mt-2">
            <label class="control-label mb-0"><strong>CC To Fax</strong></label>
            <div class="input-group">
              <input
                type="text"
                class="form-control form-control-sm"
                [ngbTypeahead]="suggestCCFaxNumbers"
                [resultTemplate]="rt"
                formControlName="cc_to_fax"
                #ccToFax
                (click)="ccClick$.next(ccToFax.value)"
                (focus)="ccFocus$.next(ccToFax.value)"
                (selectItem)="onSelectTypeaheadFax('cc_to_fax', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.cc_to_fax.value, 'FAX') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  (click)="openSendReport('cc_to_fax')"
                  *ngIf="isReportReadyToSend() | async"
                  rrTooltip="DISTRIBUTE_REPORT_TEXT_TO_FAX"
                >
                  <ng-container>
                    <span class="fa fa-fax"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>

                <button
                  role="button"
                  *ngIf="!(isReportReadyToSend() | async)"
                  class="btn btn-sm btn-outline-dark"
                  (click)="saveCCTo('cc_to_fax', 'FAX')"
                  rrTooltip="SAVE_CC_TO"
                >
                  <ng-container>
                    <span class="fa fa-save"></span>
                    Save
                  </ng-container>
                </button>
              </ng-container>
            </div>
          </div>
          <div class="mt-2">
            <label class="control-label mb-0"><strong>CC To Fax 2</strong></label>
            <div class="input-group">
              <input
                type="text"
                class="form-control form-control-sm"
                [ngbTypeahead]="suggestCCFaxNumbers2"
                [resultTemplate]="rt"
                formControlName="cc_to_fax2"
                #ccToFax2
                (click)="ccFaxClick2$.next(ccToFax2.value)"
                (focus)="ccFaxFocus2$.next(ccToFax2.value)"
                (selectItem)="onSelectTypeaheadFax('cc_to_fax2', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.cc_to_fax2.value, 'FAX') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  (click)="openSendReport('cc_to_fax2')"
                  *ngIf="isReportReadyToSend() | async"
                  rrTooltip="DISTRIBUTE_REPORT_TEXT_TO_FAX"
                >
                  <ng-container>
                    <span class="fa fa-fax"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>

                <button
                  role="button"
                  *ngIf="!(isReportReadyToSend() | async)"
                  class="btn btn-sm btn-outline-dark"
                  (click)="saveCCTo('cc_to_fax2', 'FAX')"
                  rrTooltip="SAVE_CC_TO"
                >
                  <ng-container>
                    <span class="fa fa-save"></span>
                    Save
                  </ng-container>
                </button>
              </ng-container>
            </div>
          </div>

          <div class="mt-3">
            <label class="control-label mb-0">
              <strong>Email Report Text/Secure Link</strong
              ><span
                class="fa fa-exclamation-triangle text-warning ms-2"
                ngbTooltip="The email suggestions may contain doctor's email/patient's email + doctor's info. Please double check the email before sending."
                container="body"
              ></span
            ></label>

            <div class="input-group">
              <input
                type="email"
                class="form-control form-control-sm"
                formControlName="email"
                [ngbTypeahead]="suggestEmails"
                [resultTemplate]="rt"
                #email
                (click)="emailClick$.next(email.value)"
                (focus)="emailFocus$.next(email.value)"
                (selectItem)="onSelectTypeaheadEmail('email', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.email.value, 'EMAIL') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  (click)="openSendReport('email')"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  [disabled]="!(isReportReadyToSend() | async) && !skipWarning"
                  rrTooltip="DISTRIBUTE_REPORT_TEXT_TO_EMAIL"
                >
                  <ng-container>
                    <span class="fa fa-envelope"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>
              </ng-container>
            </div>
            <div class="text-danger error-text" *ngIf="infoForm.controls['email'].errors?.pattern">
              Email is invalid
            </div>
          </div>

          <div class="mt-2">
            <label class="control-label mb-0">
              <strong>CC To Email</strong
              ><span
                class="fa fa-exclamation-triangle text-warning ms-2"
                ngbTooltip="The email suggestions may contain doctor's email/patient's email + doctor's info. Please double check the email before sending."
                container="body"
              ></span
            ></label>

            <div class="input-group">
              <input
                type="email"
                class="form-control form-control-sm"
                formControlName="cc_to_email"
                [ngbTypeahead]="suggestCCEmails"
                [resultTemplate]="rt"
                #ccToEmail
                (click)="ccEmailClick$.next(ccToEmail.value)"
                (focus)="ccEmailFocus$.next(ccToEmail.value)"
                (selectItem)="onSelectTypeaheadEmail('cc_to_email', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.cc_to_email.value, 'EMAIL') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  (click)="openSendReport('cc_to_email')"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  *ngIf="isReportReadyToSend() | async"
                  rrTooltip="DISTRIBUTE_REPORT_TEXT_TO_EMAIL"
                >
                  <ng-container>
                    <span class="fa fa-envelope"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>

                <button
                  role="button"
                  *ngIf="!(isReportReadyToSend() | async)"
                  class="btn btn-sm btn-outline-dark"
                  (click)="saveCCTo('cc_to_email', 'EMAIL')"
                  rrTooltip="SAVE_CC_TO"
                >
                  <ng-container>
                    <span class="fa fa-save"></span>
                    Save
                  </ng-container>
                </button>
              </ng-container>
            </div>
            <div class="text-danger error-text" *ngIf="infoForm.controls['cc_to_email'].errors?.pattern">
              Email is invalid
            </div>
          </div>

          <div class="mt-2">
            <label class="control-label mb-0">
              <strong>CC To Email 2</strong
              ><span
                class="fa fa-exclamation-triangle text-warning ms-2"
                ngbTooltip="The email suggestions may contain doctor's email/patient's email + doctor's info. Please double check the email before sending."
                container="body"
              ></span
            ></label>

            <div class="input-group">
              <input
                type="email"
                class="form-control form-control-sm"
                formControlName="cc_to_email2"
                [ngbTypeahead]="suggestCCEmails2"
                [resultTemplate]="rt"
                #ccToEmail2
                (click)="ccEmailClick2$.next(ccToEmail2.value)"
                (focus)="ccEmailFocus2$.next(ccToEmail2.value)"
                (selectItem)="onSelectTypeaheadEmail('cc_to_email2', $event)"
              />
              <ng-container *ngIf="checkSendStatus(infoForm.controls.cc_to_email2.value, 'EMAIL') as check">
                <button
                  role="button"
                  class="btn btn-sm"
                  (click)="openSendReport('cc_to_email2')"
                  [class.btn-outline-success]="check === 'RESOLVED'"
                  [class.btn-outline-dark]="check === 'EMPTY'"
                  [class.btn-outline-danger]="check === 'UNRESOLVED'"
                  *ngIf="isReportReadyToSend() | async"
                  rrTooltip="DISTRIBUTE_REPORT_TEXT_TO_EMAIL"
                >
                  <ng-container>
                    <span class="fa fa-envelope"></span>
                    Send
                    <span class="fas fa-check-circle" *ngIf="check === 'RESOLVED'"></span>
                    <span class="fas fa-exclamation-circle" *ngIf="check === 'UNRESOLVED'"></span>
                  </ng-container>
                </button>

                <button
                  role="button"
                  *ngIf="!(isReportReadyToSend() | async)"
                  class="btn btn-sm btn-outline-dark"
                  (click)="saveCCTo('cc_to_email2', 'EMAIL')"
                  rrTooltip="SAVE_CC_TO"
                >
                  <ng-container>
                    <span class="fa fa-save"></span>
                    Save
                  </ng-container>
                </button>
              </ng-container>
            </div>
            <div class="text-danger error-text" *ngIf="infoForm.controls['cc_to_email2'].errors?.pattern">
              Email is invalid
            </div>
          </div>

          <input hidden type="submit" />
        </form>
        <div class="d-flex mt-2 align-items-center">
          <button
            type="button"
            class="btn btn-outline-dark w-100 me-2"
            [class.mt-1]="!(parent === 'WORKLIST')"
            (click)="openSendReportMO()"
          >
            Medical Objects
          </button>
          <a
            class="ms-auto link-secondary text-nowrap"
            href="https://adelaidemri.mocloud.com.au/rest/html/explorer_online/index.html#!/app/document/send/text"
            target="_blank"
            title="Login as RDAVIES"
          >
            MO Explorer<span class="ms-1 small fa fa-external-link-alt"></span>
          </a>
        </div>

        <ng-container *ngIf="sendEvents.length > 0">
          <span class="mt-2"><strong>Send event log: </strong></span>
          <div>
            <rr-send-event-history [sendEvents]="sendEvents"></rr-send-event-history>
          </div>
        </ng-container>
      </div>
      <!--For sending Emails, Fax and SMS-->
      <div class="d-flex flex-column max-height col-8" *ngIf="!isSendingMO && type">
        <div class="d-flex bg-light p-1 rounded">
          <!-- Patient Info Section -->
          <div class="flex-fill p-1 bg-white rounded shadow-sm m-1" *ngIf="patient">
            <div class="mb-2">
              <span class="fw-bold">Patient: </span>
              <rr-patient-info [patientId]="patient.id"></rr-patient-info>
            </div>

            <div>
              <span class="fw-bold">Referrer: </span>
              <ng-container *ngIf="referrer; else noReferrer">
                <rr-referrer-name
                  [referrerId]="referrer.id"
                  [referrerType]="true"
                  [editReferrer]="true"
                ></rr-referrer-name>
              </ng-container>
              <ng-template #noReferrer>No Referrer</ng-template>
            </div>
          </div>

          <!-- Studies Section -->
          <div class="flex-fill p-1 bg-white rounded shadow-sm m-1" *ngIf="topics.length > 0">
            <div class="fw-bold mb-1">Report {{ topics.length > 1 ? 'Studies' : 'Study' }}:</div>
            <ul class="list-unstyled">
              <li *ngFor="let topic of topics">
                <ng-container *ngIf="getTemplate(topic.template_id) | async as template">
                  <i class="fa fa-book-medical"></i>
                  <strong> {{ template?.modality?.name }} - {{ template?.name }} </strong>
                </ng-container>
              </li>
            </ul>
          </div>

          <!-- Report Text Section -->
          <div class="flex-fill p-1 bg-white rounded shadow-sm m-1">
            <div class="fw-bold mb-1 text-nowrap">{{ type === 'SMS' ? 'SMS' : 'Report Text' }} Destination:</div>
            <strong>{{ sent_to }}</strong>
          </div>
        </div>

        <div *ngIf="isAddingFaxCustomMessage && type === 'EMAIL'">
          Email Subject:
          <textarea class="form-control mb-2" rows="3" rrAutoSize [formControl]="emailSubject"></textarea>
        </div>
        <div>
          <button
            *ngIf="!isAddingFaxCustomMessage && type === 'EMAIL'"
            class="btn btn-sm btn-info mb-2"
            (click)="toggleCustomMessageButton()"
          >
            + Add custom message
          </button>
        </div>
        <div class="d-flex align-items-center mb-2" *ngIf="type === 'EMAIL'">
          Email Content:
          <div class="form-check form-check-inline ms-2">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="emailContentText"
              (change)="onChangeEmailContentType('TEXT')"
              [(ngModel)]="emailContentType"
              value="TEXT"
            />
            <label class="form-check-label" for="emailContentText">Report text</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="radio"
              name="inlineRadioOptions"
              id="emailContentUrl"
              (change)="onChangeEmailContentType('URL')"
              [(ngModel)]="emailContentType"
              value="URL"
            />
            <label class="form-check-label" for="emailContentUrl">Report URL</label>
          </div>
        </div>
        <textarea
          *ngIf="isAddingFaxCustomMessage && type === 'FAX'"
          class="form-control mb-2"
          [formControl]="faxCustomMessage"
          rows="3"
          rrAutoSize
        ></textarea>
        <div>
          <button
            *ngIf="!isAddingFaxCustomMessage && type === 'FAX'"
            class="btn btn-sm btn-info mb-2"
            (click)="toggleCustomMessageButton()"
          >
            + Add custom message
          </button>
        </div>
        <textarea
          [readonly]="type === 'EMAIL' || type === 'FAX'"
          [rows]="type === 'SMS' ? 5 : 15"
          class="form-control modal-textarea"
          [(ngModel)]="text"
        ></textarea>

        <div class="mt-2 d-flex justify-content-between">
          <ng-container *ngIf="warnings$ | async as warnings">
            <div class="col-6 text-danger" *ngIf="warnings.length > 0">
              <span>
                Please check carefully {{ warnings.length > 1 ? 'these issues' : 'this issue' }} before sending
                {{ type }} to patient:
                <ul>
                  <li *ngFor="let warning of warnings">{{ warning }}</li>
                </ul>
              </span>
            </div>
          </ng-container>

          <div class="d-flex align-items-start">
            <button (click)="openProofRead()" class="btn btn-outline-secondary me-2" rrTooltip="PROOF_READ">
              Proof Read
            </button>
            <button
              *ngIf="isAddingFaxCustomMessage && type === 'FAX'"
              class="btn btn-secondary mb-2 me-1"
              (click)="toggleCustomMessageButton()"
            >
              Remove custom message
            </button>
            <button
              *ngIf="isAddingFaxCustomMessage && type === 'EMAIL'"
              class="btn btn-secondary mb-2 me-1"
              (click)="toggleCustomMessageButton()"
            >
              Remove custom message
            </button>
            <button
              #sendBtn
              class="btn btn-success"
              role="button"
              (click)="checkThenSend()"
              [disabled]="disabled"
              style="flex-grow: 0; flex-shrink: 0; flex-basis: auto"
            >
              Send
            </button>
          </div>
        </div>
      </div>
      <!--For sending MO-->
      <div class="col-8" *ngIf="isSendingMO">
        <rr-provider-lookup [report]="report" [referrer]="referrer"></rr-provider-lookup>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer bg-white d-flex">
  <div class="text-danger">
    <strong>Note</strong>: After entering phone number, email, or fax number, please click the send button, then confirm
    and wait for the flashed message to confirm the process is complete
  </div>
</div>
