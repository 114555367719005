import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { SocketService } from 'app/core/services/socket.service';
import { AppState } from 'app/store';
import { ProposedDataset } from 'app/store/dicom/dicom.service';
import { ImgsimParamsEffect } from 'app/store/imgsim-parameters/imgsim-parameters.effect';
import { fromImgSimParameter } from 'app/store/imgsim-parameters/imgsim-parameters.selector';
import {
  ImgSimJobStatus,
  ImgsimParamsHttpService,
  Parameter,
  ScheduledImgsimJobResponse,
} from 'app/store/imgsim-parameters/imgsim-parameters.service';
import { StatementChoiceEffect } from 'app/store/report/statement-choice';
import { fromCurrentTopic } from 'app/store/report/topic';
import { StatementEffect } from 'app/store/template/statement';
import { fromTemplate } from 'app/store/template/template';
import { combineLatest, finalize, map, Observable, startWith, Subscription, switchMap } from 'rxjs';

import { ReportHeadlineComponent } from '../../../shared/components/report-headline/report-headline.component';
import { StoreSelectPipe } from '../../../shared/pipes/store-select.pipe';
import { SharedModule } from '../../../shared/shared.module';
import { RecommendedStatementComponent } from '../statement-recommendation/recommended-statement/recommended-statement.component';

export type ProposedResponse = {
  title: string;
  datasets: ProposedDataset[];
  images: string[] | undefined;
  parameters?: Parameter[] | undefined;
};

@Component({
  selector: 'rr-imgsim-parameters-modal',
  templateUrl: './imgsim-parameters-modal.component.html',
  styleUrls: ['./imgsim-parameters-modal.component.css'],
  standalone: true,
  imports: [
    CdkDrag,
    CdkDragHandle,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    RecommendedStatementComponent,
    StoreSelectPipe,
    CommonModule,
    ReportHeadlineComponent,
  ],
})
export class ImgsimParametersModalComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  @ViewChildren(RecommendedStatementComponent) recommendedStatementComponents: RecommendedStatementComponent[];
  @ViewChild('parameterNameInput') parameterNameInput: ElementRef;
  topic$: Observable<RR.Topic>;
  topic: RR.Topic;
  subscription = new Subscription();
  loading = false;
  tableOpen = true;

  templateTypes: string[];
  activeResponse: Partial<ProposedResponse> | undefined = undefined;

  imgSimJobStatuses: ImgSimJobStatus[] = [];
  segmentedImagesAvailable = false;
  segmentedMessageButtons: { label: string; url: string }[];

  parameters$: Observable<Parameter[] | undefined>;
  isEditingParameterName: boolean = false;
  currentParameterId: number | undefined;

  isAddingTargetSeriesNumber = false;

  selectedStatementIds: number[] = [];

  selectTemplate = fromTemplate.selectTemplate;

  form = new FormGroup({
    hideIncompleteSentences: new FormControl(true, { nonNullable: true }),
    activeIndex: new FormControl(0, { nonNullable: true }),
    parameterName: new FormControl('', { nonNullable: true }),
    seriesNumber: new FormControl<number | null>(null, { nonNullable: true }),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private imgSimParamsEffect: ImgsimParamsEffect,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    private reportService: ReportService,
    private statementChoiceEffect: StatementChoiceEffect,
    private imgSimParamsHttpService: ImgsimParamsHttpService,
    private socketService: SocketService,
    private statementEffect: StatementEffect,
  ) {}

  ngOnInit() {
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());
    this.subscription.add(
      this.topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );

    if (this.report.accession_number) {
      this.getImgSimJobs();
      this.getSegmentedImages();
    }

    this.subscription.add(
      combineLatest([
        this.form.controls.hideIncompleteSentences.valueChanges.pipe(startWith(true)),
        this.form.controls.activeIndex.valueChanges.pipe(startWith(0)),
      ])
        .pipe(
          switchMap(([hideIncomplete, index]) => {
            return this.propose(hideIncomplete, index);
          }),
        )
        .subscribe(),
    );

    this.parameters$ = this.store.select(fromImgSimParameter.selectAll);
  }

  toggleTable() {
    this.tableOpen = !this.tableOpen;
  }

  propose(hideIncomplete: boolean, index: number): Observable<void> {
    this.loading = true;

    return this.imgSimParamsEffect.propose(this.topic.id).pipe(
      map((response) => {
        this.handleProposedResponse(response, hideIncomplete, index);
      }),
      finalize(() => {
        this.loading = false;
      }),
    );
  }

  handleProposedResponse(response: ProposedResponse[], hideIncomplete: boolean, index: number): void {
    const data = response.map(({ title, datasets, images }) => ({
      title,
      datasets,
      images,
    }));

    this.templateTypes = data.map((responseItem) => responseItem.title);
    this.activeResponse = hideIncomplete ? this.filterResponse(data)[index] : data[index];

    this.filterFavouriteStatements(this.activeResponse.datasets);
    this.cd.detectChanges();
  }

  filterResponse(response: Omit<ProposedResponse, 'parameters'>[]): Omit<ProposedResponse, 'parameters'>[] {
    return response.map((responseItem) => {
      return {
        title: responseItem.title,
        images: responseItem.images,
        datasets: responseItem.datasets.map((dataset) => ({
          ...dataset,
          proposed_statements: dataset.proposed_statements.filter((p) => p.include_sentence),
        })),
      };
    });
  }

  static open(modal: NgbModal, report: RR.Report) {
    const modalRef = modal.open(ImgsimParametersModalComponent, {
      size: 'lg',
      centered: true,
    });
    const componentInstance: ImgsimParametersModalComponent = modalRef.componentInstance;
    componentInstance.report = report;
    return modalRef;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  chooseAllProposed() {
    if (!this.activeResponse) return;
    const data = this.recommendedStatementComponents.map((component) => {
      // Reach into the component a pull out the element, rather than repeating the complex selector here.
      if (component.proposed === undefined) {
        throw new Error('Proposed is undefined');
      }
      return {
        statement_id: component.statement.id,
        element_id: component.element.id,
        text_objects: this.reportService.createTextObjectsFromProposed(component.proposed),
        region_id: null, // TODO: regions not supported
      };
    });

    this.subscription.add(this.statementChoiceEffect.createMany(this.topic.id, data).subscribe());
  }

  // TODO: Not working
  // removeAllProposed() {
  //   if (!this.activeResponse) return;

  //   const parameterisedStatementIds = this.activeResponse.datasets.flatMap((dataset) =>
  //     dataset.proposed_statements.map((proposed) => proposed.statement.id),
  //   );

  //   const statementIds$ = this.store.select(fromStatementChoice.selectAll).pipe(
  //     map((statements) => statements.map((statement) => statement.statement_id)),
  //     map((ids) => ids.filter((id) => parameterisedStatementIds.includes(id))),
  //   );

  //   this.subscription.add(
  //     statementIds$.subscribe((ids) => {
  //       this.statementChoiceEffect.deleteMany(ids);
  //     }),
  //   );
  // }

  getImgSimJobs() {
    if (!this.report.accession_number) return;
    this.subscription.add(
      this.imgSimParamsHttpService.getImgSimJobStatus(this.report.accession_number).subscribe((response) => {
        this.imgSimJobStatuses = response;
      }),
    );
  }

  getSegmentedImages() {
    if (!this.report.accession_number) return;
    this.subscription.add(
      this.imgSimParamsHttpService.getSegmentedImages(this.report.accession_number).subscribe((response) => {
        this.segmentedImagesAvailable = response.available;
        if (this.segmentedImagesAvailable) {
          this.segmentedMessageButtons = this.getSegmentedImageButtons();
        }
      }),
    );
  }

  checkLinkValidity(path: string) {
    const baseUrl = `https://radreport.adelaidemri.com`;
    const apiUrl = `/api/imgsim/${path}/${this.report.accession_number}&1`;
    const http = new XMLHttpRequest();
    http.open('HEAD', `${baseUrl}${apiUrl}`, false);
    http.send();
    // only return true if the status is 200
    return http.status === 200 ? apiUrl : null;
  }
  getSegmentedImageButtons() {
    const buttons = [];
    let segPath = '';
    let apiUrl;
    switch (this.topic.template_id) {
      case 12:
        segPath = 'liverct_lesion_slider';
        apiUrl = this.checkLinkValidity(segPath);
        if (apiUrl) {
          buttons.push({
            label: 'Liver lesion detection',
            url: apiUrl,
          });
        }
        segPath = 'liverct_lesion_ip_slider';
        apiUrl = this.checkLinkValidity(segPath);
        if (apiUrl) {
          buttons.push({
            label: 'Liver lesion detection (IP)',
            url: apiUrl,
          });
        }
        break;
      case 14:
        segPath = 'brainct_calcium_slider';
        apiUrl = this.checkLinkValidity(segPath);
        if (apiUrl) {
          buttons.push({
            label: 'Calcium Detection',
            url: apiUrl,
          });
        }
        break;
      case 18:
        segPath = 'heart_calciumscore_slider';
        apiUrl = this.checkLinkValidity(segPath);
        if (apiUrl) {
          buttons.push({
            label: 'Calcium Score',
            url: apiUrl,
          });
        }
        segPath = 'lungct_lesion_slider';
        apiUrl = this.checkLinkValidity(segPath);
        if (apiUrl) {
          buttons.push({
            label: 'Lung lesion detection',
            url: apiUrl,
          });
        }
        break;
      case 36:
        segPath = 'brainmri_slider';
        apiUrl = this.checkLinkValidity(segPath);
        if (apiUrl) {
          buttons.push({
            label: 'Brain lesion detection',
            url: apiUrl,
          });
        }
        break;
      default:
        break;
    }
    return buttons;
  }
  openSlider(url: string) {
    // eslint-disable-next-line no-restricted-properties
    window.open(url, '_blank', 'width=1920,height=1080');
  }

  openPercentileGraph(param__id: number) {
    const url = new URL(
      `/api/ml/topic/${this.topic.id}/parameter/${param__id}/graph_percentile_bands`,
      location.origin,
    );
    // eslint-disable-next-line no-restricted-properties
    window.open(url, '_blank', 'width=1920,height=1080');
  }

  editParameterName(parameterId: number, parameterName: string) {
    this.currentParameterId = parameterId;
    this.isEditingParameterName = true;
    this.form.controls.parameterName.setValue(parameterName);
    requestAnimationFrame(() => {
      this.parameterNameInput.nativeElement.focus();
    });
  }

  cancelEditParameterName() {
    this.isEditingParameterName = false;
    this.currentParameterId = undefined;
    this.form.controls.parameterName.reset();
  }

  updateParameterName(parameterId: number) {
    if (!this.form.controls.parameterName.value) return;
    const data = { name: this.form.controls.parameterName.value };
    this.subscription.add(
      this.imgSimParamsEffect.updateImgsimParameterName(parameterId, data).subscribe(() => {
        this.cancelEditParameterName();
      }),
    );
  }

  toggleAddSeriesNumber() {
    this.isAddingTargetSeriesNumber = !this.isAddingTargetSeriesNumber;
  }

  /**
   * Register to a WebSocket and send push notifications
   */
  registerImgsimTaskToWebSocket(taskId: string) {
    this.socketService.notifyCeleryResult(taskId);
  }

  rerunSegmentation() {
    if (!this.report.accession_number) return;

    this.subscription.add(
      this.imgSimParamsHttpService
        .rerunImgSegmentationJob(this.report.accession_number, this.topic.template_id)
        .subscribe({
          next: (response: ScheduledImgsimJobResponse) => {
            this.messageService.add({
              title: 'Job submitted',
              message: `Image similarity job for segmentation was submitted, you will be notified when it is done. Please do not close this tab to receive a notification.`,
              type: 'success',
            });
            this.registerImgsimTaskToWebSocket(response.task_id);
          },
          error: (error: unknown) => {
            this.messageService.httpErrorMessage(error);
          },
        }),
    );
  }

  rerunTemplate() {
    if (!this.report.accession_number) return;

    const seriesNumber = this.form.controls.seriesNumber.value;

    this.subscription.add(
      this.imgSimParamsHttpService
        .rerunImgFeaturesJob(this.report.accession_number, this.topic.template_id, seriesNumber)
        .subscribe({
          next: (response: ScheduledImgsimJobResponse) => {
            this.toggleAddSeriesNumber();
            this.form.controls.seriesNumber.reset();
            this.messageService.add({
              title: 'Job submitted',
              message: `Image similarity job for template ${this.topic.template_id} was submitted, you will be notified when it is done. Please do not close this tab to receive a notification.`,
              type: 'success',
            });
            this.registerImgsimTaskToWebSocket(response.task_id);
          },
          error: (error: unknown) => {
            this.messageService.httpErrorMessage(error);
          },
        }),
    );
  }

  filterFavouriteStatements(proposedStatements: ProposedDataset[] | undefined) {
    // Load all favourite statements
    if (proposedStatements && proposedStatements.length > 0) {
      proposedStatements.forEach((statements) => {
        const favouriteStatements = statements.proposed_statements.filter(
          (proposed) => proposed.statement.imgsim_favourite,
        );

        favouriteStatements.forEach((favourite) => {
          this.selectedStatementIds.push(favourite.statement.id);
        });
      });
    }
  }

  onChangeFavouriteStatement(statementId: number, event: Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    if (isChecked) {
      if (!this.selectedStatementIds.includes(statementId)) {
        this.subscription.add(this.statementEffect.update(statementId, { imgsim_favourite: true }).subscribe());
      }
    } else {
      this.subscription.add(this.statementEffect.update(statementId, { imgsim_favourite: false }).subscribe());
    }
  }

  refreshProposedStatements() {
    this.selectedStatementIds = [];
    const hideIncomplete = this.form.controls.hideIncompleteSentences.value;
    const index = this.form.controls.activeIndex.value;
    this.subscription.add(this.propose(hideIncomplete, index).subscribe());
  }
}
