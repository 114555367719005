import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  NgbActiveModal,
  NgbDropdown,
  NgbDropdownButtonItem,
  NgbDropdownItem,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbModal,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { HotkeysService } from 'app/core/services/hotkeys.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { ESStatementSearchResponse, TemplateService } from 'app/core/services/template.service';
import { VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { ReportHeadlineComponent } from 'app/shared/components/report-headline/report-headline.component';
import { ReportNotesButtonComponent } from 'app/shared/components/report-notes-button/report-notes-button.component';
import { VoiceRecognitionTextComponent } from 'app/shared/components/voice-recognition/voice-recognition-text/voice-recognition-text.component';
import { VoiceRecognitionComponent } from 'app/shared/components/voice-recognition/voice-recognition/voice-recognition.component';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { AppState } from 'app/store';
import { KeywordAbbreviationEffect } from 'app/store/keyword-abbr';
import { fromTag, TagActions, TagEffect } from 'app/store/prefill/tag';
import { TagChoiceEffect } from 'app/store/prefill/tag-choice';
import { fromCurrentReport } from 'app/store/report/report';
import { fromSession } from 'app/store/session';
import { fromCurrentTemplate } from 'app/store/template/template';
import { fromUserSetting } from 'app/store/user/user-setting';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, skipWhile, switchMap, take, tap } from 'rxjs/operators';

import { AutoSelectGroupsButtonComponent } from '../../../../shared/components/auto-select-groups-button/auto-select-groups-button.component';
import { PrefillService } from '../prefill.service';
import { KeywordSearchComponent } from './keyword-search/keyword-search.component';
import { TagListComponent } from './tag-list/tag-list.component';
import { TagStructureComponent } from './tag-structure/tag-structure.component';
import { TagComponent } from './tag/tag.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TagStructureComponent,
    TagListComponent,
    VoiceRecognitionComponent,
    VoiceRecognitionTextComponent,
    KeywordSearchComponent,
    TagComponent,
    ReportNotesButtonComponent,
    VoiceDirective,
    ReportHeadlineComponent,
    AutoSelectGroupsButtonComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    NgbDropdownButtonItem,
    NgbDropdownItem,
  ],
  templateUrl: './prefill-tag-search-modal.component.html',
  styleUrls: ['./prefill-tag-search-modal.component.scss'],
})
export class PrefillTagSearchModalComponent implements OnDestroy, OnInit {
  // The list of all the tags being suggested. This is a view into the ngrx
  // store.
  tagSuggested$: Observable<RR.TagSuggestion[]>;
  // The structure of the subsections and regions as defined within the
  // template.
  structure$: Observable<RR.StructuredTagSubsection[]>;

  // Loading status for suggested and structure tags
  suggestedLoading$: Observable<boolean>;
  structureLoading$: Observable<boolean>;

  searchText$: Observable<string>;

  // The topic which we are working on
  // TODO: Get this information from the RouterStore
  topicId: number;
  // The current report we are in, allowing us to get patient and accession
  // information.
  report$: Observable<RR.Report | undefined>;

  templateId$: Observable<number | undefined>;
  // Form elements that we interact with to determine the values to display.

  isAdmin = false;
  subscription: Subscription = new Subscription();

  globalSearchTags: ESStatementSearchResponse;
  globalTagSearchSection: RR.TagSearchSection = 'findings';
  activeSection$ = new BehaviorSubject<RR.TagSearchSection>(this.globalTagSearchSection);
  loadingGlobalSearch$ = new BehaviorSubject<boolean>(false);
  autoUpdate$: Observable<boolean>;
  topicTags: RR.Divider[] = [];

  isMLSuggested$: Observable<boolean>;
  voyagerNoteTags: string[] = [];
  rrConfig: rrConfigType | undefined;

  // The buttons that determine the section of the report we are in.
  section = new FormControl<RR.TagSearchSection>('findings', { nonNullable: true });
  sections: { name: string; value: RR.TagSearchSection }[] = [
    { name: 'Findings', value: 'findings' },
    { name: 'Comment', value: 'comment' },
    { name: 'Impression & Recommendations', value: 'impression_recommendations' },
  ];

  form = new FormGroup({
    threshold: new FormControl(50, { nonNullable: true }),
    thresholdNad: new FormControl(30, { nonNullable: true }),
  });

  constructor(
    public activeModal: NgbActiveModal,
    private prefillService: PrefillService,
    private hotkeysService: HotkeysService,
    private store: Store<AppState>,
    private templateService: TemplateService,
    private editorService: EditorService,
    private tagEffect: TagEffect,
    private reportService: ReportService,
    private messageService: MessageService,
    private selectorService: SelectorService,
    private voiceService: VoiceRecognitionService,
    private keywordAbbreviationEffect: KeywordAbbreviationEffect,
    private tagChoiceEffect: TagChoiceEffect,
  ) {
    this.searchText$ = this.store.select(fromTag.selectFilterText);
    this.autoUpdate$ = this.store.select(fromTag.selectAutoUpdate);
    // Get the report from the URL
    this.report$ = this.store.select(fromCurrentReport.selectReport);
    this.templateId$ = this.store.select(fromCurrentTemplate.selectTemplateId);
    this.subscription.add(
      this.store.select(fromSession.selectRRConfig).subscribe((rrConfig) => {
        this.rrConfig = rrConfig;
      }),
    );
  }

  /**
   * Perform all the changes necessary for changing sections.
   */
  onSectionChanged(): void {
    // Only reload suggested tags and structure tags if they are not in the store.
    if (this.rrConfig?.ANALYTICS) {
      this.subscription.add(
        this.store
          .select(fromTag.isSuggestedLoaded(this.section.value))
          .pipe(
            take(1),
            switchMap((loaded) => {
              if (!loaded) {
                return this.tagEffect.loadSuggested(this.topicId, this.section.value);
              } else {
                return of(null);
              }
            }),
            this.messageService.handleHttpErrorPipe,
          )
          .subscribe(),
      );
    }

    this.subscription.add(
      this.store
        .select(fromTag.isStructureLoaded(this.section.value))
        .pipe(
          take(1),
          switchMap((loaded) => {
            if (!loaded) {
              return this.tagEffect.loadStructure(this.topicId, this.section.value);
            } else {
              return of(null);
            }
          }),
        )
        .subscribe(),
    );

    this.suggestedLoading$ = this.store.select(fromTag.isSuggestedLoading(this.section.value));
    this.structureLoading$ = this.store.select(fromTag.isStructureLoading(this.section.value));
    this.isMLSuggested$ = this.store.select(fromTag.isMLSuggested(this.section.value));
    const structuredLoaded$ = this.store
      .select(fromTag.isStructureLoading(this.section.value))
      .pipe(skipWhile((loading) => loading));
    this.structure$ = structuredLoaded$.pipe(
      switchMap(() => this.store.select(fromTag.selectTemplateStructure(this.section.value))),
    );
    this.tagSuggested$ = structuredLoaded$.pipe(
      switchMap(() => this.store.select(fromTag.selectFilteredSuggestedTags(this.section.value))),
    );
  }

  init(topicId: number) {
    this.topicId = topicId;

    // Calling onSectionChanged to trigger loading structured and suggested tags and initialise subscriptions
    this.onSectionChanged();

    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(
          filterDefined(),
          take(1),
          switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))),
        )
        .subscribe((userSetting) => {
          if (userSetting?.prefill_tags_scores_auto_update) {
            this.toggleAutoUpdate(true);
          }
        }),
    );

    this.subscription.add(
      this.editorService.globalSearchTerm$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          switchMap(() => this.reportService.getTopic(this.topicId)),
          switchMap((topic) =>
            this.templateService
              .searchForSimilarStatement({
                text$: this.editorService.getGlobalSearch(topic.id),
                topic,
                activeSection$: this.activeSection$.pipe(distinctUntilChanged()),
                statement_set_id: undefined,
                _loading$: this.loadingGlobalSearch$,
              })
              .pipe(take(1)),
          ),
          tap((result) => {
            if (result) {
              this.globalSearchTags = result.response;
            }
          }),
        )
        .subscribe(),
    );

    // Load current report tags
    this.subscription.add(
      this.reportService
        .fetchTopicDividers(this.topicId)
        .pipe(take(1))
        .subscribe((tags) => {
          this.topicTags = tags;
        }),
    );

    // Check to load keyword abbreviations for rrAbbr input
    this.subscription.add(this.keywordAbbreviationEffect.findAll().subscribe());
  }

  ngOnInit() {
    this.subscription.add(
      this.hotkeysService.addShortcut({ keys: 'space' }).subscribe(() => {
        this.onClose();
      }),
    );

    this.subscription.add(
      this.report$.pipe(filterDefined(), take(1)).subscribe((report) => {
        const tags = (report.study_notes?.match(new RegExp(/(?<=tags:).*/g)) || [])
          .map((item) => item.split(','))
          .filter((note) => !!note)
          .flat();

        return (this.voyagerNoteTags = [...this.voyagerNoteTags, ...tags]);
      }),
    );
  }

  ngOnDestroy() {
    this.voiceService.stopListening();
    this.subscription.unsubscribe();
  }

  copyToSearch(text: string) {
    this.store.dispatch(TagActions.search({ text }));
    this.editorService.globalSearchTerm$.next({ source: 'TAG', term: text });
  }

  scrollToTop() {
    const elm = document.getElementById('body');
    if (elm) elm.scrollTop = 0;
  }

  static open(modalService: NgbModal, topicId: number, section?: RR.TagSearchSection) {
    const modalRef = modalService.open(PrefillTagSearchModalComponent, {
      scrollable: true,
      centered: true,
      windowClass: 'prefill-tag-search-modal',
    });
    const self: PrefillTagSearchModalComponent = modalRef.componentInstance;
    if (section) {
      self.section = new FormControl(section, { nonNullable: true });
    }
    self.init(topicId);
    return modalRef;
  }

  /**
   * Sequence of actions to perform when closing the tag selection modal.
   *
   * When closing the modal we have gone to the effort of choosing tags, so we
   * want to ensure that this is used within the prefill. We notify the prefill
   * that we want to combine the topic search and exact match results, and
   * close the modal.
   *
   */
  onClose() {
    // This tells the prefill service that we want to combine the results from
    // both the exact match (which uses the tags) and the search.
    this.prefillService.selectPrefillTopics$.next({ type: 'COMBINE' });
    this.activeModal.close();
  }

  /**
   * Perform a global search on the Tags.
   *
   * This will perform a fuzzy filtering of all the tags, whether in the
   * Structured or list view. This searches all the text within the tag
   * including region, and subsection.
   */
  onSearch(text: string): void {
    this.store.dispatch(TagActions.search({ text }));
  }

  /**
   * Bind the spacebar to closing the modal.
   *
   * This allows us to create a keyboard shortcut, speeding up the navigation
   * within the application.
   *
   */

  /**
   * Manual interaction to update the tag probabilities
   *
   */
  onManualUpdate(): void {
    if (this.rrConfig?.ANALYTICS) {
      this.subscription.add(
        this.tagEffect
          .loadSuggested(this.topicId, this.section.value)
          .pipe(this.messageService.handleHttpErrorPipe)
          .subscribe(),
      );
    }
  }

  /**
   * Toggle the automatic updating of scores
   *
   */
  toggleAutoUpdate(auto?: boolean): void {
    this.store.dispatch(TagActions.toggleAutoUpdate({ auto }));
  }

  /**
   * Select NAD tags, except NAD tags in the same region/subsection with current selected tags
   */
  onSelectNADTags() {
    const form = this.form.getRawValue();

    this.subscription.add(
      this.tagChoiceEffect
        .autoSelectNAD(this.topicId, {
          threshold: form.thresholdNad / 100,
          section: this.section.value,
        })
        .subscribe(),
    );
  }
}
