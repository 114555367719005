import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import {
  funders,
  invoiceStatusMap,
  medipassClaimStatusMap,
  medipassTransactionStatusMap,
} from 'app/modules/invoice/invoice.constants';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { BillingItemEffect } from 'app/store/billing-item';
import { fromBillingItem } from 'app/store/billing-item/billing-item.selector';
import { fromBooking } from 'app/store/booking';
import { fromInvoice, InvoiceEffect, PostInvoiceData } from 'app/store/invoice';
import { fromInvoiceItem, InvoiceItemEffect } from 'app/store/invoice-item';
import { fromPatient } from 'app/store/patient';
import { PaymentEffect } from 'app/store/payment/payment.effect';
import { fromPayment } from 'app/store/payment/payment.selector';
import { fromProviderNumber } from 'app/store/provider-number';
import { fromReferrer } from 'app/store/referrer';
import { fromReport } from 'app/store/report/report';
import { fromSite } from 'app/store/site';
import { fromUser, UserEffect } from 'app/store/user/user';
import { formatISO } from 'date-fns';
import Dinero from 'dinero.js';
import { EMPTY, merge, Observable, of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { BillingItemSearchComponent } from '../../billing-item-search/billing-item-search.component';
import { InvoiceItemFormComponent } from '../../invoice-item-form/invoice-item-form.component';
import { InvoicePaymentFormComponent } from '../../invoice-payment-form/invoice-payment-form.component';
import { SelectInstitutionComponent } from '../../select-institution/select-institution.component';

export type PaymentFormGroup = FormGroup<{
  amount_paid: FormControl<number>;
  payment_type: FormControl<RR.PaymentType>;
}>;
type InvoiceFormType = ReturnType<InvoiceFormEditComponent['form']['getRawValue']>;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InvoicePaymentFormComponent,
    InvoiceItemFormComponent,
    BillingItemSearchComponent,
    SelectInstitutionComponent,
    SharedModule,
  ],
  selector: 'rr-invoice-form-edit',
  templateUrl: './invoice-form-edit.component.html',
  styleUrls: ['./invoice-form-edit.component.css'],
})
export class InvoiceFormEditComponent implements OnInit, OnDestroy {
  @Input() reportId: number | undefined;
  @Input() bookingId: number | undefined;
  @Input() invoiceId?: number | undefined;
  @Input() reInvoice?: boolean | undefined;
  @Output() onClose = new EventEmitter();

  subscription = new Subscription();

  doctor: string | undefined;
  funders = funders;
  billingItemEntities: Dictionary<RR.BillingItem> = {};
  booking: RR.Booking | undefined;
  bookingSite: RR.Site | undefined;
  invoiceItems: Partial<RR.InvoiceItem>[] = [];
  invoice: RR.Invoice | undefined;
  report: RR.Report | undefined;
  patient: RR.Patient | undefined;
  referrer: RR.Referrer | undefined;
  providerNumber: RR.ProviderNumber | undefined;
  providerNumber$: Observable<RR.ProviderNumber | undefined>;
  report$: Observable<RR.Report | undefined>;
  institution: RR.Institution | undefined;

  booking$: Observable<RR.Booking | undefined>;

  // Status map
  invoiceStatusMap = invoiceStatusMap;
  medipassTransactionStatusMap = medipassTransactionStatusMap;
  medipassClaimStatusMap = medipassClaimStatusMap;
  invoiceStatus: RR.InvoiceStatusType[] = Object.keys(invoiceStatusMap);
  rejectedItemReason: (string | undefined)[];

  form = new FormGroup({
    invoice_no: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    funder: new FormControl<RR.FunderType>('medicare', { nonNullable: true }),
    adjustment: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    amount_paid: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    amount_expected: new FormControl<number | undefined>({ value: undefined, disabled: true }, { nonNullable: true }),
    balance_due: new FormControl<number | undefined>({ value: undefined, disabled: true }, { nonNullable: true }),
    status: new FormControl<RR.InvoiceStatusType | undefined>(undefined, { nonNullable: true }),
    medipass_transaction_status: new FormControl<RR.MedipassTransactionStatusType | undefined>(
      { value: undefined, disabled: true },
      { nonNullable: true },
    ),
    medipass_claim_status: new FormControl<RR.MedipassClaimStatusType | undefined>(
      { value: undefined, disabled: true },
      { nonNullable: true },
    ),
    institution_id: new FormControl<number | undefined>(undefined, { nonNullable: true }),
    external_claim_no: new FormControl<string | undefined>(undefined, { nonNullable: true }),
    institution_contact_person: new FormControl('', { nonNullable: true }),
    institution_phone: new FormControl('', { nonNullable: true }),
    institution_email: new FormControl('', { nonNullable: true }),
    accident_date: new FormControl('', { nonNullable: true }),
    notes: new FormControl('', { nonNullable: true }),
  });

  paymentFormArray = new FormArray<
    FormGroup<{ amount_paid: FormControl<number>; payment_type: FormControl<RR.PaymentType> }>
  >([]);

  constructor(
    private store: Store<AppState>,
    private invoiceItemEffect: InvoiceItemEffect,
    private invoiceEffect: InvoiceEffect,
    private userEffect: UserEffect,
    private paymentEffect: PaymentEffect,
    private billingItemEffect: BillingItemEffect,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    if (!this.invoiceId) {
      this.paymentFormArray.push(
        new FormGroup({
          amount_paid: new FormControl(0, { validators: [Validators.required], nonNullable: true }),
          payment_type: new FormControl<RR.PaymentType>('eftpos', {
            validators: [Validators.required],
            nonNullable: true,
          }),
        }),
      );
    }

    const billingItemEntities$ = this.store.select(fromBillingItem.selectEntities);
    this.subscription.add(
      billingItemEntities$.subscribe((entities) => {
        this.billingItemEntities = entities;
      }),
    );

    this.subscription.add(this.billingItemEffect.findAll().subscribe());

    if (this.invoiceId) {
      this.subscription.add(
        this.store.select(fromInvoice.selectInvoice(this.invoiceId)).subscribe((invoice) => {
          this.invoice = invoice;
        }),
      );
    }

    if (this.reportId) {
      this.report$ = this.store.select(fromReport.selectReport(this.reportId));

      this.booking$ = this.report$.pipe(
        switchMap((report) =>
          report ? this.store.select(fromBooking.selectBooking(report.booking_id)) : of(undefined),
        ),
      );

      this.subscription.add(
        this.report$.subscribe((report) => {
          this.report = report;
        }),
      );

      this.subscription.add(
        this.report$
          .pipe(
            switchMap((report) =>
              report?.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(undefined),
            ),
          )
          .subscribe((patient) => {
            this.patient = patient;
          }),
      );
      this.subscription.add(
        this.report$
          .pipe(
            switchMap((report) =>
              report?.referrer_id ? this.store.select(fromReferrer.selectReferrer(report.referrer_id)) : of(undefined),
            ),
          )
          .subscribe((referrer) => {
            this.referrer = referrer;
          }),
      );

      this.providerNumber$ = this.report$.pipe(
        switchMap((report) =>
          report?.medicare_provider_id
            ? this.store.select(fromProviderNumber.selectProviderNumber(report.medicare_provider_id))
            : of(undefined),
        ),
      );

      this.subscription.add(
        this.providerNumber$.subscribe((providerNumber) => {
          this.providerNumber = providerNumber;
        }),
      );

      const userId$ = this.providerNumber$.pipe(
        filterDefined(),
        take(1),
        map((providerNumber) => providerNumber.user_id),
      );

      this.subscription.add(userId$.pipe(switchMap((userId) => this.userEffect.findById(userId))).subscribe());

      this.subscription.add(
        userId$.pipe(switchMap((userId) => this.store.select(fromUser.selectUser(userId)))).subscribe((user) => {
          this.doctor = user?.name;
        }),
      );
    }

    if (this.bookingId) {
      this.booking$ = this.store.select(fromBooking.selectBooking(this.bookingId));

      this.subscription.add(
        this.booking$
          .pipe(
            switchMap((booking) => {
              if (booking && booking.patient_id) {
                return this.store.select(fromPatient.selectPatient(booking.patient_id));
              } else {
                return EMPTY;
              }
            }),
          )
          .subscribe((patient) => {
            this.patient = patient;
          }),
      );

      this.form.controls.funder.setValue('private_patient');

      this.funders = this.funders.filter((funder) => funder.id === 'private_patient' || funder.id === 'other');
    }

    this.subscription.add(
      this.booking$.subscribe((booking) => {
        this.booking = booking;
      }),
    );

    const bookingSite$ = this.booking$.pipe(
      map((r) => r?.site_id),
      filterDefined(),
      switchMap((siteId) => this.store.select(fromSite.selectSite(siteId))),
    );

    this.subscription.add(
      bookingSite$.subscribe((site) => {
        this.bookingSite = site;
      }),
    );

    if (this.invoice && !this.reInvoice) {
      // In edit invoice mode
      // Patch form with invoice data
      this.form.patchValue({
        invoice_no: this.invoice.id,
        funder: this.invoice.funder || undefined,
        adjustment: this.invoice.adjustment,
        amount_paid: this.invoice.amount_paid,
        amount_expected: this.invoice.amount_expected ?? undefined,
        balance_due: this.invoice.balance_due,
        status: this.invoice.status || undefined,
        medipass_transaction_status: this.invoice.medipass_transaction_status || undefined,
        medipass_claim_status: this.invoice.medipass_claim_status || undefined,
        institution_id: this.invoice.institution_id || undefined,
        external_claim_no: this.invoice.external_claim_no || undefined,
        institution_contact_person: this.invoice.institution_contact_person || undefined,
        institution_phone: this.invoice.institution_phone || undefined,
        institution_email: this.invoice.institution_email || undefined,
        accident_date: this.invoice.accident_date || undefined,
        notes: this.invoice.notes || undefined,
      });

      this.subscription.add(
        this.store.select(fromInvoiceItem.selectInInvoice(this.invoice.id)).subscribe((items) => {
          this.invoiceItems = items.filter((item): item is RR.InvoiceItem => !!item);
        }),
      );

      if (this.invoiceId) {
        this.subscription.add(
          this.store
            .select(fromPayment.selectInInvoice(this.invoiceId))
            .pipe(take(1)) // running twice appends extra controls
            .subscribe((payments) => {
              const paymentArray = payments.map(
                (p) =>
                  new FormGroup({
                    amount_paid: new FormControl(p.amount, { validators: [Validators.required], nonNullable: true }),
                    payment_type: new FormControl<RR.PaymentType>(p.payment_type, {
                      validators: [Validators.required],
                      nonNullable: true,
                    }),
                  }),
              );

              paymentArray.forEach((payment) => this.paymentFormArray.push(payment));
            }),
        );
      }

      // Cannot change the funder if invoice is ready to submit
      // Still can change amount_expected and amount_paid for invoice balancing
      if (this.invoice.medipass_transaction_status && this.invoice.funder === 'medicare') {
        this.form.controls.funder.disable();
        this.form.controls.amount_paid.disable();
        this.form.controls.adjustment.disable();
        this.paymentFormArray.disable();
      }
    } else if (this.invoice && this.reInvoice) {
      // In reInvoice mode
      // Patch form with invoice data
      this.form.patchValue({
        funder: this.invoice.funder || undefined,
        adjustment: this.invoice.adjustment,
        institution_id: this.invoice.institution_id || undefined,
        external_claim_no: this.invoice.external_claim_no || undefined,
        institution_contact_person: this.invoice.institution_contact_person || undefined,
        institution_phone: this.invoice.institution_phone || undefined,
        institution_email: this.invoice.institution_email || undefined,
        accident_date: this.invoice.accident_date || undefined,
        notes: this.invoice.notes || undefined,
      });

      // Creating new invoice items
      this.subscription.add(
        this.store.select(fromInvoiceItem.selectInInvoice(this.invoice.id)).subscribe((items) => {
          // To display reason why last item was rejected
          this.rejectedItemReason = items
            .filter((item): item is RR.InvoiceItem => !!item && item.medipass_client_message != null)
            .map((item) => item.medipass_client_message || undefined);

          this.invoiceItems = items
            // Only include the rejected items from an invoice
            .filter((item): item is RR.InvoiceItem => !!item && item.medipass_status === 'rejected')
            // Remove InvoiceItem properties that were added to rejectedInvoiceItems
            .map((item) => ({
              // Omit: id, medipass_status, medipass_client_message, amount_expected, amount_paid, amount_expected_original
              billing_item_id: item.billing_item_id,
              item_code: item.item_code,
              amount_expected: item.amount_expected_original,
              description: item.description,
              service_date: item.service_date,
              doctor: item.doctor,
            }));
          this.recalculateFormAmounts();
        }),
      );
    }

    this.subscription.add(
      merge(this.form.controls.adjustment.valueChanges, this.paymentFormArray.valueChanges).subscribe(() =>
        this.recalculateFormAmounts(),
      ),
    );

    this.subscription.add(
      this.form.controls.funder.valueChanges
        .pipe(filterDefined())
        .pipe(
          switchMap((funder) => {
            // Update invoice item's fees
            this.invoiceItems = this.invoiceItems.map((i) => {
              if (!i.billing_item_id) {
                return i;
              }
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const billingItem: RR.BillingItem = this.billingItemEntities[i.billing_item_id]!;
              const price = this.getItemPrice(billingItem, funder);
              return { ...i, amount_expected: price };
            });

            // Update invoice items' amount_expected and amount_charge if in invoice editing mode
            if (this.invoice) {
              this.invoiceItems.forEach((item) => {
                if (item.id) {
                  this.subscription.add(
                    this.invoiceItemEffect
                      .update(item.id, {
                        amount_expected: item.amount_expected,
                      })
                      .subscribe(),
                  );
                }
              });
            }

            if (funder !== 'other') {
              // Patch value to set institution and external claim ref fields to undefined if funder is not other
              const values: Partial<InvoiceFormType> = {
                institution_id: undefined,
                external_claim_no: undefined,
                accident_date: undefined,
                institution_contact_person: undefined,
                institution_phone: undefined,
                institution_email: undefined,
              };
              // Set status to undefined if funder is medicare. Status will be updated via medipass webhook events
              if (funder === 'medicare') {
                values.status = undefined;
              }
              this.form.patchValue(values);
            }
            this.recalculateFormAmounts();

            return of(null);
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addInvoiceItems(billingItem: RR.BillingItem) {
    const billingItemArray = [billingItem];
    // Current funder value
    const funder = this.form.controls.funder.value;
    // Convert selected billing items to invoice items and append to invoice item lists
    const addedItems: Partial<RR.InvoiceItem>[] = billingItemArray
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      .filter((i): i is RR.BillingItem => i !== undefined)
      .map((billingItem) => {
        const price = this.getItemPrice(billingItem, funder);
        return {
          item_code: billingItem.item_code,
          amount_expected: price,
          amount_paid: null,
          description: billingItem.notes,
          // Format 'yyyy-MM-dd' to be compatible with <input type=date/>
          service_date: this.report?.created
            ? formatISO(new Date(this.report.created), { representation: 'date' })
            : null,
          doctor: '',
          billing_item_id: billingItem.id,
        };
      });

    if (!this.invoice || this.reInvoice) {
      this.invoiceItems.push(...(addedItems as RR.InvoiceItem[])); // TODO: remove this cast.
      this.recalculateFormAmounts();
    } else {
      this.subscription.add(
        this.invoiceItemEffect.create(this.invoice.id, addedItems).subscribe(() => {
          this.recalculateFormAmounts();
        }),
      );
    }
  }

  removeInvoiceItem(invoiceItem: RR.InvoiceItem) {
    // If create new invoice => just filter out the item in invoice item list. Otherwise, call api to remove item from invoice
    if (!this.invoice || this.reInvoice) {
      this.invoiceItems = this.invoiceItems.filter((item) => item !== invoiceItem);
      this.recalculateFormAmounts();
    } else {
      this.subscription.add(
        this.invoiceItemEffect.delete(invoiceItem.id).subscribe(() => {
          this.recalculateFormAmounts();
        }),
      );
    }
  }

  removePayment(payment: PaymentFormGroup) {
    const index = this.paymentFormArray.controls.indexOf(payment);
    this.paymentFormArray.removeAt(index);
  }

  addPayment() {
    this.paymentFormArray.push(
      new FormGroup({
        amount_paid: new FormControl(0, { validators: [Validators.required], nonNullable: true }),
        payment_type: new FormControl<RR.PaymentType>('eftpos', {
          validators: [Validators.required],
          nonNullable: true,
        }),
      }),
    );
  }

  editPayment(payment: RR.Payment, changes: Partial<RR.Payment>) {
    this.subscription.add(this.paymentEffect.update(payment.id, changes).subscribe());
  }

  submit() {
    if (this.form.invalid) return of(false);

    if (this.invoice && !this.reInvoice) {
      return this.invoiceEffect.update(this.invoice.id, this.updateInvoiceData()).pipe(
        switchMap((action) =>
          this.paymentEffect.deleteMany(action.invoice.id, action.invoice.payments).pipe(map((_) => action)),
        ),
        switchMap((action) =>
          this.paymentEffect.createMany(
            action.invoice.id,
            this.paymentFormArray.controls.map((paymentFormGroup) => ({
              amount: paymentFormGroup.controls.amount_paid.value,
              payment_type: paymentFormGroup.controls.payment_type.value,
            })),
          ),
        ),
        map(() => true),
      );
    } else {
      let invoiceUpdate$: Observable<any> = of(null);
      if (this.invoice && this.reInvoice) {
        invoiceUpdate$ = this.invoiceEffect.update(this.invoice.id, { no_further_action_required: true });
      }

      let createInvoice$: Observable<number>;
      const invoiceData = this.postInvoiceData();
      if (this.report) {
        createInvoice$ = this.invoiceEffect
          .createReportInvoice(this.report.id, invoiceData)
          .pipe(map((action) => action.actions.createInvoiceSuccess.invoice.id));
      } else if (this.bookingId) {
        createInvoice$ = this.invoiceEffect
          .createBookingInvoice(this.bookingId, invoiceData)
          .pipe(map((action) => action.actions.createInvoiceSuccess.invoice.id));
      } else {
        throw new Error('No report or booking id found');
      }
      return invoiceUpdate$.pipe(
        switchMap(() => createInvoice$),
        switchMap((invoiceId) => this.createInvoiceItemsAndPayments(invoiceId)),
        map(() => true),
      );
    }
  }

  createInvoiceItemsAndPayments(invoiceId: number) {
    return this.invoiceItemEffect.create(invoiceId, this.invoiceItems).pipe(
      switchMap(() =>
        this.paymentEffect.createMany(
          invoiceId,
          this.paymentFormArray.controls.map((paymentFormGroup) => ({
            amount: paymentFormGroup.controls.amount_paid.value,
            payment_type: paymentFormGroup.controls.payment_type.value,
          })),
        ),
      ),
    );
  }

  recalculateFormAmounts() {
    // When funder or invoice items changed, recalculate amount expected and amount charge
    const { sum, balance_due, amount_paid } = this.invoiceCalculations();
    const patch: Pick<typeof this.form.value, 'amount_expected' | 'balance_due' | 'amount_paid'> = {
      amount_expected: sum,
      balance_due: balance_due,
      amount_paid: amount_paid,
    };

    const unchanged = (Object.keys(patch) as unknown as (keyof typeof patch)[]).every((key) => {
      // Every key is the same
      return patch[key] === this.form.controls[key].value;
    });
    if (unchanged) {
      console.warn('Form unchanged. Avoided infinite loop.');
    } else {
      this.form.patchValue(patch);
    }
  }

  invoiceCalculations() {
    // Dinero amount is in cents
    const sum = this.invoiceItems
      .map((item) => Dinero({ amount: Math.round((item.amount_expected ?? 0) * 100) }))
      .reduce((total, currentValue) => total.add(currentValue), Dinero({ amount: 0 }));
    const adjustment = Dinero({ amount: Math.round((this.form.controls.adjustment.value ?? 0) * 100) });
    const amount_paid = this.paymentFormArray.controls
      .map((p) => Dinero({ amount: Math.round((p.value.amount_paid ?? 0) * 100) }))
      .reduce((total, currentValue) => total.add(currentValue), Dinero({ amount: 0 }));
    const balance_due = sum.add(adjustment).subtract(amount_paid);
    const funder = funders.find((f) => f.id === this.form.controls.funder.value);

    return {
      // Convert back from cents to dollars
      sum: sum.getAmount() / 100,
      adjustment: adjustment.getAmount() / 100,
      amount_paid: amount_paid.getAmount() / 100,
      balance_due: balance_due.getAmount() / 100,
      funder,
    };
  }

  updateInvoiceData() {
    // Edit invoice -> only update form field
    const form = this.form.getRawValue();
    const { sum, adjustment, amount_paid, balance_due, funder } = this.invoiceCalculations();
    const updateData: Partial<RR.Invoice> = {
      funder: form.funder,
      amount_paid,
      adjustment,
      balance_due,
      status: form.status,
      institution_id: form.institution_id,
      external_claim_no: form.external_claim_no || undefined,
      institution_contact_person: form.institution_contact_person || undefined,
      institution_phone: form.institution_phone || undefined,
      institution_email: form.institution_email || undefined,
      accident_date: form.accident_date
        ? formatISO(new Date(form.accident_date), { representation: 'date' })
        : undefined,
      notes: form.notes,
      is_draft: this.invoice?.funder !== form.funder,
    };

    if (this.invoice?.is_draft) {
      updateData.amount_expected = sum;
      updateData.funder = funder?.id || undefined;
      updateData.balance_due = balance_due;
    } else {
      // TODO (invoice balancing): update invoice balance when data changed?
      updateData.amount_expected = this.form.controls.amount_expected.value;
    }
    return updateData;
  }

  mapReferrerTypeForMedipass(referrerType: RR.ReferrerType): 'gp' | 'specialist' {
    if (referrerType === 'gp') {
      return 'gp';
    } else {
      return 'specialist';
    }
  }

  postInvoiceData() {
    const form = this.form.getRawValue();
    const { sum, adjustment, amount_paid, balance_due, funder } = this.invoiceCalculations();
    const invoice: PostInvoiceData = {
      // Invoice & payment
      invoice_created: formatISO(new Date(), { representation: 'complete' }),
      funder: funder?.id || null,
      amount_expected: sum,
      amount_paid,
      adjustment,
      gst_included: 0,
      balance_due,

      // Patient
      patient_id: this.patient?.id ?? null,
      patient_number: this.patient?.patient_number || '',
      patient_first_name: this.patient?.patient_first_name || '',
      patient_last_name: this.patient?.patient_last_name || '',
      patient_sex: this.patient?.patient_sex || null,
      patient_dob: this.patient?.patient_dob || null,
      patient_mobile: this.patient?.phone_mobile || null,
      patient_address: `${this.patient?.address || ''} ${this.patient?.city || ''} ${this.patient?.state || ''} ${
        this.patient?.zip || ''
      }`,
      patient_fund_membership: '', // Need?
      patient_medicare_number: this.patient?.medicare_number || '',
      patient_medicare_reference_number: this.patient?.medicare_reference_number || null,

      // Site
      site_id: this.bookingSite?.id || null,
      site_name: this.bookingSite?.name || '',
      site_abn: this.bookingSite?.abn || '',
      site_lspn: this.bookingSite?.lspn || '',
      site_address: this.bookingSite?.address || '',
      site_phone: this.bookingSite?.phone || '',
      site_fax: this.bookingSite?.fax || '',
      site_bsb: this.bookingSite?.bsb || '',
      site_bank_name: this.bookingSite?.bank_name || '',
      site_account_name: this.bookingSite?.account_name || '',
      site_account_number: this.bookingSite?.account_number || '',

      // Provider
      service_provider_name: `${this.doctor || ''}`,
      service_provider_number: `${this.providerNumber?.medicare_provider_number || ''}`,

      // Referrer
      referrer_id: this.referrer?.id || null,
      referrer_name: `${this.referrer?.physician_given_name || ''} ${this.referrer?.physician_family_name || ''}`,
      referrer_number: this.referrer?.medicare_provider_number || '',
      ref_date: this.report?.referral_date
        ? formatISO(new Date(this.report.referral_date), { representation: 'date' })
        : null,
      ref_period: this.report?.referral_period || null,
      referrer_type: this.referrer?.referrer_type ? this.mapReferrerTypeForMedipass(this.referrer.referrer_type) : 'gp',
      referrer_type_code: 'D',
      // Draft, status
      is_draft: true,
      status: form.status || null,
      institution_id: form.institution_id || null,
      external_claim_no: form.external_claim_no || null,
      institution_contact_person: form.institution_contact_person
        ? form.institution_contact_person
        : this.institution?.contact_person || null,
      institution_phone: form.institution_phone ? form.institution_phone : this.institution?.phone || null,
      institution_email: form.institution_email ? form.institution_email : this.institution?.email || null,
      accident_date: form.accident_date ? formatISO(new Date(form.accident_date), { representation: 'date' }) : null,
      notes: form.notes || null,
    };
    return invoice;
  }

  getItemPrice(billingItem: RR.BillingItem, funder: RR.FunderType | null) {
    let price = billingItem.private_fee;
    if (funder === 'medicare') {
      price = billingItem.medicare_fee;
    } else if (funder === 'other') {
      price = billingItem.other_fee;
    } else if (funder === 'dva') {
      price = billingItem.dva_fee;
    } else if (funder === 'bupa') {
      price = billingItem.bupa_fee;
    }
    return price;
  }

  editInvoiceItem(item: RR.InvoiceItem, changes: Partial<RR.InvoiceItem>) {
    if (item.id) {
      this.subscription.add(
        this.invoiceItemEffect
          .update(item.id, changes)
          .pipe(take(1))
          .subscribe(() => {
            if ('amount_expected' in changes) {
              this.recalculateFormAmounts();
            }
          }),
      );
    } else {
      const invoiceItem = this.invoiceItems.find((i) => i === item);
      Object.keys(changes).forEach((k: string) => {
        if (invoiceItem) {
          // @ts-expect-error noImplicitAny
          invoiceItem[k] = changes[k];
        }
      });

      // Recalculate invoice amount when expected amount changed
      if ('amount_expected' in changes) {
        this.recalculateFormAmounts();
      }
    }
  }

  selectInstitution(ins: RR.Institution | null) {
    if (ins !== null) {
      this.institution = ins;
      this.form.patchValue({
        institution_id: ins.id,
        institution_contact_person: ins.contact_person || undefined,
        institution_phone: ins.phone || undefined,
        institution_email: ins.email || undefined,
      });
    } else {
      this.institution = undefined;
      // If institution is removed, clear external claim ref fields too
      this.form.patchValue({
        institution_id: undefined,
        institution_contact_person: undefined,
        institution_phone: undefined,
        institution_email: undefined,
        external_claim_no: undefined,
        accident_date: undefined,
      });
    }
  }
}
