import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { fromSession } from 'app/store/session';
import { AttributeOptionEffect } from 'app/store/template/attribute-option';
import { TextObjectEffect } from 'app/store/template/text-object';
import { Observable, Subscription } from 'rxjs';
import { take, switchMap, map, tap } from 'rxjs/operators';

import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import { SharedModule } from '../../../../shared/shared.module';
import { AttributeSearchHit } from '../statement-attribute/statement-attribute.component';

@Component({
  selector: '[rr-attribute-option]',
  templateUrl: './attribute-option.component.html',
  styleUrls: ['./attribute-option.component.scss'],
  standalone: true,
  imports: [SharedModule, AutoFocusDirective, CommonModule],
})
@LifecycleLogger
export class AttributeOptionComponent implements OnInit, OnDestroy {
  @Input() attribute_option: RR.AttributeOption;
  @Input() hit: AttributeSearchHit | undefined;
  @Input() @BindObservable() statement_attribute: RR.TextObjectSet | undefined;
  statement_attribute$: Observable<RR.TextObjectSet | undefined>;
  @Input() choice: RR.StatementChoice;
  @Input() selected = false;
  @Input() chosen = false;
  @Input() autoShortList = false;
  @Input() attribute_half: 'top' | 'bottom';
  @Input() frequency: number;
  @Output() onChoose = new EventEmitter();
  @Output() onDelete = new EventEmitter();
  @ViewChild('textinput') textinput: ElementRef;
  @HostBinding('class') get getClass() {
    const selectedClass = this.selected ? 'selected' : '';
    const chosenClass = this.chosen ? 'text-info fw-bold' : '';
    // .dropdown-item needed so that ngbDropdown [autoClose]=true works
    return `dropdown-item ${selectedClass} ${chosenClass}`;
  }

  searchQuery = '';
  // @ts-expect-error noImplicitAny
  topAttributes;
  // @ts-expect-error noImplicitAny
  rawBottomAttributes;
  // @ts-expect-error noImplicitAny
  bottomAttributes;
  _editing = false;
  isDefault: Observable<boolean>;
  editingUser$: Observable<RR.User | undefined>;

  set editing(value) {
    requestAnimationFrame(() => {
      // delay so that parent handlers can check presence of [data-no-bubble-choice] attribute
      // before editing is false and html element is removed
      this._editing = value;
      this.cd.markForCheck();
    });
  }

  get editing() {
    return this._editing;
  }
  displayAsterisk: Observable<boolean>;

  subscription = new Subscription();

  constructor(
    private templateService: TemplateService,
    private reportService: ReportService,
    private cd: ChangeDetectorRef,
    private textObjectEffect: TextObjectEffect,
    private attributeOptionEffect: AttributeOptionEffect,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.isDefault = this.reportService.getChoiceParents(this.choice).pipe(
      switchMap((ctx) => {
        return this.templateService.getDefaultAttribute(
          // @ts-expect-error strictNullChecks
          ctx.topic.template_id,
          // @ts-expect-error strictNullChecks
          this.statement_attribute?.id,
          ctx.region_choice?.region_id || null,
        );
      }),
      map((tda) => tda?.default_option_id === this.attribute_option.id),
    );

    this.displayAsterisk = this.isDefault.pipe(
      map((isDefault) => {
        if (!this.statement_attribute) {
          return false;
        } else if (this.attribute_half === 'top') {
          // No need to display asterisk for defaults because they already appear in the top section.
          return !isDefault;
        } else {
          return !this.statement_attribute.shortlist.includes(this.attribute_option.id);
        }
      }),
    );

    // Check if current user is admin. Should check editing user(who puts in initials), not logged in user
    this.editingUser$ = this.store.select(fromSession.selectKioskUser);
  }

  @HostListener('click', ['$event'])
  // @ts-expect-error noImplicitAny
  onClick(event) {
    if (!event.target.closest('[data-no-bubble-attribute]')) {
      this.toggleAttribute();
    }
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  toggleAttribute = () => {
    this.onChoose.emit();
  };

  onClickShortlist() {
    if (!this.statement_attribute) return; // there is no TextObject to update

    // Copy the current state of the suggested values so we can edit it
    const values = [...this.statement_attribute.shortlist];
    const isAdding = !values.includes(this.attribute_option.id);

    if (!isAdding) {
      // If the value already exists within the array, we can remove it.
      values.splice(values.indexOf(this.attribute_option.id), 1);
    } else {
      // Otherwise add it to the array
      values.push(this.attribute_option.id);
    }
    this.subscription.add(
      this.textObjectEffect
        .update(this.statement_attribute.id, { shortlist: values })
        .pipe(
          tap(() => {
            if (isAdding) {
              // We also want to choose the option when we add it. TODO: bug, this closes the dropdown. We run it after
              // updating, to make sure `textObjectEffect.update` doesn't cancel when the dropdown closes.
              this.toggleAttribute();
            }
          }),
        )
        .subscribe(),
    );
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  setDefaultAttributeOption = () => {
    if (!this.statement_attribute) return;
    // eslint-disable-next-line rxjs-angular/prefer-composition
    this.isDefault.pipe(take(1)).subscribe((isDefault) => {
      if (!isDefault) {
        // If already set, or there is nothing to update, do nothing
        this.reportService
          .getChoiceParents(this.choice)
          .pipe(take(1))
          /* eslint-disable-next-line rxjs-angular/prefer-composition, rxjs/no-nested-subscribe -- 2, 2 */
          .subscribe((ctx) => {
            this.templateService.setDefaultAttribute({
              // @ts-expect-error strictNullChecks
              template_id: ctx.topic.template_id,
              default_option_id: this.attribute_option.id,
              // @ts-expect-error strictNullChecks
              text_object_id: this.statement_attribute.id,
              // @ts-expect-error strictNullChecks
              region_id: ctx.region_choice.region_id,
            });
          });
      }
    });
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  editAttributeOption = () => {
    const text = this.textinput.nativeElement.value;
    this.subscription.add(
      this.attributeOptionEffect
        .update(this.attribute_option.id, {
          text,
        })
        .subscribe(() => {
          this.editing = false;
        }),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  removeAttributeOption = () => {
    this.attributeOptionEffect
      .delete(this.attribute_option.id)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe(() => {
        this.onDelete.emit(this.attribute_option);
      });
    this.editing = false;
  };

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
