<form [formGroup]="searchForm">
  <div class="form-group mb-2 input-group no-shrink">
    <div class="input-group-text">
      <span class="fa fa-search"></span>
    </div>
    <input
      class="form-control col-sm-9"
      type="text"
      [formControl]="searchForm.controls.search_name"
      data-cy="search-name"
      placeholder="Search by patient name"
      (focus)="focusInput('name', searchForm.controls.search_name.value)"
      [resultTemplate]="rt"
      [ngbTypeahead]="nameTypeahead"
      (selectItem)="selectPatient($event)"
      popupClass="patient-search-dropdown"
    />
  </div>
  <div class="form-group mb-2 input-group no-shrink">
    <div class="input-group-text">
      <span class="fa fa-search"></span>
    </div>
    <input
      class="form-control col-sm-9"
      type="date"
      [formControl]="searchForm.controls.search_dob"
      data-cy="search-dob"
      (focus)="focusInput('dob', searchForm.controls.search_dob.value)"
      [ngbTypeahead]="dobTypeahead"
      [resultTemplate]="rt"
      (selectItem)="selectPatient($event)"
      popupClass="patient-search-dropdown"
    />
  </div>
  <div class="form-group mb-2 input-group no-shrink">
    <div class="input-group-text">
      <span class="fa fa-search"></span>
    </div>
    <input
      class="form-control col-sm-9"
      type="text"
      [formControl]="searchForm.controls.search_medicare"
      data-cy="search-medicare"
      placeholder="Search by patient medicare"
      (focus)="focusInput('medicare', searchForm.controls.search_medicare.value)"
      [ngbTypeahead]="medicareTypeahead"
      [resultTemplate]="rt"
      (selectItem)="selectPatient($event)"
      popupClass="patient-search-dropdown"
    />
  </div>
  <div class="form-group mb-2 input-group no-shrink">
    <div class="input-group-text">
      <span class="fa fa-search"></span>
    </div>
    <input
      class="form-control col-sm-9"
      type="text"
      [formControl]="searchForm.controls.search_mobile"
      placeholder="Search by patient mobile"
      [ngbTypeahead]="mobileTypeahead"
      [resultTemplate]="rt"
      (focus)="focusInput('mobile', searchForm.controls.search_mobile.value)"
      (selectItem)="selectPatient($event)"
      popupClass="patient-search-dropdown"
    />
  </div>
</form>

<ng-template #rt let-r="result" let-t="term">
  <div *ngIf="r.patient_first_name || r.patient_last_name">
    <ngb-highlight class="me-1" [result]="r.patient_first_name + ' ' + r.patient_last_name" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.patient_sex" class="text-capitalize">
    <ngb-highlight class="me-1" [result]="r.patient_sex" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.patient_number">
    <span>Patient No: </span>
    <ngb-highlight class="me-1" [result]="r.patient_number" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.patient_dob">
    <span>DOB: </span>
    <ngb-highlight [result]="r.patient_dob | date: 'dd/MM/yyyy'" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.medicare_number || r.veteran_file_number">
    <span>Medicare/Veteran No: </span>
    <ngb-highlight [result]="r.medicare_number || r.veteran_file_number" [term]="t"></ngb-highlight>
  </div>
  <div *ngIf="r.phone_mobile || r.phone_home || r.phone_work || r.email">
    <span>Phone/Email: </span>
    <ngb-highlight
      [result]="r.phone_mobile || r.phone_home || r.phone_work || r.email || 'N/A'"
      [term]="t"
    ></ngb-highlight>
  </div>
  <div>
    <span>Medicare validation: </span>
    <ngb-highlight
      [result]="(r.medipass_last_validated | date: 'dd/MM/yyyy') || 'Never/Failed validation'"
      [term]="t"
    ></ngb-highlight>
  </div>
</ng-template>
