import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, scrollIntoView } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AppState } from 'app/store';
import { SessionEffect } from 'app/store/session';
import {
  AttributeOptionEffect,
  AttributeOptionHttpService,
  TopAttributes,
  fromAttributeOption,
} from 'app/store/template/attribute-option';
import { TextObjectEffect, fromTextObject } from 'app/store/template/text-object';
import { EMPTY, fromEvent, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skip,
  switchMap,
  take,
  tap,
  throttleTime,
  withLatestFrom,
} from 'rxjs/operators';

import { AutoFocusDirective } from '../../../../shared/directives/auto-focus.directive';
import { VoiceDirective } from '../../../../shared/directives/voice.directive';
import { StoreSelectPipe } from '../../../../shared/pipes/store-select.pipe';
import { SharedModule } from '../../../../shared/shared.module';
import { AttributeOptionComponent } from '../attribute-option/attribute-option.component';

type AttributeSearchResult = {
  hits: {
    hits: AttributeSearchHit[];
  };
};

export type AttributeSearchHit = {
  _id: string;
  _score: number;

  _source: {
    attribute_set_id: number;
    text: string;
  };

  highlight?: {
    text: string[];
  };

  // elasticsearch with store_attribute_option embedded
  store_attribute_option: RR.AttributeOption;
};

@Component({
  selector: 'rr-statement-attribute',
  templateUrl: './statement-attribute.component.html',
  styleUrls: ['./statement-attribute.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    VoiceDirective,
    AutoFocusDirective,
    ReactiveFormsModule,
    NgbPopover,
    AttributeOptionComponent,
    StoreSelectPipe,
  ],
})
@LifecycleLogger
export class StatementAttributeComponent implements OnInit, OnDestroy {
  @HostBinding('class') class = 'card text-dark'; // bg-warning
  @BindObservable() @Input() choice_object: RR.TextObjectChoiceSet;
  choice_object$: Observable<RR.TextObjectChoiceSet>;
  @Input() choice: RR.StatementChoice;
  @Input() @BindObservable() statement: RR.Statement | null;
  statement$: Observable<RR.Statement | null>;
  @Input() position: number;
  @Output() tabAttributes = new EventEmitter();
  @ViewChild('newAttribute', { static: true }) newAttributeElement: ElementRef;
  @ViewChild('topElement') topElement: ElementRef;
  @ViewChild('bottomElement') bottomElement: ElementRef;
  @ViewChild('searchResultElement') searchResultElement: ElementRef;
  @ViewChild('scrollElement', { static: false }) scrollElement: ElementRef;
  @ViewChild('popover') popover: NgbPopover;

  editing = false;
  @Output() enter = new EventEmitter();
  selectedAttribute:
    | {
        attribute: RR.AttributeOption;
        half: 'top' | 'bottom' | null;
      }
    | undefined;
  searchQuery = new FormControl('', { nonNullable: true });
  topAttributes: RR.AttributeOption[] = [];
  bottomAttributes: RR.AttributeOption[] = [];
  name: string;
  subscription = new Subscription();
  attributeSearchHits: AttributeSearchHit[] = [];
  isSearchingAttribute = false;
  attributeSet: RR.AttributeSet | undefined;
  shortListResponses: TopAttributes | undefined;
  loadingShortList = true;

  showAllAttributes = false;
  usedVoiceMode: boolean = false;

  equivalentTextObject: RR.TextObjectSet | undefined;

  constructor(
    private cd: ChangeDetectorRef,
    private reportService: ReportService,
    private templateService: TemplateService,
    private el: ElementRef<Element>,
    private sessionEffect: SessionEffect,
    private messageService: MessageService,
    private attributeOptionEffect: AttributeOptionEffect,
    private attributeOptionService: AttributeOptionHttpService,
    private store: Store<AppState>,
    private editorService: EditorService,
    private textObjectEffect: TextObjectEffect,
    private http: HttpClient,
  ) {}

  getRelatedTextObject(): Observable<RR.TextObjectSet | undefined> {
    return this.store.select(fromTextObject.selectTextObject(this.choice_object.text_object_id)).pipe(
      map((textObject) => {
        if (textObject && textObject.type !== 'set') {
          throw new Error('TextObject is not of type set');
        }
        return textObject;
      }),
    );
  }

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent) {
    this.tabAttributes.emit(event);
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string }>) {
    this.searchQuery.setValue(event.detail.term);
    this.usedVoiceMode = true;
  }

  ngOnInit() {
    if (window.__e2e__) {
      console.warn('Do not hide attributes for e2e test');
      this.showAllAttributes = true;
    }

    this.subscription.add(
      this.editorService.globalSearchTerm$
        .pipe(skip(1), debounceTime(200), distinctUntilChanged())
        .subscribe((response) => {
          if (response?.term) {
            this.searchQuery.setValue(response.term);
          }
        }),
    );

    const equivalentTextObject$ = this.getRelatedTextObject().pipe(
      tap((textObject) => {
        this.equivalentTextObject = textObject;
      }),
    );

    this.subscription.add(
      equivalentTextObject$
        .pipe(
          switchMap((equivalentTextObject) =>
            this.reportService.getChoiceParents(this.choice).pipe(
              switchMap((ctx) => {
                if (ctx.topic && equivalentTextObject && ctx.region_choice) {
                  return this.templateService.getDefaultAttribute(
                    ctx.topic.template_id,
                    equivalentTextObject.id,
                    ctx.region_choice.region_id,
                  );
                }
                return of(null);
              }),
              switchMap((tda) => {
                let order: number[] = [];
                if (equivalentTextObject) {
                  order = order
                    .concat(equivalentTextObject.shortlist)
                    .concat(tda?.default_option_id || [])
                    .filter((o) => !!o);
                }
                return this.templateService.getAttributeSetOptions(this.choice_object.attribute_set_id).pipe(
                  map((attributeOptions) => {
                    return attributeOptions.filter((o) => order.includes(o.id));
                  }),
                );
              }),
            ),
          ),
        )
        .subscribe((attribute_options) => {
          this.topAttributes = this.filter(attribute_options);
          this.getSentenceAttributeSetShortlist({ text_object_id: this.choice_object.text_object_id });
          this.cd.markForCheck();
        }),
    );

    this.subscription.add(
      this.templateService
        .getAttributeSetOptions(this.choice_object.attribute_set_id)
        .subscribe((attribute_options) => {
          this.bottomAttributes = attribute_options;
          // select the chosen attribute
          this.selectById(this.choice_object.attribute_option_id);
          this.cd.markForCheck();
        }),
    );

    this.subscription.add(
      this.templateService.getAttributeSet(this.choice_object.attribute_set_id).subscribe((attributeSet) => {
        this.attributeSet = attributeSet;
      }),
    );

    this.subscription.add(
      fromEvent(this.el.nativeElement, 'keydown')
        .pipe(throttleTime(50))
        .subscribe((event) => {
          if (!(event instanceof KeyboardEvent)) {
            throw new Error(`Unexpected event ${event.type}`);
          }
          if (!this.selectedAttribute) return;
          let attributes: RR.AttributeOption[] = [];
          if (this.isSearchingAttribute) {
            attributes = this.attributeSearchHits.map((r) => r.store_attribute_option);
          } else if (this.selectedAttribute.half === 'top') {
            attributes = this.topAttributes;
          } else if (this.selectedAttribute.half === 'bottom') {
            attributes = this.bottomAttributes;
          }
          let index = 0;
          index = attributes.indexOf(this.selectedAttribute.attribute);
          let offset = 0;
          if (event.key === 'ArrowUp') {
            offset = -1;
            event.preventDefault();
          } else if (event.key === 'ArrowDown') {
            offset = 1;
            event.preventDefault();
          } else {
            return;
          }
          if (index + offset >= attributes.length && this.selectedAttribute.half === 'top') {
            // change to bottom half
            attributes = this.bottomAttributes;
            const nextAttribute = attributes[0];
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (nextAttribute) {
              this.selectedAttribute = {
                attribute: nextAttribute,
                half: 'bottom',
              };
              this.scrollIntoView();
            }
          } else if (index + offset < 0 && this.selectedAttribute.half === 'bottom') {
            // change to top half
            attributes = this.topAttributes;
            const nextAttribute = attributes[this.topAttributes.length - 1];
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (nextAttribute) {
              this.selectedAttribute = {
                attribute: nextAttribute,
                half: 'top',
              };
              this.scrollIntoView();
            }
          } else {
            const nextAttribute = attributes[index + offset];
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (nextAttribute) {
              this.selectedAttribute = {
                attribute: nextAttribute,
                half: this.selectedAttribute.half,
              };
              this.scrollIntoView();
            }
          }
        }),
    );

    const searchText$ = this.searchQuery.valueChanges.pipe(
      distinctUntilChanged(),
      filter((searchText) => {
        if (searchText === '') {
          this.isSearchingAttribute = false;
        }
        return searchText !== '';
      }),
    );

    this.subscription.add(
      searchText$
        .pipe(
          debounceTime(500),
          withLatestFrom(this.choice_object$),
          switchMap(([searchText, choiceObject]) => {
            if (!choiceObject.attribute_set_id) {
              return EMPTY;
            }
            return this.searchForSimilarAttribute(searchText, this.choice_object.attribute_set_id).pipe(
              this.messageService.handleHttpErrorPipe,
            );
          }),
        )
        .subscribe((searchResult) => {
          const hits = searchResult.hits.hits;
          if (this.searchQuery.value && hits.length > 0) {
            this.isSearchingAttribute = true;
          }
          this.attributeSearchHits = hits;
          // Repeat back search text for user feedback
          this.scrollToTop();
          this.shortlistSelectFirst();
          // Ignore any numbers because autocomplete doesn't work well with them
          // Todo: User setting to turn off all together.
          if (
            this.usedVoiceMode &&
            hits.find(
              (r) =>
                isNaN(Number(this.searchQuery.value)) &&
                r.store_attribute_option.text.toLowerCase() === this.searchQuery.value.toLowerCase(),
            )
          ) {
            this.onEnter();
          }
          // Set back to false so that it doesn't auto submit when typing. If voice is used again it will be set to true
          // in the `voiceEvent` listener.
          this.usedVoiceMode = false;
          this.cd.markForCheck();
        }),
    );

    this.subscription.add(
      this.choice_object$.subscribe((choiceObject) => {
        // set Blue Attribute label text
        if (!choiceObject.attribute_option_id) {
          this.name = choiceObject.text;
        }
      }),
    );
  }

  scrollIntoView() {
    if (!this.selectedAttribute) {
      return;
    }
    let element: HTMLElement | undefined;
    if (this.isSearchingAttribute) {
      element = this.searchResultElement.nativeElement;
    } else if (this.selectedAttribute.half === 'top') {
      element = this.topElement.nativeElement;
    } else if (this.selectedAttribute.half === 'bottom') {
      element = this.bottomElement.nativeElement;
    }
    if (element) {
      const attributeEl: HTMLElement | null = element.querySelector(
        `[data-attribute-id="${this.selectedAttribute.attribute.id}"]`,
      );
      if (attributeEl) {
        scrollIntoView({ element: attributeEl, parent: this.scrollElement.nativeElement });
      }
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickAttribute(attribute_option: RR.AttributeOption) {
    this.toggleAttribute(attribute_option, true);
  }

  toggleAttribute(attribute_option: RR.AttributeOption, emitEnter = false) {
    // Toggle buttons are visible even to viewers so check authorisation first
    this.subscription.add(
      this.sessionEffect
        .authorise({ only: ['report_manage'] })
        .pipe(
          take(1),
          filter((a) => a),
          switchMap(() => {
            return this.reportService
              .editTextObject(this.choice_object.id, {
                attribute_option_id: attribute_option.id,
                text: attribute_option.text,
              })
              .pipe(take(1));
          }),
        )
        .subscribe(() => {
          if (emitEnter) this.enter.emit();
        }),
    );
  }

  onAttributeDelete(deleted_attribute: RR.AttributeOption) {
    this.attributeSearchHits = this.attributeSearchHits.filter(
      (a) => a.store_attribute_option.id !== deleted_attribute.id,
    );
  }

  addAdminAttribute() {
    const text = this.searchQuery.value;
    if (this.selectedAttribute?.attribute.text === text) {
      // Convenience thing: it selects the first one if you typed exactly
      // instead of creating a blue attribute
      this.onEnter();
    } else {
      this.addAttributes(text);
    }
    this.searchQuery.setValue('');
    this.isSearchingAttribute = false;
  }

  addBlueAttribute() {
    requestAnimationFrame(() => {
      // Make sure the popover disappears before we update variables that change inside it ("BLANK")
      const text = this.searchQuery.value;
      if (this.selectedAttribute?.attribute.text === text) {
        // Convenience thing: it selects the first one if you typed exactly
        // instead of creating a blue attribute
        this.onEnter();
      } else {
        this.editAttribute(text);
      }
      this.searchQuery.setValue('');
      this.isSearchingAttribute = false;
    });
  }

  clearField() {
    this.searchQuery.setValue('');
  }

  clickBluePlus() {
    const text = this.searchQuery.value;
    if (this.selectedAttribute?.attribute.text === text) {
      this.onEnter();
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (this.popover) {
        this.popover.open();
      }
    }
  }

  onEnter() {
    if (this.selectedAttribute != null) {
      this.toggleAttribute(this.selectedAttribute.attribute, true);
    }
  }

  selectById(attribute_option_id: number) {
    if (this.isSearchingAttribute && this.attributeSearchHits.length) {
      const attribute_option = this.attributeSearchHits.find(
        (r) => r.store_attribute_option.id === attribute_option_id,
      );
      if (attribute_option) {
        this.selectedAttribute = {
          attribute: attribute_option.store_attribute_option,
          half: null,
        };
      }
    } else {
      if (this.topAttributes.length) {
        const attribute = this.topAttributes.find((a) => a.id === attribute_option_id);
        if (attribute) {
          this.selectedAttribute = {
            attribute,
            half: 'top',
          };
        }
      } else if (this.bottomAttributes.length) {
        const attribute = this.bottomAttributes.find((a) => a.id === attribute_option_id);
        if (attribute) {
          this.selectedAttribute = {
            attribute,
            half: 'bottom',
          };
        }
      }
    }
  }

  shortlistSelectFirst() {
    if (this.isSearchingAttribute && this.attributeSearchHits.length) {
      this.selectedAttribute = {
        attribute: this.attributeSearchHits[0].store_attribute_option,
        half: null,
      };
      // TODO: disabled because this was adding the first attribute to the shortlist, even if it didn't match.
      // if (this.voiceMode) this.addToShortlist(this.attributeSearchResult[0].store_attribute_option.id);
    } else {
      if (this.topAttributes.length) {
        this.selectedAttribute = {
          attribute: this.topAttributes[0],
          half: 'top',
        };
        // if (this.voiceMode) this.addToShortlist(this.topAttributes[0].id);
      } else if (this.bottomAttributes.length) {
        this.selectedAttribute = {
          attribute: this.bottomAttributes[0],
          half: 'bottom',
        };
        // if (this.voiceMode) this.addToShortlist(this.bottomAttributes[0].id);
      }
    }
  }

  // Modified function from attribute-option.comp
  // Adds attribute to shortlist if not already there
  addToShortlist(attributeId: number) {
    if (!this.equivalentTextObject) return; // there is no TextObject to update

    // Copy the current state of the suggested values so we can edit it
    const values = [...this.equivalentTextObject.shortlist];

    if (values.includes(attributeId)) {
      // If the value already exists within the array, do nothing.
      return;
    } else {
      // Otherwise add it to the array
      values.push(attributeId);
    }
    this.subscription.add(
      this.textObjectEffect.update(this.equivalentTextObject.id, { shortlist: values }).subscribe(),
    );
  }

  filter(attribute_options: RR.AttributeOption[]) {
    return attribute_options.sort((o, j) => o.text.localeCompare(j.text));
  }

  editAttribute(attributeText: string) {
    this.editing = false;
    this.reportService
      .editTextObject(this.choice_object.id, {
        text: attributeText,
      })
      // TODO: The component is being destroyed before the effect completes and the request gets cancelled.
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe();
  }

  scrollToTop() {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (this.scrollElement) this.scrollElement.nativeElement.scrollTop = 0;
  }

  approveBlueAttribute() {
    this.addAttributes(this.name);
  }

  addAttributes(text: string) {
    this.searchQuery.setValue('');
    const data = {
      text,
      attribute_set_id: this.choice_object.attribute_set_id,
    };
    this.subscription.add(
      this.attributeOptionEffect
        .create(data)
        .pipe(take(1))
        .subscribe((action) => {
          const attribute = action.actions.findAttributeOptionSuccess.attributeOption;
          // doesn't emitEnter (so we don't close the dropdown and jump to next attribute)
          this.toggleAttribute(attribute);
        }),
    );
  }

  getSentenceAttributeSetShortlist({ text_object_id, filtering }: { text_object_id: number; filtering?: boolean }) {
    this.loadingShortList = true;
    this.subscription.add(
      this.attributeOptionService
        .getTopAttributes({ text_object_id, limit: 5, statement_choice_id: this.choice.id, filtering })
        .pipe(
          finalize(() => {
            this.loadingShortList = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe((response) => {
          this.shortListResponses = response;
          this.cd.markForCheck();
        }),
    );
  }

  selectAttributeOption = fromAttributeOption.selectAttributeOption;

  getSelectedBottomAttribute() {
    // Return null if the selected attribute is in top list
    const selectedTop = this.topAttributes.find((opt) => opt.id === this.choice_object.attribute_option_id);
    return selectedTop ? null : this.bottomAttributes.find((opt) => opt.id === this.choice_object.attribute_option_id);
  }

  searchForSimilarAttribute(attributeSearchText: string, attribute_set_id: number) {
    return this.http.post<AttributeSearchResult>('/api/attribute_option_search', {
      search: attributeSearchText,
      attribute_set_id,
    });
  }
}
