import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReferrerNameComponent } from 'app/shared/components/referrer-name/referrer-name.component';

import { ReferrerPreferredContactsComponent } from '../referrer-preferred-contacts/referrer-preferred-contacts.component';
import { SoftwarePreferencesTextComponent } from '../software-preferences-text/software-preferences-text.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReferrerNameComponent, ReferrerPreferredContactsComponent, SoftwarePreferencesTextComponent],
  selector: 'rr-referrer-form-view',
  templateUrl: './referrer-form-view.component.html',
  styleUrls: ['./referrer-form-view.component.css'],
})
export class ReferrerFormViewComponent implements OnInit {
  @Input() referrer: RR.Referrer;
  @Input() invalid: boolean;
  @Output() onClear = new EventEmitter();
  onClearObserved = false;

  ngOnInit(): void {
    // Check if onClear has any event listeners
    if (this.onClear.observed) {
      this.onClearObserved = true;
    }
  }
}
