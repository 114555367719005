import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { ExactMatchTopicEffect } from 'app/store/exact-match-topic/exact-match-topic.effect';
import { fromExactMatchTopic } from 'app/store/exact-match-topic/exact-match-topic.selector';
import { fromUserSetting } from 'app/store/user/user-setting';
import {
  debounceTime,
  delayWhen,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  map,
  Observable,
  Subscription,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';

import { SharedModule } from '../../../../shared/shared.module';
import { PrefillExactMatchRowComponent } from '../prefill-row/prefill-exact-match-row.component';
import { PrefillService } from '../prefill.service';

@Component({
  selector: 'rr-prefill-search-report-sentences',
  templateUrl: './prefill-search-report-sentences.component.html',
  styleUrls: ['./prefill-search-report-sentences.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, PrefillExactMatchRowComponent],
})
export class PrefillSearchReportSentencesComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic_id: number;
  topic_id$: Observable<number>;

  searchState: 'INITIAL' | 'LOADED' = 'INITIAL';

  prefillExpanded$: Observable<boolean>;
  searching = false;

  // Search results
  similarTopics$: Observable<RR.ExactMatchTopic[] | null>;
  subscription = new Subscription();

  BOOST_SCORES = [1, 2, 3, 4, 5];

  searchForm = new FormGroup({
    title: new FormControl(3, { nonNullable: true }),
    age: new FormControl(5, { nonNullable: true }),
    rarity: new FormControl(5, { nonNullable: true }),
    recency: new FormControl(1, { nonNullable: true }),
  });

  constructor(
    private editorService: EditorService,
    private reportService: ReportService,
    public prefillService: PrefillService,
    private store: Store<AppState>,
    private cd: ChangeDetectorRef,
    private exactMatchTopicEffect: ExactMatchTopicEffect,
  ) {
    this.prefillExpanded$ = this.editorService.prefill.pipe(filter((p) => p));
  }

  ngOnInit(): void {
    this.subscription.add(
      this.topic_id$.subscribe((_) => {
        // Reset form value on topic changed
        this.searchForm.patchValue({ title: 3, age: 5, rarity: 5, recency: 1 });
      }),
    );

    this.subscription.add(
      this.reportService
        .selectKioskUser()
        .pipe(
          filterDefined(),
          switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))),
          map((userSettings) => userSettings?.show_exact_match_search || false),
          distinctUntilChanged(),
        )
        .subscribe((showExactMatchSearch) => {
          if (showExactMatchSearch) {
            this.prefillService.exactMatchOpen$.next(true);
            // Default to combine search result if exact match search open by default
            this.prefillService.changePrefillSource$.next('COMBINE');
          }
        }),
    );

    // Auto open exact match if click on combine or top6 exact match button
    this.subscription.add(
      this.prefillService.selectPrefillTopics$
        .pipe(withLatestFrom(this.prefillService.exactMatchOpen$))
        .subscribe(([select, open]) => {
          if ((select.type === 'COMBINE' || select.type === 'TOP6_EXACT_MATCH') && !open) {
            this.prefillService.exactMatchOpen$.next(true);
          }
        }),
    );

    // Subscribe to refresh exact match event (can be trigger from Divider Modal)
    this.subscription.add(
      this.prefillService.refreshExactMatch$
        .pipe(
          withLatestFrom(this.prefillService.exactMatchOpen$),
          switchMap(([val, open]) => {
            if (!open) {
              this.prefillService.exactMatchOpen$.next(true);
            }
            if (val === 'TOP6_EXACT_MATCH') {
              this.prefillService.changePrefillSource$.next('EXACT_MATCH');
            }

            return this.findAllExactMatchTopics();
          }),
        )
        .subscribe(() => {
          this.searching = false;
        }),
    );

    // Do not refresh the prefill unless the "Show B3 Results" is clicked
    this.subscription.add(
      this.prefillService.exactMatchOpen$
        .pipe(
          delayWhen(() => this.prefillExpanded$),
          debounceTime(300),
          filter((open) => open && this.searchState === 'INITIAL'),
          switchMap(() => {
            const data = this.getSearchData();
            return this.store.select(fromExactMatchTopic.selectLoaded).pipe(
              take(1),
              switchMap((loaded) => {
                if (!loaded) {
                  this.searching = true;
                  return this.exactMatchTopicEffect.findAll(data).pipe(
                    finalize(() => {
                      this.searching = false;
                      this.searchState = 'LOADED';
                      this.prefillService.isExactMatchResultsLoading$.next(false);
                      this.cd.markForCheck();
                    }),
                  );
                }
                return EMPTY;
              }),
            );
          }),
        )
        .subscribe(),
    );

    // Refresh prefill if there is any form change
    this.subscription.add(
      this.searchForm.valueChanges
        .pipe(
          switchMap((form) => {
            if (form.age && form.rarity && form.recency && form.title && this.searchState === 'LOADED') {
              return this.findAllExactMatchTopics();
            }
            return EMPTY;
          }),
        )
        .subscribe(() => {
          this.searching = false;
        }),
    );

    this.similarTopics$ = this.store.select(fromExactMatchTopic.selectAll);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  toggleExactMatch() {
    this.subscription.add(
      this.prefillService.exactMatchOpen$.pipe(take(1)).subscribe((val) => {
        this.prefillService.exactMatchOpen$.next(!val);
        // If closing the exact match, set selection source to METADATA
        if (val) {
          this.prefillService.changePrefillSource$.next('METADATA');
        }
      }),
    );
  }

  findAllExactMatchTopics() {
    this.searching = true;
    const data = this.getSearchData();
    return this.exactMatchTopicEffect.findAll(data);
  }

  // Refresh prefill if refresh button is clicked
  refresh() {
    this.subscription.add(
      this.findAllExactMatchTopics().subscribe(() => {
        this.searching = false;
      }),
    );
  }

  getSearchData() {
    const form = this.searchForm.getRawValue();
    return {
      age_metric: form.age,
      rarity_metric: form.rarity,
      recency_metric: form.recency,
      title_metric: form.title,
      topicId: this.topic_id,
    };
  }
}
