import { createReducer, on } from '@ngrx/store';

import { TopicActions } from '../report/topic/topic.action';
import { EditorActions } from './editor.action';
import { EditorState, TopicState } from './editor.state';

const initialState: EditorState = {
  topics: {},
  editMode: false,
  selectedStatements: new Set(),
  prefill: false,
  templateChooser: false,
  underlinePrefilled: { underline: false, choice_ids: new Set() },
  rightPaneViewMode: 'REPORT_PREVIEW',
  lastReportAccessEvent: undefined,
  scheduleSendToVoyager: undefined,
  recentCategories: [],
  recentTop20Categories: [],
  clipboard: undefined,
  sidebarOpen: false,
  voiceResults: [],
  elementFilterType: 'NONE',
};

const initialTopicState: TopicState = {
  prefillPreview: { topicIds: [] },
  savedGlobalSearches: [],
  adjacentStatement: {
    id: null,
    element_id: null,
    topic_id: null,
    region_id: null,
  },
  globalSearch: '',
  breadcrumb: {
    section_id: null,
    subsection_id: null,
    region_id: null,
    element_id: null,
  },
  lastClickedBreadcrumb: null,
};

function setTopicState(state: EditorState, topic_id: number, newTopicState: Partial<TopicState>) {
  const topicState = state.topics[topic_id];
  if (!topicState && newTopicState !== initialTopicState) {
    throw new Error('Topic state has not been initialised');
  }
  return {
    ...state,
    topics: {
      ...state.topics,
      [topic_id]: {
        ...topicState,
        ...newTopicState,
      },
    },
  };
}

function getTopicState(state: EditorState, topicId: number) {
  const topicState = state.topics[topicId];
  if (!topicState) {
    throw new Error('Topic not found');
  }
  return topicState;
}

export const editorReducer = createReducer(
  initialState,
  // Reset some states on editor open
  on(EditorActions.open, (state) => ({
    ...state,
    topics: {},
    prefill: false,
    templateChooser: false,
    underlinePrefilled: { underline: false, choice_ids: new Set<number>() },
    rightPaneViewMode: 'REPORT_PREVIEW' as const,
    lastReportAccessEvent: undefined,
    voiceResults: [],
  })),
  // Edit mode
  on(EditorActions.toggleEditMode, (state, { editMode }) => ({
    ...state,
    editMode: editMode !== undefined ? editMode : !state.editMode,
    selectedStatements: new Set<number>(),
  })),
  // Select statement
  on(EditorActions.selectStatement, (state, { statementId }) => {
    const selectedStatements = new Set(state.selectedStatements);
    if (selectedStatements.has(statementId)) {
      selectedStatements.delete(statementId);
    } else {
      selectedStatements.add(statementId);
    }
    return { ...state, selectedStatements };
  }),
  on(EditorActions.clearSelectedStatements, (state) => ({ ...state, selectedStatements: new Set<number>() })),
  // Prefill
  on(EditorActions.togglePrefill, (state, { prefill }) => ({
    ...state,
    prefill: prefill !== undefined ? prefill : !state.prefill,
  })),
  // Template Chooser
  on(EditorActions.toggleTemplateChooser, (state, { templateChooser }) => ({
    ...state,
    templateChooser: templateChooser !== undefined ? templateChooser : !state.templateChooser,
  })),
  // Right pane view mode
  on(EditorActions.toggleRightPaneViewMode, (state, { mode }) => ({
    ...state,
    rightPaneViewMode:
      state.rightPaneViewMode === mode && (mode === 'AUDIT' || mode === 'SUGGESTIONS') ? 'REPORT_PREVIEW' : mode,
  })),
  // Schedule send to Voyager
  on(EditorActions.setScheduleSendToVoyagerTask, (state, { reportId }) => ({
    ...state,
    scheduleSendToVoyager: reportId,
  })),
  // Clipboard
  on(EditorActions.saveClipboardSentence, (state, { sentence }) => ({ ...state, clipboard: sentence })),
  // Open sidebar
  on(EditorActions.openSidebar, (state, { open }) => ({
    ...state,
    sidebarOpen: open !== undefined ? open : !state.sidebarOpen,
  })),
  // Add voice result to list
  on(EditorActions.addVoiceSearchResult, (state, { term, note_type, subsection }) => ({
    ...state,
    voiceResults: [
      ...state.voiceResults,
      {
        term,
        note_type,
        subsection,
      },
    ],
  })),
  on(EditorActions.addVoiceNotes, (state, { voiceResults }) => {
    return {
      ...state,
      voiceResults: [...state.voiceResults, ...voiceResults],
    };
  }),
  // Clear voice results
  on(EditorActions.clearVoiceSearchReults, (state) => ({
    ...state,
    voiceResults: [],
  })),
  on(EditorActions.removeVoiceSearchResult, (state, { voiceResult }) => ({
    ...state,
    voiceResults: state.voiceResults.filter((r) => r !== voiceResult),
  })),
  // Element filter type
  on(EditorActions.updateElementFilter, (state, { filterType }) => {
    return { ...state, elementFilterType: state.elementFilterType === filterType ? 'NONE' : filterType };
  }),
  // Most recent category
  on(EditorActions.saveMostRecentCategory, (state, { categoryId }) => {
    const categories = [...state.recentCategories.filter((id) => id !== categoryId), categoryId].slice(-10);
    return { ...state, recentCategories: categories };
  }),
  on(EditorActions.saveMostRecentTop20Category, (state, { categoryId }) => {
    const categories = [...state.recentTop20Categories.filter((id) => id !== categoryId), categoryId].slice(-10);
    return { ...state, recentTop20Categories: categories };
  }),
  // Last report access event
  on(EditorActions.saveLastReportAccessEvent, (state, { reportAccessEvent }) => ({
    ...state,
    lastReportAccessEvent: reportAccessEvent,
  })),
  // Prefilled Underline
  on(EditorActions.togglePrefilledUnderline, (state, { underline, choice_ids }) => ({
    ...state,
    underlinePrefilled: {
      underline: underline !== undefined ? underline : !state.underlinePrefilled.underline,
      choice_ids: choice_ids || new Set(),
    },
  })),
  // Dispatch from editor effect after copying choices success
  on(EditorActions.underlinePrefilledChoices, (state, { choice_ids }) => ({
    ...state,
    underlinePrefilled: {
      underline: true,
      choice_ids,
    },
  })),
  // Topic
  on(TopicActions.openTopic, (state, { topicId }) => {
    if (state.topics[topicId]) {
      return state;
    }
    return setTopicState(state, topicId, initialTopicState);
  }),
  on(EditorActions.setPrefillTopicPreview, (state, { openTopicId, prefillPreview }) =>
    setTopicState(state, openTopicId, { prefillPreview }),
  ),
  on(EditorActions.addTopicToPrefillPreview, (state, { openTopicId, topicId }) => {
    const topicState = getTopicState(state, openTopicId);
    const prefillPreview = topicState.prefillPreview;
    const topic_ids = prefillPreview.topicIds.includes(topicId)
      ? prefillPreview.topicIds
      : [...prefillPreview.topicIds, topicId];
    return setTopicState(state, openTopicId, {
      prefillPreview: { ...prefillPreview, topicIds: topic_ids },
    });
  }),
  on(EditorActions.removeTopicFromPrefillPreview, (state, { openTopicId, topicId }) => {
    const topicState = getTopicState(state, openTopicId);
    const prefillPreview = topicState.prefillPreview;
    const topic_ids = prefillPreview.topicIds.filter((id) => id !== topicId);
    return setTopicState(state, openTopicId, {
      prefillPreview: { ...prefillPreview, topicIds: topic_ids },
    });
  }),
  on(EditorActions.doGlobalSearch, (state, { openTopicId, text }) =>
    setTopicState(state, openTopicId, { globalSearch: text }),
  ),
  on(EditorActions.saveGlobalSearch, (state, { openTopicId, text }) => {
    const topicState = getTopicState(state, openTopicId);
    const savedGlobalSearches = [...topicState.savedGlobalSearches];
    const indexGlobal = savedGlobalSearches.findIndex((t) => t === text);
    if (indexGlobal !== -1) {
      savedGlobalSearches.splice(indexGlobal);
    }
    savedGlobalSearches.unshift(text);
    return setTopicState(state, openTopicId, { savedGlobalSearches });
  }),
  on(EditorActions.saveAdjacentStatement, (state, { openTopicId, adjacentStatement }) =>
    setTopicState(state, openTopicId, { adjacentStatement }),
  ),
  on(EditorActions.setBreadcrumb, (state, { openTopicId, breadcrumb }) =>
    setTopicState(state, openTopicId, { breadcrumb }),
  ),
  on(EditorActions.setLastClickedBreadcrumb, (state, { openTopicId, lastClickedBreadcrumb }) =>
    setTopicState(state, openTopicId, { lastClickedBreadcrumb }),
  ),
);
