<ng-container *ngIf="!shouldHide">
  <ng-template #popContent>
    <div class="btn-group-vertical">
      <button
        *ngIf="unresolvedTodos.length === 0"
        (click)="toggleFlagged()"
        class="btn btn-sm btn-info"
        rrTooltip="STATEMENT_MEDICAL_NOTE"
        data-no-bubble-choice
      >
        <span class="fa fa-notes-medical"></span> Medical Note
      </button>
      <!-- Buttons for moving sentence directly -->
      <button
        *ngIf="
          !cloneMap['comment'] &&
          (section_choice.type === 'findings' ||
            section_choice.type === 'technique' ||
            section_choice.type === 'impression_recommendations')
        "
        (click)="toggleClone('comment')"
        class="btn btn-light"
      >
        <span class="fa fa-comment text-danger"></span> Comment
      </button>
      <button
        *ngIf="!cloneMap['key_finding'] && (section_choice.type === 'findings' || section_choice.type === 'technique')"
        (click)="toggleClone('key_finding')"
        class="btn btn-light"
      >
        <span class="fa fa-asterisk text-danger"></span> Key Finding
      </button>
      <button
        *ngIf="
          !cloneMap['impression_recommendations'] &&
          (section_choice.type === 'findings' ||
            section_choice.type === 'technique' ||
            section_choice.type === 'comment')
        "
        (click)="toggleClone('impression_recommendations')"
        class="btn btn-light"
      >
        <span class="fa fa-italic text-danger"></span> Impression
      </button>

      <button
        class="btn btn-sm btn-info"
        rrTooltip="PATIENT_STATEMENT_NOTE"
        data-no-bubble-choice
        (click)="generateAndAddSentence()"
      >
        <span class="fa fa-plus me-1"></span> Patient Note
      </button>

      <!-- This remove button is only shown in "final" mode? -->
      <button (click)="remove()" class="btn btn-light" *ngIf="mode === 'final'">
        <span class="fa fa-trash text-danger"></span> Remove
      </button>

      <button
        (click)="categoriseSentence()"
        *ngIf="mode === 'editor' && !isComposite && choice.statement_id && (is_in_sc_mode$ | async)"
        class="btn btn-light"
        [title]="sentenceCategoriesTooltip | async"
      >
        <span
          class="fa fa-layer-group"
          [ngClass]="(isSentenceCategorised | async) ? 'text-success' : 'text-danger'"
        ></span>
        Categorise Statement
      </button>
    </div>
  </ng-template>
  <div hidden data-cy="hidden-text">
    <!-- So e2e tests can detect if the choice is in the preview -->
    <ng-container *ngFor="let obj of text_object_choices">
      <ng-container *ngIf="obj.type === 'literal' || obj.text">{{ obj.text }}</ng-container>
      <ng-container *ngIf="obj.type === 'number' && !obj.text && obj.formula">{{ obj.formula }}</ng-container>
      <ng-container *ngIf="!obj.text && (obj.type !== 'number' || !obj.formula)">**</ng-container>
    </ng-container>
  </div>
  <div
    [class.text-muted]="excluded"
    *ngrxLet="underlinePrefilled$ as underlinePrefilled"
    class="content newline my-2 preview-statement-text px-1"
    #p="ngbPopover"
    [ngbPopover]="popContent"
    placement="left left-bottom"
    container="body"
    triggers="manual"
    popoverClass="popover-no-padding"
    [class.rr-choice-highlight]="highlight | async"
    [class.blue]="!choice.statement_id"
    [class.bolt-blue]="choice.source === 'voice_notes'"
    [class.prefilled]="
      choice_id != null &&
      choice.prefilled_from_choice_id &&
      underlinePrefilled.underline &&
      underlinePrefilled.choice_ids.has(choice_id)
    "
  >
    <div class="d-inline-block mb-2" [title]="reason">
      <rr-todo-tooltip
        *ngIf="todosForFlag && todosForFlag.length > 0"
        [todos]="todosForFlag"
        (onToggleFlagged)="toggleFlagged()"
      ></rr-todo-tooltip>
      <span
        (click)="toggleClone('key_finding'); $event.stopPropagation()"
        class="fa fa-asterisk pe-1 text-danger"
        role="button"
        *ngIf="cloneMap['key_finding']"
        title="Move back to {{ section_choice.type }}"
      ></span>
      <span
        role="button"
        (click)="toggleClone('comment'); $event.stopPropagation()"
        class="fa fa-comment pe-1 text-danger"
        *ngIf="cloneMap['comment']"
        title="Move back to {{ section_choice.type }}"
      ></span>
      <span
        role="button"
        (click)="toggleClone('impression_recommendations'); $event.stopPropagation()"
        class="fa fa-italic pe-1 text-danger"
        *ngIf="cloneMap['impression_recommendations']"
        title="Move back to {{ section_choice.type }}"
      ></span>
      <span
        class="fa fa-eye-slash pe-1 text-muted"
        role="button"
        (click)="toggleExcluded(); $event.stopPropagation()"
        rrTooltip="UNEXCLUDE_SENTENCE"
        *ngIf="excluded"
        placement="right"
      ></span>
      <button
        *ngIf="statementDefaultSection && !cloneMap[statementDefaultSection]"
        class="btn btn-sm btn-warning flash"
        role="button"
        [ngbTooltip]="'Moved from default section: ' + formattedTextDefaultSection + '. Click to restore.'"
        data-no-bubble-choice
        (click)="toggleClone(statementDefaultSection); $event.stopPropagation()"
      >
        <i class="fas fa-exclamation"></i>
      </button>
      <span rrTooltip="RECOMMENDED_STATEMENT" class="text-info me-1">
        <button
          class="btn btn-sm btn-outline-secondary"
          *ngIf="choice.source === 'voice_notes'"
          (click)="openDividerStatementsModal()"
          rrTooltip="OPEN_GROUP_MODAL"
        >
          <i class="fa fa-eye fa-sm"></i>
        </button>
      </span>
      <p class="d-inline-block w-100" *ngIf="choice_id == null" [linkify]="displayText" title="{{ reason }}"></p>
      <span
        [ngbTooltip]="choice.source === 'voice_notes' ? 'Recommended via Voice-Note' : ''"
        *ngIf="choice_id != null"
        title="{{ reason }}"
      >
        <ng-container *ngFor="let obj of text_object_choices; let first = first; let index = index">
          <div class="d-inline" *ngIf="obj.type === 'literal'" [linkify]="obj.text"></div>
          <span
            class="d-inline btn-attribute"
            [class.text-info]="obj.type === 'set' && !obj.attribute_option_id"
            *ngIf="obj.type === 'set'"
            (click)="selectAttribute(obj, $event)"
            role="button"
            data-no-bubble-choice
            >{{ obj.text }}</span
          >
          <span
            class="d-inline btn-attribute"
            *ngIf="obj.type === 'number'"
            (click)="selectAttribute(obj, $event)"
            role="button"
            data-no-bubble-choice
          >
            <ng-container *ngIf="obj.text">{{ obj.text }}</ng-container
            ><ng-container *ngIf="!obj.text && obj.formula">{{ obj.formula }}</ng-container
            ><ng-container *ngIf="!obj.text && !obj.formula">**</ng-container>
          </span>

          <span
            class="d-inline btn-attribute"
            *ngIf="obj.type === 'date'"
            (click)="selectAttribute(obj, $event)"
            role="button"
            data-no-bubble-choice
          >
            <ng-container *ngIf="obj.text">{{ obj.text }}</ng-container>
            <ng-container *ngIf="obj.text === ''"
              ><rr-date-type [dateType]="obj.date_type"></rr-date-type
            ></ng-container>
          </span>
        </ng-container>
      </span>
    </div>
    <div class="item-btns-right btn-group item-hover" *ngIf="!isComposite && mode === 'editor'">
      <button
        *ngIf="!isComposite && (section_choice.type === 'history' || section_choice.type === 'findings')"
        (click)="toggleExcluded()"
        class="btn btn-info btn-sm"
        [rrTooltip]="excluded ? 'UNEXCLUDE_SENTENCE' : 'EXCLUDE_SENTENCE'"
        [ngClass]="excluded ? 'btn-danger' : 'btn-primary'"
        data-no-bubble-choice
      >
        <i class="fa fa-eye-slash"></i>
      </button>

      <button
        rrTooltip="REMOVE_STATEMENT_REPORT"
        class="btn btn-info btn-sm"
        role="button"
        (click)="remove(); $event.stopPropagation()"
        data-no-bubble-choice
      >
        <i class="fa fa-trash"></i>
      </button>
    </div>
  </div>
</ng-container>
