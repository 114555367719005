import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { EditorService } from 'app/core/services/editor.service';
import { AppState } from 'app/store';
import { TopicState } from 'app/store/editor';
import { fromExactMatchTopic } from 'app/store/exact-match-topic/exact-match-topic.selector';
import { PresetSearchResponse } from 'app/store/report/preset';
import { BehaviorSubject, combineLatest, filter, Observable, Subscription, take } from 'rxjs';

import { PrefillSearchMetadataComponent } from '../prefill-search-metadata/prefill-search-metadata.component';
import { PrefillSearchReportSentencesComponent } from '../prefill-search-report-sentences/prefill-search-report-sentences.component';
import { PrefillService } from '../prefill.service';
import { ESResult, PrefillSourceType } from '../prefill.types';

@Component({
  selector: 'rr-prefill-search-container',
  templateUrl: './prefill-search-container.component.html',
  styleUrls: ['./prefill-search-container.component.scss'],
  standalone: true,
  imports: [PrefillSearchMetadataComponent, PrefillSearchReportSentencesComponent, CommonModule],
})
export class PrefillSearchContainerComponent implements OnInit, OnDestroy {
  @Input() topic_id: number;
  @Input() report_id: number;

  showExactMatch$: BehaviorSubject<boolean>;
  prefillExpanded$: Observable<boolean>;

  metadataResults: ESResult | null;
  exactMatchResults: RR.ExactMatchTopic[] | null;
  exactMatchResults$: Observable<RR.ExactMatchTopic[] | null>;
  presets: RR.Report[] | null;
  addOneMoreTopic: boolean = false;

  /*
   * Selection source rules:
   *  - Default to select from metadata result
   *  - If user's setting is show exact match by default: auto switch to combine
   *  - Metadata search input changed and trigger new search:
   *    + If source type is COMBINE => keep COMBINE
   *    + If source type is EXACT_MATCH => switch to METADATA
   *  - Exact match search input changed, or show/hide exact match
   *    + Show exact match, or search input changed: keep COMBINE or switch to EXACT_MATCH if current source is METADATA
   *    + Hide exact match: set source type to METADATA
   *  - 'Show B3 Results' is used to manually load the exact match topics, as requested by RD. Since the
   *    exact match API response may take some time, the auto-refresh of the prefill was causing
   *    interruptions in user interactions.
   */
  selectionSourceType: PrefillSourceType = 'METADATA';

  subscription: Subscription = new Subscription();

  isPrefillPreviewLoading: boolean;

  constructor(
    private prefillService: PrefillService,
    private editorService: EditorService,
    private store: Store<AppState>,
  ) {
    this.showExactMatch$ = this.prefillService.exactMatchOpen$;
    this.prefillExpanded$ = this.editorService.prefill.pipe(filter((p) => p));
  }

  ngOnInit(): void {
    this.subscription.add(
      this.prefillService.changePrefillSource$.subscribe((sourceType) => {
        this.selectionSourceType = sourceType;
      }),
    );

    this.exactMatchResults$ = this.store.select(fromExactMatchTopic.selectAll);

    this.subscription.add(
      this.exactMatchResults$.subscribe((exactMatchResults) => {
        this.exactMatchResults = exactMatchResults;
      }),
    );

    this.subscription.add(
      this.prefillService.selectPrefillTopics$.subscribe((data) => {
        if (data.type === 'ONE_MORE') {
          // Select one more row from the current source type
          this.selectOneMoreTopic();
          this.addOneMoreTopic = true;
        } else if (data.type === 'TOP6_METADATA') {
          this.selectionSourceType = 'METADATA';
          this.selectTop6Metadata();
        } else if (data.type === 'TOP6_EXACT_MATCH') {
          this.selectionSourceType = 'EXACT_MATCH';
          this.selectTop6ExactMatch();
        } else if (data.type === 'COMBINE') {
          this.selectionSourceType = 'COMBINE';
          this.selectCombineTopics();
        }
      }),
    );

    // 'Show B3 Results' is displayed only when there are exact match results and the exact match tab is open.
    this.subscription.add(
      combineLatest([this.exactMatchResults$, this.showExactMatch$]).subscribe(
        ([exactMatchResults, isExactMatchOpen]) => {
          if (isExactMatchOpen && exactMatchResults && exactMatchResults.length > 0) {
            this.prefillService.isShowB3ResultsButtonVisible$.next(true);
          }
        },
      ),
    );

    // Load the topics when the 'Show B3 Results' button is clicked
    this.subscription.add(
      this.prefillService.showExactMatchResults$.subscribe((show) => {
        if (show) {
          if (this.selectionSourceType === 'COMBINE') {
            this.selectCombineTopics();
          } else {
            this.selectTop6ExactMatch();
          }
          // Set it to false so the button becomes hidden after being clicked
          this.prefillService.isShowB3ResultsButtonVisible$.next(false);
        }
      }),
    );

    this.subscription.add(
      this.prefillService.prefillPreviewLoading$.subscribe((loading) => {
        this.isPrefillPreviewLoading = loading;
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onPresetResultsChanged(results: PresetSearchResponse) {
    this.presets = results.reports;
  }

  onMetadataResultsChanged(results: ESResult | null) {
    this.metadataResults = results;

    // If adding one more topic does not need to change the state
    if (this.addOneMoreTopic) return;

    // Metadata search was triggered, switch to METADATA source if the source is EXACT_MATCH
    if (this.selectionSourceType === 'EXACT_MATCH') {
      this.selectionSourceType = 'METADATA';
    }

    // If B3 is set to open automatically the initial state is COMBINE
    // Or when changing between filter
    if (this.selectionSourceType === 'COMBINE') {
      this.selectCombineTopics();
    }

    // If B3 is not set to open automatically, show top 6
    if (this.selectionSourceType === 'METADATA') {
      this.selectTop6Metadata();
    }
  }

  selectCombineTopics() {
    // Combine top 8 from exact match, top 8 from metadata and top 8 from presets
    const hits = this.metadataResults?.hits.hits || [];

    const es_topic_ids: string[] = hits.slice(0, 8).map((hit: any) => this.getTopicIdFromHit(hit));

    const exact_match_topic_ids = (this.exactMatchResults || []).slice(0, 8).map((t) => t.topic_id.toString());

    const presetTopicIds = [
      ...new Set((this.presets || []).flatMap((preset) => preset.topic_ids.map((id) => id.toString()))),
    ].slice(0, 8);

    const selected_topic_ids = new Set([...es_topic_ids, ...exact_match_topic_ids, ...presetTopicIds]);
    this.prefillService.setPrefillPreviewTopics({
      openTopicId: this.topic_id,
      topicIds: [...selected_topic_ids],
    });
  }

  selectTop6ExactMatch() {
    if (this.selectionSourceType === 'METADATA') {
      this.selectionSourceType = 'EXACT_MATCH';
    }

    // Top 6 result from exact match
    this.subscription.add(
      this.exactMatchResults$.subscribe((exactMatchResults) => {
        const topic_ids = (exactMatchResults || []).slice(0, 6).map((t) => t.topic_id.toString());
        this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: topic_ids });
      }),
    );
  }

  selectTop6Metadata() {
    // Top 6 results from metadata
    const hits = this.metadataResults?.hits.hits || [];
    const topic_ids = hits.slice(0, 6).map((hit) => this.getTopicIdFromHit(hit));
    this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: topic_ids });
  }

  selectOneMoreTopic() {
    this.subscription.add(
      this.prefillService
        .getPrefillPreviewObject(this.topic_id)
        .pipe(take(1))
        .subscribe((previewObj) => {
          let addedTopicIds: string[] = [];
          if (this.selectionSourceType === 'METADATA') {
            // One more from metadata
            const id = this.getNextTopicIdFromResults('METADATA', previewObj);
            if (id) addedTopicIds = [id];
          } else if (this.selectionSourceType === 'EXACT_MATCH') {
            const id = this.getNextTopicIdFromResults('EXACT_MATCH', previewObj);
            if (id) addedTopicIds = [id];
          } else {
            // Combine: 1 more from metadata and one more from exact match
            const metadataId = this.getNextTopicIdFromResults('METADATA', previewObj);
            const exactMatchId = this.getNextTopicIdFromResults('EXACT_MATCH', previewObj);
            if (metadataId) addedTopicIds.push(metadataId);
            if (exactMatchId) addedTopicIds.push(exactMatchId);
          }
          if (addedTopicIds.length) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
            if (!previewObj || !previewObj.topicIds) {
              this.prefillService.setPrefillPreviewTopics({ openTopicId: this.topic_id, topicIds: addedTopicIds });
            } else {
              this.prefillService.setPrefillPreviewTopics({
                openTopicId: this.topic_id,
                topicIds: [...previewObj.topicIds, ...addedTopicIds],
              });
            }
          }
          this.addOneMoreTopic = false;
        }),
    );
  }

  getNextTopicIdFromResults(from: 'METADATA' | 'EXACT_MATCH', currentPreviewObj: TopicState['prefillPreview']) {
    if (from === 'METADATA') {
      // One more from metadata
      const hits = this.metadataResults?.hits.hits || [];
      if (!hits.length) return null;
      // PreviewObj might be null/undefined if store value has not been initialised yet
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
      const hit = hits.find((h) => !(currentPreviewObj.topicIds || []).includes(this.getTopicIdFromHit(h)));
      return hit ? this.getTopicIdFromHit(hit) : null;
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (from === 'EXACT_MATCH') {
      if (!this.exactMatchResults) return;
      const topic = this.exactMatchResults.find(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        (t: any) => !(currentPreviewObj.topicIds || []).includes(t.topic_id.toString()),
      );
      return topic ? topic.topic_id.toString() : null;
    }
    return null;
  }

  getTopicIdFromHit(hit: RR.ESTopic) {
    return hit._id;
  }
}
