<div class="alert alert-light" [class.border-danger]="invalid">
  <div class="d-flex">
    <div class="flex-grow-1 grid w-100" style="row-gap: 0">
      <div class="g-col-4">
        <strong>Referrer:</strong>
        <p><rr-referrer-name *ngIf="referrer" [referrerId]="referrer.id" [strong]="false"></rr-referrer-name></p>
      </div>
      <div class="g-col-4">
        <strong>Provider Number:</strong>
        <p>{{ referrer.medicare_provider_number }}</p>
      </div>
      <div class="g-col-4">
        <strong>Referrer Type:</strong>
        <p class="text-capitalize">{{ referrer.referrer_type }}</p>
      </div>
      <div class="g-col-4">
        <strong>Address:</strong>
        <p>{{ referrer.service }} {{ referrer.address }} {{ referrer.zip }}</p>
      </div>
      <div class="g-col-8" *ngIf="referrer.email">
        <strong>Email:</strong>
        <p class="text-wrap">{{ referrer.email }}</p>
      </div>
      <div class="g-col-4" *ngIf="referrer.phone_mobile">
        <strong>Mobile:</strong>
        <p>{{ referrer.phone_mobile }}</p>
      </div>
      <div class="g-col-4" *ngIf="referrer.phone_work">
        <strong>Work Phone:</strong>
        <p>{{ referrer.phone_work }}</p>
      </div>
      <div class="g-col-4" *ngIf="referrer.fax">
        <strong>Fax:</strong>
        <p>{{ referrer.fax }}</p>
      </div>

      <div class="g-col-4" *ngIf="referrer && referrer.contact_method_ids.length">
        <strong>Preferred Contact Method:</strong>
        <div>
          <rr-referrer-preferred-contacts [referrer_id]="referrer.id"></rr-referrer-preferred-contacts>
        </div>
      </div>

      <div class="g-col-4" *ngIf="referrer && referrer.software_preference_ids.length">
        <strong>Software Preferences:</strong>
        <div>
          <rr-software-preferences-text [referrerId]="referrer.id"></rr-software-preferences-text>
        </div>
      </div>

      <div class="g-col-4" *ngIf="referrer.collater_notes">
        <strong>Collater Notes:</strong>
        <p class="text-truncate" *ngIf="referrer.collater_notes; else noNotes">{{ referrer.collater_notes }}</p>
        <ng-template #noNotes><p>No</p></ng-template>
      </div>
    </div>
    <!-- Close button on the right -->
    <button
      *ngIf="onClearObserved"
      role="button"
      class="btn-close"
      (click)="onClear.emit()"
      rrTooltip="REGISTRAION_REMOVE_REFERRER"
    ></button>
  </div>

  <!-- Action buttons are passed in with Content Projection -->
  <div class="col d-flex flex-column">
    <ng-content select="[buttons]"></ng-content>
  </div>
</div>
