<ng-template #defaultTemplate>(Q)</ng-template>
<a
  class="{{ btnClass ? btnClass : '' }}"
  [ngClass]="{
    'btn-success': btnStatus && report.scan_code_id && report.patient_id && report.referrer_id,
    'btn-info': btnStatus && !(report.scan_code_id && report.patient_id && report.referrer_id),
  }"
  routerLink="/registration/report"
  [queryParams]="{ reportId: report.id }"
  [title]="
    report.scan_code_id && report.patient_id && report.referrer_id
      ? 'Open registration details.'
      : 'Navigate to registration page. The report is not ready for modality worklist.'
  "
  [target]="newTab ? '_blank' : ''"
>
  <div [rrNoContent]="defaultTemplate"><ng-content></ng-content></div>
</a>
