<ng-container *ngIf="loading">
  <div class="d-flex justify-content-center align-items-center h-100">
    <i class="fa fa-spinner fa-spin fa-2x text-secondary"></i>
  </div>
</ng-container>
<div
  *ngIf="correlatedStatements && !loading"
  class="container"
  [ngClass]="{
    expanded: isExpanded,
    normal: !isExpanded,
  }"
>
  <div class="bg-primary text-white rounded-top mt-1 py-1 correlated_finding_modal">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <div class="mx-2">
        <h5 class="text-truncate mb-0" rrTooltip="CORRELATED_FINDINGS_WINDOW_TITLE">
          <span *ngIf="statementId">
            Correlated finding <br />
            Suggestions (&)
          </span>
          <span *ngIf="!statementId">
            Statistical Statements <br />
            Prediction
          </span>
        </h5>
      </div>
      <div class="d-flex gap-1">
        <button role="button" class="btn btn-light" (click)="close()" rrTooltip="CLOSE_CORRELATED_FINDINGS">
          <i class="fas fa-times"></i>
        </button>
        <button
          role="button"
          class="btn btn-light"
          (click)="toggleContainerSize()"
          rrTooltip="EXPAND_CORRELATED_FINDING"
        >
          <i [ngClass]="isExpanded ? 'fas fa-arrow-down' : 'fas fa-arrow-up'"></i>
        </button>
        <button
          *ngIf="statementId"
          role="button"
          class="btn btn-light"
          (click)="getReportCorrelatedSearch()"
          rrTooltip="REPORT_PAST_RESULTS_CORRELATION"
          rrTrack
        >
          <i class="fas fa-clock"></i>
        </button>
      </div>
    </div>
    <div class="input-group mb-2" rrTooltip="CORRELATED_FINDINGS_CLONES_FILTER">
      <select class="form-control" name="modalitySelect" [formControl]="form.controls.selectedFilter">
        <option [value]="'all'">All</option>
        <option [value]="'finding'">Key Findings</option>
        <option [value]="'impression'">Impressions</option>
        <option [value]="'comment'">Comments</option>
      </select>
    </div>
    <div class="d-flex align-items-center mb-2">
      <input
        type="checkbox"
        id="correlatingVoiceMode"
        [formControl]="form.controls.voiceMode"
        (change)="checkVoiceMode($event)"
      />
      <label class="form-check-label ms-1" for="correlatingVoiceMode" rrTooltip="CORRELATED_READOUT_TOGGLE"
        ><i class="fas fa-microphone"></i
      ></label>
    </div>
  </div>
  <div class="mb-2">
    <div *ngIf="statementId" class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="excludeOtherSectionsCheckbox"
        [formControl]="form.controls.excludeOtherSubsections"
      />
      <label
        class="form-check-label"
        for="excludeOtherSectionsCheckbox"
        rrTooltip="CORRELATED_EXCLUDE_OTHER_SUBSECTIONS"
        >Exclude Other Subsections</label
      >
    </div>
    <div *ngIf="statementId" class="form-check">
      <input
        type="checkbox"
        class="form-check-input"
        id="filterBySimilarAgeGroupCheckbox"
        [formControl]="form.controls.filterBySimilarAgeGroup"
      />
      <label
        class="form-check-label"
        for="filterBySimilarAgeGroupCheckbox"
        rrTooltip="CORRELATED_FILTER_BY_SIMILAR_AGE_GROUP"
        >Filter by similar age group</label
      >
    </div>
  </div>

  <div class="d-flex flex-column h-100">
    <!-- <div class="mb-2 d-flex flex-column">
      <ng-container *ngIf="templateId">
        <h5>Searching for correlated statements against:</h5>
        <div class="alert alert-light mb-4">
          <rr-statement-text-render
            [statement_id]="statementId"
            [template_id]="templateId"
            [region]="region"
          ></rr-statement-text-render>
        </div>
      </ng-container>
      <div class="d-flex">
        <button
          rrTooltip="CORRELATED_SENTENCE_SELECT"
          class="btn btn-sm btn-primary me-2"
          type="button"
          (click)="selectAll()"
          [disabled]="true"
        >
          Select all
        </button>
        <button
          rrTooltip="CORRELATED_SENTENCE_SELECT_TOP"
          [disabled]="true"
          class="btn btn-sm btn-secondary me-2"
          type="button"
          (click)="selectTopThree()"
        >
          Select top 3
        </button>
        <button
          rrTooltip="CORRELATED_SENTENCE_DESELECT"
          [disabled]="true"
          class="btn btn-sm btn-danger"
          type="button"
          (click)="deselectAll()"
        >
          De-select all
        </button>
      </div>

      <div rrTooltip="CORRELATED_SENTENCE_TECHNIQUE" class="form-check" >
        <input
          class="form-check-input"
          type="checkbox"
          id="includeTechniqueSentencesCheck"
          [formControl]="includeTechniqueSentences"
          
        />
        <label class="form-check-label" for="includeTechniqueSentencesCheck"> Include 'Technique' Sentences </label>
      </div>
    </div> -->

    <div class="d-flex flex-column p-1 border rounded scroll-y">
      <ng-container *ngFor="let correlatedStatement of correlatedStatements">
        <div class="list-group d-flex flex-column">
          <div *ngIf="correlatedStatement.statement">
            <rr-recommended-statement
              [statement]="correlatedStatement.statement"
              [topic]="topic"
              [correlationScore]="correlatedStatement.score"
            ></rr-recommended-statement>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-container *ngIf="!correlatedStatements && !loading">
  <div class="alert alert-danger m-2">
    No Recommended <strong>Statements</strong>: select to find correlated results.
  </div>
</ng-container>
