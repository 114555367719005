<rr-checkbox [formControl]="any" rrTooltip="ANY_SITE">Any</rr-checkbox>
<div>
  <rr-checkbox
    *ngFor="let site of sites"
    (change)="onCheck(site, $event)"
    [checked]="formArray.value | includes: site.id"
  >
    {{ site.short_name }}
  </rr-checkbox>
</div>
