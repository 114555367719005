import { Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, FormControlDirective, NgControl } from '@angular/forms';
import { BindObservable } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { Observable, Subscription } from 'rxjs';

let nextId = 0;

/**
 * A simple checkbox component that wraps a form control. Usage:
 * ```html
 * <rr-checkbox [control]="myFormControl">
 *   My Checkbox
 * </rr-checkbox>
 * ```
 */
@Component({
  selector: 'rr-checkbox',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.css',
})
export class CheckboxComponent implements OnInit, ControlValueAccessor, OnDestroy {
  /**
   * Generally you pass a form control to this component, but if you use `[checked]` and `(change)` you can use it as a
   * template driven form.
   */
  // formControl is a special Input that's linked to NgControl
  formControl: FormControl<boolean>;

  /**
   * Fields for template driven forms
   */
  @BindObservable() @Input() checked?: boolean;
  checked$: Observable<boolean>;

  /**
   * automatic unique id for every checkbox we render, this links the input to the label.
   */
  id = `rr-checkbox-${nextId++}`;

  subscription = new Subscription();

  constructor(@Optional() @Self() public ngControl?: NgControl) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl instanceof FormControlDirective) {
      this.formControl = this.ngControl.control;
    } else if (!this.ngControl) {
      this.formControl = new FormControl(false, { nonNullable: true });
    }
    this.subscription.add(
      this.checked$.subscribe((checked) => {
        this.formControl.setValue(checked);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onChangeFn = (_: string) => {
    // do nothing
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  onTouchedFn = () => {
    // do nothing
  };

  // These are just to make Angular happy. Not needed since the control is passed to the child input
  writeValue() {
    // do nothing
  }

  // When the value in the UI is changed, this method will invoke a callback function
  registerOnChange(fn: any) {
    this.onChangeFn = fn;
  }

  // When the element is touched, this method will get called
  registerOnTouched(fn: any) {
    this.onTouchedFn = fn;
  }
}
