import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbModalRef, NgbPopover, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined, trackById } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { CorrelatedStatementSource, EditorService, FocusStatement } from 'app/core/services/editor.service';
import { GoogleAnalyticsService } from 'app/core/services/google-analytics.service';
import { MandatoryStatementService } from 'app/core/services/mandatory-statement.service';
import { ChooseStatementData, ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { ICategorisedAttribute, TemplateService } from 'app/core/services/template.service';
import { stripPunctuation } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store/app.state';
import { CorrelatedStatementScore } from 'app/store/correlated-statement-search';
import { fromMandatoryStatement } from 'app/store/mandatory-statement';
import { fromPatient } from 'app/store/patient';
import { fromElementChoice } from 'app/store/report/element-choice';
import { ReportHttpService } from 'app/store/report/report';
import { fromSexSpecificWord } from 'app/store/sex-specific-words/sex-specific-word.selector';
import { fromStatement, StatementEffect } from 'app/store/template/statement';
import { fromStatementSet } from 'app/store/template/statement-set';
import { combineLatest, EMPTY, Observable, of as observableOf, of, Subscription } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { LinkifyDirective } from '../../../shared/directives/linkify/linkify.directive';
import { SharedModule } from '../../../shared/shared.module';
import { CategoriseSentenceModalComponent } from '../category/categorise-sentence-modal/categorise-sentence-modal.component';
import { ChoiceComponent } from '../choice/choice.component';
import { StatementChoiceUsageAnalyticsModalComponent } from '../modals/statement-choice-usage-analytics-modal/statement-choice-usage-analytics-modal.component';
import { EditType } from '../statement-edit/statement-edit.component';
import { StatementTextRenderComponent } from '../statement-edit/statement-text-render/statement-text-render.component';
import { StatementDeleteModalComponent } from './statement-delete-modal.component';
import { StatementEditModalComponent } from './statement-edit-modal.component';

@Component({
  selector: 'rr-statement, [rr-statement]',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, StatementTextRenderComponent, LinkifyDirective, SharedModule, NgbTooltip, ChoiceComponent],
})
@LifecycleLogger
export class StatementComponent implements OnInit, OnDestroy {
  @ViewChild('popoverMandatory') popoverMandatory: NgbPopover;
  @BindObservable() @Input() statement: RR.Statement;
  statement$: Observable<RR.Statement>;
  @BindObservable() @Input() element: RR.Element | undefined;
  element$: Observable<RR.Element | undefined>;
  isNotepad: boolean;
  /**
   * Notepads create choices in the currently selected Element, not the Notepad Element.
   */
  @Input() createInElement: RR.Element;

  @Input() @BindObservable() element_choice: RR.ElementChoice | null;
  element_choice$: Observable<RR.ElementChoice | null>;
  @BindObservable() @Input() topic: RR.Topic | undefined;
  topic$: Observable<RR.Topic | undefined>;
  /**
   * In attribute admin, we don't have a `topic`. So just the `template_id` is passed in.
   */
  @Input() template_id: number;
  @BindObservable() @Input() region: RR.Region | undefined;
  region$: Observable<RR.Region | undefined>;
  @Input() section: RR.Section;
  @Input() subsection: RR.Subsection;
  @Input() type: 'normal' | 'recommendation' | 'stub' | 'preview' | 'divider' = 'normal';
  @Input() parent: 'EDITOR' | 'PREFILL' | 'NOTEPAD' = 'EDITOR'; // For recommendation in DicomSrModalComponent
  @Output() onEdit = new EventEmitter<boolean>();
  // Apply filtering on statements in element
  @Input() filterType: 'NONE' | 'AGE_FREQUENCY' | 'FREQUENCY' | 'LOGISTIC_REGRESSION' = 'NONE';
  @Input() frequency?: number | undefined;
  @Input() correlationScore?: CorrelatedStatementScore[] | undefined;
  @Input() context?: 'IMGSIM' | 'MAIN_EDITOR' | undefined;
  /**
   * Proposed values to use for STOs instead of the defaults
   */
  @Input() proposed?: RR.ProposedStatement;
  @Input() statementGroupIds: number[];
  @Input() @BindObservable() report: RR.Report;
  report$: Observable<RR.Report>;
  @Output() onClickDivider = new EventEmitter<number>();
  isSentenceCategorised: Observable<boolean>;
  sentenceCategoriesTooltip: Observable<string>;
  justification: RR.Justification | undefined;
  hasMatchingWord: boolean = false;

  @HostBinding('class.list-group-item-success') get success() {
    return !this.statement.legacy;
  }

  @HostBinding('class.warning-focus') get mandatory() {
    return this._incompleteMandatoryStatements;
  }

  @HostBinding('class.divider') get divider() {
    return this.statement.is_divider;
  }

  @HostBinding('class.cursor-divider') get notRecommendationDivider() {
    return this.statement.is_divider && this.type !== 'recommendation';
  }
  @HostBinding('class.statement-sex-warning') get sexMatchingWord() {
    return this.hasMatchingWord;
  }
  can_focus_child = false;

  statementPreviewText: string;
  asteriskStyle: string;
  subscription = new Subscription();
  choices = [] as RR.FullStatementChoice[];
  /**
   * the number of choices in the element (determines exclusive select behaviours)
   */
  choicesCount = 0;
  modalInstance: NgbModalRef;
  onmouseenter() {
    this.hover = true;
    this.cd.markForCheck();
  }
  onmouseleave() {
    this.hover = false;
    this.cd.markForCheck();
  }
  hover = false;
  editMode = false;
  isSelected = false;
  is_in_sc_mode$ = this.editorService.toggleSCModeEventListener();

  currentUser: RR.User;
  mandatoryStatement: RR.MandatoryStatement | undefined;
  incompleteMandatoryStatements: RR.MandatoryStatement[] | undefined;

  _incompleteMandatoryStatements: RR.MandatoryStatement | undefined;

  constructor(
    protected templateService: TemplateService,
    protected reportService: ReportService,
    protected editorService: EditorService,
    protected cd: ChangeDetectorRef,
    protected el: ElementRef,
    protected modalService: NgbModal,
    private store: Store<AppState>,
    private statementEffect: StatementEffect,
    private gaService: GoogleAnalyticsService,
    private selectorService: SelectorService,
    private reportHttpService: ReportHttpService,
    protected mandatoryStatementService: MandatoryStatementService,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.statement$
        .pipe(
          switchMap(() => {
            if (this.type === 'normal' || this.type === 'divider') {
              return this.editorService.focusEvents.pipe(
                filter((e): e is FocusStatement => e?.type === 'FocusStatement'),
              );
            } else {
              return observableOf(null);
            }
          }),
        )
        .subscribe((e) => {
          if (e && e.statementId === this.statement.id) {
            requestAnimationFrame(() => {
              this.el.nativeElement.focus();
              this.el.nativeElement.scrollIntoView({ block: 'center' });
              e.doneCallback();
            });
          }
        }),
    );
    if (this.type === 'stub' || this.type === 'preview') {
      this.asteriskStyle = 'btn-primary';
    } else {
      this.subscription.add(
        this.element_choice$
          .pipe(
            switchMap((element_choice) =>
              !element_choice
                ? observableOf([])
                : this.store.select(
                    fromElementChoice.selectStatementChoicesByStatementId(element_choice.id, this.statement.id),
                  ),
            ),
          )
          .subscribe((choices) => {
            this.choices = choices;
            this.asteriskStyle = choices
              .map((choice) => {
                if (!choice.clones) return false;
                // @ts-expect-error strictNullChecks
                return choice.clones.includes('key finding');
              })
              .some((t) => t)
              ? 'btn-danger'
              : 'btn-primary';
            this.cd.markForCheck();
          }),
      );

      this.subscription.add(
        this.element_choice$
          .pipe(
            switchMap((element_choice) =>
              !element_choice
                ? observableOf([])
                : this.store.select(fromElementChoice.selectStatementChoices(element_choice.id)),
            ),
          )
          .subscribe((choices) => {
            this.choicesCount = choices.filter((c) => c.statement_id !== null).length;
            this.cd.markForCheck();
          }),
      );

      this.subscription.add(
        this.editorService.editMode.subscribe((editMode) => {
          this.editMode = editMode;
          this.cd.markForCheck();
        }),
      );
      this.subscription.add(
        this.editorService.isSelected(this.statement.id).subscribe((isSelected) => {
          this.isSelected = isSelected;
          this.cd.markForCheck();
        }),
      );

      /**
       * Check if sentence was categorised by using combo of statement and attributes
       */
      this.isSentenceCategorised = this.getStatementDefaultAttributes().pipe(
        switchMap((a) => this.templateService.checkSentenceCategories(this.statement.id, a)),
      );
      this.sentenceCategoriesTooltip = this.getStatementDefaultAttributes().pipe(
        switchMap((a) => this.templateService.getSentenceCategoriesTooltip(this.statement.id, a)),
      );

      this.subscription.add(
        this.element$.subscribe((element) => {
          this.isNotepad = element?.type === 'notepad';
        }),
      );
    }

    this.subscription.add(
      this.selectorService.selectLoadedCurrentUser().subscribe((currentUser) => {
        // @ts-expect-error strictNullChecks
        this.currentUser = currentUser;
      }),
    );

    this.subscription.add(
      this.topic$
        .pipe(
          switchMap((topic) => {
            if (topic) {
              return this.reportService
                .getIncompleteMandatoryStatements(topic)
                .pipe(
                  map((mandatoryStatements: RR.MandatoryStatement[]) =>
                    mandatoryStatements.find((m) => m.statement_id === this.statement.id),
                  ),
                );
            }
            return EMPTY;
          }),
        )
        .subscribe((mandatoryStatement) => {
          this._incompleteMandatoryStatements = mandatoryStatement;
          this.cd.markForCheck();
        }),
    );

    this.subscription.add(
      this.topic$
        .pipe(
          switchMap((topic) => {
            if (topic) {
              return this.reportService.getIncompleteMandatoryStatements(topic);
            }
            return EMPTY;
          }),
        )
        .subscribe((mandatoryStatements) => {
          this.incompleteMandatoryStatements = mandatoryStatements;
          this.cd.markForCheck();
        }),
    );

    const mandatoryStatement$ = this.topic$.pipe(
      switchMap((topic) => {
        if (topic) {
          return this.store.select(
            fromMandatoryStatement.selectMandatoryStatementByStatementId(this.statement.id, topic.template_id),
          );
        }

        return EMPTY;
      }),
    );

    this.subscription.add(
      mandatoryStatement$.subscribe((mandatoryStatement) => {
        this.mandatoryStatement = mandatoryStatement;
      }),
    );

    this.subscription.add(
      mandatoryStatement$
        .pipe(
          filterDefined(),
          switchMap((mandatoryStatement) =>
            this.store.select(fromMandatoryStatement.selectJustification(mandatoryStatement.id)),
          ),
        )
        .subscribe((justification) => {
          this.justification = justification;
        }),
    );

    const sexSpecificWords$ = this.store.select(fromSexSpecificWord.selectAll);
    const textObjects$ = this.store.select(fromStatement.selectTextObjects(this.statement.id));
    const patient$ = this.report$.pipe(
      switchMap((report) =>
        report.patient_id ? this.store.select(fromPatient.selectPatient(report.patient_id)) : of(null),
      ),
    );

    if (this.topic) {
      this.subscription.add(
        combineLatest([textObjects$, sexSpecificWords$, patient$]).subscribe(([textObjects, wordList, patient]) => {
          const statementText = textObjects
            .filter((obj): obj is RR.TextObjectLiteral => obj.type === 'literal')
            .map((obj) => obj.value)
            .join(' ');

          const strippedStatementWords = stripPunctuation(statementText).toLowerCase().split(' ');

          if (patient) {
            this.hasMatchingWord = wordList.some(
              (w) =>
                w.sex.toLowerCase() !== patient.patient_sex?.toLowerCase() &&
                strippedStatementWords.includes(w.word.toLowerCase()),
            );
          }

          this.cd.markForCheck();
        }),
      );
    }
  }

  get region_id() {
    return this.region ? this.region.id : undefined;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  openEditor(editType: EditType, firstStatement_id?: number, selectedStatements?: number[]) {
    if (!this.topic || !this.element) return;
    if (editType === 'editStatement') {
      this.subscription.add(
        this.editorService
          .saveAdjacentStatement(this.statement.id, this.element, this.topic.id, this.region_id)
          .subscribe(),
      );
    }

    const windowClass =
      editType === 'editStatement' || editType === 'moveMultipleStatement' ? 'statement-edit-modal' : '';
    const scrollable = editType === 'editStatement' || editType === 'moveMultipleStatement';
    const { componentInstance, modalRef } = StatementEditModalComponent.open(this.modalService, {
      scrollable,
      windowClass,
    });
    componentInstance.statement_id = firstStatement_id || this.statement.id;
    componentInstance.region = this.region;
    componentInstance.topic = this.topic;
    componentInstance.subsection = this.subsection;
    componentInstance.element = this.element;
    componentInstance.editType = editType;
    componentInstance.source = this.parent;
    if (selectedStatements) {
      componentInstance.selectedStatements = selectedStatements;
    }

    modalRef.result.then(
      ({ editType, firstStatement_id, selectedStatements }) => {
        if (editType === 'insertStatement') {
          this.openEditor(editType, firstStatement_id, selectedStatements);
        } else {
          this.openEditor(editType);
        }
      },
      () => {
        // Do nothing
      },
    );
  }

  onClick(event: MouseEvent) {
    if (this.type === 'stub' || this.type === 'preview' || !this.topic) return;
    const target = event.target as HTMLElement;
    // Expand sentences under this divider in 20% filter mode
    if (this.divider && this.filterType !== 'NONE' && !target.closest('[data-no-bubble-statement]')) {
      this.onClickDivider.emit(this.statement.id);
    }
    // Used "data-no-bubble" attributes instead of `stopPropagation` so that the dropdowns can still close.
    // If we stopPropagation for clicks the dropdown document.click listener will never be called.
    if (!target.closest('[data-no-bubble-statement]')) {
      if (!this.editMode) {
        if (!this.divider) {
          // Cannot choose dividers
          this.gaService.trackClick(event, { action: 'Click statement' }); // so that clicks on the children buttons aren't tracked
          this.addStatementChoice();
          this.mandatoryStatementService.mandatoryStatementChecker(this.statement, this.incompleteMandatoryStatements);

          if (this.context === 'MAIN_EDITOR') {
            this.editorService.searchAndToggleCorrelatedStatement({
              statementId: this.statement.id,
              templateId: this.topic.template_id,
              region: this.region,
              source: 'STATEMENT',
            } as CorrelatedStatementSource);
          }
        }
      } else {
        // Dividers are allowed to be clicked here
        this.editorService.select(this.statement.id);
      }
    }
  }

  updateGlobalSearchWithDividerText() {
    // TODO: Disabled automatic search because results overlay statements in the middle pane.
    // this.templateService
    //   .getDivider(this.element.id, this.statement.id)
    //   .pipe(take(1))
    //   .subscribe(divider => {
    //     if (divider) {
    //       this.editorService.doGlobalSearch(this.topic.id, divider.text);
    //     }
    //   });
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  addStatementChoice = () => {
    if (this.type === 'stub' || !this.topic || !this.element) return;
    this.can_focus_child = true;
    this.chooseStatement({
      topic_id: this.topic.id,
      element_id: this.isNotepad ? this.createInElement.id : this.element.id,
      statement_id: this.statement.id,
      region_id: this.region_id,
      proposed: this.proposed,
    });

    this.updateGlobalSearchWithDividerText();
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  singleStatementSelect = () => {
    if (this.type === 'stub' || !this.topic || !this.element) return;
    this.can_focus_child = true;

    this.subscription.add(
      this.reportService
        .singleSelectStatement(this.element_choice?.id, {
          topic_id: this.topic.id,
          element_id: this.isNotepad ? this.createInElement.id : this.element.id,
          statement_id: this.statement.id,
          region_id: this.region_id,
        })
        .subscribe((action) => {
          const statementChoiceId = action.actions.statementChoiceAddMany.statementChoices[0].id;
          this.afterChooseStatement(statementChoiceId);
        }),
    );
    this.updateGlobalSearchWithDividerText();
  };

  onToggleClone(newSection: RR.ReportSection) {
    this.reportService.verifyDefaultSectionAndToggleClone(newSection, this.statement, (section) =>
      this.toggleClone(section),
    );
  }

  toggleClone(newSection: RR.ReportSection) {
    if (this.type === 'stub' || !this.topic || !this.element) return;

    if (this.choices.length === 0) {
      this.chooseStatement({
        topic_id: this.topic.id,
        element_id: this.isNotepad ? this.createInElement.id : this.element.id,
        statement_id: this.statement.id,
        region_id: this.region_id,
        clones: [newSection],
      });
    } else {
      throw new Error('toggleKeyFinding should only be called if statement_choices.length === 0');
    }
  }

  choiceTracking = trackById;

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  promptDeleteStatement = () => {
    if (this.type === 'stub' || this.type === 'preview') return;
    const modalRef = this.modalService.open(StatementDeleteModalComponent);
    const componentInstance: StatementDeleteModalComponent = modalRef.componentInstance;
    componentInstance.statement = this.statement;
    componentInstance.topic = this.topic;
    modalRef.result.then(
      () => {
        this.removeStatement();
      },
      () => {
        /* dismissed */
      },
    );
  };

  removeStatement() {
    if (this.type === 'stub' || this.type === 'preview' || !this.element) return;
    let neighbourId: number | undefined = undefined;

    this.subscription.add(
      this.store
        .select(fromStatementSet.selectStatementNeighbourId(this.element.statement_set_id, this.statement.id))
        .pipe(
          take(1),
          tap((neighbour) => {
            neighbourId = neighbour;
          }),
          switchMap(() => this.statementEffect.delete(this.statement.id)),
        )
        .subscribe(() => {
          if (neighbourId) {
            this.editorService.publishFocus({ statement_id: neighbourId, target: this.parent });
          }
        }),
    );
  }

  openMoveMulti(): void {
    if (this.type === 'stub' || this.type === 'preview') return;

    this.subscription.add(
      combineLatest([this.editorService.selectedStatements, this.store.select(fromStatement.selectIds)])
        .pipe(take(1))
        .subscribe(([selectedStatementIds, allStatementIds]) => {
          // intersection
          const statementIds = (allStatementIds as number[]).filter((value) => selectedStatementIds.has(value));
          if (statementIds.length === 0) {
            return;
          }
          this.openEditor('moveMultipleStatement', statementIds[0], statementIds);
        }),
    );
  }

  openEditMode(value: boolean) {
    if (this.type === 'stub' || this.type === 'preview') return;
    this.editorService.toggleEditMode(value);
  }

  copyToTemplate() {
    // TODO(slatement): passed the text, so this was never working properly with attributes
  }

  categoriseStatement() {
    this.subscription.add(
      this.getStatementDefaultAttributes()
        .pipe(take(1))

        .subscribe((attributes: ICategorisedAttribute[]) => {
          if (!this.topic) return;
          CategoriseSentenceModalComponent.open(
            this.editorService,
            this.modalService,
            this.topic.report_id,
            this.topic.id,
            this.element ? this.element.statement_set_id : null,
            this.region_id || null,
            this.statement.id,
            attributes,
          );
        }),
    );
  }

  getStatementDefaultAttributes(): Observable<ICategorisedAttribute[]> {
    return this.store.select(fromStatement.selectTextObjects(this.statement.id)).pipe(
      map((textObjects) => {
        // TODO(tda): this is duplicated in divider.component
        return textObjects
          .filter((obj) => obj.type === 'set')
          .map((obj) => {
            if (!this.topic) throw new Error('topic is undefined');
            let default_attribute: RR.DefaultAttribute | undefined;

            this.subscription.add(
              this.templateService
                .getDefaultAttribute(this.topic.template_id, obj.id, this.region_id || null)
                .pipe(take(1))
                .subscribe((tda) => {
                  default_attribute = tda;
                }),
            );

            return {
              attribute_option_id: default_attribute?.default_option_id,
              text_object_id: obj.id,
            } as ICategorisedAttribute;
          });
      }),
    );
  }

  afterChooseStatement(statementChoiceId: number) {
    if (this.topic?.report_id) {
      this.editorService.createMedicalNotesFromStatementTooltip(
        statementChoiceId,
        this.topic.report_id,
        this.statement,
      );
    }
  }

  chooseStatement(data: ChooseStatementData) {
    this.subscription.add(
      this.reportService.chooseStatement(data).subscribe((action) => {
        const statementChoiceId = action.actions.statementChoiceAddMany.statementChoices[0].id;
        this.afterChooseStatement(statementChoiceId);
      }),
    );
  }

  searchCorrelatedStatements() {
    this.editorService.searchAndToggleCorrelatedStatement({
      statementId: this.statement.id,
      templateId: this.template_id,
      region: this.region,
      source: 'BUTTON',
    });
  }

  copyToGlobalSearch() {
    this.subscription.add(
      this.reportHttpService.findStatementTextWithAttributes(this.statement.id).subscribe((statement) => {
        this.editorService.globalSearchTerm$.next({
          source: 'PREFILL',
          term: statement.sentence_text,
        });
      }),
    );
  }

  openStatementUsageAnalytics() {
    if (this.topic) {
      StatementChoiceUsageAnalyticsModalComponent.open(
        this.modalService,
        this.statement.id,
        this.topic,
        'statement',
        this.statement.is_divider,
        this.statementGroupIds,
      );
    }
  }
}
