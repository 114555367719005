<nav
  class="navbar p-0 fixed-top"
  [ngClass]="{
    'bg-warning text-black-50': rrConfig.environment === 'development' || rrConfig.environment === 'testing',
    'navbar-dark bg-dark text-white-50': rrConfig.environment === 'production',
    'bg-light border-bottom': rrConfig.environment === 'staging',
  }"
>
  <div class="container-fluid d-flex flex-nowrap align-items-center">
    <button
      class="btn btn-outline-secondary"
      (click)="toggleSideNavigation()"
      [rrTooltip]="'NAVIGATION_SIDEBAR'"
      rrTrack
    >
      <i class="fas fa-bars"></i>
    </button>
    <ng-container *ngIf="openTopic">
      <rr-global-search [openTopic]="openTopic"></rr-global-search>
      <button
        class="btn btn-outline-secondary btn-sm ms-1"
        (click)="openDividerStatementsModal()"
        *ngIf="(userSettings$ | async)?.voice_recognition_features"
      >
        <span class="fa fa-eye"></span>
      </button>
      <rr-navbar-buttons [isEditorPage]="true" class="navbar-nav d-flex ms-auto"></rr-navbar-buttons>
    </ng-container>
  </div>
</nav>
