import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { atLeastOneContact, BindObservable, expiredMedicare } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { combineLatest, Observable, Subscription } from 'rxjs';

import { RegistrationService } from '../../services/registration.service';
import { PatientFormEditComponent } from './patient-form-edit/patient-form-edit.component';
import { PatientFormSearchComponent } from './patient-form-search/patient-form-search.component';
import { PatientFormViewComponent } from './patient-form-view/patient-form-view.component';

export type PatientFormParent = 'REGISTRATION' | 'PATIENT' | 'BOOKING';
export const PATIENT_FORM_ID = 'patient-form';

@Component({
  standalone: true,
  imports: [CommonModule, PatientFormEditComponent, PatientFormViewComponent, PatientFormSearchComponent, SharedModule],
  selector: 'rr-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss'],
  host: {
    id: PATIENT_FORM_ID,
  },
})
export class PatientFormComponent implements OnInit, OnDestroy {
  @ViewChild(PatientFormSearchComponent) patientFormSearchComponent: PatientFormSearchComponent;
  @ViewChild(PatientFormEditComponent) patientFormEdit: PatientFormEditComponent | undefined;
  /**
   * A real patient entity. Not a partial patient. If this is here, it is in patient editing mode.
   */
  @Input() @BindObservable() patient: RR.Patient | undefined;
  patient$: Observable<RR.Patient | undefined>;

  // Parent component (report registration, patient listing page, or booking list)
  @Input() parent: PatientFormParent = 'REGISTRATION';

  removedPatient: RR.Patient | undefined;
  bookingPatient: RR.Booking['booking_patient'] | undefined;
  searchFormPatientDetails: Partial<RR.Patient>;
  /**
   * search: show the search input. create patient button.
   * view: non editable preview of the patient. edit and remove patient buttons.
   * edit: show the form. if there's a patient, edit patient. if there's no patient, create patient.
   */
  viewMode: 'search' | 'view' | 'edit' | 'create' = 'view';
  formValid = true;
  expiredMedicare: boolean;
  subscription: Subscription = new Subscription();

  constructor(private registrationService: RegistrationService) {}

  ngOnInit() {
    const fromBooking$ = this.registrationService.fromBooking$;

    this.subscription.add(
      combineLatest([this.patient$, fromBooking$]).subscribe(([patient, booking]) => {
        if (patient) {
          this.viewMode = 'view';
        } else {
          this.bookingPatient = booking && booking.booking_patient;
          this.viewMode = 'search';
        }
      }),
    );

    // Mirroring form validation from patient-form-edit
    this.subscription.add(
      this.patient$.subscribe((patient) => {
        if (patient) {
          const atLeastOnePatientContact = atLeastOneContact(patient, [
            'phone_mobile',
            'phone_home',
            'phone_work',
            'email',
          ]);

          const missingPatientDetails =
            !patient.patient_first_name || !patient.patient_last_name || !patient.patient_dob || !patient.patient_sex;

          this.formValid = !missingPatientDetails && atLeastOnePatientContact;
          this.expiredMedicare = expiredMedicare(
            patient.medicare_expiry_month || undefined,
            patient.medicare_expiry_year || undefined,
          );
        }
      }),
    );
  }

  createNewPatient() {
    this.viewMode = 'create';
    const {
      patient_first_name: patient_full_name,
      patient_dob,
      medicare_number,
      phone_mobile,
    } = this.patientFormSearchComponent.getPatientDataFromSearchInput();

    let patient_first_name = '';
    let patient_last_name = '';

    if (patient_full_name) {
      const separatedNames = patient_full_name.split(' ');

      // If there's only one name
      if (separatedNames.length === 1) {
        patient_first_name = separatedNames[0];
      }
      // If there are two names
      else if (separatedNames.length === 2) {
        patient_first_name = separatedNames[0];
        patient_last_name = separatedNames[1];
      }
      // If there are three or more names
      else {
        patient_first_name = separatedNames[0];
        patient_last_name = separatedNames.slice(1).join(' ');
      }
    }

    this.searchFormPatientDetails = {
      patient_first_name,
      patient_last_name,
      patient_dob,
      medicare_number,
      phone_mobile,
    };
  }

  selectPatient($event: { patient: RR.Patient; created: boolean }) {
    const { patient } = $event;
    this.subscription.add(
      this.registrationService.selectPatient(patient).subscribe(() => {
        this.viewMode = 'view';
      }),
    );
  }

  editPatient() {
    this.viewMode = 'edit';
  }

  removePatient(patient: RR.Patient) {
    this.removedPatient = patient;
    this.subscription.add(this.registrationService.selectPatient(null).subscribe());
    this.viewMode = 'search';
  }

  undoRemoval(removedPatient: RR.Patient) {
    this.subscription.add(this.registrationService.selectPatient(removedPatient).subscribe());
    this.removedPatient = undefined;
    this.viewMode = 'view';
  }

  cancel() {
    if (this.patient) {
      this.viewMode = 'view';
    } else {
      this.viewMode = 'search';
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
