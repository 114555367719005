<rr-editor-navbar *ngIf="openTopic" [openTopic]="openTopic"></rr-editor-navbar>
<div *ngIf="report" class="container-fluid px-0 flex-grow-1">
  <rr-sidebar></rr-sidebar>
  <div class="row g-1 h-100 w-100">
    <!-- Left panel -->
    <div class="d-flex flex-column col-3 editor-padding-left" *ngIf="showIndex">
      <div class="d-flex flex-column flex-auto">
        <!--INDEX SEARCH-->
        <div class="d-flex flex-row mt-1">
          <div
            role="button"
            class="d-flex justify-content-center heading rounded-top"
            (click)="togglePrefill()"
            [ngClass]="{
              'bg-primary': editorExpandedState === 'INDEX',
              'bg-secondary': editorExpandedState !== 'INDEX',
            }"
          >
            <h3 class="mb-0">
              <span *ngIf="editorExpandedState === 'INDEX'">
                <i class="fas fa-chevron-circle-down fa-sm"></i>
              </span>
              <span *ngIf="editorExpandedState !== 'INDEX'">
                <i class="fas fa-chevron-circle-right fa-sm"></i>
              </span>
              Index (E)
            </h3>
          </div>

          <div
            id="prefillHeader"
            role="button"
            class="d-flex justify-content-center heading rounded-top"
            (click)="togglePrefill()"
            [ngClass]="{
              'bg-primary': editorExpandedState === 'PREFILL',
              'bg-secondary': editorExpandedState !== 'PREFILL',
            }"
          >
            <h3 class="mb-0">
              <span *ngIf="editorExpandedState === 'PREFILL'">
                <i class="fas fa-chevron-circle-down fa-sm"></i>
              </span>
              <span *ngIf="editorExpandedState !== 'PREFILL'"> <i class="fas fa-chevron-circle-right fa-sm"></i> </span>
              Prefill (B)
            </h3>
          </div>
        </div>

        <!--PREFILL REPORT-->
        <div class="d-flex flex-column border flex-grow-1">
          <rr-index
            *ngIf="editorExpandedState === 'INDEX'"
            class="scroll flex-grow-1 flex-basis-0"
            [id]="INDEX_SCROLL_ID"
          ></rr-index>
          <!-- 
              TODO: Use *ngIf here instead of .d-none. Store the prefill state and restore it when reopening. State
              includes prefill search terms, boost checkboxes, page, exact match expanded... etc
             -->
          <rr-prefill-search-container
            *ngIf="openTopic"
            [class.d-flex]="editorExpandedState === 'PREFILL'"
            [class.d-none]="editorExpandedState !== 'PREFILL'"
            [topic_id]="openTopic.id"
            [report_id]="reportId"
            class="flex-column flex-grow-1 flex-basis-0"
          ></rr-prefill-search-container>
        </div>
      </div>
    </div>
    <rr-template-chooser
      class="d-flex flex-column col-8 editor-padding-left flex-grow-1"
      *ngIf="templateChooser"
    ></rr-template-chooser>

    <div
      [class.d-none]="!(editorExpandedState === 'INDEX' && !templateChooser)"
      [class.d-flex]="editorExpandedState === 'INDEX' && !templateChooser"
      class="flex-column flex-grow-1"
      [class.col-8]="!showIndex"
      [class.col-5]="showIndex"
    >
      <div class="heading rounded-top mt-1">
        <h2>Edit Report (E)</h2>
      </div>
      <rr-report-edit-pane
        *ngIf="openTopic"
        [report]="report"
        [openTopic]="openTopic"
        class="d-flex flex-column flex-grow-1"
      >
      </rr-report-edit-pane>
    </div>
    <!-- column width expands to 8 when index is hidden -->

    <div
      [hidden]="editorExpandedState !== 'PREFILL'"
      [ngClass]="{ 'd-flex flex-column flex-grow-1 ': editorExpandedState === 'PREFILL' }"
      [class.col-8]="!showIndex"
      [class.col-5]="showIndex"
    >
      <div class="heading rounded-top mt-1">
        <h2>Prefill Copy (B1)</h2>
      </div>
      <rr-prefill
        class="d-flex flex-column flex-grow-1"
        *ngIf="openTopic !== undefined"
        [topic_id]="openTopic.id"
      ></rr-prefill>
    </div>

    <!--REPORT PANEL-->
    <div class="col-4 d-flex flex-column h-100">
      <rr-editor-preset [report]="report" [presetFromReport]="presetFromReport" [topicId]="topicId"></rr-editor-preset>
      <div class="d-flex mt-1" data-cy="study-list" cdkDropList (cdkDropListDropped)="drop($event)">
        <ul class="list-group rounded w-100">
          <ng-container *ngFor="let topic of activeTopics">
            <rr-topic-button [topic]="topic" (click)="setTopicOpen(topic.id)"></rr-topic-button>
          </ng-container>
        </ul>
        <button
          permission
          [permission-only]="['report_manage']"
          role="button"
          class="btn btn-sm btn-outline-success ms-1"
          (click)="setTopicOpen(-1)"
          rrTooltip="ADD_NEW_STUDY_TITLE"
          ngbTooltip="Add another template to report"
          [openDelay]="500"
          data-cy="add-template"
        >
          <i class="fa fa-plus fa-sm"></i>
        </button>
      </div>
      <div
        (click)="compressViewClick($event)"
        rrTooltip="SHOW_PATIENT_INFO_BY_EDITORS"
        *ngIf="report.type === 'default'"
      >
        <div class="d-flex align-items-center justify-content-between lh-sm">
          <div class="d-flex flex-column">
            <!--Patient Name-->
            <div *ngIf="report.patient_id">
              <rr-patient-info
                [patientId]="report.patient_id"
                [editPatient]="true"
                [patientNumber]="false"
              ></rr-patient-info>
            </div>
            <div *ngIf="referrer">
              <rr-referrer-name
                [referrerId]="referrer.id"
                [editReferrer]="true"
                [referrerType]="true"
              ></rr-referrer-name>
            </div>

            <div class="d-flex align-items-center">
              <rr-report-accession-number [report]="report" [shortText]="true"></rr-report-accession-number>
              <button
                role="button"
                class="btn btn-sm d-flex align-items-center text-secondary p-0 ms-1"
                (click)="copyText(report.accession_number)"
                data-no-bubble
                rrTrack
                rrTooltip="COPY_ACCESSION_NUMBER"
              >
                <i class="fas fa-copy"></i>
              </button>
            </div>

            <div>
              <!--Editing User-->
              <span class="d-flex ms-2">
                <i *ngIf="editing_users.length > 0" class="fa fa-user-edit fa-sm"></i>
                {{ getEditingUsers() }}
                <rr-time-tracker></rr-time-tracker>
              </span>
            </div>
          </div>
          <div class="text-end">
            <div class="my-1">
              <small>
                <rr-report-date data-no-bubble [reportId]="reportId"></rr-report-date>
              </small>
            </div>
            <div>
              <small>
                <rr-due-date data-no-bubble [report]="report"></rr-due-date>
              </small>
            </div>
          </div>
        </div>
        <!--Button container-->
        <div class="d-flex flex-column flex-nowrap mt-1">
          <!--Sign/signed button-->
          <!-- TODO: move this button to the sidebar -->
          <div class="d-flex">
            <button
              *ngIf="openTopic"
              class="btn btn-sm text-nowrap me-1"
              [ngClass]="openTopic.signature_text ? 'btn-success' : 'btn-outline-dark'"
              role="button"
              (click)="openSignature()"
              [disabled]="(rightPaneViewMode$ | async) === 'AUDIT' || !(isDoctorEditing() | async)"
              rrTooltip="OPEN_SIGNATURE"
              data-no-bubble
            >
              <ng-container *ngIf="!openTopic?.signature_text"><i class="fa fa-file-signature"></i>Sign</ng-container>
              <ng-container *ngIf="openTopic?.signature_text"
                ><i class="fa fa-check-circle fa-sm"></i>
                Signed (S)
              </ng-container>
            </button>
            <!--Check report-->
            <!-- TODO: move this button to the sidebar -->
            <button
              *ngIf="openTopic"
              rr-check-report
              class="btn btn-sm text-nowrap me-1"
              [class.btn-outline-dark]="!report || !report.send_to_voyager_time || !report.text_modified"
              [class.btn-success]="
                report &&
                report.send_to_voyager_time &&
                report.text_modified &&
                report.send_to_voyager_time > report.text_modified
              "
              [class.btn-warning]="
                report &&
                report.send_to_voyager_time &&
                report.text_modified &&
                report.send_to_voyager_time < report.text_modified
              "
              role="button"
              [disabled]="(rightPaneViewMode$ | async) === 'AUDIT'"
              [topic]="openTopic"
              [focus]="true"
              data-no-bubble
              [rrTrack]="'Check Report - Top'"
            ></button>

            <!--Open notes-->
            <rr-report-notes-button [report_id]="reportId" data-no-bubble></rr-report-notes-button>
          </div>

          <div class="d-flex mt-1">
            <!--Open sidebar-->
            <!-- TODO: move this button to the sidebar -->
            <button
              class="btn btn-sm btn-info text-nowrap me-1"
              (click)="toggleSidebar()"
              rrTooltip="OPEN_SIDEBAR"
              [rrTrack]="'Open Sidebar - Right Pane'"
              data-no-bubble
            >
              Sidebar (!)
            </button>
            <!-- TODO: move this button to the sidebar -->
            <rr-open-voyager-button class="me-1" [report_id]="report.id" [expand]="false"></rr-open-voyager-button>
            <rr-zero-fp-images data-no-bubble [report]="report"></rr-zero-fp-images>
            <!-- DO NOT ADD ANY MORE BUTTONS HERE, USE THE SIDEBAR INSTEAD -->
          </div>
        </div>
      </div>

      <div class="d-flex flex-nowrap justify-content-evenly bg-primary text-white rounded-top mt-1 py-1">
        <h5 class="mb-0">
          <ng-container>
            <div *ngIf="(rightPaneViewMode$ | async) === 'AUDIT'">Audit/Undo List (Z)</div>
            <div
              *ngIf="
                (rightPaneViewMode$ | async) === 'REPORT_PREVIEW' || (rightPaneViewMode$ | async) === 'SUGGESTIONS'
              "
            >
              Report Preview (E)
            </div>
          </ng-container>
        </h5>
        <div class="d-flex">
          <button
            rrTooltip="REPORT_PREVIEW"
            role="button"
            [ngClass]="{ active: (rightPaneViewMode$ | async) === 'REPORT_PREVIEW' }"
            class="btn btn-sm btn-light py-0 me-1"
            (click)="toggleRightPane('REPORT_PREVIEW')"
          >
            Preview
          </button>
          <button
            rrTooltip="REPORT_UNDO_STATEMENTS"
            role="button"
            [ngClass]="{ active: (rightPaneViewMode$ | async) === 'AUDIT' }"
            class="btn btn-sm btn-light py-0 me-1"
            (click)="toggleRightPane('AUDIT')"
          >
            Undo
          </button>
          <button
            rrTooltip="CORRELATED_SENTENCE_FINDING"
            role="button"
            [ngClass]="{ active: (rightPaneViewMode$ | async) === 'SUGGESTIONS' }"
            class="btn btn-sm btn-light py-0"
            (click)="toggleRightPane('SUGGESTIONS')"
          >
            Corr.
          </button>
        </div>
      </div>

      <rr-report-preview
        *ngIf="
          ((rightPaneViewMode$ | async) === 'REPORT_PREVIEW' || (rightPaneViewMode$ | async) === 'SUGGESTIONS') &&
          openTopic
        "
        [topic]="openTopic"
      ></rr-report-preview>

      <rr-audit-list
        class="d-flex flex-column flex-grow-1"
        *ngIf="(rightPaneViewMode$ | async) === 'AUDIT' && openTopic"
        [topic]="openTopic"
      ></rr-audit-list>

      <rr-correlated-statement-search
        [hidden]="(rightPaneViewMode$ | async) !== 'SUGGESTIONS'"
        *ngIf="openTopic && report"
        [report]="report"
      ></rr-correlated-statement-search>
    </div>
  </div>
</div>
