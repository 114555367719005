<div class="col-9">
  <div class="row">
    <div class="col-6">
      <strong>Patient:</strong>
      <p>{{ bookingPatientForm.controls.first_name.value }} {{ bookingPatientForm.controls.last_name.value }}</p>
    </div>
    <div class="col-3">
      <strong>Sex:</strong>
      <p class="text-capitalize">{{ bookingPatientForm.controls.gender.value }}</p>
    </div>
    <div class="col-3" *ngIf="bookingPatientForm.controls.dob.value">
      <strong>DOB:</strong>
      <p>{{ bookingPatientForm.controls.dob.value | date: 'dd/MM/yyyy' }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6" *ngIf="bookingPatientForm.controls.phone.value">
      <strong>Phone:</strong>
      <p>{{ bookingPatientForm.controls.phone.value }}</p>
    </div>
    <div class="col-sm-6" *ngIf="bookingPatientForm.controls.email.value">
      <strong>Email:</strong>
      <p>{{ bookingPatientForm.controls.email.value }}</p>
    </div>
  </div>
</div>
