<div class="modal-header cursor-move" cdkDrag cdkDragHandle cdkDragRootElement=".modal-content">
  <h5 class="modal-title">Statement Conditions Modal</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div class="alert alert-light mb-2">
    <rr-statement-text-render
      [statement_id]="statementId"
      [region]="region"
      [template_id]="templateId"
      [proposed]="proposed"
    ></rr-statement-text-render>
  </div>
  <div
    class="row d-flex align-items-end mb-4"
    *ngFor="let condition of conditionForm.controls.conditions.controls; let i = index"
    [formGroup]="condition"
  >
    <div class="col-3">
      <label for="operator">Parameter</label>
      <ng-container *ngIf="data && condition.controls.parameter_id.value !== undefined">
        <div class="mb-2">
          <strong>{{ condition.controls.parameter_id.value | parameterName: parameters }}</strong>
        </div>
      </ng-container>
      <rr-parameter-dropdown
        *ngIf="parameters"
        [title]="false"
        [parameters]="parameters"
        (onChoose)="chooseParameter($event, condition)"
      ></rr-parameter-dropdown>
    </div>
    <div class="col-3">
      <div class="form-group" *ngIf="parameterOperatorTypeMap">
        <label for="operator">Operator</label>
        <select class="form-select" formControlName="operator" id="operator">
          <option [ngValue]="null" disabled>Select Operator</option>
          <option *ngFor="let operator of parameterOperatorTypeMap | keyvalue" [value]="operator.key">
            {{ operator.value }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label for="value">Value</label>
        <input formControlName="value" id="value" class="form-control" placeholder="Numeric value" />
      </div>
    </div>
    <div class="col-3 d-flex align-items-center">
      <div class="me-2">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" formControlName="disabled" id="disabled-{{i}}" />
          <label class="form-check-label" for="disabled-{{i}}">Disabled</label>
        </div>
      </div>
      <button
        *ngIf="!condition.controls.id.value && conditionForm.controls.conditions.controls.length > 1"
        (click)="removeCondition(condition)"
        class="btn btn-danger ms-2"
        type="button"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        *ngIf="data && condition.controls.id.value"
        (click)="deleteParamCondition(condition.controls.id.value)"
        class="btn btn-danger ms-2"
        type="button"
      >
        <i class="fas fa-times"></i>
      </button>
      <button
        *ngIf="data && condition.controls.id.value && (condition.touched || condition.dirty)"
        (click)="updateParamCondition(condition.controls.id.value)"
        class="btn btn-outline-success ms-2"
        type="button"
      >
        <i class="fas fa-pencil-alt"></i>
        Update
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-7 d-flex">
      <button (click)="addCondition()" class="col-3 btn btn-outline-primary" type="button">
        <i class="fas fa-plus"></i>
        New Condition
      </button>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-md-3">
      <div class="form-group" [formGroup]="conditionForm" *ngIf="parameterActionTypeMap">
        <label for="action">Action</label>
        <select class="form-select" formControlName="action" id="action">
          <option [ngValue]="null" disabled>Select Action</option>
          <option *ngFor="let action of parameterActionTypeMap | keyvalue" [value]="action.key">
            {{ action.value }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-3 d-flex align-items-end">
      <div class="form-group" [formGroup]="conditionForm">
        <input class="form-check-input me-1" type="checkbox" formControlName="hide_if_invalid" id="hide_if_invalid" />
        <label for="hide_if_invalid">Hide if invalid</label>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-6 d-flex">
      <button *ngIf="!data" (click)="createParamConditionGroup()" class="col-3 btn btn-primary me-2">Submit</button>
      <button
        *ngIf="data && (conditionForm.controls.action.dirty || conditionForm.controls.hide_if_invalid.dirty) "
        (click)="updateParamConditionGroup()"
        class="col btn btn-success text-nowrap me-2"
      >
        <i class="fas fa-pencil-alt"></i>
        Update Condition Group
      </button>
      <button *ngIf="data && hasNewCondition" (click)="addParamCondition()" class="col btn btn-success text-nowrap">
        <i class="fas fa-pencil-alt"></i>
        Add Parameter Conditions
      </button>
    </div>
  </div>
</div>
