<div *ngIf="booking$ | async as booking">
  <h2 [class.text-center]="context === 'REGISTRATION'"><strong>Booking Summary</strong></h2>
  <div class="form-group alert alert-light align-items-center mb-2">
    <div class="d-flex">
      <h5 class="mb-0 me-1">Booking Type:</h5>
      <span>
        {{ booking.type | titlecase }}
      </span>
    </div>
    <div class="d-flex">
      <h5 class="mb-0 me-1">Patient Type:</h5>
      <span>
        {{ booking.booking_patient ? 'Temporary Booking Patient' : 'RR Existing Patient' }}
      </span>
    </div>
    <div class="d-flex" *ngIf="booking.referrer_type">
      <h5 class="mb-0 me-1">Referrer Type:</h5>
      <span>
        {{ referrerTypes[booking.referrer_type] }}
      </span>
    </div>
    <div *ngIf="booking.referral_enquiry_id" class="d-flex align-items-center">
      <h5 class="mb-0 me-2">E-Referral</h5>
      <button (click)="openReferralViewModal(booking.referral_enquiry_id)" class="btn btn-sm btn-outline-secondary">
        View Data
      </button>
    </div>

    <rr-booking-metadata [booking]="booking"></rr-booking-metadata>

    <ng-container *ngFor="let report of reports$ | async as reports">
      <div class="d-flex flex-nowrap me-1 mb-2" *ngIf="!report.deleted">
        <a class="btn btn-sm btn-success me-1 text-nowrap" [routerLink]="['/report', report.id, 'edit']">
          Open Report: <rr-report-accession-number [report]="report"></rr-report-accession-number
        ></a>
      </div>
    </ng-container>

    <div class="d-flex form-group align-items-center">
      <div class="col">
        <strong>Booking Note:</strong>
        <p>{{ booking.notes || 'None' }}</p>
      </div>
      <div class="col-2">
        <button
          role="button"
          class="btn btn-info btn-sm ms-auto"
          (click)="editNotes(booking.id)"
          rrTooltip="EDIT_BOOKING_NOTES"
        >
          <span class="fa fa-sticky-note"></span> {{ booking.notes ? 'Edit Note' : 'Add Note' }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="context === 'BOOKING'">
    <div
      *ngIf="!booking.patient_id && booking.booking_patient as bookingPatient"
      class="form-group bg-light rounded p-2 align-items-center"
    >
      <rr-booking-patient-view [bookingPatientForm]="bookingPageService.bookingPatientForm"></rr-booking-patient-view>
    </div>

    <rr-patient-form-view *ngIf="patient$ | async as patient" [patient]="patient"> </rr-patient-form-view>
  </ng-container>

  <div *ngIf="context === 'BOOKING'" class="mb-3">
    <rr-notes *ngIf="patient$ | async as patient" [patientId]="patient.id" [type]="'patient'" [minimalView]="true">
      <button role="button" class="btn btn-outline-success" (click)="openCreateNoteModal({ patientId: patient.id })">
        <i class="fas fa-plus"></i>
        Add Note
      </button>
    </rr-notes>
  </div>

  <div ngbAccordion class="mt-2">
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button ngbAccordionButton>SMS History</button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template>
            <ng-container *ngFor="let event of sendEvents; let last = last">
              <div
                class="sms-booking-confirmation"
                [ngClass]="{ 'sms-booking-confirmation-border': !last || sendEvents.length > 1 }"
                *ngIf="event.communication_type === 'SMS'"
              >
                <span
                  >Sent by <strong>{{ event.user_name }}</strong> on
                  <strong>{{ event.when | date: 'd/MM' }}</strong></span
                >
                <span> : {{ event.message }}</span>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
