import { Route, Routes } from '@angular/router';

import { AuthGuard, NoAuthGuard } from './core/guards/auth-guard.service';

export const startRoute: Route = {
  path: 'start',
  loadComponent: () =>
    import('app/modules/worklist/pages/worklist/worklist.component').then((m) => m.WorklistComponent),
  canActivate: [AuthGuard],
};

export const loginRoute: Route = {
  path: 'login',
  loadComponent: () => import('./core/auth/login/login.component').then((m) => m.LoginComponent),
  canActivate: [NoAuthGuard],
};

export const registerRoute: Route = {
  path: 'register',
  loadComponent: () => import('./core/auth/register/register.component').then((m) => m.RegisterComponent),
  canActivate: [NoAuthGuard],
};

export const userRoute: Route = {
  path: 'user-setting',
  loadComponent: () =>
    import('./modules/user/pages/user-settings/user-settings.component').then((m) => m.UserSettingsComponent),
  canActivate: [AuthGuard],
};

export const adminRoute: Route = {
  path: 'admin',
  // The main component
  loadComponent: () => import('app/modules/admin/pages/admin/admin.component').then((m) => m.AdminComponent),
  // For the <router-outlet> in AdminComponent
  loadChildren: () => import('app/modules/admin/admin-routing.module').then((m) => m.ROUTES),
  canActivate: [AuthGuard],
};

export const rrCodesRoute: Route = {
  path: 'codes',
  canActivate: [AuthGuard],
  loadComponent: () => import('./modules/rr-codes/rr-codes.component').then((m) => m.RrCodesComponent),
  loadChildren: () => import('./modules/rr-codes/rr-codes-routing').then((m) => m.ROUTES),
};

export const statisticsRoute: Route = {
  path: 'staff-performance',
  canActivate: [AuthGuard],
  loadComponent: () =>
    import('./modules/staff-performance/staff-performance/staff-performance.component').then(
      (m) => m.StaffPerformanceComponent,
    ),
};
export const graphingRoute: Route = {
  path: 'graphs',
  canActivate: [AuthGuard],
  loadComponent: () => import('./modules/graphing/graphing/graphing.component').then((m) => m.GraphingComponent),
};

export const bookingRoute: Route = {
  // TODO: different path?
  path: 'registration',
  loadChildren: () => import('app/modules/booking/booking-routing.module').then((m) => m.ROUTES),
};

export const registrationRoute: Route = {
  path: 'registration',
  loadChildren: () => import('app/modules/registration/registration-routing.module').then((m) => m.ROUTES),
};

export const entityListingRoute: Route = {
  path: 'entities',
  loadChildren: () => import('app/modules/entity-listing/entity-listing-routing.module').then((m) => m.ROUTES),
};

export const reportRoute: Route = {
  path: 'report',
  loadChildren: () => import('app/modules/report/report-routing.module').then((m) => m.ROUTES),
  data: {
    showToolbar: false,
  },
};

const launchReportRoute: Route = {
  path: 'report/launch',
  loadComponent: () =>
    import('app/modules/launch-report/launch-report-route.component').then((m) => m.LaunchReportRouteComponent),
  canActivate: [AuthGuard],
  data: {
    permissions: {
      only: ['view'],
      redirectTo: '/login',
    },
  },
};

export const mergeRoute: Route = {
  path: 'merge',
  loadChildren: () => import('app/modules/merge/merge-routing.module').then((m) => m.ROUTES),
};

export const invoiceRoute: Route = {
  path: 'invoices',
  canActivate: [AuthGuard],
  loadComponent: () => import('app/modules/invoice/pages/invoice/invoice.component').then((m) => m.InvoiceComponent),
};

export const imageAnnotationRoute: Route = {
  path: 'tasks',
  canActivate: [AuthGuard],
  loadComponent: () =>
    import('app/modules/image-annotation/pages/annotation-task-list/annotation-task-list.component').then(
      (m) => m.AnnotationTaskListComponent,
    ),
};

export const followupTaskRoute: Route = {
  path: 'followup-tasks',
  canActivate: [AuthGuard],
  loadComponent: () =>
    import('app/modules/followup-task/pages/followup-task-search/followup-task-search.component').then(
      (m) => m.FollowupTaskSearchComponent,
    ),
};

export const sandboxRoute: Route = {
  path: 'sandbox',
  loadComponent: () => import('app/modules/sandbox/sandbox/sandbox.component').then((m) => m.SandboxComponent),
};

const radresActivityRoute: Route = {
  path: 'radres-activity',
  loadComponent: () =>
    import('app/modules/radres-activity/radres-activity/radres-activity.component').then(
      (m) => m.RadresActivityComponent,
    ),
};

export const githubIssueRoute: Route = {
  path: 'issues/:githubIssueId',
  canActivate: [AuthGuard],
  loadComponent: () =>
    import('app/modules/radreport-issues/components/radreport-issues/radreport-issues.component').then(
      (m) => m.RadreportIssuesComponent,
    ),
};

export const ROUTES: Routes = [
  loginRoute,
  registerRoute,
  startRoute,
  userRoute,
  adminRoute,
  statisticsRoute,
  graphingRoute,
  bookingRoute,
  registrationRoute,
  entityListingRoute,
  reportRoute,
  mergeRoute,
  launchReportRoute,
  invoiceRoute,
  imageAnnotationRoute,
  followupTaskRoute,
  sandboxRoute,
  radresActivityRoute,
  githubIssueRoute,
  rrCodesRoute,
  {
    path: '**',
    redirectTo: '/start',
  },
];
