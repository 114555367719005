import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { BindObservable } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { SharedModule } from 'app/shared/shared.module';
import { PatientHttpService } from 'app/store/patient';
import { formatISO } from 'date-fns';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

type SearchInputName = 'name' | 'dob' | 'medicare' | 'mobile' | 'none';

@Component({
  standalone: true,
  selector: 'rr-patient-form-search',
  templateUrl: './patient-form-search.component.html',
  styleUrls: ['./patient-form-search.component.css'],
  imports: [CommonModule, NgbModule, ReactiveFormsModule, SharedModule],
})
export class PatientFormSearchComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() bookingPatient: Partial<RR.Booking['booking_patient']> | undefined;
  bookingPatient$: Observable<RR.Booking['booking_patient'] | undefined>;
  /** We don't create Patients on the Booking page */
  @Input() parent: 'REGISTRATION' | 'PATIENT' | 'BOOKING' = 'REGISTRATION';

  @Output() onCreateNewPatient = new EventEmitter();
  @Output() onUndoRemoval = new EventEmitter();
  @Output() onSelectPatient: EventEmitter<{ patient: RR.Patient; created: boolean }> = new EventEmitter();

  focus$ = new Subject<string>();
  focusedInput: SearchInputName = 'none';

  searchForm = new FormGroup({
    search_name: new FormControl('', { nonNullable: true }),
    search_dob: new FormControl('', { nonNullable: true }),
    search_medicare: new FormControl('', { nonNullable: true }),
    search_mobile: new FormControl('', { nonNullable: true }),
  });

  subscription = new Subscription();

  nameTypeahead: (text$: Observable<string>) => Observable<RR.Patient[]>;
  dobTypeahead: (text$: Observable<string>) => Observable<RR.Patient[]>;
  medicareTypeahead: (text$: Observable<string>) => Observable<RR.Patient[]>;
  mobileTypeahead: (text$: Observable<string>) => Observable<RR.Patient[]>;

  constructor(
    private patientService: PatientHttpService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.bookingPatient$.subscribe((patient) => {
        if (patient) {
          const p = patient;
          this.patchSearchForm({
            patient_first_name: p.first_name,
            patient_last_name: p.last_name,
            phone_mobile: p.phone,
          });
        }
      }),
    );

    this.nameTypeahead = this.suggestPatients('name');
    this.dobTypeahead = this.suggestPatients('dob');
    this.medicareTypeahead = this.suggestPatients('medicare');
    this.mobileTypeahead = this.suggestPatients('mobile');
  }

  patchSearchForm(patient: Partial<RR.Patient>) {
    const first_name = patient.patient_first_name;
    const last_name = patient.patient_last_name;
    this.searchForm.patchValue({
      search_name: (first_name ? first_name + ' ' : '') + (last_name ?? ''),
      search_dob: `${patient.patient_dob ? patient.patient_dob : ''}`,
      search_medicare: `${patient.medicare_number ? patient.medicare_number : ''}`,
      search_mobile: patient.phone_mobile ?? '',
    });
  }

  getPatientDataFromSearchInput() {
    const form = this.searchForm.getRawValue();
    return {
      patient_first_name: form.search_name,
      patient_last_name: form.search_name,
      patient_dob: form.search_dob ? formatISO(new Date(form.search_dob), { representation: 'date' }) : undefined,
      medicare_number: form.search_medicare,
      phone_mobile: form.search_mobile,
    };
  }

  createNewPatient() {
    this.onCreateNewPatient.emit();
  }

  undoRemoval() {
    this.onUndoRemoval.emit();
  }

  focusInput(inputName: SearchInputName, value: string) {
    this.focusedInput = inputName;
    this.focus$.next(value);
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  suggestPatients = (inputName: SearchInputName) => (text$: Observable<string>) => {
    const debouncedText$ = merge(this.searchForm.valueChanges, text$).pipe(debounceTime(200), distinctUntilChanged());
    return merge(debouncedText$, this.focus$).pipe(
      filter(() => this.focusedInput === inputName),
      switchMap(() => {
        const form = this.searchForm.getRawValue();
        const p = this.getPatientDataFromSearchInput();

        return this.patientService
          .elasticSearch({
            patient_full_name: form.search_name,
            patient_dob: p.patient_dob,
            medicare_number: p.medicare_number,
            phone_mobile: p.phone_mobile,
          })
          .pipe(
            map((result) => result.patients),
            this.messageService.handleHttpErrorPipe,
          );
      }),
    );
  };

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  selectPatient = (event: NgbTypeaheadSelectItemEvent<RR.Patient>) => {
    this.onSelectPatient.emit({ patient: event.item, created: false });
    event.preventDefault();
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
