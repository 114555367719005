import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { AppState } from 'app/store';
import { fromReport } from 'app/store/report/report';
import { Subscription } from 'rxjs';

import { ReportHeadlineComponent } from '../../../../shared/components/report-headline/report-headline.component';
import { RegistrationComponent } from '../../components/registration/registration.component';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'rr-registration-modal',
  templateUrl: './registration-modal.component.html',
  styleUrls: ['./registration-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, ReportHeadlineComponent, RegistrationComponent],
  providers: [
    // Create a new instance of RegistrationService for the modal and its child RegistrationComponent
    RegistrationService,
  ],
})
export class RegistrationModalComponent implements OnInit, OnDestroy {
  @Input() reportId: number;

  subscription = new Subscription();
  report: RR.Report | undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private registrationService: RegistrationService,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store
        .select(fromReport.selectReport(this.reportId))
        .pipe(filterDefined())
        .subscribe((report) => {
          this.report = report;
          this.registrationService.reportForm.patchValue({
            reportId: report.id,
            bookingId: report.booking_id,
            referrerId: report.referrer_id,
            patientId: report.patient_id,
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(modalService: NgbModal, reportId: number) {
    const modalRef = modalService.open(RegistrationModalComponent, {
      scrollable: true,
      backdrop: 'static',
      windowClass: 'registration-modal',
    });
    modalRef.componentInstance.reportId = reportId;

    return modalRef;
  }
}
