import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BindObservable } from 'app/app.utils';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { EditorExpandedState } from 'app/modules/editor/editor.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromReport, ReportEffect } from 'app/store/report/report';
import { Observable, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { PriorStudyLinksService, RelatedReport } from './prior-study-links.service.component';
import { RelatedReportComponent } from './related-report/related-report.component';
import { RelatedTopicComponent } from './related-topic/related-topic.component';

export type PriorReportMode = 'all' | 'related' | 'unreported' | 'recentUnreported' | 'sameDay';

let nextId = 0;

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, RelatedReportComponent, RelatedTopicComponent, SharedModule],
  selector: 'rr-prior-study-links',
  templateUrl: './prior-study-links.component.html',
  styleUrls: ['./prior-study-links.component.css'],
})
export class PriorStudyLinksComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  @Input() topic: RR.Topic;
  @Input() defaultMode: PriorReportMode = 'all';
  @Input() @BindObservable() selectedTopicIds: number[] = [];
  // Topics in related report can be selected and copied heading or imported to prefill
  @Input() selectable = false;
  @Input() editorExpandedState: EditorExpandedState = 'INDEX';

  @Output() onSelectedTopicsChange = new EventEmitter();
  mode = new FormControl<PriorReportMode | undefined>(undefined, { nonNullable: true });
  subscription = new Subscription();

  relatedReportsLoaded$: Observable<boolean>;
  relatedReports: { [key: string]: RelatedReport[] } = {};

  /**
   * So that the labels and checkbox inputs have unique ids across instances of this component
   */
  instanceId = `${nextId++}`;

  constructor(
    private reportService: ReportService,
    private templateService: TemplateService,
    private priorStudyLinksService: PriorStudyLinksService,
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
  ) {}

  ngOnInit() {
    this.mode.setValue(this.defaultMode);
    this.relatedReportsLoaded$ = this.store.select(fromReport.selectRelatedReportsLoaded(this.report.id));

    this.subscription.add(this.reportEffect.findRelatedReports(this.report.id, this.topic.id).subscribe());

    this.subscription.add(
      this.priorStudyLinksService.getRelatedReports(this.report, this.topic).subscribe((reports) => {
        const { sameDay, all, related, unreported, recentUnreported } = reports;
        this.relatedReports = {
          sameDay,
          all,
          related,
          unreported,
          recentUnreported,
        };
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getTemplateName(topic_id: number) {
    return this.reportService
      .getTopic(topic_id)
      .pipe(switchMap((topic) => this.templateService.getTemplateName(topic.template_id)));
  }
}
