/**
 * The tooltips are defined in this file to make it for the project manager (non technical) to change them. The numbers
 * make it easy to find the tooltip in the code.
 *
 * The TooltipDirective uses the `rrTooltip` attribute to display the tooltip, for example:
 *   ```html
 *   <button rrTooltip="REMOVE_STUDY_TITLE">Remove</button>
 *   ```
 *
 * Numbers were generated using this script: src/scripts/app-constants-tidy.ts
 */
export const TOOLTIPS = {
  REMOVE_STUDY_TITLE: '1: Remove this template from the report',
  REORDER_STUDY_TITLE: '2: Click to open the study, drag to reorder',
  ADD_NEW_STUDY_TITLE: '3: Add another template to this report',
  GO_BACK_TO: '4: Return to report without adding a template',
  CHOOSE_STUDY_TYPE:
    '5: Search for a template by booking code, name or part name match, then click-select to add to current report ',
  SEARCH_TEMPLATE_TEXT: '6: Enter text to locate matching text in Group or Topic Statements (both in centre panel)',
  CLEAR_TEXT: '7: Clear text from Search Group and Topic Statements',
  FLAG: '8: Click to add report notes',
  SHOW_PATIENT_INFO_BY_EDITORS:
    '9: Click to open further report details including old studies, Accession (Episode) No., DOB, Voyager Study Date, Site, Phone number to SMS report. Click Images to open Fast Viewer and verified Report text',
  NUMBER_OF_REPORTS: '10: The number of reports related to this patient',
  REPORT_ALERT: '11: There is at least one report issued in the same day',
  DETAILED_EDIT_HISTORY: '12: Show editorial history, additions and deletions for each contributor to this report',
  HIDDEN_EDITORS: '13: Click to show all editors of this report',
  DISTRIBUTE_LINK_TO_IMAGE: '14: Distribute a secure web link to access this report, to the phone number listed',
  DISTRIBUTE_REPORT_TEXT_TO_EMAIL: '15: Distribute report text to the email address listed',
  DISTRIBUTE_REPORT_TEXT_TO_FAX: '16: Distribute report text to the fax number listed',
  PREVIOUS_REPORTS: '17: Jump to Prefill Report page to add selected Topics and statements to the current report',
  OPEN_SIGNATURE: '18: Select Radiologist Signature from Signature Dialogue Box',
  COPY_TO_CLIPBOARD: '19: Open Readable version of report for final preview and checking',
  TAG_SENTENCE: '20: Group statement',
  ADD_SIGNATURE: '21: Select Signature from Signature Dialogue Box',
  REMOVE_SIGNATURE: '22: Delete Radiologist Signature',
  NAME_FAVOURITE: '23: Name and Add this report to Favourite Reports listing',
  HOVER_TAG: '24: Click to add this group into search box',
  OPEN_PREFILL_IN_NEW_WINDOW: '25: Open this report in new window',
  SEND_TO_VOYAGER: '26: Save the report text to Voyager and set status to Transcribed',
  DELAY_SEND_TO_VOYAGER:
    '27: Schedule to save the report text to Voyager and set status to Transcribed. After scheduling, you can still edit the report and the new report text will be sent to Voyager.',
  CHECKED_SENTENCE: '28: This statement was checked in a different study',
  PREFILL_METRIC_1: '29: Percentage of statements in the report that are also suggested by prefill',
  PREFILL_METRIC_2: '30: Percentage of suggestions that are also in the report',
  COPY_ALL: '31: Copy all visible statements from the Prefill or Favourite to the report',
  COPY_ALL_KEEP_NUMBERS:
    '32: Copy all visible statements(keep all numbers) from the Prefill or Favourite to the report',
  BACK_TO_EDITOR: '33: Go back to Main Edit Page',
  SHOW_INDEX: '34: Show or Hide History and Technique sections in Prefill Panel',
  SHOW_DEFAULTS: '35: Show or Hide the Prefill or Favourite statements that default to ON in the default template',
  SHOW_MORE_COMMENTS: '36: Show all comments and recommendations that appear in selected reports',
  ADD_TITLE: '37: Select a title for the report, then Click away to Close the Title Select window',
  PREFILL_METADATA_RADIO: '38: Search previous reports by report information',
  PREFILL_FAVOURITES_RADIO: '39: Search favourite reports',
  PREFILL_SUBDIVISION_RADIO: '40: Search previous reports by subdivision',
  PREFILL_FULL_TEXT_RADIO: '41: Search previous reports containing text',
  NAVIGATE_MIDDLE_PANE: '42: Navigate Statement Panel to this Section',
  REMOVE_SENTENCES: '43: To remove section, click then press ENTER key',
  KEY_FINDING: '44: Add statement to Key Findings as well as Findings in Detail',
  COPY_TO_FULL_TEXT_SEARCH: '45: Search for this statement in full text search',
  EXCLUDE_SENTENCE: '46: Click to exclude the statement from the final report',
  UNEXCLUDE_SENTENCE: '47: The statement is excluded, click to include it in the final report.',
  RECOMMENDED_STATEMENT: '48: The statement was suggested via voice-notes',
  COMMENT: '49: Add as a comment and remove from Findings in Detail',
  CLONE_IMPRESSION_RECOMMENDATIONS: '50: Add statement to Impression and Recommendations',
  CHOOSE_ONLY_THIS_STATEMENT:
    '51: Add statement to Findings in Detail (and unchoose up to two other currently selected statements from this Topic)',
  REMOVE_FROM_REPORT: '52: Remove statement from all Sections of this report',
  CHOOSE_AGAIN: '53: Repeat this statement with additional or different variable selections',
  CREATE_BLUE_ATTRIBUTE: '54: Save the search term in the statement only(Blue attributes)',
  EDIT: '55: Open statement text for editing, attribute assignment, default assignment, or statement deletion',
  CREATE_AND_CLONE_STATEMENT: '56: Create a new statement and then create a copy of that statement above the new one',
  CLONE_STATEMENT: '57: Create a copy of this statement above the current one',
  COPY_STATEMENT_TO_TEMPLATE: '58: Copy this notepad statement to the template',
  // TODO: SET_DEFAULT conflated incorrectly. Used for both Statement and Attribute.
  SET_DEFAULT: '59: Set as default statement in default template or default attribute in a statement',
  EDIT_ATTRIBUTE: '60: Edit or Remove Attribute',
  REMOVE_ATTRIBUTE: '61: Remove Attribute from Attribute List',
  CANCEL: '62: Cancel editorial changes to Attribute or statement',
  SUBMIT: '63: Submit Attribute or statement editorial changes',
  SUBMIT_AND_CLOSE: '64: Submit Attribute or statement editorial changes, then close the modal',
  ADD_ATTRIBUTE: '65: Convert designated words to Attributes in the selected statement',
  DECORATE_SENTENCE: '66: Capitalise the first letter and add full stop for the statement',
  AUTO_DECORATE_SENTENCE:
    '67: Capitalise the first letter and add full stop for the statement automatically for voice feature',
  MANDATORY_STATEMENT: '68: Make this statement mandatory for the template',
  APPROVE:
    '69: Approve a manually entered statement for inclusion in the Template and current report at the end of the current Topic',
  NOT_ORDERED_ALPHABETICALLY: '70: Click to order this attribute list manually (Enable Drag and Drop)',
  EDIT_ATTRIBUTE_SET_NAME: '71: Edit Attribute Set name',
  CHECK_ATTRIBUTE_SET_USAGE: '72: Check Attribute Set usage/Replace attribute set in statements',
  REPLACE_MATCHING_TEXT:
    '73: When approving a manually entered statement, replace matching text with this Attribute List',
  ADD_TOP_OF_FUTURE_LIST: '74: Add to the short list on all future occurrences of this Attribute List',
  REMOVE: '75: Remove this item from the Attribute List permanently',
  COMMA_SEPARATED: '76: Comma separated if multiple billing codes',
  DELETE_NOTICE: '77: Delete notice',
  REMOVE_ELEMENT_FROM_TEMPLATE: '78: Remove Element from the template and return Element to Element Library',
  SHOW_ELEMENT_USAGES: '79: Click to show Element usage in other Template types',
  CREATING_STATEMENT:
    '80: Superuser Only: Create a new statement for this Element, for later re-use in all Templates sharing this Element. Use Attribute creation syntax to enter Attributes in place of free text wherever appropriate. Restrict each statement to a single concept or descriptor. Start a new statement for every new conceptual finding or comment. Each statement must stand alone and not rely on context or implicit reference to other nearby statements for specific meaning. Avoid combining findings that are independent or that may occur separately, make into separate statements.',
  ONLY_THIS_REGION: '81: Select "Only" the indicated region to hide remaining regions in the current report',
  REMOVE_REGION:
    '82: Hide this region in the current report. Select again by going to the Pick Lumbar Segments selection panel and ticking the required region tick box.',
  PREVIOUS_REGION:
    '83: Navigate to the same Topic Heading in the Prior Region Statement Panel. The Regional statement selections from any region can be accessed and copied to the currently selected region by using the down arrow at the right hand end of the Region Header Bar to show and select available Regional selections. Click on the required level to copy across to the currently selected region and delete the current selections.',
  NEXT_REGION:
    '84: Navigate to the same Topic Heading in the Prior Region Statement Panel. The Regional statement selections from any region can be accessed and copied to the currently selected region by using the down arrow at the right hand end of the Region Header Bar to show and select available Regional selections. Click on the required level to copy across to the currently selected region and delete the current selections.',
  REGION_DROPDOWN: '85: Select a different region',
  COPY_FROM_REGION_DROPDOWN: '86: Copy from a different region',
  INSERT_INTO_SENTENCE: '87: Select Attribute to replace Default Attribute in this use of the statement',
  DELETE_STATEMENT:
    '88: Delete this statement from this Topic, including all occurrences in all Template types. If this is the last statement in the Element then the Element itself will also be permanently deleted.',
  TAB_OR_ENTER_SPECIFICATION:
    '89: Enter numeric value into the Numeric dialogue window then TAB to move to the next Numeric dialogue window where applicable. Press Tab or ENTER to submit the values. The numeric value can be adjusted with the multiple Clicks on the up and down arrows in the Numeric dialogue window.',
  SET_BOUNDS:
    '90: Set a Normal range by entering upper and or lower bounds in the Statement number dialogue box. A numeric formula can be added to the Formula window, using any prior numeric values in the same statement designated a,b,c etc. The Formula is in the format a*b-c etc. Where upper or lower bounds are set, the Numeric dialogue window will be RED until a number in the normal range is entered or calculated.',
  NUMBER_MULTIPLE_DICOM_MEASUREMENT: '91: Has multiple DICOM SR measurements available',
  INSERT_NEW_OPTION:
    '92: Create a new Element by creating the first statement that will then be added to this location in the report. Add an Index Heading at time of creation of the Element. Add further statements as required.',
  INSERT_SUBSECTION:
    '93: Insert a new Subsection into the Template to delineate the information in the report clearly. Navigation to the Subsection is easier because the Subsection remains visible in the right hand statement panel but is not shown in clipboard view if there are no contents in the subsection.',
  ADD_ELEMENT:
    '94: Add Element to Template permitting sharing of Elements across multiple Template types. Modifications in one Template type are reflected almost instantly, everywhere the Element is inserted for use.',
  EDIT_SUBSECTION: '95: Edit subsection',
  DELETE_SUBSECTION: '96: Delete this subsection and move the Group and statements into the previous subsection',
  SUBSECTION_ON_OFF:
    '97: Subsection inclusion as a default in the default Template in the baseline report is given by ON or OFF status toggle',
  INSERT_STATEMENT_BEFORE:
    '98: While in Edit mode, Insert slot before designated statement, to permit insertion of new or additional statements in the Element.',
  PROMPT_DELETE_STATEMENT: '99: Delete this statement from all Default Templates of this study type',
  INITIALS_ENTERED_UNTIL_LOG_OUT: '100: Your initials will be automatically entered until you log out',
  NAVIGATE: '101: Click to navigate Statement Panel to this Subsection',
  RR_CODES: '102: View booking, scan or billing codes',
  BOOKING_CODES: '103: View booking codes',
  SCAN_CODES: '104: View scan codes',
  BILLING_CODES: '105: View billing codes',
  DATE_OF_BIRTH: "106: Select patient's date of birth",
  SORT: '107: Select sort by',
  ACCESSED_BY: '108: Reports accessed by these users',
  SIGNED_BY: '109: Reports signed by these users',
  ANYTIME: '110: View all reports',
  TODAY: "111: View only today's reports",
  YESTERDAY: "112: View only yesterday's reports",
  THIS_WEEK: '113: View reports since Monday this week',
  LAST_WEEK: "114: View all last week's reports",
  LAST_2_WEEK: '115: View all reports since Monday last week',
  MONTH: "116: View this month's reports",
  LAST_MONTH: "117: View last month's reports",
  THIS_YEAR: "118: View this year's reports",
  FLAGGED_ONLY: '119: View only flagged report',
  HAVE_NOT_SIGNED: '120: View report which are not signed yet',
  SIGNED_ONLY: '121: View signed reports only',
  ANY_SITE: '122: View reports from all sites',
  OPEN_IMAGE: '123: Open Images and Report template in separate Tabs',
  OPEN_IMAGE2: '124: Open Images Login - To view referral images',
  CANCEL_FAVOURITE: '125: Cancel',
  SAVE_FAVOURITE: '126.1: Save favourite',
  UPDATE_PRESET: '126.2: Update preset',
  REPLACE_FAVOURITE: '127: Replace favourite with this report',
  UNDO_CHANGES: '128: Click to undo',
  GOTO_CHANGES: '129: Click to check the statement',
  CLIPBOARD_VIEW: '130: Click to open clipboard view',
  SHORTCUTS: '131: Shortcuts',
  GLOBAL_SEARCH_SHOW_HISTORY: '132: Show search history',
  IMPORT_TO_PREFILL: '133: Click to show prefill panel for this study',
  IMPORT_TO_PREFILL_LATEST_STUDY: '134: Click to show prefill panel for the latest prior study of this patient',
  OPEN_NOTICES: '135: Click to open Notice popup',
  SET_DIVIDER: '136: Set this statement as a group',
  TUTORIALS_START_TEMPLATE: '137: How to start a template',
  TUTORIALS_NAME_FAVOURITE: '138: How to name a favourite',
  TUTORIALS_FIND_DELETED_SENTENCES: '139: How to find statements that were deleted',
  BOOST_TITLE: '140: ',
  BOOST_SEX: '141: ',
  BOOST_DOB: '142: ',
  BOOST_DATE_CREATED: '143: ',
  BOOST_SENTENCES: '144: ',
  BOOST_MULTIPLIERS: '145: ',
  SEARCH_ACCESSION: '146: ',
  SORT_BY_DUE: '147: Sort reports by urgency',
  URGENT_NOTES: '148: Click to add admin notes',
  ADD_TO_OTHER_REGIONS: '149: Click to add this statement to other regions',
  HIGHLIGHT_STATEMENT_ONLY: '150: Click to redirect to the statement',
  HIGHLIGHT_AND_CHOOSE_STATEMENT: '151: Click to redirect to the statement and select it in the report.',
  EDIT_PATIENT_DETAILS: '152: Click to edit patient details',
  COPY_REPORT_HEADINGS: '153: Copy prior reports to other imaging',
  COPY_REPORT_HEADINGS_AND_KEY_FINDINGS: '154: Copy prior reports and their key findings to other imaging',
  SET_DEFAULT_SUBSECTION_HEADINGS: '155: Click to hide or show subsection headings',
  SHOW_SUBSECTION_HEADING: '156: Click to show the subsection heading',
  HIDE_SUBSECTION_HEADING: '157: Click to hide the subsection heading',
  ATTRIBUTIFY_ONE:
    '158: Steps to attributify one word: 1. Double click a word 2. Click this button 3. Choose an attribute\nSteps to attributify words: 1. Highlight word(s) 2. Click the button. 3. Choose an attribute.',
  CREATE_ADMIN_ATTRIBUTE: '159: Create a new attribute in the attribute dropdown list',
  APPROVE_BLUE_ATTRIBUTE: '160: Click to approve this blue attribute',
  MOVE_MULTI: '161: Click to move multiple statements',
  OPEN_EDIT_MODE: '162: Click to open edit mode (to select multiple statements), or press "e" on the keyboard',
  EDIT_OTHER_IMAGING:
    "163: To edit other imaging: Click on edit button Or 1.Expand patient's info area 2. Select prior studies 3.Click 'Copy report headings'",
  FIVE_LATEST_TOPICS: '164: Click to open prefill and get 5 latest topics that have the same title with current topic',
  PREFILL_TITLE_SENTENCES:
    '166: Add common statements (both defaults and non-defaults) from previous reports that have the same title',
  PREFILL_PREDICTION_SENTENCES:
    '169: Add common statements from closely related reports (Reports that might have same title, age and sex)',
  FEEDBACK: '170: Click to check feedbacks',
  UNDISTRIBUTED_REPORT: '171: View reports which are not distributed yet',
  DISTRIBUTED_REPORT: '172: View distributed reports only',
  UNBILLED_REPORT: '175: View reports that have no invoices',
  DEFAULT_COPY_REGION: '176: Tick the checkbox to copy the statement with default attributes',
  CURRENT_COPY_REGION: '177: Tick the checkbox to copy the statement with current attributes',
  ADD_TOOLTIP_SENTENCE: '178: Click to add a tooltip for the statement',
  SEND_FEEDBACK: '179: Send feedback to editors who contributed to the report',
  DISTRIBUTE_REPORT: '180: Distribute report to patient via SMS/Fax/Email',
  REMOVE_NOTEPAD_SENTENCE: '181: Click to remove this global statement',
  INSERT_NOTEPAD_SENTENCE: '182: Click to insert a global statement above',
  EDIT_NOTEPAD_SENTENCE: '183: Click to edit this global statement',
  MOVE_ATTRIBUTE_TO_TOP: '184: Click to move this attribute to the top',
  MOVE_ATTRIBUTE_TO_BOTTOM: '185: Click to move this attribute to the bottom',
  MARK_AS_URGENT: '186: Click to mark this report as an urgent report',
  SELECT_FOR_MERGE:
    '187: Select this Report merging with another Report. Click "Merge with Selected" on the another Report',
  DELETE_REPORT: '188: Delete this report and hide it from the worklist',
  ADD_REPORT_ADMIN_NOTES: '189: Click to add report or admin notes',
  ORDER_ALPHABETICALLY: '190: Click to order this attribute list alphabetically',
  ADMIN_SHOW_PERMISSIONS: '191: Edit the Permissions inherited by this Company Role',
  CC_TO: '192: Create a note for distributing report to this phone/fax number or email when the report is completed.',
  FILTER_BY_PATIENT_NAME: '195: Filter worklist by patient name',
  WORKLIST_AUDIT_EVENT_SUMMARY: '196: Summary of who contributed to the report and for how long',
  SAVE_CC_TO: '197: Save fax number/email address to distribute the report to that fax/email once it is finalised',
  CREATE_CATEGORY: '201: Create new category under this element',
  EDIT_CATEGORY: '202: Edit this category',
  REMOVE_CATEGORY: '203: Remove this category',
  CATEGORISE_SENTENCE: '204: Assign a category for this statement',
  REMOVE_CATEGORY_FOR_SENTENCE: '205: Remove this category for the statement',
  CLONE_CATEGORY: '206: Copy this category text, then edit and press enter to create a new category',
  SHOW_CATEGORY_SENTENCES: '207: Show statements classified under this category',
  SHORTEN_STATEMENT_LIST:
    '208: Click to toggle between all statements in this element as a % of commonly used statements',
  SHORTEN_STATEMENT_LIST_WITH_AGE:
    '209: Click to toggle between all statements in this element as a % of commonly used statements in patients of the same age',
  SHORTEN_STATEMENT_LIST_WITH_LOGISTIC_REGRESSION:
    '210: Click to toggle between all statements in this element as a % of linear regression suggestions',
  INCREASE_TEN_PERCENT: '211: Click to show a greater number of less commonly used statements',
  DECREASE_TEN_PERCENT: '212: Click to show only more common statements',
  ELEMENT_SHOW_ALL_SENTENCES:
    '213: Click to show all available statements in this element including rarely used statements',
  EDITOR_CLIPBOARD:
    '214: Click to show clipboard statement (recently deleted statement or blue statement, to make it easier for statement substitution).',
  COPY_ALL_CHOICES: '215: Copy all sections from this study to current selected study',
  INVALID_SENTENCE: '216: This statement has never been used in previous reports.',
  CATEGORISE_TAG_SENTENCE: '217: Click to categorise this group/statement',
  TOP_20_CATEGORIES: '218: Select categories from top 20 most used categories to classify the statement.',
  SELECT_OTHER_CATEGORIES: '219: Select categories from category modal to classify the statement.',
  NEXT_UNCATEGORISED_DIVIDER: '220: Move to the next uncategorised group in the same element.',
  NEXT_DIVIDER: '221: Move to the next group in the same element.',
  EXACT_MATCH_TOPICS: '222: Click to show/hide studies that have exactly the same statements with current study.',
  EXACT_MATCH_REFRESH: '223: Click to refresh exact match study list when statements are added/removed.',
  PREFILL_EXACT_MATCH_STUDIES:
    '224: When it is checked, prefill buttons (Top6, One more...) will populate prefill with studies in exact match study list',
  REFRESH_AND_SELECT_TOP6_EXACT_MATCH:
    '225: Close the modal, refresh exact match search with new added/removed statements, and select top 6 studies',
  REFRESH_EXACT_MATCH_FROM_MODAL: '226: Close the modal, refresh exact match search with new added/removed statements',
  SEARCH_DIVIDER_IN_PREFILL: '227: Click to add this group to Prefill search',
  JUMP_TO_DIVIDER_IN_TEMPLATE: '228: Click to jump to this group in the template',
  SHOW_DIVIDER_SENTENCES: '229: Click to show statements under this group',
  CURRENT_REPORT_DIVIDER: '230: Group is included in current report',
  EXCLUSIVE_DIVIDER: '231: Group should be excluded due to conflict with other selected groups in the current report',
  ELEMENT_SCROLL_TO_TOP: '232: Click to scroll to top of the element',
  ELEMENT_SCROLL_TO_BOTTOM: '233: Click to scroll to the end of the element',
  PREFILL_STATEMENT_LIST_WITH_LOGISTIC_REGRESSION:
    '235: Click to apply linear regression suggestions to selected studies',
  PREFILL_LOGREG_THRESHOLD_INCREASE: '236: Click to increase probability threshold by 10%',
  PREFILL_LOGREG_THRESHOLD_DECREASE: '237: Click to decrease probability threshold by 10%',
  SEARCH_CURRENT_ELEMENT:
    '238: Search statements in this element or add blue statements for this report only. If you want to search for statements in other elements/subsections/sections, please use global search function on the top bar',
  PREFILL_COMBINE: '239: Select top 8 from metadata, top 8 from exact match search and top 8 from presets',
  PREFILL_TAG_MODAL_COMBINE: '240: Close the modal, select top 8 from metadata and top 8 from exact match search',
  PREFILL_TOP6: '241: Select top 6 rows from prefill metadata search B2',
  PREFILL_TOP6_EXACT_MATCH: '242: Select top 6 rows from prefill statements match B3',
  PREFILL_ONE_MORE_ROW: '243: Select 1 more row from prefill search results',
  PREFILL_REMOVE_LAST_ROW: '244: Remove last row from the selections',
  PREFILL_CLEAR_ALL: '245: Clear all selections',
  PREFILL_SETTING: '246: Change threshold, show or hide defaults, history, technique',
  RADRES_TOGGLE_USER_OR_TEMPLATES:
    '247: Switch direction. For example "User then Template" OR select "Template then User" modes',
  SHOW_DICOM_SR_TABLE: '248: Show DICOM SR measurement table',
  RULE_CUSTOM: '250: A pre-coded rule',
  RULE_CUSTOM_GENERATED: '251: A custom rule, but was generated on the fly',
  RULE_GENERATED: '252: A simple top-to-bottom rule',
  AUTO_SELECT_TAGS: '253: Auto select groups in section that have scores higher than the threshold',
  AUTO_SELECT_NAD_TAGS: '254: Auto select NAD groups in regions with no group selections',
  AUTO_SELECT_TAGS_AND_CLOSE:
    '255: Auto select groups in section that have scores higher than the threshold then close the modal',
  DELETE_AUTO_SELECTED_TAGS: '256: Delete auto selected groups in section',
  AUTO_UPDATE_TOGGLE: '257: Toggle the automatic update of group scores when a group is selected',
  UPDATE_MACHINE_LEARNING_SCORES: '258: Manually trigger the update of group scores',
  SELF_CHECK_FEEDBACK: '259: Check the changes made since you last edited the report',
  EDIT_TEMPLATE: '260: Edit a template',
  RADREPORT_TUTORIALS: '261: Radreport Tutorials',
  TECHNICIANS_PERFORMANCE: "262: Technician's Performance - Has been moved to Graphs",
  AMRI_PROTOCOLS: '263: AdelaideMRI Protocols',
  NAVIGATOR_PREFILL: '264: Go to prefill',
  NAVIGATOR_PRIOR_REPORT: '265: Prior Reports link in navigator',
  NAVIGATOR_SIGN: '266: Sign link in navigator',
  NAVIGATOR_VIEW_FINAL_REPORT: '267: View Final Report link in navigator',
  NAVIGATOR_DICOM_SR_MEASUREMENTS: '268: DICOM SR measurements link in navigator',
  NAVIGATOR_CHECK_REPORT: '269: Check Report link in navigator',
  NAVIGATOR_GO_BACK_TO_WORKLIST: '270: Go back to worklist link in navigator',
  NAVIGATOR_CLOSE_BUTTON: '271: Close navigator',
  NAVBAR_BTN_ANNOTATION_TASKS: '273: Navigate to image annotation task list',
  NAVBAR_BTN_WORKLIST: '274: Worklist link in Navbar',
  INITIALS_START_EDITING: '275: Start Editing button in initials modal',
  INITIALS_CLOSE_REPORT: '276: Close Report button in initials modal',
  INITIALS_CLOSE_BTN: '277: Close Report button in initials modal',
  DROPDOWN_TOOLBOX_BTN: '278: Report preview dropdown toolbox button for choosing item 191-195',
  PATIENT_INFO: '279: Open patient information modal, formerly in Study List (D)',
  SIDE_OPEN_SHORTCUTS: '280: Open shortcuts modal',
  FINDING_TAG: '281: Open finding groups modal',
  SIDE_CHECK_REPORT: '282: Open to view report status',
  SIDE_BACK_WORKLIST: '283: Click to go back to worklist',
  SIDE_TUTORIAL: '284: Open tutorial modal',
  SIDE_PREFILL_TITLE: '285: Open Prefill Reports (B) with prefill title',
  SIDE_PREFILL_PREDICTION: '286: Open Prefill Reports (B) with prefill prediction',
  SIDE_PREFILL_TOGGLE: '287: Open/close Prefill Reports (B)',
  PREFILL_PRESET: '288: Open Prefill Reports (B) and filter by Presets',
  COPY_ACCESSION_NUMBER: '289: Copy accession number to clipboard',
  PREFILL_LIST_FAVOURITES:
    '290: Use a simpler, possibly slower search for favourites. Use this as a fallback when the other searches are not working',
  OPEN_SIDEBAR: '291: Toggle sidebar open/close',
  OPEN_DOCUMENT_ATTACHMENT: '292: Open report document attachment modal',
  OPEN_NOTES: '293: View all notes',
  OPEN_PATIENT_QUESTIONNAIRE: '294: Open patient history questionnaires',
  OPEN_REGISTRATION_MODAL: '295: Open registration modal',
  REGISTER_WALKIN_PATIENT: '296: New booking query for walk-in patient',
  MAKE_NEW_BOOKING: '297: Create a new booking',
  REGISTER_NEW_PATIENT_SCAN: '298: Register a new scan for the same patient with the same referrer',
  INVOICES_PAGE: '299: Open invoices page',
  CHOOSE_INVOICE_ITEMS: '300: Click to choose billing items for the report invoice',
  DOCTOR_TIME: '301: Default to doctor time slots in RadRes booking',
  DOCTOR_ONSITE:
    '302: Require doctor to be onsite (include both doctor time and injection time) to make bookings in RadRes',
  INJECTION_TIME: '303: Default to doctor injection slots in RadRes booking',
  VOYAGER_NOTE_TAGS:
    "304: In voyager notes use keyword 'dividers:' followed by items separated by comma. e.g 'other notes... dividers: keyword1, keyword2'",
  COPY_REPORT: '305: Copy report to clipboard',
  VOICE_TERMS: '306: List of reports voice notes, click to paste text into dialogue and search',
  TOGGLE_VOICE_MODE: '307: Toggle voice mode features',
  VOICE_NOTES: '308: Open voice notes modal',
  ABBR_LIST: '309: Open abbreviation list modal',
  PROOF_READ: '310: Open proof read modal',
  RESET_STATEMENT: '311: Reset statement to initial state',
  NAV_MODAL: '312: Open navigator modal',
  SAVE_DATE: '313: Click to save date and time',
  DUE_DATE_DUE_NOW: '314: Makes the report urgent and changes the due date to right now.',
  CANCEL_EDIT_DATE: '315: Click to cancel',
  EDIT_REGISTRATION_DATE: '316: Click to edit Registration Date',
  EDIT_DUE_DATE: '317: Click to edit Due Date',
  SIDE_WEB_VIEWER_FORMS: '318: Open Web Viewer with Forms',
  REGISTRAION_EDIT_PATIENT: '319: Edit patient details',
  REGISTRAION_EDIT_REFERRER: '320: Edit referrer details',
  REGISTRAION_REMOVE_REFERRER: '321: Clear referrer (safe) to access create/search functions',
  REGISTRAION_REMOVE_PATIENT: '322: Clear patient (safe) to access create/search functions',
  REGISTRAION_EDIT_REPORT: '323: Edit report details',
  REGISTRATION_CANCEL_EDIT: '324: Cancel edit mode',
  REGISTRATION_CREATE_NEW_PATIENT: '325: Create new AdelaideMRI patient and assign to report',
  REGISTRATION_CREATE_NEW_REFERRER: '326: Create new AdelaideMRI referrer and assign to report',
  REGISTRATION_UNDO_REMOVAL: '327: Undo removal and view report patient/referrer',
  REGISTRATION_VALIDATE_MEDICARE: '328: Validate patient medicare details',
  VIEW_BOOKING: '329: View booking details',
  VIEW_DB4_FORM: '330: Open new window to view report DB4 form',
  SEND_FOLLOWUP_SMS: '331: Send sms to ask patient for their followup appointment with specialists',
  FOLLOWUP_TASKS: '332: Open followup tasks page',
  UNPROCESSED_FOLLOWUP_TASKS: '333: The number of unprocessed SMS responses',
  BOOKING_LIST: '334: Open booking list',
  EDIT_LANDMARK: '335: Add or edit landmark for subsection/region for use with image similarity',
  CREATE_ANOTHER_BOOKING: '336: Create another booking for the same patient',
  EDIT_BOOKING: '337: Edit booking details',
  CANCEL_BOOKING: '338: Cancel/delete booking',
  UNARRIVE_BOOKING: '339: Undo patient arrival',
  ARRIVE_BOOKING: '340: Mark patient as arrived and register in new tab',
  EDIT_BOOKING_NOTES: '341: Edit booking notes',
  OVERRIDE_BOOKING: '342: Override booking time, date and doctor',
  CHECK_BOOKING_AVAILABILITY: '343: Check booking slot availability modal',
  EXISTING_PATIENT_SEARCH: '344: Toggle between patient search and creation modes',
  SWITCH_USER: '345: Change the active user (without logging out) so the audit trail is correct',
  OPEN_VOYAGER_REPORT: '346: Open report in voyager',
  FOCUS_VOICE_NOTES: '347: Hide non voice-note sections',
  EXCLUDE_EDIT_BY_DOCTOR: '348: Exclude reports edited by doctor',
  SEND_BOOKING_SMS: '349: Send sms for booking confirmation/preparation',
  BOOKING_ENQUIRY: '350: Open new booking enquiry list',
  BOOKING_ENQUIRY_PENDING_COUNT: '351: Number of pending booking enquiries',
  EXCLUDE_INJECTIONS: '352: Exclude reports that have injections in booking code',
  SHOW_IMG_SIMILARITY_PARAMETERS: '353: Show image similarity parameters',
  IMG_SIM_JOB_STATUS: '354: Show image similarity feature extraction job status',
  DOCUMENTATION_LINK: '355: Open link to adelaide mri documentation',
  PATIENT_STATEMENT_NOTE: '356: Add statement choice to patient notes',
  STATEMENT_MEDICAL_NOTE: '357: Add medical note',
  REMOVE_STATEMENT_REPORT: '358: Remove statement choice from report',
  CORRELATED_SENTENCE_SEARCH: '359: Search for correlated statements',
  CORRELATED_SENTENCE_SELECT: '360: Select all correlated statements',
  CORRELATED_SENTENCE_SELECT_TOP: '361: Select top 3 correlated statements',
  CORRELATED_SENTENCE_DESELECT: '362: De-select all correlated statements',
  CORRELATED_SENTENCE_TECHNIQUE: '363: Toggle search to include/exclude Technique setnences',
  FILTER_BY_REFERRER_NAME: '364: Filter worklist by referrer name',
  SELECT_BOOKING_SCAN_CODE: '365: Click to choose a scan code',
  SELECT_BOOKING_BOOKING_CODE: '366: Click to choose a booking code',
  REGISTER_BOOKING_PATIENT: '367: Click to add new patient',
  REGISTER_BOOKING_REFERRER: '368: Click to add a new referrer',
  REGISTER_BOOKING: '369: Register/save booking',
  VOICE_NOTES_AUTO_OPEN: '370: Open voice notes and start listening automatically',
  IMGSIM_WEIGHTS: '371: Open and edit image similarity weights',
  IMGSIM_WEIGHTS_UPDATE_WEIGHT: '372: Update image similarity weight automatically on change',
  OPEN_GROUP_MODAL: '373: Open statement group modal (>)',
  TOGGLE_AUTO_SUGGESTED_SENTENCES: '374: Show/hide voice note suggested statements',
  VOICE_THRESHOLD: '375: Set the threshold for similarity between created note and suggested statements',
  VOICE_REPORT_CLEAR: '376: Clear all general voice notes for report (non-persisted)',
  REMOVE_LAST_SUGGESTED: '377: Remove last suggested statement from report',
  DIVIDER_TAG_BTN: '378: Select a group tag to view its statements',
  GLOBAL_STATEMENT_SEARCH: '379: Search statement in global search',
  INSERT_GLOBAL_SEARCH_TEXT: '380: Insert global search text into text-box',
  VOICE_NOTE_RECOMMENDED: '381: This voice note created a recommended statement',
  VOICE_NOTE_RECOMMENDED_FAILED: '382: This voice note did not recommend a statement at set threshold',
  VOICE_NOTE_RECOMMENDED_RERUN:
    '383: Uses first match threshold value to re-run voice-note statement-similarity-search',
  OPEN_REFERRER_MANAGEMENT_PAGE: '384: Open referrer management page in new tab',
  OPEN_PATIENT_MANAGEMENT_PAGE: '385: Open patient management page in new tab',
  FILTER_WORKLIST_VIA_TITLE: '386: Filter worklist via report title',
  FILTER_WORKLIST_VIA_STUDY_TYPE: '387: Filter worklist via study type',
  FILTER_WORKLIST_VIA_SITE_NAME: '388: Filter worklist via site name',
  BLUE_STATEMENT_DESC:
    '389: Search for statements in current element, use dropdowns above to change section/subsection/element.',
  REFERRAL_ENQUIRY: '390: Open referral enquiry',
  INSERT_INTO_SOABS: "391: Paste text into 'Search or Add Blue Statement' dialogue for search",
  PERSIST_VOICE_NOTE: '392: Persist voice note to template (can then be used to filter groups across all report)',
  EDIT_VOICE_NOTE: '393: Edit voice note',
  DELETE_VOICE_NOTE: '394: Delete voice note',
  OPEN_BOOKING: '395: Open existing booking that could be related to this enquiry',
  SAVE_SEARCH: '396: Save search term to the search clipboard',
  ADDED_VIA_SEARCH_CLIPBOARD: '397: Term was added to the search clipboard',
  ADDED_VIA_VOICE: '398: Term was added via voice note',
  ROTATE_VOICE_CLIPBOARD: '399: Rotate through search clipboard terms',
  VOICE_TERMS_LIST: '400: General voice notes, click to fill into local search box',
  SEARCH_CLIPBOARD: '401: Notes and searches persisted to clipboard',
  SEND_REFERRAL_ENQUIRY_CONFIRM_SMS: '402: Send sms to confirm referral enquiry',
  SAVE_NOTE: '403: Click to save note',
  EDIT_NOTE: '404: Click to edit note',
  ADD_NOTE: '405: Click to add note',
  DELETE_NOTE: '406: Click to delete note',
  ADD_DOCTOR_FOLLOWUP: '407: Add followup link',
  DELETE_DOCTOR_FOLLOWUP: '408: Delete followup link',
  REPORT_UNDO_STATEMENTS: '409: Revise any statements that have been added or removed',
  CORRELATED_SENTENCE_FINDING: '410: Select to find correlated results',
  REPORT_PREVIEW: '411: Select to view a preliminary draft of the patient report',
  SEARCH_ALL_STUDIES: '412: Search for all studies',
  SEARCH_RELATED_STUDIES: '413: Search for related studies',
  SEARCH_UNREPORTED_STUDIES: '414: Search for unreported studies',
  OPEN_EDIT_REPORT: '415: Open the patient report edit page',
  REMOVE_SELECTED_PATIENT: '416: Remove selected patient',
  PREFILL_EDIT_STATEMENT: '417: Click to edit this statement',
  EXTRACT_IMG_SIM_TEMPLATES_MODAL: '418: Open modal to select templates to be processed by image similarity server',
  EXTRACT_IMG_SIM_TEMPLATE: '419: Extract image similarity features for this template, close modal to view status',
  SEND_USER_NOTIFICATION: '420: Send user notification',
  BOOKING_LIST_FILTER_BY_PATIENT_NAME: '421: Filter booking list by patient name',
  JUSTIFY_MANDATORY_STATEMENT: '422: Provide a reason for your decision to omit this statement from the report',
  DELETE_MANDATORY_STATEMENT_JUSTIFICATION:
    '423: Click to remove the provided reason for omitting this statement from the report',
  CORRELATED_FINDINGS_CLONES_FILTER: '424: Filter correlated findings by key findings, comments or impressions',
  REPORT_PAST_RESULTS_CORRELATION: '425: Past search result correlations',
  CLOSE_CORRELATED_FINDINGS: '426: Close correlated findings window',
  CORRELATED_FINDINGS_WINDOW_TITLE: '427: Correlated finding Suggestions (&)',
  CORRELATED_READOUT_TOGGLE: '428: Toggle correlated findings readout',
  CORRELATED_EXCLUDE_OTHER_SUBSECTIONS: '429: Exclude correlated findings from other subsections',
  SOFTWARE_PREFERENCES: "430: Add/view a referrer's software preferences",
  CORRELATED_FILTER_BY_SIMILAR_AGE_GROUP: '431: Filter correlated findings by similar age group',
  GITHUB_ISSUES_OPEN_PAGE: '432: Navigate to the Radreport Feedback Issues Page to View All Open Issues',
  GLOBAL_PREFILL_SEARCH: '433: Search statement either in the global search field or the prefill search.',
  CAPITALISE_SENTENCE: '434: Convert the Entire Sentence to Uppercase',
  STATEMENT_BUILDER: '435: Pre-Populated Statements',
  UPPER_TITLE: '436: Highlight a word, and convert the first letter to uppercase',
  LOWER_CASE_SENTENCE: '437: Highlight a word or a whole sentence, and convert it to lowercase',
  STATEMENT_BUILDER_SAVE: '438: Save this statement for future quick access.',
  INSERT_DATE_ATTRIBUTE: '439: Insert date attribute',
  FILTER_FEEDBACK_BY_USER: '440: Filter feedback by user',
  FIND_PREVIOUS_REPORT_BY_STATEMENT_ATTRIBUTE:
    '441: Search for previous reports using the selected attributes in the statement.',
  FIND_PREVIOUS_REPORT_BY_STATEMENT: '442: Utilise this statement to search for previous reports.',
  FILTER_BY_REPORT_STATUS: '443: Sort reports based on their status',
  VIEW_DELETED_REPORT: '444: View deleted reports',
  RESTORE_DELETED_REPORT: '445: Restore deleted report',
  RESTORE_BOOKING: '446: Restore cancelled booking',
  VIEW_DELETED_REFERRER: '447: View deleted referrers',
  VIEW_DELETED_PATIENT: '448: View deleted patients',
  CREATE_REPORT_LABEL: '449: Create New Label',
  ADD_REPORT_LABEL: '450: Add Labels to Report',
  SORT_BY_NUMERICAL_VALUE: '451: Click to sort this attribute list by numerical value',
  DELETE_PRIOR_REPORTS_HEADINGS: '452: Delete key findings or other imaging headings from previous reports.',
  EXCLUDE_TECHNICIAN_SIGNATURE: '453: Click to exclude the technician signature from the final report',
  UNEXCLUDE_TECHNICIAN_SIGNATURE: '454: The technician signature is excluded, click to include it in the final report.',
  MARK_AS_SUPER_URGENT: '455: Click to mark this report as super urgent',
  SAME_DAY_PENDING_REPORTS: '457: Click to see same day pending reports for this patient',
  EDIT_MACHINE_NAME: '458: Click to edit machine name',
  UNAVAILABLE_MACHINE_NOTE: '459: Click to specify machine unavailability',
  DUE_TIME_REPORT: '460: Click to set a due time',
  DUE_TIME_IN_MINUTES_REPORT: '461: Click to choose a due time from quick minute options',
  SHOW_B3_RESULTS: '462: Click to show the B3 results',
  SEX_SPECIFIC_WORDS: '463: Click to add or delete sex specific words',
  START_LISTENING: '465: Click to activate the start listening mode',
  STOP_LISTENING: '466: Click to stop the listening mode',
  CHOOSE_BOOKING_INVOICE_ITEMS: '467: Click to choose billing items for the booking invoice',
  NEXT_REPORT_VOYAGER: '468: Click to open the next report and voyager',
  NEXT_REPORT_MODAL: '469: Click to open the next report modal',
  PRINT_REFERRAL_ENQUIRY_FORM: '470: Click to print the referral enquiry form',
  PRESETS: '471: Click to open presets',
  PREFILL_PRESETS_RADIO: '472: Search preset reports',
  PRESET_FROM_REPORT: '473: Prefill the preset from this report',
  DELETE_PRESET: '474: Delete preset',
  EDIT_PRESET: '475: Edit preset',
  USE_PRESET_STATEMENTS: '476: Click to use all the statements from this preset',
  DELETE_DOCUMENT: '477: Click to delete the attached document',
  EDIT_DOCUMENT_TYPE: '478: Click to edit document type',
  UPDATE_DOCUMENT_TYPE: '479: Click to update document type',
  EXIT_EDIT_DOCUMENT_TYPE: '480: Click to exit edit document type mode',
  NAVIGATION_SIDEBAR: '481: Links to all commonly visited pages in RadReport',
  VIEW_ALL_FORMS: '482: Open a new window to view all the forms required for this booking',
  EXPAND_CORRELATED_FINDING: '483: Click to expand or collapse the correlated findings window ',
  PRESET_TOP6: '484: Select top 6 from presets',
  FAVOURITE_PROPOSED_STATEMENT: '485: Mark this statement as a favourite',
};

export const NEW_TEMPLATE_STATEMENT = 'New template statement';
export const NEW_REPORT_SPECIFIC = 'Search or add blue statements';
export const NO_REFERRER_TYPE_BILLING_ITEM = 'No referrer type registered for this billing item';

// Other app constants (non-visual)
export const ELEMENT_SELECTOR = 'rr-element';
export const STATEMENT_LIST_ID = 'statement-list';
export const SIDEBAR_COMPONENT_SELECTOR = 'rr-sidebar';
export const SIDEBAR_REGISTRATION_QUESTIONS_ID = 'sidebar-registration-questions';
export const SIDEBAR_INVOICE_ITEMS_ID = 'sidebar-invoice-items';
export const SIDEBAR_NOTES_ID = 'sidebar-notes';
export const SIDEBAR_WEB_VIEWER_ID = 'sidebar-web-viewer';

export const INDEX_SCROLL_ID = 'index-scroll';
export const PREVIEW_SCROLL_ID = 'preview-scroll';
export const ELEMENT_SCROLL_ID = 'element-scroll';
export const NOTEPAD_SCROLL_ID = 'notepad-scroll';

export const SLATE_EDITOR_ID = 'slate-editor';
export const ATTRIBUTE_MATCH_DROPDOWN = 'attribute-match-dropdown';

export const TUTORIALS = {
  // How do I use RadReport?
  VIEW_WORKLIST: {
    TUTORIAL_TITLE: 'How to view the worklist',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  START_TEMPLATE: {
    TUTORIAL_TITLE: 'How to start a template',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  KEYBOARD_SHORTCUTS: {
    TUTORIAL_TITLE: 'Keyboard shortcuts for RadReport',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  FILTER_THE_WORKLIST: {
    TUTORIAL_TITLE: 'How to filter the worklist',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  SEARCH_PATIENT: {
    TUTORIAL_TITLE: 'How to search for a patient',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  CHECK_THE_EDITORS: {
    TUTORIAL_TITLE: 'How to check the editors',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  CHECK_MY_PERFORMANCE: {
    TUTORIAL_TITLE: 'How to check my performance',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  NAV_AROUND: {
    TUTORIAL_TITLE: 'How to navigate around the RadReport panels',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  FAX_FROM_RADREPORT: {
    TUTORIAL_TITLE: 'How to fax from RadReport',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use RadReport',
  },
  // How do I Create a report from a Template?
  FIND_AND_SELECT_A_TEMPLATE: {
    TUTORIAL_TITLE: 'How to find and select a template for a new report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  ADD_TITLE: {
    TUTORIAL_TITLE: 'How to add a report title',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  ADD_DEFAULT_SENTENCES: {
    TUTORIAL_TITLE: 'How to add default statements',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  ADD_A_SENTENCE: {
    TUTORIAL_TITLE: 'How to add a Statement',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  MAKE_FINDING_COMMENT: {
    TUTORIAL_TITLE: 'How to move a Findings statement to Key Findings or Comments section',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  FIND_DELETED_SENTENCES: {
    TUTORIAL_TITLE: 'How to find statements that have been deleted',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  DELETE_ADD_SENTENCE: {
    TUTORIAL_TITLE: 'How to delete and add new statement',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  MOVE_SENTENCE: {
    TUTORIAL_TITLE: 'How to move statements in a report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  ADD_ATTRIBUTES: {
    TUTORIAL_TITLE: 'How to add attributes to a statement',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  CHOOSE_APPROPRIATE_ATTRIBUTE: {
    TUTORIAL_TITLE: 'How to choose the most appropriate attribute',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  DUPLICATE_A_SENTENCE: {
    TUTORIAL_TITLE: 'How to duplicate a statement',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  ADD_PRIOR_REPORTS: {
    TUTORIAL_TITLE: 'How to add history of previous reports',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Create a report from a Template',
  },
  // How do I search for a Statement to Add to a Report?
  GLOBAL_SEARCH: {
    TUTORIAL_TITLE: 'How to search for statements',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I search for a Statement to Add to a Report',
  },
  ACCESS_RECENT_SEARCHES: {
    TUTORIAL_TITLE: 'How to access your most recent searches',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I search for a Statement to Add to a Report',
  },
  // How do I add numbers or values to a Statement?
  ADD_DIMENSIONS_IN_SENTENCES: {
    TUTORIAL_TITLE: 'How to add numeric values into RadReport statement',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I add numbers or values to a Statement',
  },
  // How do I use the multiple Regions feature?
  ADD_SAME_SENTENCE_REGIONS: {
    TUTORIAL_TITLE: 'How to add the same statement to another region of the same body part',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I use the multiple Regions feature',
  },
  // How do I check and View the final Report?
  CHECK_REVIEW_REPORT: {
    TUTORIAL_TITLE: 'How to check and review a report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I check and View the final Report',
  },
  // How do I view Images belonging to a Report?
  IMAGES: {
    TUTORIAL_TITLE: 'How to view images from RadReport',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I view Images belonging to a Report',
  },
  // How do I view old reports?
  VIEW_PREVIOUS_REPORTS: {
    TUTORIAL_TITLE: "How to view patient's previous reports",
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I view old reports',
  },
  // How do I see who else is editing a report?
  CONCURRENT: {
    TUTORIAL_TITLE: 'How to view who is editing the report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I see who else is editing a report',
  },
  // How do I leave edit messages?
  FLAG_SENTENCES: {
    TUTORIAL_TITLE: 'How to flag statements',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I leave edit messages',
  },
  LEAVE_MESSAGES_IN_FLAG: {
    TUTORIAL_TITLE: 'how to leave messages in a flag',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I leave edit messages',
  },
  // How do I Prefill the Report from a saved Template?
  START_REPORT_USING_PREFILL: {
    TUTORIAL_TITLE: 'How to start a report using a prefill',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  WAYS_TO_PREFILL: {
    TUTORIAL_TITLE: '4 Ways to prefill',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_TOP_SIX_ROWS: {
    TUTORIAL_TITLE: 'How to use the prefill top 6 rows function',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_ADD_DELETE_SENTENCES: {
    TUTORIAL_TITLE: 'How to add or delete statements using a prefill report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_WHOLE_REPORT: {
    TUTORIAL_TITLE: 'How to prefill a whole report including numbers',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_REPORT: {
    TUTORIAL_TITLE: 'How to prefill a report without clearing numbers and attributes',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_PREVIOUS_REPORT: {
    TUTORIAL_TITLE: 'How to prefill a template using previous reports',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_USING_DOB: {
    TUTORIAL_TITLE: 'How to prefill using DOB',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  USE_PREVIOUS_REPORT: {
    TUTORIAL_TITLE: 'How to prefill using a patients previous report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_HISTORY_TECH: {
    TUTORIAL_TITLE: 'How to prefill history and technique',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_SUBSECTION: {
    TUTORIAL_TITLE: 'How to prefill subsections',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  PREFILL_DIVIDER_SENTENCES: {
    TUTORIAL_TITLE: 'How to choose similar prefill statement groups',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  EDIT_PREFILL_DIVIDER_SENTENCES: {
    TUTORIAL_TITLE: 'How to edit prefill statement groups',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  USE_FAVOURITE_TAGS: {
    TUTORIAL_TITLE: 'How to use most common favourite groups',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  NAME_FAVOURITE: {
    TUTORIAL_TITLE: 'How to name a favourite',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  SEE_ONLY_MY_FAVOURITES: {
    TUTORIAL_TITLE: 'how to see only my favourites',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I Prefill the Report from a saved Template',
  },
  // How to Process and Distribute a report?
  ADD_SIG: {
    TUTORIAL_TITLE: 'How to add signatures onto the report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  SMS_LINK_TO_PATIENT: {
    TUTORIAL_TITLE: 'How to sms a link to a patient',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  ADD_DUE_DATE: {
    TUTORIAL_TITLE: 'How to add a due date to a report',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  ADD_TODO_URGENT_NOTES: {
    TUTORIAL_TITLE: 'How to add report notes and admin notes',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  EDIT_PATIENT_METADATA: {
    TUTORIAL_TITLE: 'How to edit patient metadata',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  VIEW_REPORT_WITHOUT_OPENING_EDITOR: {
    TUTORIAL_TITLE: 'How to view a report without opening the report editor',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  COPY_REPORT_TO_CLIPBOARD: {
    TUTORIAL_TITLE: 'How to copy a report to clipboard',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  PRINT_REPORT: {
    TUTORIAL_TITLE: 'How to print a report from RadReport',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How to Process and Distribute a report',
  },
  // How do I see the latest features added to RadReport?
  VIEW_UPDATES: {
    TUTORIAL_TITLE: 'How to view updates on RadReport',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'How do I see the latest Features added to RadReport',
  },
  HOW_TO_CATEGORISE_SENTENCES: {
    TUTORIAL_TITLE: 'How to categorise statements?',
    TUTORIAL_NOTES: '',
    TUTORIAL_SECTION: 'Statement categorisation',
  },
};

// Voice
export const DELIMITER = 'comma';
export const TRIGGER_VOICE_STOP_CURRENT = ['fullstop', 'full stop', '.'];
export const ARE_YOU_LISTENING_TO_ME = 'are you listening to me';
export const STOP_SPEECH_SYNTHESIS = 'stop talking';

export const COMMAND_ACTIONS = {
  clearSearch: 'clear search',
  clearVoice: 'clear voice',
  stopListening: 'stop listening',
  addFavourite: 'shift + a',
  toPrefill: 'shift + b',
  checkReport: 'shift + c',
  demographics: 'shift + d',
  toEditor: 'shift + e',
  prefillFavourites: 'shift + f',
  giveFeedback: 'shift + g',
  feedbackHelp: 'shift + h',
  reportTitle: 'shift + i',
  tagsModal: 'shift + j',
  copyKeyFindings: 'shift + k',
  dicom: 'shift + l',
  invoices: 'shift + m',
  notes: 'shift + n',
  tutorials: 'shift + o',
  prefillPrediction: 'shift + p',
  registration: 'shift + q',
  sendReport: 'shift + r',
  sign: 'shift + s',
  prefillTitle: 'shift + t',
  urgent: 'shift + u',
  webViewerImages: 'shift + v',
  webImages: 'shift + w',
  export: 'shift + x',
  registrationQuestions: 'shift + y',
  undoList: 'shift + z',
  nextSubsection: 'next subsection',
  previousSubsection: 'previous subsection',
  deleteLastSentence: 'delete last',
  acceptSuggestion: 'insert match', // Not used yet
  superUrgent: 'shift + *',
};
