import { InjectionToken } from '@angular/core';
import { routerReducer } from '@ngrx/router-store';
import { ActionReducerMap } from '@ngrx/store';

import { AddedTopicReducer } from './added-topics/added-topics.reducer';
import { AppState } from './app.state';
import { billingGroupReducer } from './billing-group/billing-group.reducer';
import { billingItemReferrerTypeReducer } from './billing-item-referrer-type/billing-item-referrer-type.reducer';
import { billingItemReducer } from './billing-item/billing-item.reducer';
import { bookingCodeLocatorReducer } from './booking-code-locator/booking-code-locator.reducer';
import { bookingCodeReducer } from './booking-code/booking-code.reducer';
import { bookingRateReducer } from './booking-rate/booking-rate.reducer';
import { bookingReducer } from './booking/booking.reducer';
import { categoryStatementComboReducer } from './category-statement-combo/category-statement-combo.reducer';
import { categoryReducer } from './category/category.reducer';
import { dicomReducer } from './dicom/dicom.reducer';
import { documentationLinkReducer } from './documentation-link';
import { editorReducer } from './editor/editor.reducer';
import { ExactMatchTopicReducer } from './exact-match-topic/exact-match-topic.reducer';
import { githubIssueCommentReducer } from './github-issue-comment/github-issue-comment.reducer';
import { githubIssueReducer } from './github-issue/github-issue.reducer';
import { ImgSimParameterReducer } from './imgsim-parameters/imgsim-parameters.reduce';
import { institutionReducer } from './institution/institution.reducer';
import { invoiceItemReducer } from './invoice-item/invoice-item.reducer';
import { invoiceReducer } from './invoice/invoice.reducer';
import { keywordAbbreviationReducer } from './keyword-abbr/keyword-abbr.reducer';
import { landmarkLabelReducer } from './landmark-label/landmark-label.reducer';
import { machineReducer } from './machine/machine.reducer';
import { mandatoryStatementReducer } from './mandatory-statement/mandatory-statement.reducer';
import { noticeReducer } from './notice/notice.reducer';
import { userNotificationsReducer } from './notifications/notifications.reducer';
import { PathologyReducer } from './pathology/pathology.reducer';
import { patientNoteCategoryReducer } from './patient-note-category';
import { patientNoteReducer } from './patient-note/patient-note.reducer';
import { patientReducer } from './patient/patient.reducer';
import { paymentReducer } from './payment/payment.reducer';
import { percentileBandReducer } from './percentile-band';
import { percentileBandGroupReducer } from './percentile-band-group';
import { percentileBandRuleReducer } from './percentile-band-rule';
import { permissionReducer } from './permission/permission.reducer';
import { prefillSearchReducer } from './prefill/prefill-search/prefill-search.reducer';
import { tagChoiceReducer } from './prefill/tag-choice/tag-choice.reducer';
import { tagReducer } from './prefill/tag/tag.reducer';
import { providerNumberReducer } from './provider-number/provider-number.reducer';
import { patientQuestionAnswerReducer } from './questionnaire/patient-question-answer/patient-question-answer.reducer';
import { patientQuestionSetReducer } from './questionnaire/patient-question-set/patient-question-set.reducer';
import { patientQuestionReducer } from './questionnaire/patient-question/patient-question.reducer';
import { referralEnquiryReducer } from './referral-enquiry/referral-enquiry.reducer';
import { referrerContactMethodReducer } from './referrer-contact-method/referrer-contact-method.reducer';
import { referrerNoteCategoryReducer } from './referrer-note-category';
import { referrerNoteReducer } from './referrer-note/referrer-note.reducer';
import { referrerReducer } from './referrer/referrer.reducer';
import { RegistrationFormReducer } from './registration-form';
import { reportMergeConflictReducer } from './report-merge-conflict/report-merge-conflict.reducer';
import { reportAccessEventReducer } from './report/access-event/access-event.reducer';
import { auditEventReducer } from './report/audit-event/audit-event.reducer';
import { choiceErrorReducer } from './report/choice-error/choice-error.reducer';
import { doctorFollowupReducer } from './report/doctor-followup/doctor-followup.reducer';
import { documentReducer } from './report/document/document.reducer';
import { elementChoiceReducer } from './report/element-choice/element-choice.reducer';
import { favouriteReducer } from './report/favourite/favourite.reducer';
import { feedbackCommentReducer } from './report/feedback-comment/feedback-comment.reducer';
import { feedbackReducer } from './report/feedback/feedback.reducer';
import { followupTaskReducer } from './report/followup-task/followup-task.reducer';
import { justificationReducer } from './report/justification';
import { LabelReducer } from './report/label/label.reducer';
import { PresetTitleReducer } from './report/preset-title/preset-title.reducer';
import { regionChoiceReducer } from './report/region-choice/region-choice.reducer';
import { reportStatusReducer } from './report/report-status/report-status.reducer';
import { reportReducer } from './report/report/report.reducer';
import { sectionChoiceReducer } from './report/section-choice/section-choice.reducer';
import { sendEventReducer } from './report/send-event/send-event.reducer';
import { statementChoiceUsageAnalyticsReducer } from './report/statement-choice-usage-analytics/statement-choice-usage-analytics.reducer';
import { statementChoiceReducer } from './report/statement-choice/statement-choice.reducer';
import { subsectionChoiceReducer } from './report/subsection-choice/subsection-choice.reducer';
import { TextObjectChoiceReducer } from './report/text-object-choice/text-object-choice.reducer';
import { todoReducer } from './report/todo/todo.reducer';
import { topicReducer } from './report/topic/topic.reducer';
import { urgentNoteReducer } from './report/urgent-note/urgent-note.reducer';
import { voiceNoteReducer } from './report/voice-note';
import { scanCodeReducer } from './scan-code/scan-code.reducer';
import { sessionReducer } from './session/session.reducer';
import { settingReducer } from './setting/setting.reducer';
import { SexSpecificWordReducer } from './sex-specific-words/sex-specific-word.reducer';
import { signatureReducer } from './signature/signature.reducer';
import { siteReducer } from './site/site.reducer';
import { SoftwarePreferenceReducer } from './software-preferences';
import { tagSearchTermReducer } from './tag-search-term';
import { attributeOptionReducer } from './template/attribute-option/attribute-option.reducer';
import { attributeSetReducer } from './template/attribute-set/attribute-set.reducer';
import { BodyPartReducer } from './template/body-part/body-part.reducer';
import { defaultAttributeReducer } from './template/default-attribute/default-attribute.reducer';
import { elementReducer } from './template/element/element.reducer';
import { regionSetReducer } from './template/region-set/region-set.reducer';
import { regionReducer } from './template/region/region.reducer';
import { sectionReducer } from './template/section';
import { statementCoincidenceReducer } from './template/statement-coincidence/statement-coincidence.reducer';
import { statementSetReducer } from './template/statement-set/statement-set.reducer';
import { statementReducer } from './template/statement/statement.reducer';
import { subsectionReducer } from './template/subsection/subsection.reducer';
import { templateReducer } from './template/template/template.reducer';
import { textObjectReducer } from './template/text-object/text-object.reducer';
import { titleOptionReducer } from './title/title-option/title-option.reducer';
import { titleSetReducer } from './title/title-set/title-set.reducer';
import { toastReducer } from './toast/toast.reducer';
import { companyRoleReducer } from './user/company-role/company-role.reducer';
import { userSettingReducer } from './user/user-setting/user-setting.reducer';
import { userReducer } from './user/user/user.reducer';
import { worklistReducer } from './worklist/worklist.reducer';

export const reducers: ActionReducerMap<AppState> = {
  // UI Reducers
  router: routerReducer,
  editor: editorReducer,
  worklist: worklistReducer,
  toast: toastReducer,
  // Entity Reducers
  user: userReducer,
  user_setting: userSettingReducer,
  company_role: companyRoleReducer,
  report: reportReducer,
  report_status: reportStatusReducer,
  topic: topicReducer,
  element_choice: elementChoiceReducer,
  subsection_choice: subsectionChoiceReducer,
  section_choice: sectionChoiceReducer,
  region_choice: regionChoiceReducer,
  access_event: reportAccessEventReducer,
  send_event: sendEventReducer,
  audit_event: auditEventReducer,
  todo: todoReducer,
  voice_note: voiceNoteReducer,
  referral_enquiry: referralEnquiryReducer,
  urgent_note: urgentNoteReducer,
  site: siteReducer,
  signature: signatureReducer,
  statement_choice: statementChoiceReducer,
  text_object_choice: TextObjectChoiceReducer,
  statement_coincidence: statementCoincidenceReducer,
  statement: statementReducer,
  text_object: textObjectReducer,
  category: categoryReducer,
  category_statement_combo: categoryStatementComboReducer,
  title_option: titleOptionReducer,
  title_set: titleSetReducer,
  statement_set: statementSetReducer,
  element: elementReducer,
  feedback: feedbackReducer,
  feedback_comment: feedbackCommentReducer,
  section: sectionReducer,
  subsection: subsectionReducer,
  template: templateReducer,
  attribute_set: attributeSetReducer,
  attribute_option: attributeOptionReducer,
  region: regionReducer,
  region_set: regionSetReducer,
  keyword_abbreviation: keywordAbbreviationReducer,
  landmark_label: landmarkLabelReducer,
  notice: noticeReducer,
  favourite: favouriteReducer,
  default_attribute: defaultAttributeReducer,
  machine: machineReducer,
  booking_rate: bookingRateReducer,
  prefillSearch: prefillSearchReducer,
  dicom: dicomReducer,
  tag: tagReducer,
  tag_choice: tagChoiceReducer,
  setting: settingReducer,
  choiceErrors: choiceErrorReducer,
  patient: patientReducer,
  referrer: referrerReducer,
  referrer_contact_method: referrerContactMethodReducer,
  software_preference: SoftwarePreferenceReducer,
  referrer_note: referrerNoteReducer,
  referrer_note_category: referrerNoteCategoryReducer,
  patient_note: patientNoteReducer,
  patient_note_category: patientNoteCategoryReducer,
  booking: bookingReducer,
  document: documentReducer,
  report_merge_conflict: reportMergeConflictReducer,
  patient_question_answer: patientQuestionAnswerReducer,
  patient_question_set: patientQuestionSetReducer,
  patient_question: patientQuestionReducer,
  session: sessionReducer,
  permission: permissionReducer,
  scan_code: scanCodeReducer,
  billing_item: billingItemReducer,
  billing_group: billingGroupReducer,
  booking_code: bookingCodeReducer,
  booking_code_locator: bookingCodeLocatorReducer,
  provider_number: providerNumberReducer,
  invoice: invoiceReducer,
  invoice_item: invoiceItemReducer,
  institution: institutionReducer,
  payment: paymentReducer,
  tag_search_term: tagSearchTermReducer,
  followup_task: followupTaskReducer,
  documentation_link: documentationLinkReducer,
  doctor_followup: doctorFollowupReducer,
  mandatory_statement: mandatoryStatementReducer,
  user_notification: userNotificationsReducer,
  justification: justificationReducer,
  percentile_band_group: percentileBandGroupReducer,
  percentile_band_rule: percentileBandRuleReducer,
  percentile_band: percentileBandReducer,
  github_issue: githubIssueReducer,
  github_issue_comment: githubIssueCommentReducer,
  statement_choice_usage_analytics: statementChoiceUsageAnalyticsReducer,
  body_part: BodyPartReducer,
  label: LabelReducer,
  billing_item_referrer_type: billingItemReferrerTypeReducer,
  added_topics: AddedTopicReducer,
  sex_specific_word: SexSpecificWordReducer,
  exact_match_topic: ExactMatchTopicReducer,
  pathology: PathologyReducer,
  preset_title: PresetTitleReducer,
  registration_form: RegistrationFormReducer,
  imgsim_paremeter: ImgSimParameterReducer,
};

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Reducers');

export function getReducers() {
  return reducers;
}

export const reducerProvider = [{ provide: reducerToken, useFactory: getReducers }];
