import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { PatientNameComponent } from 'app/shared/components/patient-name/patient-name.component';
import { ReferrerNameComponent } from 'app/shared/components/referrer-name/referrer-name.component';
import { RegistrationLinkComponent } from 'app/shared/components/registration-link/registration-link.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { UserHeadlineComponent } from 'app/shared/components/user-headline/user-headline.component';
import { AppState } from 'app/store';
import { fromPatient, PatientEffect } from 'app/store/patient';
import { fromProviderNumber } from 'app/store/provider-number';
import { fromReferrer, ReferrerEffect } from 'app/store/referrer';
import { fromReport } from 'app/store/report/report';
import { fromScanCode, ScanCodeEffect } from 'app/store/scan-code';
import { fromUser, UserEffect } from 'app/store/user/user';
import { combineLatest, EMPTY, Observable, of, Subscription } from 'rxjs';
import { switchMap, take, map } from 'rxjs/operators';

import { TopicTitleOptionTextComponent } from '../../topic-title-option-text/topic-title-option-text.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TopicTitleOptionTextComponent,
    NgbModule,
    RouterModule,
    PatientNameComponent,
    ReferrerNameComponent,
    UserHeadlineComponent,
    RegistrationLinkComponent,
    ReportAccessionNumberComponent,
  ],
  selector: 'rr-invoice-form-metadata',
  templateUrl: './invoice-form-metadata.component.html',
  styleUrls: ['./invoice-form-metadata.component.css'],
})
export class InvoiceFormMetadataComponent implements OnInit, OnDestroy {
  @Input() reportId: number;
  @Input() invoiceId?: number | undefined;
  @Input() reInvoice?: boolean | undefined;
  @Input() linkReports?: boolean | undefined;

  report: RR.Report;
  referrer: RR.Referrer | undefined;
  providerNumber: RR.ProviderNumber | undefined;
  scanCode$: Observable<RR.ScanCode | undefined>;
  report$: Observable<RR.Report>;
  user$: Observable<RR.User | undefined>;

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private patientEffect: PatientEffect,
    private referrerEffect: ReferrerEffect,
    private scanCodeEffect: ScanCodeEffect,
    private userEffect: UserEffect,
  ) {}

  ngOnInit(): void {
    this.report$ = this.store.select(fromReport.selectReport(this.reportId)).pipe(filterDefined());

    this.subscription.add(
      this.report$.subscribe((report) => {
        this.report = report;
      }),
    );

    this.subscription.add(
      this.report$
        .pipe(
          take(1),
          map((report) => report.patient_id),
          filterDefined(),
          switchMap((patientId) => {
            return this.store.select(fromPatient.selectPatient(patientId)).pipe(
              map((patient) => {
                return {
                  loaded: !!patient,
                  patientId,
                };
              }),
            );
          }),
          switchMap(({ loaded, patientId }) => {
            if (!loaded) {
              return this.patientEffect.findById(patientId);
            } else {
              return EMPTY;
            }
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.report$
        .pipe(
          take(1),
          map((report) => report.referrer_id),
          switchMap((referrerId) => {
            return combineLatest([
              referrerId ? this.store.select(fromReferrer.selectReferrersLoaded(referrerId)) : of(undefined),
              this.report$,
            ]).pipe(
              switchMap(([loaded, report]) => {
                if (!loaded && report.referrer_id) {
                  return this.referrerEffect.findById(report.referrer_id);
                }
                return of(null);
              }),
            );
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.report$
        .pipe(
          switchMap((report) =>
            report.referrer_id ? this.store.select(fromReferrer.selectReferrer(report.referrer_id)) : of(undefined),
          ),
        )
        .subscribe((referrer) => {
          this.referrer = referrer;
        }),
    );

    const providerNumber$ = this.report$.pipe(
      switchMap((report) =>
        report.medicare_provider_id
          ? this.store.select(fromProviderNumber.selectProviderNumber(report.medicare_provider_id))
          : of(undefined),
      ),
    );

    this.subscription.add(
      providerNumber$.subscribe((providerNumber) => {
        this.providerNumber = providerNumber;
      }),
    );

    const userId$ = providerNumber$.pipe(
      filterDefined(),
      take(1),
      map((providerNumber) => providerNumber.user_id),
    );

    this.subscription.add(userId$.pipe(switchMap((userId) => this.userEffect.findById(userId))).subscribe());

    this.user$ = userId$.pipe(switchMap((userId) => this.store.select(fromUser.selectUser(userId))));

    this.subscription.add(this.scanCodeEffect.findAll().subscribe());

    this.scanCode$ = this.report$.pipe(
      switchMap((report) =>
        report.scan_code_id ? this.store.select(fromScanCode.selectScanCode(report.scan_code_id)) : of(undefined),
      ),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
