import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { MessageService } from 'app/core/services/message.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../../app.state';
import { ReportStatusActions } from './report-status.action';
import { fromReportStatus } from './report-status.selector';
import { ReportStatusHttpService } from './report-status.service';

@Injectable()
export class ReportStatusEffect {
  constructor(
    private store: Store<AppState>,
    private service: ReportStatusHttpService,
    private message: MessageService,
  ) {}

  findAll() {
    return this.store.select(fromReportStatus.selectLoaded).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findAll()),
      this.message.handleHttpErrorPipe,
      map((reportStatuses) => ReportStatusActions.findAllSuccess({ reportStatuses })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
