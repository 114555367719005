<div class="modal-header">
  <h5 class="modal-title" data-cy="edit-statement" *ngIf="editType === 'editStatement'">Edit statement</h5>
  <h5 class="modal-title" *ngIf="editType === 'insertStatement'">Insert statement</h5>
  <h5 class="modal-title" *ngIf="editType === 'createStatement'">Create statement</h5>
  <h5 class="modal-title" *ngIf="editType === 'editBlueStatement'">Edit blue statement</h5>
  <h5 class="modal-title" *ngIf="editType === 'insertNotepadStatement'">Insert global statement</h5>
  <h5 class="modal-title" *ngIf="editType === 'createNotepadStatement'">Create global statement</h5>
  <h5 class="modal-title" *ngIf="editType === 'editNotepadStatement'">Edit global statement</h5>
  <!--TODO-->
  <h5 class="modal-title" *ngIf="editType === 'moveMultipleStatement'">
    Move multiple statements: {{ currentStatementIndex + 1 }} of {{ selectedStatements.length }}
  </h5>
  <button ngbAutoFocus role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div
  class="modal-body"
  [class.d-flex]="editType === 'editStatement' || editType === 'moveMultipleStatement'"
  [class.flex-column]="editType === 'editStatement' || editType === 'moveMultipleStatement'"
  [class.flex-grow-1]="editType === 'editStatement' || editType === 'moveMultipleStatement'"
>
  <!--Statement Preview for edit or moving statements-->
  <div *ngIf="editType === 'editStatement' || editType === 'moveMultipleStatement'">
    Statement Preview:
    <rr-statement-preview
      class="mb-2"
      [statement_id]="editType === 'editStatement' ? statement_id : selectedStatements[currentStatementIndex]"
      [region]="region"
      [topic]="topic"
      [template_id]="topic.template_id"
    ></rr-statement-preview>
  </div>

  <!--Button groups for switching mode between insert/edit, voice button, and reset statement-->
  <div class="d-flex mb-2 mt-2">
    <button
      *ngIf="editType === 'editStatement'"
      class="btn btn-sm btn-outline-primary me-2"
      (click)="openToInsertMode()"
    >
      Insert Statement
    </button>
    <button
      *ngIf="editType === 'insertStatement'"
      class="btn btn-sm btn-outline-primary me-2"
      (click)="openToEditMode()"
    >
      Edit Statement
    </button>
    <a class="btn btn-sm btn-outline-secondary me-2" routerLink="/admin/attributes" role="button"> Attributes page </a>
    <rr-voice-recognition
      class="me-2"
      *ngIf="editType === 'editStatement' || editType === 'insertStatement' || editType === 'createStatement'"
      [source]="'STATEMENT_EDIT'"
      [excludeVoiceTerms]="true"
    >
    </rr-voice-recognition>
  </div>

  <rr-statement-edit
    class="w-100 d-flex flex-column flex-grow-1 mb-2"
    *ngIf="editType === 'editStatement'"
    permission
    [permission-only]="['report_manage']"
    (onCancel)="activeModal.dismiss()"
    (onSubmit)="editStatement($event)"
    (onDelete)="promptDeleteStatement()"
    (onClone)="clone()"
    (dismissModal)="activeModal.dismiss()"
    (focusStatement)="focusCreatedStatement($event)"
    [statement]="statement"
    [region]="region"
    [editType]="editType"
    [templateId]="topic.template_id"
    data-no-bubble-statement
  >
  </rr-statement-edit>

  <rr-statement-edit
    *ngIf="editType === 'insertStatement'"
    (onSubmit)="insertStatementBefore($event)"
    (onCancel)="activeModal.dismiss()"
    (onCreateAndClone)="createAndClone($event)"
    (dismissModal)="activeModal.dismiss()"
    (focusStatement)="focusCreatedStatement($event)"
    [editType]="editType"
    [region]="region"
    [templateId]="topic.template_id"
    [statementSetId]="element.statement_set_id"
    data-no-bubble-statement
  >
  </rr-statement-edit>

  <rr-statement-edit
    *ngIf="editType === 'createStatement'"
    (onSubmit)="createStatement($event)"
    (onCancel)="activeModal.dismiss()"
    (onCreateAndClone)="createAndClone($event)"
    (dismissModal)="activeModal.dismiss()"
    (focusStatement)="focusCreatedStatement($event)"
    [editType]="editType"
    [region]="region"
    [topic]="topic"
    [statementSetId]="element.statement_set_id"
  >
  </rr-statement-edit>

  <rr-statement-edit
    class="w-100"
    *ngIf="editType === 'editBlueStatement'"
    permission
    [permission-only]="['report_manage']"
    (onCancel)="activeModal.dismiss()"
    (onSubmitBlue)="editChoice($event)"
    (onDelete)="removeChoice()"
    (onApprove)="approveChoice($event)"
    [blueChoice]="choice"
    [region]="region"
    [topic]="topic"
    [statementSetId]="element.statement_set_id"
    [editType]="editType"
  >
  </rr-statement-edit>

  <!--Notepad edit statement-->
  <rr-statement-edit
    class="w-100"
    *ngIf="editType === 'editNotepadStatement'"
    permission
    [permission-only]="['report_manage']"
    (onCancel)="activeModal.dismiss()"
    (onSubmit)="editStatement($event)"
    (onDelete)="promptDeleteStatement()"
    (onClone)="clone()"
    (onCopy)="copyToTemplate()"
    (dismissModal)="activeModal.dismiss()"
    (focusStatement)="focusCreatedStatement($event)"
    [statement]="statement"
    [editType]="editType"
    data-no-bubble-statement
  >
  </rr-statement-edit>
  <rr-statement-edit
    *ngIf="editType === 'insertNotepadStatement'"
    (onSubmit)="insertStatementBefore($event)"
    (onCancel)="activeModal.dismiss()"
    (onCreateAndClone)="createAndClone($event)"
    (dismissModal)="activeModal.dismiss()"
    (focusStatement)="focusCreatedStatement($event)"
    [editType]="editType"
    data-no-bubble-statement
  >
  </rr-statement-edit>

  <rr-statement-edit
    *ngIf="editType === 'createNotepadStatement'"
    (onSubmit)="createStatement($event)"
    (onCancel)="activeModal.dismiss()"
    (onCreateAndClone)="createAndClone($event)"
    (dismissModal)="activeModal.dismiss()"
    (focusStatement)="focusCreatedStatement($event)"
    [editType]="editType"
  >
  </rr-statement-edit>

  <!--Move statement inside an element or move to the another element, only for editStatement/moveMultipleStatement mode-->
  <rr-statement-moving
    permission
    [permission-only]="['template_manage']"
    *ngIf="editType === 'editStatement' || editType === 'moveMultipleStatement'"
    [statement_id]="editType === 'editStatement' ? statement_id : selectedStatements[currentStatementIndex]"
    [topic]="topic"
    [parent]="source"
    (onMoveCompleted)="onMoveCompleted()"
  ></rr-statement-moving>
</div>
