import { Clipboard } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filterDefined } from 'app/app.utils';
import { GoogleAnalyticsService } from 'app/core/services/google-analytics.service';
import { HotkeysService } from 'app/core/services/hotkeys.service';
import { MessageService } from 'app/core/services/message.service';
import { funders } from 'app/modules/invoice/invoice.constants';
import { CheckboxComponent } from 'app/shared/components/checkbox/checkbox.component';
import { ReportAccessionNumberComponent } from 'app/shared/components/report-accession-number/report-accession-number.component';
import { TrackDirective } from 'app/shared/directives/track.directive';
import { CreateNoteModalComponent } from 'app/shared/modals/create-note-modal/create-note-modal.component';
import { BillingGroupEffect } from 'app/store/billing-group';
import { BillingItemEffect } from 'app/store/billing-item';
import { BillingItemReferrerTypeEffect } from 'app/store/billing-item-referrer-type/billing-item-referrer-type.effect';
import { BookingEffect } from 'app/store/booking';
import { BookingCodeEffect } from 'app/store/booking-code';
import { ProviderNumberEffect } from 'app/store/provider-number';
import { AuditEventEffect } from 'app/store/report/audit-event';
import { AvailableForm, ReportEffect } from 'app/store/report/report';
import { ScanCodeEffect } from 'app/store/scan-code';
import { SiteEffect } from 'app/store/site';
import { SoftwarePreferenceEffect } from 'app/store/software-preferences';
import { UserEffect } from 'app/store/user/user';
import { of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { WorklistLinkComponent } from '../../../../core/toolbar-navbar/components/worklist-link/worklist-link.component';
import { DocumentationLinkButtonComponent } from '../../../../shared/components/documentation-link-button/documentation-link-button.component';
import { NotesComponent } from '../../../../shared/components/notes/notes.component';
import { ReferrerNameComponent } from '../../../../shared/components/referrer-name/referrer-name.component';
import { ReportNotesButtonComponent } from '../../../../shared/components/report-notes-button/report-notes-button.component';
import { TooltipDirective } from '../../../../shared/directives/tooltip.directive';
import { BookingViewSummaryComponent } from '../../../booking/components/booking-view/booking-view-summary/booking-view-summary.component';
import { BookingForm, RegistrationService, ReportForm } from '../../services/registration.service';
import { AllFormComponent } from '../all-form/all-form.component';
import { Db4FormComponent } from '../db4-form/db4-form.component';
import { DocumentAttachmentButtonComponent } from '../document-attachment-button/document-attachment-button.component';
import { PatientFormComponent } from '../patient-form/patient-form.component';
import { PatientQuestionnairesComponent } from '../patient-questionnaires/patient-questionnaires.component';
import { ReferrerFormComponent } from '../referrer-form/referrer-form.component';
import { RegistrationEventsComponent } from '../registration-events/registration-events.component';
import { ReportFormComponent } from '../report-form/report-form.component';
import { ReportInvoicesComponent } from '../report-invoices/report-invoices.component';
import { SameDayBookingsComponent } from '../same-day-bookings/same-day-bookings.component';
import { SameDayRegistrationsComponent } from '../same-day-registrations/same-day-registrations.component';

@Component({
  selector: 'rr-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    DocumentAttachmentButtonComponent,
    ReportNotesButtonComponent,
    RouterLink,
    TooltipDirective,
    Db4FormComponent,
    RegistrationEventsComponent,
    DocumentationLinkButtonComponent,
    ReferrerNameComponent,
    SameDayBookingsComponent,
    BookingViewSummaryComponent,
    FormsModule,
    ReactiveFormsModule,
    PatientFormComponent,
    NotesComponent,
    SameDayRegistrationsComponent,
    ReferrerFormComponent,
    ReportFormComponent,
    ReportInvoicesComponent,
    PatientQuestionnairesComponent,
    WorklistLinkComponent,
    ReportAccessionNumberComponent,
    AllFormComponent,
    TrackDirective,
    CheckboxComponent,
  ],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  @ViewChild(PatientFormComponent) patientFormComponent: PatientFormComponent;
  @ViewChild(ReferrerFormComponent) referrerFormComponent: ReferrerFormComponent;

  report: RR.Report | undefined;
  patient: RR.Patient | undefined;
  referrer: RR.Referrer | undefined;
  booking: RR.Booking | undefined;

  // Same day bookings in case register 2nd scan from another registration
  sameDayBookingIds: number[] = [];
  subscription: Subscription = new Subscription();

  funders = funders;

  availableForms: AvailableForm[] = [];
  bookingForm: BookingForm;
  reportForm: ReportForm;

  constructor(
    public registrationService: RegistrationService,
    private clipboard: Clipboard,
    private messageService: MessageService,
    private hotkeysService: HotkeysService,
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private gaService: GoogleAnalyticsService,
    private reportEffect: ReportEffect,
    private bookingEffect: BookingEffect,
    private scanCodeEffect: ScanCodeEffect,
    private auditEventEffect: AuditEventEffect,
    private providerNumberEffect: ProviderNumberEffect,
    private bookingCodeEffect: BookingCodeEffect,
    private siteEffect: SiteEffect,
    private softwarePreferenceEffect: SoftwarePreferenceEffect,
    private billingItemReferrerTypeEffect: BillingItemReferrerTypeEffect,
    private userEffect: UserEffect,
    private billingItemEffect: BillingItemEffect,
    private billingGroupEffect: BillingGroupEffect,
  ) {
    this.bookingForm = registrationService.bookingForm;
    this.reportForm = registrationService.reportForm;
  }

  ngOnInit(): void {
    this.title.setTitle('Registration - RadReport');

    // TODO(reg): find the doctors, return these from `/api/medicare_provider`
    this.subscription.add(this.userEffect.findAll().subscribe());
    this.subscription.add(this.siteEffect.findAll().subscribe());
    this.subscription.add(this.bookingCodeEffect.findAll().subscribe());
    this.subscription.add(this.scanCodeEffect.findAll().subscribe());
    this.subscription.add(this.providerNumberEffect.findAll().subscribe());
    this.subscription.add(this.softwarePreferenceEffect.getAll().subscribe());
    this.subscription.add(this.billingItemReferrerTypeEffect.findAll().subscribe());
    this.subscription.add(this.billingGroupEffect.findAll().subscribe());
    this.subscription.add(this.billingItemEffect.findAll().subscribe());

    // Search for a patients same day bookings
    this.subscription.add(
      this.registrationService.bookingId$
        .pipe(
          switchMap((bookingId) => {
            if (bookingId) {
              return this.bookingEffect.searchPatientSameDayBookings(bookingId);
            }

            return of(undefined);
          }),
        )
        .subscribe((action) => {
          if (action && action.actions.bookingFindManySuccess.bookings.length > 1) {
            this.sameDayBookingIds = action.actions.bookingFindManySuccess.bookings.map((b) => b.id);
          } else {
            this.sameDayBookingIds = [];
          }
        }),
    );

    this.subscription.add(
      this.registrationService.reportId$
        .pipe(
          filterDefined(),
          switchMap((reportId) => this.reportEffect.availableForms(reportId)),
        )
        .subscribe((response) => {
          this.availableForms = response.forms;
        }),
    );

    this.subscription.add(
      this.registrationService.patient$.subscribe((patient) => {
        this.patient = patient;
      }),
    );
    this.subscription.add(
      this.registrationService.referrer$.subscribe((referrer) => {
        this.referrer = referrer;
      }),
    );
    this.subscription.add(
      this.registrationService.report$.subscribe((report) => {
        this.report = report;
      }),
    );
  }

  @HostListener('window:beforeunload', ['$event'])
  // @ts-expect-error noImplicitReturns
  beforeUnloadHandler() {
    if (
      this.referrerFormComponent.referrerFormEdit.form.dirty ||
      (this.patientFormComponent.patientFormEdit && this.patientFormComponent.patientFormEdit.form.dirty) ||
      this.registrationService.reportForm.dirty ||
      this.registrationService.bookingForm.dirty
    ) {
      return false;
    }
  }

  copy(value: string | number) {
    const copied = this.clipboard.copy(value.toString());
    if (copied) {
      this.messageService.add({ type: 'success', title: 'Success', message: 'Copied!', timeout: 1000 });
    } else {
      this.messageService.add({ type: 'danger', title: 'Failure', message: 'Copy Failed!', timeout: 1000 });
    }
  }

  changeSameDayBooking(booking: RR.Booking) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: {
          bookingId: booking.id,
          patientId: this.patient?.id || this.report?.patient_id,
          referrerId: this.referrer?.id || this.report?.referrer_id,
        },
      }),
    );

    // eslint-disable-next-line no-restricted-properties
    window.open(url, '_blank');
  }

  openCreateNoteModal({ patientId, referrerId }: { patientId?: number; referrerId?: number }) {
    CreateNoteModalComponent.open({
      modalService: this.modalService,
      referrerId: referrerId ?? undefined,
      patientId: patientId ?? undefined,
      reportId: this.report?.id,
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
