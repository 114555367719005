import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { AppState } from 'app/store';
import { PresetEffect } from 'app/store/report/preset';
import { fromUserSetting } from 'app/store/user/user-setting';
import { Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, switchMap, take } from 'rxjs/operators';

import { SharedModule } from '../../../shared/shared.module';
import { PrefillCopyComponent } from './prefill-copy/prefill-copy.component';
import { PrefillService } from './prefill.service';

@Component({
  selector: 'rr-prefill',
  templateUrl: './prefill.component.html',
  styleUrls: ['./prefill.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    FormsModule,
    PrefillCopyComponent,
    AutoFocusDirective,
  ],
})
@LifecycleLogger
export class PrefillComponent implements OnInit, OnDestroy {
  @BindObservable() @Input() topic_id: number;
  topic_id$: Observable<number>;

  topic: RR.Topic;
  numTopics = 0;
  threshold = 0; // Threshold for majority merge strategy
  hideHistory = true;
  moreComments = true;
  userSetting: RR.UserSetting;

  // Apply linear regression to filter choices from selected topics
  logReg = false;
  logRegStatements: { statement_id: number; probability: number }[] = [];
  filterLogRegStatements: number[] = [];
  logRegThreshold = 50;

  subscription = new Subscription();

  prefillBySubdivisions = false;

  constructor(
    private prefillService: PrefillService,
    private cd: ChangeDetectorRef,
    private store: Store<AppState>,
    private reportService: ReportService,
    private templateService: TemplateService,
    private presetEffect: PresetEffect,
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.topic_id$.pipe(switchMap((topicId) => this.reportService.getTopic(topicId))).subscribe((topic) => {
        this.topic = topic;
      }),
    );

    const userSetting$ = this.reportService.selectKioskUser().pipe(
      filterDefined(),
      switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))),
      filterDefined(),
      take(1),
    );

    this.subscription.add(
      userSetting$.subscribe((userSetting) => {
        this.hideHistory = !userSetting.prefill_show_history_technique; // Default value is true
        if (window.__e2e__) {
          console.warn('Default to false for e2e test');
          this.hideHistory = false;
        }
        this.cd.detectChanges();
      }),
    );

    this.subscription.add(
      userSetting$
        .pipe(
          take(1),
          switchMap(() => this.prefillService.getPrefillPreviewObject(this.topic_id).pipe(distinctUntilChanged())),
        )
        .subscribe((previewObject) => {
          // Subscribe to selected topics after get user settings, because getLogRegStatements uses the settings.
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
          this.numTopics = previewObject ? previewObject.topicIds.length : 0;
          this.threshold = this.numTopics > 1 ? 2 : 1;
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
          if (previewObject && this.numTopics > 0) {
            this.getLogRegStatements(previewObject.topicIds);
          }
          this.cd.detectChanges();
        }),
    );

    this.subscription.add(
      this.prefillService.prefillBySubdivisions$.subscribe((bySubdivisions) => {
        this.prefillBySubdivisions = !!bySubdivisions.length;
      }),
    );
  }

  isTemplateLoaded(): Observable<boolean> {
    return this.templateService.isCurrentTemplateLoaded();
  }

  getThresholds() {
    if (this.numTopics < 2) return [];
    const arr = [];
    for (let i = 1; i <= this.numTopics; i++) {
      arr.push(i);
    }
    return arr;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleHideHistory() {
    this.hideHistory = !this.hideHistory;
  }

  toggleMoreComments() {
    this.moreComments = !this.moreComments;
  }

  changeThreshold(val: number) {
    this.threshold += val;
    if (this.threshold < 1) this.threshold = 1;
    if (this.threshold > this.numTopics) this.threshold = this.numTopics;
  }

  getLogRegStatements(topic_ids: string[]) {
    if (!this.logReg || topic_ids.length === 0) {
      this.logRegStatements = [];
      this.filterLogRegStatements = [];
      return;
    }
    this.prefillService
      .getPrefillLogisticRegressionFiltering(topic_ids, this.topic_id)
      .pipe(take(1))
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe((data: { statement_id: number; probability: number }[]) => {
        this.logRegStatements = data.sort((a, b) => b.probability - a.probability);
        if (this.logRegStatements.length === 0) {
          this.filterLogRegStatements = [];
          return;
        }

        // Find the 'best' threshold before filtering
        const DECENT_PROBABILITY_GUESS = 0.5;
        // Guessed a number that made sense as a decent probablity
        // and chose that index as the cutoff threshold
        const minIndex = this.logRegStatements.findIndex((lrs) => lrs.probability < DECENT_PROBABILITY_GUESS);

        // Calculate percentage threshold, round up to the nearest 10
        this.logRegThreshold = Math.ceil((((minIndex + 1) / this.logRegStatements.length) * 100) / 10) * 10;

        this.filterLogRegStatements = this.filterLogRegStatementsByThreshold(this.logRegThreshold);
        this.cd.detectChanges();
      });
  }

  logRegChanged() {
    this.logReg = !this.logReg;
    if (this.logReg) {
      this.prefillService
        .getPrefillPreviewObject(this.topic_id)
        .pipe(take(1))
        // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
        .subscribe((previewObject) => {
          this.getLogRegStatements(previewObject.topicIds);
        });
    } else {
      this.logRegStatements = [];
      this.filterLogRegStatements = [];
      this.cd.detectChanges();
    }
  }

  filterLogRegStatementsByThreshold(threshold: number) {
    const n = Math.round(this.logRegStatements.length * (threshold / 100));
    return this.logRegStatements.slice(0, n).map((lrs) => lrs.statement_id);
  }

  changeLogRegThreshold(val: number) {
    this.logRegThreshold += val;
    if (this.logRegThreshold < 10) this.logRegThreshold = 10;
    if (this.logRegThreshold > 100) this.logRegThreshold = 100;
    this.filterLogRegStatements = this.filterLogRegStatementsByThreshold(this.logRegThreshold);
    this.cd.detectChanges();
  }
}
