import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, forwardRef } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { AddOtherImagingButtonComponent } from 'app/modules/editor/add-other-imaging-button/add-other-imaging-button.component';
import { PatientQuestionnairesModalComponent } from 'app/modules/registration/modals/patient-questionnaires-modal/patient-questionnaires-modal.component';
import { RegistrationModalComponent } from 'app/modules/registration/modals/registration-modal/registration-modal.component';
import { ReportHeadlineComponent } from 'app/shared/components/report-headline/report-headline.component';
import { ReportNotesButtonComponent } from 'app/shared/components/report-notes-button/report-notes-button.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromPatient } from 'app/store/patient';
import { fromReport } from 'app/store/report/report';
import { fromCurrentTopic } from 'app/store/report/topic';
import { SignatureEffect } from 'app/store/signature';
import { Observable, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { SendReportButtonComponent } from '../../send-report/send-report-button/send-report-button.component';
import { CheckTopicComponent } from '../check-topic/check-topic.component';
import { FinalPreviewModalComponent } from '../final-preview-modal/final-preview-modal.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgbModule,
    SharedModule,
    CheckTopicComponent,
    ReportNotesButtonComponent,
    forwardRef(() => AddOtherImagingButtonComponent),
    SendReportButtonComponent,
    ReportHeadlineComponent,
    AutoFocusDirective,
  ],
  selector: 'rr-check-report-modal',
  templateUrl: './check-report-modal.component.html',
  styleUrls: ['./check-report-modal.component.css'],
})
export class CheckReportModalComponent implements OnInit, OnDestroy {
  @Input() report_id: number;
  @Input() topic_id: number;
  @Input() default_topic_id: number;

  report$: Observable<RR.Report>;
  topic$: Observable<RR.Topic | undefined>;
  patient$: Observable<RR.Patient>;
  signatureText$: Observable<string | null>;

  subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private reportService: ReportService,
    private modal: NgbModal,
    private editorService: EditorService,
    private store: Store<AppState>,
    private signatureEffect: SignatureEffect,
  ) {}

  ngOnInit() {
    this.report$ = this.store.select(fromReport.selectReport(this.report_id)).pipe(filterDefined());
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());
    this.patient$ = this.report$.pipe(
      filterDefined(),
      map((r) => r.patient_id),
      filterDefined(),
      switchMap((patient_id) => this.store.select(fromPatient.selectPatient(patient_id))),
      filterDefined(),
    );

    this.signatureText$ = this.topic$.pipe(
      map((topic) => {
        if (topic) {
          return topic.signature_text;
        } else {
          return null;
        }
      }),
    );

    // Load signatures for checking if current user has signed the report
    this.subscription.add(this.signatureEffect.findAll().subscribe());
  }

  getActiveTopics() {
    return (
      this.reportService
        .getActiveTopics(this.report_id)
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        .pipe(map((topics) => topics.filter((t): t is RR.Topic => !!t)))
    );
  }

  checkAutoCloseModal(_$event: any) {
    this.getActiveTopics()
      .pipe(
        map((topics) => topics.length),
        take(1),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((len) => {
        if (len === 1) {
          setTimeout(() => {
            this.viewFinalReport();
          });
        }
      });
  }

  viewFinalReport() {
    this.activeModal.dismiss();
    setTimeout(() => {
      FinalPreviewModalComponent.open(this.modal, {
        topic_id: this.default_topic_id,
        report_id: this.report_id,
        mode: 'final',
      });
    });
  }

  togglePrefill() {
    this.activeModal.dismiss();
    this.editorService.togglePrefill(true);
  }

  openRegistrationModal() {
    RegistrationModalComponent.open(this.modal, this.report_id);
  }

  openPatientQuestionnaireModal() {
    PatientQuestionnairesModalComponent.open(this.modal, this.report_id);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  static open(modalService: NgbModal, report_id: number, topic_id: number) {
    const modalRef = modalService.open(CheckReportModalComponent, {
      size: 'xl',
      scrollable: true,
    });
    modalRef.componentInstance.report_id = report_id;
    modalRef.componentInstance.default_topic_id = topic_id;
    return modalRef;
  }
}
