import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkDragDrop, CDK_DRAG_CONFIG, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { COMMAND_ACTIONS } from 'app/app.constants';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import { CommandOutput, VoiceInputTerm, VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { TagCopyComponent } from 'app/modules/editor/prefill/prefill-tag/tag-copy/tag-copy.component';
import { GroupedTags } from 'app/modules/editor/prefill/prefill-tag/tag-structure/tag-structure.component';
import { AbbreviationDirective } from 'app/shared/directives/abbreviation/abbreviation.directive';
import { AutoSizeDirective } from 'app/shared/directives/auto-size.directive';
import { VoiceDirective } from 'app/shared/directives/voice.directive';
import { StoreSelectPipe } from 'app/shared/pipes/store-select.pipe';
import { StructuredNoteLengthPipe } from 'app/shared/pipes/structured-note-pipe';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { EditorActions } from 'app/store/editor';
import { KeywordAbbreviationEffect } from 'app/store/keyword-abbr';
import { fromTag, TagActions, TagEffect } from 'app/store/prefill/tag';
import { fromTagChoice, TagChoiceEffect } from 'app/store/prefill/tag-choice';
import { ReportHttpService, fromReport } from 'app/store/report/report';
import { StatementChoiceEffect } from 'app/store/report/statement-choice';
import { fromCurrentTopic, fromTopic } from 'app/store/report/topic';
import { fromVoiceNote, VoiceNoteEffect } from 'app/store/report/voice-note';
import { VoiceNoteCreateSuccessAction } from 'app/store/report/voice-note/voice-note.action';
import { fromSession } from 'app/store/session';
import { fromTagSearchTerm, TagSearchTermEffect } from 'app/store/tag-search-term';
import { fromSubsection, SubsectionEffect } from 'app/store/template/subsection';
import { TemplateEffect } from 'app/store/template/template';
import { UserSettingEffect, fromUserSetting } from 'app/store/user/user-setting';
import { groupBy } from 'lodash-es';
import {
  catchError,
  combineLatest,
  filter,
  forkJoin,
  map,
  Observable,
  of,
  skipWhile,
  Subscription,
  switchMap,
  take,
} from 'rxjs';

import { VoiceRecognitionComponent } from '../../../components/voice-recognition/voice-recognition/voice-recognition.component';
import { TagSearchTermComponent } from './tag-search-term/tag-search-term.component';
import { TempVoiceNoteComponent } from './temp-voice-note/temp-voice-note.component';
import { VoiceNoteItemComponent } from './voice-note-item/voice-note-item.component';
import { VoiceNoteTagsComponent } from './voice-note-tags/voice-note-tags.component';

export type TempNote = { text: string; destination: 'GENERAL' | 'STRUCTURED' };
@Component({
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    CdkAccordionModule,
    DragDropModule,
    FormsModule,
    VoiceNoteTagsComponent,
    VoiceNoteItemComponent,
    TempVoiceNoteComponent,
    TagSearchTermComponent,
    StructuredNoteLengthPipe,
    TagCopyComponent,
    StoreSelectPipe,
    VoiceRecognitionComponent,
    VoiceDirective,
    AbbreviationDirective,
    LetDirective,
    NgbModule,
    AutoSizeDirective,
  ],
  providers: [
    {
      provide: CDK_DRAG_CONFIG,
      useValue: {
        zIndex: 1055, // Same z-index as NgbModal. So that the drag preview isn't behind the modal.
      },
    },
  ],
  selector: 'rr-voice-notes',
  templateUrl: './voice-notes.component.html',
  styleUrls: ['./voice-notes.component.css'],
})
export class VoiceNotesComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('voiceNoteGeneral', { static: false }) voiceNoteGeneral: ElementRef;
  @Input() reportId: number;
  @Input() topicId?: number | undefined;
  @Output() onVoiceMode = new EventEmitter<boolean>();
  topic: RR.Topic | undefined;

  generalNoteText = '';
  structuredNoteText = '';
  generalNoteFilterText = '';
  structuredNoteFilterText = '';

  subscription = new Subscription();

  report$: Observable<RR.Report | undefined>;
  voiceNotes$: Observable<RR.VoiceNote[]>;
  generalNotes: RR.VoiceNote[] = [];
  structuredNotes: RR.StructuredNote[] = [];
  structure$: Observable<RR.StructuredTagSubsection[]>;
  tagSuggested$: Observable<RR.TagSuggestion[]>;
  section: RR.TagSearchSection = 'findings';
  subsectionName: string | undefined;
  topicTags: RR.Divider[] = [];
  groupedTags: GroupedTags;
  regions: RR.StructuredTagRegion[] | undefined;
  structure: RR.StructuredTagSubsection[];
  subsectionIx = 0;
  activeRegion: string | undefined;

  // Auto update scores for tags
  autoUpdate$: Observable<boolean>;
  tempNotes: TempNote[] = [];
  tagSearchTerms: RR.TagSearchTerm[];
  subsections: RR.Subsection[] | undefined;
  subsectionNameParent$: Observable<RR.Subsection | undefined>;
  rrConfig: rrConfigType | undefined;
  autoAddThreshold = new FormControl(50, { nonNullable: true });
  retryThreshold = new FormControl(15, { nonNullable: true });
  lastAddedStatementId: number | undefined;
  // Loading
  tagsLoading: boolean;
  voiceMode: boolean | undefined = undefined;
  userSetting: RR.UserSetting | undefined = undefined;

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private voiceNoteEffect: VoiceNoteEffect,
    private voiceService: VoiceRecognitionService,
    private tagEffect: TagEffect,
    private tagChoiceEffect: TagChoiceEffect,
    private cd: ChangeDetectorRef,
    private keywordAbbreviationEffect: KeywordAbbreviationEffect,
    private reportService: ReportService,
    private selectorService: SelectorService,
    private tagSearchTermEffect: TagSearchTermEffect,
    private subsectionEffect: SubsectionEffect,
    private templateEffect: TemplateEffect,
    private editorService: EditorService,
    private messageService: MessageService,
    private reportHttpService: ReportHttpService,
    private speechService: VoiceRecognitionService,
    private userSettingEffect: UserSettingEffect,
    private statementChoiceEffect: StatementChoiceEffect,
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.store.select(fromSession.selectRRConfig).subscribe((rrConfig) => {
        this.rrConfig = rrConfig;
      }),
    );

    const currentUser$ = this.selectorService.selectLoadedCurrentUser();

    this.subscription.add(
      currentUser$
        .pipe(filterDefined())
        .pipe(switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))))
        .subscribe((userSetting) => {
          if (userSetting) {
            this.userSetting = userSetting;
            this.voiceMode = userSetting.voice_recognition_features;
          }
        }),
    );

    // Check to load keyword abbreviations for rrAbbr input
    this.subscription.add(this.keywordAbbreviationEffect.findAll().subscribe());

    // Check user setting to toggle auto update score
    this.subscription.add(
      this.selectorService
        .selectLoadedCurrentUser()
        .pipe(
          filterDefined(),
          take(1),
          switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))),
        )
        .subscribe((userSetting) => {
          if (userSetting?.prefill_tags_scores_auto_update) {
            this.toggleAutoUpdate(true);
          }
        }),
    );
    this.autoUpdate$ = this.store.select(fromTag.selectAutoUpdate);
    this.structure$ = this.store.select(fromTag.selectTemplateStructure(this.section));
    this.tagSuggested$ = this.store.select(fromTag.selectOrderedSuggestedTagsBySection(this.section));
    this.report$ = this.store.select(fromReport.selectReport(this.reportId));
    this.voiceNotes$ = this.store.select(fromVoiceNote.selectInReport(this.reportId));
    if (this.topicId) {
      // When opening tag modal from worklist, force reload structure and suggested tags because the existing data
      // in the store might belong to another topic
      this.loadStructureAndTags(this.topicId, this.section, { forceReload: true });
    } else {
      this.subscription.add(
        this.store
          .select(fromCurrentTopic.selectTopicId)
          .pipe(filterDefined(), take(1))
          .subscribe((topicId) => {
            this.topicId = topicId;
            // Not forcing reload suggested and structured tags if they are already in the store
            this.loadStructureAndTags(this.topicId, this.section);
          }),
      );
    }

    this.subscription.add(
      this.store
        .select(fromTag.isStructureLoaded(this.section))
        .pipe(
          skipWhile((loaded) => !loaded),
          switchMap(() =>
            this.structure$.pipe(
              filterDefined(),
              // filterDefined doesn't filter empty array
              filter((structure) => !!structure.length),
              take(1),
            ),
          ),
        )
        .subscribe((structure) => {
          this.subsectionName = structure[0]?.name ?? 'NO NAME';
          this.regions = structure[this.subsectionIx].regions;
          this.structure = structure;
        }),
    );

    this.subscription.add(
      this.tagSuggested$
        .pipe(
          // from tag-structure.component
          map((tags) => {
            this.tagsLoading = true;
            const groupedTags: GroupedTags = {};
            for (const [key, value] of Object.entries(groupBy(tags, 'subsection_text'))) {
              groupedTags[key] = groupBy(value, 'region_text');
            }
            return groupedTags;
          }),
        )
        .subscribe((suggestedTags) => {
          this.groupedTags = suggestedTags;
          this.tagsLoading = false;
        }),
    );

    this.subscription.add(
      combineLatest([this.structure$, this.voiceNotes$]).subscribe(([structure, voiceNotes]) => {
        this.generalNotes = voiceNotes.filter(
          (note) => note.note_type === 'general' || note.note_type === 'search_clipboard',
        );

        if (this.topicId) {
          this.structuredNotes = structure.reduce<RR.StructuredNote[]>((acc, subsection) => {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            const subsectionName = subsection.name ?? 'NO NAME';
            const subsectionVoiceNotes = voiceNotes.filter((note) => note.subsection === subsectionName);
            acc.push({ subsection: subsectionName, notes: subsectionVoiceNotes });
            return acc;
          }, []);
        }
      }),
    );

    if (this.topicId) {
      const topic$ = this.store.select(fromTopic.selectTopic(this.topicId)).pipe(filterDefined());
      this.subscription.add(
        topic$.subscribe((topic) => {
          this.topic = topic;
        }),
      );
      this.subscription.add(
        topic$
          .pipe(
            filterDefined(),
            switchMap((topic) => this.store.select(fromTagSearchTerm.selectInTemplate(topic.template_id))),
          )
          .subscribe((tagSearchTerms) => {
            // sort persisted notes alphabetically
            tagSearchTerms.sort((a, b) => a.text.localeCompare(b.text));
            this.tagSearchTerms = tagSearchTerms;
          }),
      );
      this.subscription.add(
        topic$
          .pipe(
            switchMap((topic) =>
              forkJoin([
                this.subsectionEffect.findInTemplate(topic.template_id),
                this.tagSearchTermEffect.findAll(topic.template_id),
              ]),
            ),
          )
          .subscribe(),
      );
    }

    this.subscription.add(
      this.voiceService.voiceTermSubject$
        .pipe(
          filter(
            (voiceInput: VoiceInputTerm) =>
              voiceInput.source === 'STRUCTURED_NOTES' || voiceInput.source === 'VOICE_NOTES',
          ),
        )
        .subscribe((voiceInput) => {
          if (voiceInput.source === 'VOICE_NOTES') {
            this.addGeneralNote(voiceInput.term, 'voice');
          } else if (voiceInput.source === 'STRUCTURED_NOTES') {
            this.addStructuredNote(voiceInput.term);
          }
        }),
    );

    this.subscription.add(
      this.store
        .select(fromSubsection.selectAll)
        .pipe(filter((subsection) => !!subsection.length))
        .subscribe((subsections) => {
          this.subsections = subsections;
        }),
    );

    this.subsectionNameParent$ = this.store.select(fromSubsection.selectAll).pipe(
      filter((subsection) => !!subsection.length),
      map((subsections) => subsections.filter((subsection) => subsection.name === this.subsectionName)[0]),
    );

    this.subscription.add(
      this.voiceService.commandSubject$
        .pipe(filter((command: CommandOutput) => command.destination === 'VOICE_NOTES'))
        .subscribe(({ command }) => {
          if (command === COMMAND_ACTIONS.deleteLastSentence) {
            this.removeLastAddedStatement();
          }
          this.cd.detectChanges();
        }),
    );
  }

  loadStructureAndTags(
    topicId: number,
    section: RR.TagSearchSection,
    options: { forceReload: boolean } = { forceReload: false },
  ) {
    if (options.forceReload) {
      this.subscription.add(this.tagEffect.loadStructure(topicId, section).subscribe());
      if (this.rrConfig?.ANALYTICS) {
        this.subscription.add(
          this.tagEffect.loadSuggested(topicId, section).pipe(this.messageService.handleHttpErrorPipe).subscribe(),
        );
      }
      this.subscription.add(this.tagChoiceEffect.loadTagChoices(topicId).subscribe());
    } else {
      this.subscription.add(
        this.store
          .select(fromTag.isStructureLoaded(section))
          .pipe(
            take(1),
            switchMap((loaded) => {
              if (!loaded) {
                return this.tagEffect.loadStructure(topicId, section);
              } else {
                return of(null);
              }
            }),
          )
          .subscribe(),
      );

      if (this.rrConfig?.ANALYTICS) {
        this.subscription.add(
          this.store
            .select(fromTag.isSuggestedLoaded(section))
            .pipe(
              take(1),
              switchMap((loaded) => {
                if (!loaded) {
                  return this.tagEffect.loadSuggested(topicId, section);
                } else {
                  return of(null);
                }
              }),
              this.messageService.handleHttpErrorPipe,
            )
            .subscribe(),
        );
      }

      this.subscription.add(
        this.store
          .select(fromTagChoice.selectLoaded)
          .pipe(
            take(1),
            switchMap((loaded) => {
              if (!loaded) {
                return this.tagChoiceEffect.loadTagChoices(topicId);
              } else {
                return of(null);
              }
            }),
          )
          .subscribe(),
      );
    }

    // Load current report tags
    this.subscription.add(
      this.reportService
        .fetchTopicDividers(topicId)
        .pipe(take(1))
        .subscribe((tags) => {
          this.topicTags = tags;
        }),
    );
  }

  startListeningGeneralNote() {
    if (!this.voiceMode) return;
    this.voiceService.startListening('VOICE_NOTES');
  }

  toggleSubsection(subsectionName: string) {
    if (this.subsectionName === subsectionName) {
      this.subsectionName = undefined;
      this.voiceService.stopListening();
    } else {
      this.dictateSpeech(subsectionName);
    }
  }

  dictateSpeech(subsectionName: string) {
    this.subsectionName = subsectionName;
    this.subsectionIx = this.structure.map((s) => s.name).indexOf(this.subsectionName);
    this.regions = this.structure[this.subsectionIx]?.regions;
    // activeRegion to display the first region in a subsection by default
    this.activeRegion = this.structure[this.subsectionIx]?.regions[0]?.name;

    if (this.voiceMode) {
      this.voiceService.startListening('STRUCTURED_NOTES');
    }

    // Select the first notes for tag filtering
    const structuredNote = this.structuredNotes.find((note) => note.subsection === subsectionName);
    if (structuredNote && structuredNote.notes.length) {
      this.structuredNoteFilterText = structuredNote.notes[0].text;
    } else {
      this.structuredNoteFilterText = '';
    }
  }

  handleVoiceNoteSubscription(voiceNoteCreate$: Observable<VoiceNoteCreateSuccessAction>) {
    this.subscription.add(
      voiceNoteCreate$
        .pipe(
          switchMap((action) => {
            let retries = 0;
            if (action.voiceNote.id && this.topicId) {
              return this.voiceNoteEffect
                .addSuggestedNoteDividerStatementChoice(action.voiceNote.id, this.topicId, this.autoAddThreshold.value)
                .pipe(
                  map((response) => ({ response, voiceId: action.voiceNote.id })),
                  catchError((error: unknown) => {
                    if (this.topicId && retries === 0) {
                      this.messageService.add({
                        title: `Search at threshold ${this.autoAddThreshold.value} unsuccessful:`,
                        message: 'Threshold is being lowered and the search re-run',
                        type: 'warning',
                        timeout: 5000,
                      });
                      if (this.voiceMode) {
                        this.speechService.startSpeechSynth('Threshold lowered, and search re-run');
                      }
                      retries++;
                      return this.voiceNoteEffect
                        .addSuggestedNoteDividerStatementChoice(
                          action.voiceNote.id,
                          this.topicId,
                          this.retryThreshold.value,
                        )
                        .pipe(
                          map((retryResponse) => ({ response: retryResponse, voiceId: action.voiceNote.id })),
                          catchError((retryError: unknown) => {
                            // Handle error from retry here
                            this.messageService.httpErrorMessage(retryError);
                            return of({ response: null, voiceId: action.voiceNote.id });
                          }),
                        );
                    } else {
                      this.messageService.httpErrorMessage(error);
                      return of({ response: null, voiceId: action.voiceNote.id });
                    }
                  }),
                );
            }
            return of(null);
          }),
        )
        .subscribe((data) => {
          if (data && data.response) {
            const { response, voiceId } = data;
            const dividerAdded = !!response.tagChoiceActions;
            const statementAdded = !!response.statementChoiceAction;

            if (statementAdded && response.statementChoiceAction) {
              this.lastAddedStatementId =
                response.statementChoiceAction.actions.statementChoiceAddMany.statementChoices[0].id;
              this.flashSuggestedItem({
                id: response.statementChoiceAction.actions.statementChoiceAddMany.statementChoices[0].id,
                isDivider: false,
              });
              this.subscription.add(
                this.voiceNoteEffect
                  .update(voiceId, {
                    successful_recommendation: true,
                  })
                  // eslint-disable-next-line rxjs/no-nested-subscribe
                  .subscribe(),
              );
            }

            if (dividerAdded && response.tagChoiceActions) {
              for (const action of response.tagChoiceActions) {
                this.flashSuggestedItem({
                  id: action.actions.addSuccess.tag.tag_id,
                  isDivider: true,
                });
              }
            }

            this.editorService.triggerAutoSuggestedLoad();
          } else if (data) {
            this.subscription.add(
              this.voiceNoteEffect
                .update(data.voiceId, {
                  successful_recommendation: false,
                })
                // eslint-disable-next-line rxjs/no-nested-subscribe
                .subscribe(),
            );
          }

          this.cd.detectChanges();
        }),
    );
  }

  flashSuggestedItem({ id, isDivider = false }: { id: number; isDivider?: boolean }) {
    this.subscription.add(
      (isDivider
        ? this.reportHttpService.findDividerText(id)
        : this.reportHttpService.findStatementChoiceText(id)
      ).subscribe({
        next: (response) => {
          const item = isDivider ? 'Divider' : 'Statement';
          const dictateString = `Statement added... ${response.sentence_text}`;
          if (this.voiceMode && !isDivider) this.speechService.startSpeechSynth(dictateString);
          this.messageService.add({
            title: `${item} Added: `,
            message: response.sentence_text,
            type: 'success',
            timeout: 10000,
          });
        },
        error: (err: unknown) => {
          this.messageService.httpErrorMessage(err);
        },
      }),
    );
  }

  retrySearch(note: RR.VoiceNote) {
    if (this.topicId) {
      this.subscription.add(
        this.voiceNoteEffect
          .addSuggestedNoteDividerStatementChoice(note.id, this.topicId, this.autoAddThreshold.value)
          .pipe(
            map((response) => ({ response, voiceId: note.id })),
            catchError((error: unknown) => {
              this.messageService.httpErrorMessage(error);
              return of({ response: null, voiceId: note.id });
            }),
          )
          .subscribe((data) => {
            if (data.response) {
              const { response, voiceId } = data;
              const dividerAdded = !!response.tagChoiceActions;
              const statementAdded = !!response.statementChoiceAction;

              if (statementAdded && response.statementChoiceAction) {
                this.lastAddedStatementId =
                  response.statementChoiceAction.actions.statementChoiceAddMany.statementChoices[0].id;
                this.flashSuggestedItem({
                  id: response.statementChoiceAction.actions.statementChoiceAddMany.statementChoices[0].id,
                  isDivider: false,
                });
                this.subscription.add(
                  this.voiceNoteEffect
                    .update(voiceId, {
                      successful_recommendation: true,
                    })
                    // eslint-disable-next-line rxjs/no-nested-subscribe
                    .subscribe(),
                );
              }

              if (dividerAdded && response.tagChoiceActions) {
                for (const action of response.tagChoiceActions) {
                  this.flashSuggestedItem({
                    id: action.actions.addSuccess.tag.tag_id,
                    isDivider: true,
                  });
                }
              }

              this.editorService.triggerAutoSuggestedLoad();
            } else {
              this.subscription.add(
                this.voiceNoteEffect
                  .update(data.voiceId, {
                    successful_recommendation: false,
                  })
                  // eslint-disable-next-line rxjs/no-nested-subscribe
                  .subscribe(),
              );
            }

            this.cd.detectChanges();
          }),
      );
    }
  }

  retryStatementSearch(voiceNote: RR.VoiceNote) {
    this.retrySearch(voiceNote);
  }

  removeLastAddedStatement() {
    if (this.lastAddedStatementId) {
      this.subscription.add(this.statementChoiceEffect.delete(this.lastAddedStatementId).subscribe());
      this.speechService.startSpeechSynth('Statement deleted');
      this.lastAddedStatementId = undefined;
    }
  }

  addGeneralNote(text: string, source: string) {
    if (!text) {
      return;
    }

    if (source === 'voice') {
      this.addTempNote(text, 'STRUCTURED');

      const voiceNoteCreate$ = this.voiceNoteEffect.create({
        report_id: this.reportId,
        text,
        note_type: 'general',
      });

      this.store.dispatch(
        EditorActions.addVoiceSearchResult({
          term: text,
          note_type: 'general',
        }),
      );

      this.generalNoteFilterText = text;
      setTimeout(() => {
        this.generalNoteText = '';
        this.editorService.triggerSelectTopChoice('GENERAL');
      });

      this.cd.detectChanges();

      this.handleVoiceNoteSubscription(voiceNoteCreate$);
    } else {
      this.subscription.add(
        this.voiceNoteEffect
          .create({
            report_id: this.reportId,
            text,
            note_type: 'search_clipboard',
          })
          .subscribe(() => {
            this.messageService.add({
              title: 'Search term saved',
              message: text,
              type: 'success',
              timeout: 2000,
            });
          }),
      );
    }
  }

  addStructuredNote(text: string) {
    if (!text) {
      return;
    }

    this.addTempNote(text, 'GENERAL');
    this.addTempNote(text, 'STRUCTURED');

    if (this.subsectionName) {
      const voiceNoteCreate$ = this.voiceNoteEffect.create({
        report_id: this.reportId,
        text: text,
        note_type: 'structured',
        subsection: this.subsectionName,
      });

      this.store.dispatch(
        EditorActions.addVoiceSearchResult({
          term: text,
          note_type: 'structured',
          subsection: this.subsectionName,
        }),
      );

      this.structuredNoteFilterText = text;
      setTimeout(() => {
        this.structuredNoteText = '';
        this.editorService.triggerSelectTopChoice('STRUCTURED');
      });

      this.handleVoiceNoteSubscription(voiceNoteCreate$);
    }
  }

  addTempNote(text: string, destination: 'GENERAL' | 'STRUCTURED') {
    this.tempNotes.push({ text, destination });
  }

  deleteTempNote(tempNote: TempNote) {
    this.tempNotes = this.tempNotes.filter((n) => n !== tempNote);
  }

  clearAll(voiceNotes: RR.VoiceNote[]) {
    voiceNotes.forEach((note) => {
      this.subscription.add(this.voiceNoteEffect.delete(note.id).subscribe());
    });
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<{ term: string; source: string }>) {
    if (event.detail.source === 'GENERAL') {
      this.addGeneralNote(event.detail.term, 'voice');
    } else if (event.detail.source === 'STRUCTURED') {
      this.addStructuredNote(event.detail.term);
    }
  }

  copyToSearch(text: string) {
    this.structuredNoteFilterText = text;
  }

  copyTempNoteToSearch(note: TempNote) {
    if (note.destination === 'GENERAL') {
      this.generalNoteFilterText = note.text;
    } else {
      this.structuredNoteFilterText = note.text;
    }
  }

  stopListening() {
    this.voiceService.stopListening();
  }

  setRegion(region: string) {
    if (this.activeRegion === region) {
      this.activeRegion = undefined;
    } else {
      this.activeRegion = region;
    }
  }

  ngOnDestroy(): void {
    this.stopListening();
    this.subscription.unsubscribe();
  }

  clearFilter(type: 'GENERAL' | 'STRUCTURE') {
    if (type === 'GENERAL') {
      this.generalNoteFilterText = '';
    } else {
      this.structuredNoteFilterText = '';
    }
  }

  selectNote(note: RR.VoiceNote, type: 'GENERAL' | 'STRUCTURE') {
    if (type === 'GENERAL') {
      this.generalNoteFilterText = note.text;
    } else {
      this.structuredNoteFilterText = note.text;
      if (this.subsectionName !== note.subsection) {
        this.subsectionName = note.subsection || 'NO NAME';
      }
    }
  }

  selectTemplateTagSearchTerm(searchTerm: RR.TagSearchTerm, type: 'GENERAL' | 'STRUCTURE') {
    if (type === 'GENERAL') {
      this.generalNoteFilterText = searchTerm.text;
    }
  }

  selectSubsectionTagSearchTerm(subsectionId: number, searchTerm: RR.TagSearchTerm, type: 'GENERAL' | 'STRUCTURE') {
    if (type === 'STRUCTURE') {
      this.structuredNoteFilterText = searchTerm.text;
      this.subscription.add(
        this.store.select(fromSubsection.selectSubsection(subsectionId)).subscribe((subsection) => {
          if (this.subsectionName !== subsection?.name) {
            this.subsectionName = subsection?.name || 'NO NAME';
          }
        }),
      );
    }
  }

  /**
   * Toggle the automatic updating of scores
   *
   */
  toggleAutoUpdate(auto?: boolean): void {
    this.store.dispatch(TagActions.toggleAutoUpdate({ auto }));
  }

  /**
   * Manual refresh tag scores
   */
  manualUpdateScores() {
    if (this.rrConfig?.ANALYTICS && this.topicId) {
      this.subscription.add(
        this.tagEffect
          .loadSuggested(this.topicId, this.section)
          .pipe(this.messageService.handleHttpErrorPipe)
          .subscribe(),
      );
    }
  }

  selectSubsectionTagSearchTerms = fromTagSearchTerm.selectInSubsection;

  dropTagSearchTerm(event: CdkDragDrop<unknown>) {
    if (!this.topic) {
      throw new Error('Topic undefined');
    }
    moveItemInArray(this.tagSearchTerms, event.previousIndex, event.currentIndex);
    this.subscription.add(
      this.templateEffect
        .update(this.topic.template_id, {
          tag_search_terms: this.tagSearchTerms.map((o) => o.id),
        })
        .subscribe(),
    );
  }

  dropTagSearchTermSubsection(event: CdkDragDrop<unknown>, subsectionId: number, tagSearchTerms: RR.TagSearchTerm[]) {
    if (!this.topic) {
      throw new Error('Topic undefined');
    }
    moveItemInArray(tagSearchTerms, event.previousIndex, event.currentIndex);
    this.subscription.add(
      this.subsectionEffect
        .update(subsectionId, {
          tag_search_terms: tagSearchTerms.map((o) => o.id),
        })
        .subscribe(),
    );
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.editorService.voiceNoteFocus$.subscribe(() => {
        this.voiceNoteGeneral.nativeElement.focus();
      }),
    );
  }

  triggerVoiceNoteFocus() {
    this.onVoiceMode.emit(true);
    this.editorService.triggerVoiceNoteFocus();
  }

  toggleVoiceMode() {
    if (this.userSetting) {
      this.subscription.add(
        this.userSettingEffect
          .update(this.userSetting.id, {
            voice_recognition_features: !this.userSetting.voice_recognition_features,
          })
          .subscribe(),
      );

      this.voiceService.stopListening();
    }
  }
}
