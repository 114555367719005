<!--Action button group-->

<div class="d-flex mb-1 btn-group justify-content-end statement-buttons">
  <button
    role="button"
    *ngIf="editType === 'createStatement' || editType === 'insertStatement' || editType === 'editStatement'"
    (click)="setDefaultStatement('KEY_FINDING', !form.controls.default_key_finding.value)"
    [ngClass]="form.controls.default_key_finding.value ? 'active' : ''"
    class="btn btn-primary btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="SET_DEFAULT"
  >
    <span class="fas fa-asterisk"></span> Key Finding
  </button>

  <button
    role="button"
    *ngIf="editType === 'createStatement' || editType === 'insertStatement' || editType === 'editStatement'"
    (click)="setDefaultStatement('COMMENT', !form.controls.default_comment.value)"
    [ngClass]="form.controls.default_comment.value ? 'active' : ''"
    class="btn btn-primary btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="SET_DEFAULT"
  >
    <span class="fas fa-comment"></span> Default Comment
  </button>

  <button
    role="button"
    *ngIf="editType === 'createStatement' || editType === 'insertStatement' || editType === 'editStatement'"
    (click)="setDefaultStatement('IMPRESSION_RECOMMENDATION', !form.controls.default_impression_recommendation.value)"
    [ngClass]="form.controls.default_impression_recommendation.value ? 'active' : ''"
    class="btn btn-primary btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="SET_DEFAULT"
  >
    <span class="fas fa-italic"></span> Default I&R
  </button>

  <button
    role="button"
    class="btn btn-primary btn-sm flex-grow-0"
    [class.active]="statement.tooltip"
    (click)="popoverTooltip.open()"
    *ngIf="editType === 'editStatement' && statement"
    rrTooltip="ADD_TOOLTIP_SENTENCE"
    [ngbPopover]="popContentTooltip"
    #popoverTooltip="ngbPopover"
    container="body"
    triggers="manual"
    [autoClose]="false"
  >
    <ng-template #popContentTooltip
      ><div>Tooltip</div>
      <textarea #tooltipTextarea class="textarea-section" rrAutoFocus>{{ statement.tooltip }}</textarea>

      <button class="btn btn-light" (click)="popoverTooltip.close()">Cancel</button>
      <button class="btn btn-success" (click)="saveStatementTooltip(tooltipTextarea.value)">Save</button>
    </ng-template>
    <span class="fas fa-comment-medical"></span> Tooltip
  </button>

  <button
    role="button"
    *ngIf="!isBlueEditType()"
    (click)="autoDecorate = !autoDecorate"
    [class.active]="autoDecorate"
    class="btn btn-primary btn-sm"
    rrTooltip="AUTO_DECORATE_SENTENCE"
  >
    <span class="fas fa-paint-brush"></span> Auto Decorate
  </button>
  <button
    role="button"
    *ngIf="statement"
    class="btn btn-primary btn-sm"
    [class.active]="mandatoryStatement"
    (click)="popoverMandatory.open()"
    rrTooltip="MANDATORY_STATEMENT"
    [ngbPopover]="popContentMandatory"
    #popoverMandatory="ngbPopover"
    container="body"
    triggers="manual"
    [autoClose]="false"
    [placement]="'bottom'"
  >
    <ng-template #popContentMandatory>
      <ng-container *ngIf="mandatoryStatement; else saveTemplate">
        <button class="btn btn-light" (click)="popoverMandatory.close()">Cancel</button>
        <button class="btn btn-danger" (click)="onClickDeleteMandatory()">Delete</button>
      </ng-container>
      <ng-template #saveTemplate>
        <div>Why is this mandatory?</div>
        <textarea
          #mandatoryTextarea
          class="textarea-section"
          rrAutoFocus
          placeholder="Reason..."
          [value]="mandatoryStatement?.reason ?? ''"
        ></textarea>
        <button class="btn btn-light" (click)="popoverMandatory.close()">Cancel</button>
        <button
          class="btn btn-success"
          (click)="onClickMandatory({ statement: statement, reason: mandatoryTextarea.value })"
        >
          Save
        </button>
      </ng-template>
    </ng-template>
    <span class="fas fa-exclamation"></span> Mandatory
  </button>

  <button
    role="button"
    *ngIf="
      editType === 'createStatement' ||
      editType === 'insertStatement' ||
      editType === 'editStatement' ||
      editType === 'insertNotepadStatement' ||
      editType === 'createNotepadStatement'
    "
    (click)="setDefaultStatement('DIVIDER', !form.controls.is_divider.value)"
    [ngClass]="form.controls.is_divider.value ? 'active' : ''"
    class="btn btn-primary btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="SET_DIVIDER"
  >
    <span class="fas fa-anchor"></span> Group
  </button>
</div>

<div class="d-flex mb-1 btn-group justify-content-start statement-buttons">
  <button
    role="button"
    *ngIf="!isBlueEditType()"
    (click)="capitaliseWholeSentence()"
    class="btn btn-warning btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="CAPITALISE_SENTENCE"
  >
    <span class="fas fa-text-height"></span> Capitalise
  </button>
  <button
    role="button"
    *ngIf="!isBlueEditType()"
    (click)="decorateSentence()"
    class="btn btn-primary btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="DECORATE_SENTENCE"
  >
    <span class="fas fa-paint-brush"></span> Decorate
  </button>
  <button
    role="button"
    *ngIf="!isBlueEditType()"
    (click)="upperTitle(); $event.stopPropagation()"
    class="btn btn-warning btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="UPPER_TITLE"
  >
    <span class="fas fa-font"></span> Title case
  </button>

  <button
    role="button"
    *ngIf="!isBlueEditType()"
    (click)="lowerSentence(); $event.stopPropagation()"
    class="btn btn-warning btn-sm"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="LOWER_CASE_SENTENCE"
  >
    <span class="fas fa-font"></span> Lower case
  </button>
</div>

<div class="d-flex mb-1 btn-group justify-content-start statement-buttons">
  <button
    class="btn btn-sm btn-info"
    (click)="popoverStatementBuilder.open()"
    [ngbPopover]="popoverStatementBuilderContent"
    #popoverStatementBuilder="ngbPopover"
    container="body"
    triggers="manual"
    [autoClose]="'outside'"
    [placement]="'bottom'"
    [popoverClass]="statementBuilderPopoverClass"
    (shown)="onStatementBuilderPopoverShown()"
    rrTooltip="STATEMENT_BUILDER"
    *ngIf="editType === 'createStatement' || editType === 'editStatement'"
  >
    <ng-template #popoverStatementBuilderContent>
      <div
        class="d-flex justify-content-between align-items-center border-bottom my-1"
        *ngFor="let statement of statementBuilderStatements"
      >
        <rr-statement-preview
          *ngIf="statementBuilderTemplateId"
          [statement_id]="statement.id"
          [region]="region"
          [topic]="topic"
          [template_id]="statementBuilderTemplateId"
          (click)="insertIntoStatementBuilder(statement.id); popoverStatementBuilder.close()"
        ></rr-statement-preview>
        <button class="btn btn-sm ms-2" (click)="deleteStatementFromStatementBuilder(statement.id)">
          <span class="fa fa-trash"></span>
        </button>
      </div>
    </ng-template>
    <span><i class="fa fa-comment-dots"></i></span> Builders
  </button>

  <button
    class="btn btn-sm btn-info"
    (click)="createStatementToStatementBuilder()"
    rrTooltip="STATEMENT_BUILDER_SAVE"
    *ngIf="editType === 'createStatement'"
  >
    <span><i class="fa fa-save"></i></span> Create Builder
  </button>

  <ng-template #popoverInsertDate class="insert-date">
    <button
      rrVoice
      [source]="'DATE_SEARCH'"
      class="btn btn-sm btn-info d-block w-100"
      *ngIf="editType === 'createStatement' || editType === 'editStatement'"
      (click)="insertDateAttribute('day_month_year')"
      rrTooltip="INSERT_DATE_ATTRIBUTE"
    >
      dd/mm/yyyy
    </button>
    <button
      rrVoice
      [source]="'DATE_SEARCH'"
      class="btn btn-sm btn-info d-block w-100 my-1"
      *ngIf="editType === 'createStatement' || editType === 'editStatement'"
      (click)="insertDateAttribute('month_year')"
      rrTooltip="INSERT_DATE_ATTRIBUTE"
    >
      mm/yyyy
    </button>
    <button
      rrVoice
      [source]="'DATE_SEARCH'"
      class="btn btn-sm btn-info d-block w-100"
      *ngIf="editType === 'createStatement' || editType === 'editStatement'"
      (click)="insertDateAttribute('year')"
      rrTooltip="INSERT_DATE_ATTRIBUTE"
    >
      yyyy
    </button>
  </ng-template>
  <button type="button" class="btn btn-sm btn-primary" [ngbPopover]="popoverInsertDate" [placement]="'bottom'">
    Insert Date
  </button>

  <button
    role="button"
    *ngIf="!isBlueEditType()"
    (click)="autoAttributifyStatement()"
    class="btn btn-warning btn-sm disabled pe-auto"
    permission
    [permission-only]="['superuser']"
    rrTooltip="ADD_ATTRIBUTE"
    ngbTooltip="Not working"
  >
    <span class="fas fa-magic"></span> Attributify
  </button>

  <button
    role="button"
    *ngIf="!!statement"
    (click)="onDelete.emit()"
    class="btn btn-danger btn-sm flex-grow-0"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="DELETE_STATEMENT"
  >
    <span class="fas fa-trash"></span> Delete
  </button>
  <button
    role="button"
    *ngIf="onClone.observers.length"
    (click)="doClone()"
    class="btn btn-primary btn-sm flex-grow-0"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="CLONE_STATEMENT"
  >
    <span class="fas fa-clone"></span> Clone
  </button>
  <button
    role="button"
    *ngIf="onCreateAndClone.observers.length"
    (click)="doCreateAndClone()"
    class="btn btn-primary btn-sm flex-grow-0"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="CREATE_AND_CLONE_STATEMENT"
  >
    <span class="fas fa-clone"></span> Create and Clone
  </button>
  <button
    role="button"
    *ngIf="onCopy.observers.length && editType === 'editNotepadStatement'"
    (click)="onCopy.emit()"
    class="btn btn-primary btn-sm flex-grow-0 disabled pe-auto"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="COPY_STATEMENT_TO_TEMPLATE"
    ngbTooltip="Not working"
  >
    <span class="fas fa-copy"></span> Copy to template
  </button>

  <button
    role="button"
    ngbAutoFocus
    (click)="onApprove.emit(getFinalText())"
    class="btn btn-success btn-sm"
    *ngIf="onApprove.observers.length"
    permission
    [permission-only]="['template_manage']"
    rrTooltip="APPROVE"
  >
    <span class="fas fa-check"></span> Approve and Edit
  </button>
  <button
    *ngIf="editType === 'editStatement' || editType === 'editNotepadStatement'"
    (click)="resetStatement()"
    role="button"
    class="btn btn-sm btn-secondary"
    rrTooltip="RESET_STATEMENT"
  >
    Reset
  </button>

  <button
    rrTooltip="INSERT_GLOBAL_SEARCH_TEXT"
    *ngIf="editType === 'createBlueStatement'"
    class="btn btn-primary"
    (click)="insertGlobalSearchText(); $event.preventDefault(); $event.stopPropagation()"
  >
    <i class="fas fa-globe me-1"></i>Insert Global Text
  </button>
  <button role="button" class="btn btn-sm btn-secondary" (click)="saveSearch()" rrTooltip="SAVE_SEARCH">
    <i class="fas fa-comment"></i>
    Save Search
  </button>
  <button (click)="doCancel()" role="button" class="btn btn-sm btn-danger" rrTooltip="CANCEL">Cancel</button>
  <button
    role="button"
    (click)="doSubmit({ focus: true, dismissModal: suggestedDropdown.isOpen() })"
    class="btn btn-sm"
    [ngClass]="editType === 'createBlueStatement' || editType === 'editBlueStatement' ? 'btn-info' : 'btn-success'"
    rrTooltip="SUBMIT"
  >
    Submit
  </button>
  <rr-voice-recognition-text [parent]="'SOABS'" *ngIf="editType === 'createBlueStatement'"></rr-voice-recognition-text>
  <!-- Everything except "createBlueStatement" -->
  <button
    *ngIf="
      editType === 'createStatement' ||
      editType === 'insertStatement' ||
      editType === 'createElement' ||
      editType === 'createNotepadStatement' ||
      editType === 'insertNotepadStatement' ||
      editType === 'editStatement' ||
      editType === 'editNotepadStatement' ||
      editType === 'editBlueStatement'
    "
    role="button"
    (click)="doSubmit()"
    class="btn btn-sm"
    [ngClass]="editType === 'editBlueStatement' ? 'btn-info' : 'btn-success'"
    rrTooltip="SUBMIT_AND_CLOSE"
  >
    Submit and Close
  </button>
</div>

<div class="container-fluid p-0">
  <ng-container *ngIf="editType === 'editStatement' || editType === 'editNotepadStatement'">
    <rr-slatement-angular
      data-cy="textbox"
      #slatementAngularComponent
      *ngIf="statement"
      class="mb-2"
      [editType]="editType"
      [templateId]="templateId"
      [region]="region || null"
      [statementId]="statement.id"
      [devMode]="false"
      (slateOnChange)="slateOnChangeCallback($event)"
      (slateOnEditor)="slateOnEditorCallback($event)"
      [attributeGroup]="attributeGroup"
    ></rr-slatement-angular>
  </ng-container>
  <ng-container
    *ngIf="
      editType === 'createStatement' ||
      editType === 'insertStatement' ||
      editType === 'createElement' ||
      editType === 'createNotepadStatement' ||
      editType === 'insertNotepadStatement'
    "
  >
    <!-- No statementId in this case -->
    <rr-slatement-angular
      data-cy="textbox"
      class="mb-2"
      [editType]="editType"
      [templateId]="templateId"
      [region]="region || null"
      [devMode]="false"
      (slateOnChange)="slateOnChangeCallback($event)"
      (slateOnEditor)="slateOnEditorCallback($event)"
    ></rr-slatement-angular>
  </ng-container>
  <ng-container *ngIf="editType === 'createBlueStatement' || editType === 'editBlueStatement'">
    <textarea
      rrVoice
      [source]="'BLUE_TEXTBOX'"
      data-cy="blue-textbox"
      class="form-control textarea rounded-0"
      rrTooltip="BLUE_STATEMENT_DESC"
      #blueTextInput
    ></textarea>
  </ng-container>

  <!--Create blue sentence navigate to global search-->
  <div
    #suggestedDropdown="ngbDropdown"
    ngbDropdown
    [autoClose]="'outside'"
    placement="bottom"
    (openChange)="openChange($event)"
    popupClass="statement-suggestion-dropdown"
    data-cy="statement-suggestion-dropdown"
  >
    <div ngbDropdownAnchor class="no-dropdown-toggle"></div>
    <ul ngbDropdownMenu class="scrollable-dropdown mw-100">
      <ng-container *ngIf="suggested_statements.length > 0">
        <rr-last-clicked-breadcrumb
          *ngIf="lastClickedBreadcrumb$ | async as lastClickedBreadcrumb"
          [lastClickedBreadcrumb]="lastClickedBreadcrumb"
        ></rr-last-clicked-breadcrumb>
        <li
          class="dropdown-item"
          role="button"
          style="max-width: 50vw"
          *ngFor="let statement of suggested_statements; let i = index"
          (click)="clickStatementSuggestion(statement)"
        >
          <button
            rrTooltip="GLOBAL_STATEMENT_SEARCH"
            class="btn btn-sm btn-outline-primary me-1"
            (click)="handleGlobalStatementSearch(statement._source.text); $event.stopPropagation()"
          >
            <i class="fas fa-search fa-sm"></i>
          </button>
          <button
            rrTooltip="OPEN_GROUP_MODAL"
            class="btn btn-sm btn-outline-primary me-1"
            (click)="openDividerStatementsModal(i); $event.stopPropagation()"
          >
            <i class="fas fa-eye fa-sm"></i>
          </button>
          <span class="badge bg-info">{{ getSectionTitle(statement._source.section) }}</span>
          <ng-container *ngIf="statement.highlight?.text">
            <span *ngFor="let highlight of statement.highlight.text" [innerHTML]="highlight"></span>
          </ng-container>
          <span *ngIf="!statement.highlight?.text" [innerHTML]="statement._source.text"></span>
        </li>
      </ng-container>
      <ng-container *ngIf="suggested_statements.length === 0">
        <li class="dropdown-item">
          <span class="text-secondary">No statements found</span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
