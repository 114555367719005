<div class="modal-header cursor-move" cdkDrag cdkDragHandle cdkDragRootElement=".modal-content">
  <h5 class="modal-title">Signature Dialogue Box (S)</h5>
  <button role="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body" *ngIf="topic$ | async as topic">
  <ng-container *ngIf="loading">
    <div class="d-flex justify-content-center align-items-center">
      <i class="fa fa-spinner fa-spin fa-2x text-secondary"></i>
      <span class="ms-2">Loading...</span>
    </div>
  </ng-container>

  <div *ngIf="report">
    <button
      role="button"
      class="btn btn-sm mb-1 btn-light"
      (click)="openImgsimParameters()"
      rrTrack
      rrTooltip="SHOW_IMG_SIMILARITY_PARAMETERS"
    >
      <span>(^) Image Similarity Parameters</span>
    </button>

    <button
      rr-add-other-imaging-button
      class="mb-1"
      [topic]="topic"
      [report]="report"
      [btnSm]="true"
      [btnLight]="true"
      rrTrack
      rrTooltip="COPY_REPORT_HEADINGS_AND_KEY_FINDINGS"
      (click)="activeModal.dismiss()"
    ></button>
  </div>

  <div *ngIf="!loading && signature_role === 'DOCTOR'" class="mb-2">
    <div>
      <strong>Provider number: </strong>
      <ng-container *ngIf="providerNumber$ | async as providerNumber; else noProvNo">
        <rr-user-headline *ngIf="providerNumberName$ | async as user" [user]="user"></rr-user-headline>
        {{ providerNumber.medicare_provider_number }}
        {{ (site$ | async)?.short_name ?? '' }}
      </ng-container>
      <ng-template #noProvNo>None</ng-template>
    </div>
    <div>
      <strong>Signature: </strong>
      <ng-container *ngIf="signature$ | async as signature; else noSig">
        <ng-container *ngFor="let user of signature.user_ids">
          <span>{{ getInitials(user) | async }}</span>
        </ng-container>
        <ng-container *ngIf="signature.site_id && (getSite(signature.site_id) | async) as site">
          <span> - {{ site.short_name }} </span>
        </ng-container>
      </ng-container>
      <ng-template #noSig>None</ng-template>
    </div>
  </div>

  <ng-container *ngIf="signature_role === 'DOCTOR' && !loading">
    <form class="form-group mb-2" [formGroup]="searchForm">
      <!-- add .button-group-toggle to hide checks/radios -->
      <h4>Site:</h4>
      <div role="group" name="siteRadio" class="btn-group d-flex align-items-center mb-2">
        <ng-container *ngFor="let site of sites">
          <input
            id="signature-site-{{ site.id }}"
            class="btn-check"
            formControlName="site_id"
            type="radio"
            [value]="site.id"
          />

          <label for="signature-site-{{ site.id }}" class="btn btn-outline-primary" [title]="site.name"
            >{{ site.short_name }}
          </label>
        </ng-container>

        <input id="signature-site-any" class="btn-check" type="radio" formControlName="site_id" [value]="-1" />
        <label for="signature-site-any" class="btn btn-outline-primary"> Any </label>
      </div>

      <h4>Doctor:</h4>
      <div formArrayName="doctors">
        <label
          class="btn btn-primary ms-1"
          *ngFor="let doctor of searchForm.controls.doctors.controls; let i = index"
          [formGroupName]="i"
        >
          <input type="checkbox" formControlName="checked" />
          {{ doctor.value.initials }}
        </label>
      </div>
    </form>

    <div>
      <div class="list-group">
        <button
          role="button"
          class="list-group-item list-group-item-action text-start d-block"
          *ngFor="let signature of doctorSignatures"
          (click)="chooseDoctorSignature(signature)"
          [class.active]="topic.signature_id === signature.id"
        >
          <div *ngFor="let userId of signature.user_ids" class="badge bg-secondary ms-1 float-end">
            <rr-user-headline
              *ngIf="userId | storeSelectPipe: userSelectorFn | async as user"
              [user]="user"
            ></rr-user-headline>
          </div>
          <div
            *ngIf="signature.site_id && (getSite(signature.site_id) | async) as site"
            class="badge bg-dark ms-1 float-end"
            [title]="site.name"
          >
            {{ site.short_name }}
          </div>
          <div [innerText]="signature.text"></div>
        </button>
      </div>
    </div>
  </ng-container>

  <!--Radiology registrar-->
  <ng-container *ngIf="signature_role === 'RADIOLOGY_REGISTRAR' && !loading">
    <!--Radiology Registrar Signature-->
    <div *ngIf="radiologyRegistrarSignatures.length > 0">
      <div class="d-flex">
        <strong>Radiology Registrar Signatures:</strong>
        <button
          *ngIf="topic.radiology_registrar_signature_text"
          role="button"
          class="btn btn-sm btn-danger ms-auto"
          (click)="clearSignature('RADIOLOGY_REGISTRAR')"
        >
          <span class="fa fa-trash"></span> Clear
        </button>
      </div>
      <div class="list-group mt-1">
        <button
          role="button"
          class="list-group-item list-group-item-action text-start d-block"
          *ngFor="let signature of radiologyRegistrarSignatures"
          (click)="chooseTechnicianSignature(signature, 'RADIOLOGY_REGISTRAR')"
          [class.active]="topic.radiology_registrar_signature_user_id === signature.user_id"
        >
          <div
            class="badge ms-1 float-end"
            [ngClass]="currentUserId === signature.user_id ? 'bg-success' : 'bg-secondary'"
          >
            <rr-user-headline
              *ngIf="signature.user_id | storeSelectPipe: userSelectorFn | async as user"
              [user]="user"
            ></rr-user-headline>
          </div>
          <div [innerText]="signature.text"></div>
        </button>
      </div>
    </div>
  </ng-container>

  <!--Technician signature-->
  <ng-container *ngIf="(signature_role === 'JUNIOR_TECHNICIAN' || signature_role === 'TECHNICIAN') && !loading">
    <!--Junior Sonographer/Radiographer Signature-->
    <div *ngIf="juniorSignatures.length > 0">
      <div class="d-flex">
        <strong>{{ technician_role === 'sonographer' ? 'Sonographer' : 'Radiographer' }} Assistant Signatures:</strong>
        <button
          *ngIf="topic.junior_signature_text"
          role="button"
          class="btn btn-sm btn-danger ms-auto"
          (click)="clearSignature('JUNIOR_TECHNICIAN')"
        >
          <span class="fa fa-trash"></span> Clear
        </button>
      </div>
      <div class="list-group mt-1">
        <button
          role="button"
          class="list-group-item list-group-item-action text-start d-block"
          *ngFor="let signature of juniorSignatures"
          (click)="chooseTechnicianSignature(signature, 'JUNIOR_TECHNICIAN')"
          [class.active]="topic.junior_signature_user_id === signature.user_id"
        >
          <div
            class="badge ms-1 float-end"
            [ngClass]="currentUserId === signature.user_id ? 'bg-success' : 'bg-secondary'"
          >
            <rr-user-headline
              *ngIf="signature.user_id | storeSelectPipe: userSelectorFn | async as user"
              [user]="user"
            ></rr-user-headline>
          </div>
          <div [innerText]="signature.text"></div>
        </button>
      </div>
      <div class="my-4"></div>
    </div>
    <div *ngIf="radiologyRegistrarSignatures.length > 0">
      <div class="d-flex">
        <strong>Radiology Registrar Signatures:</strong>
        <button
          *ngIf="topic.radiology_registrar_signature_text"
          role="button"
          class="btn btn-sm btn-danger ms-auto"
          (click)="clearSignature('RADIOLOGY_REGISTRAR')"
        >
          <span class="fa fa-trash"></span> Clear
        </button>
      </div>
      <div class="list-group mt-1">
        <button
          role="button"
          class="list-group-item list-group-item-action text-start d-block"
          *ngFor="let signature of radiologyRegistrarSignatures"
          (click)="chooseTechnicianSignature(signature, 'RADIOLOGY_REGISTRAR')"
          [class.active]="topic.radiology_registrar_signature_user_id === signature.user_id"
        >
          <div
            class="badge ms-1 float-end"
            [ngClass]="currentUserId === signature.user_id ? 'bg-success' : 'bg-secondary'"
          >
            <rr-user-headline
              *ngIf="signature.user_id | storeSelectPipe: userSelectorFn | async as user"
              [user]="user"
            ></rr-user-headline>
          </div>
          <div [innerText]="signature.text"></div>
        </button>
      </div>
      <div class="my-4"></div>
    </div>
  </ng-container>
  <ng-container *ngIf="(signature_role === 'JUNIOR_TECHNICIAN' || signature_role === 'TECHNICIAN') && !loading">
    <!--Imaging Technician Signature-->
    <div *ngIf="imagingTechSignatures.length > 0" class="mb-3">
      <div class="d-flex">
        <strong> Imaging Technician Signatures:</strong>
        <button
          *ngIf="
            topic.technician_signature_text &&
            checkContainTechnicianSignature(imagingTechSignatures, topic.technician_signature_text)
          "
          role="button"
          class="btn btn-sm btn-danger ms-auto"
          (click)="clearSignature('TECHNICIAN')"
        >
          <span class="fa fa-trash"></span> Clear
        </button>
      </div>
      <div class="list-group mt-1">
        <button
          role="button"
          class="list-group-item list-group-item-action text-start d-block"
          *ngFor="let signature of imagingTechSignatures"
          (click)="chooseTechnicianSignature(signature, 'TECHNICIAN')"
          [class.active]="topic.technician_signature_user_id === signature.user_id"
        >
          <div
            class="badge ms-1 float-end"
            [ngClass]="currentUserId === signature.user_id ? 'bg-success' : 'bg-secondary'"
          >
            <rr-user-headline
              *ngIf="signature.user_id | storeSelectPipe: userSelectorFn | async as user"
              [user]="user"
            ></rr-user-headline>
          </div>
          <div [innerText]="signature.text"></div>
        </button>
      </div>
    </div>
    <!--Sonographer/Radiographer Signature-->
    <div>
      <div class="d-flex">
        <strong>{{ technician_role === 'sonographer' ? 'Sonographer' : 'Radiographer' }} Signatures: </strong>
        <button
          *ngIf="
            topic.technician_signature_text &&
            checkContainTechnicianSignature(seniorSignatures, topic.technician_signature_text)
          "
          role="button"
          class="btn btn-sm btn-danger ms-auto"
          (click)="clearSignature('TECHNICIAN')"
        >
          <span class="fa fa-trash"></span> Clear
        </button>
      </div>
      <div class="list-group mt-1">
        <button
          role="button"
          class="list-group-item list-group-item-action text-start d-block"
          *ngFor="let signature of seniorSignatures"
          (click)="chooseTechnicianSignature(signature, 'TECHNICIAN')"
          [class.active]="topic.technician_signature_user_id === signature.user_id"
        >
          <div
            class="badge ms-1 float-end"
            [ngClass]="currentUserId === signature.user_id ? 'bg-success' : 'bg-secondary'"
          >
            <rr-user-headline
              *ngIf="signature.user_id | storeSelectPipe: userSelectorFn | async as user"
              [user]="user"
            ></rr-user-headline>
          </div>
          <div [innerText]="signature.text"></div>
        </button>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-footer d-flex justify-content-between">
  <div class="d-flex gap-1">
    <button *ngIf="signature_role === 'DOCTOR'" role="button" class="btn btn-primary" (click)="openCheckReport()">
      Check Report (C)
    </button>
    <ng-container *ngIf="!parent">
      <button rrAutoFocus role="button" class="btn btn-primary" (click)="openPrefill()" rrTooltip="NAVIGATOR_PREFILL">
        <span>Back to Prefill (B)</span>
      </button>
    </ng-container>
    <ng-container *ngIf="parent === 'FINAL_PREVIEW'">
      <rr-send-voyager-button
        [report_id]="report_id"
        [delay]="false"
        [focus]="true"
        (onSentToVoyager)="onSentToVoyager($event)"
      >
      </rr-send-voyager-button>
    </ng-container>
  </div>

  <button class="btn btn-outline-secondary" role="button" (click)="close()">Close</button>
</div>
