import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface FetchSlotParams {
  start_date: string | undefined;
  end_date: string | undefined;
  booking_code_id: number | undefined;
  site_id: number | undefined;
  doctor_preferred: string | undefined;
  gender_preferred: string | undefined;
  doctor_id: number | undefined;
  ignore_booking_id: number | undefined;
  user_id: number | undefined;
}

export type CreateBookingBody = {
  booking: PartialBooking;
  booking_patient?: Partial<RR.BookingPatient>;
  billing_items?: number[];
  booking_enquiry_id?: number;
  booking_id?: number;
};

// PutBookingSchema booking_update
export type UpdateBookingBody = Partial<
  Pick<RR.Booking, 'notes' | 'booking_code_id' | 'funder' | 'site_id' | 'patient_id' | 'arrived_time' | 'billing_items'>
>;

export type PartialBooking = Omit<Partial<RR.Booking>, 'booking_patient'> & {
  booking_patient?: Partial<RR.BookingPatient>;
  enquiry_id?: number;
  referral_enquiry_id?: number;
  billing_items?: number[];
};

export type BookingError = {
  message: string;
  code: string;
};

export interface BookingHttpError extends HttpErrorResponse {
  error: { errors: BookingError[] };
}

export type SameDayBookingResponse = {
  bookings: RR.Booking[];
  reports: RR.Report[];
};

export type BookingSearchHttpResponse = {
  count: number;
  bookings: RR.Booking[];
  send_events: RR.SendEvent[];
  audit_events: RR.AuditEvent[];
  reports: RR.Report[];
  users: RR.User[];
  patients: RR.Patient[];
  invoices: RR.Invoice[];
};

export type BookingSearchFilterForm = {
  text?: string | null;
  sites?: number[];
  modalities?: string[];
  booked_to?: number | null;
  start_date: string | null | undefined;
  end_date: string | null | undefined;
  cancelled?: boolean | null;
  draft?: boolean | null;
  offset: string | null;
  limit: string | null;
};

export type SiteFormType = {
  id: number | undefined;
  name: string | undefined;
  short_name: string | undefined;
  checked: boolean | undefined;
};

export type ModalityFormType = {
  name: string | undefined;
  checked: boolean | undefined;
};

export type GetBookingResponse = {
  booking: RR.Booking;
  send_events: RR.SendEvent[];
  reports: RR.Report[];
};

export type SendBookingConfirmSMSResponse = {
  booking: RR.Booking;
  send_event: RR.SendEvent;
};

@Injectable()
export class BookingHttpService {
  constructor(private http: HttpClient) {}

  find(bookingId: number): Observable<GetBookingResponse> {
    return this.http.get<GetBookingResponse>(`/api/booking/${bookingId}`);
  }

  create(data: CreateBookingBody, skipValidations?: string[]): Observable<RR.Booking> {
    if (skipValidations && skipValidations.length) {
      return this.http.post<RR.Booking>('/api/booking', data, {
        params: { skip_validations: skipValidations.join(',') },
      });
    }
    return this.http.post<RR.Booking>('/api/booking', data);
  }

  update(id: number, changes: UpdateBookingBody): Observable<RR.Booking> {
    return this.http.put<RR.Booking>(`/api/booking/${id}`, changes);
  }

  findAvailableSlots(slotData: FetchSlotParams) {
    return this.http.post<{ slots: RR.SlotGroup[]; users: RR.User[] }>('/api/available_slots', slotData);
  }

  machineServices(params: HttpParams): Observable<{ machine_services: RR.MachineService[] }> {
    return this.http.get<{ machine_services: RR.MachineService[] }>('/api/radres/machine_services', { params });
  }

  search(queryBody: BookingSearchFilterForm): Observable<BookingSearchHttpResponse> {
    return this.http.post<BookingSearchHttpResponse>('/api/bookings', queryBody);
  }

  searchPatientSameDayBookings(bookingId: number): Observable<SameDayBookingResponse> {
    return this.http.get<SameDayBookingResponse>(`/api/booking/${bookingId}/same_day`);
  }

  delete(id: number, body: { password: string; cancelled_notes: string }): Observable<RR.Booking> {
    return this.http.delete<RR.Booking>(`/api/booking/${id}`, { body: body });
  }

  updateBookingPatient(id: number, changes: Partial<Omit<RR.BookingPatient, 'id'>>): Observable<RR.BookingPatient> {
    return this.http.put<RR.BookingPatient>(`/api/booking_patient/${id}`, changes);
  }

  sendBookingConfirmationSMS(
    bookingId: number,
    sendTo: string,
    message: string,
  ): Observable<SendBookingConfirmSMSResponse> {
    return this.http.post<SendBookingConfirmSMSResponse>(`/api/booking/send_sms`, {
      booking_id: bookingId,
      send_to: sendTo,
      message: message,
    });
  }

  sendBookingPreparationSMS(
    bookingId: number,
    sendTo: string,
    message: string,
  ): Observable<SendBookingConfirmSMSResponse> {
    return this.http.post<SendBookingConfirmSMSResponse>(`/api/booking/send_sms`, {
      booking_id: bookingId,
      send_to: sendTo,
      message: message,
    });
  }

  restoreCancelledBooking(bookingId: number) {
    return this.http.post<RR.Booking>(`/api/restore_booking/${bookingId}`, {});
  }
}
