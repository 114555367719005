import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'rr-patient-form-view',
  templateUrl: './patient-form-view.component.html',
  styleUrls: ['./patient-form-view.component.css'],
})
export class PatientFormViewComponent implements OnInit {
  @Input() patient: RR.Patient | undefined;
  @Input() invalid: boolean;
  @Output() onClear = new EventEmitter();
  onClearObserved = false;

  ngOnInit(): void {
    // Check if onClear has any event listeners
    if (this.onClear.observed) {
      this.onClearObserved = true;
    }
  }
}
