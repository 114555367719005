import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

import { AppState } from '../app.state';
import { ScanCodeActions } from './scan-code.action';
import { fromScanCode } from './scan-code.selector';
import { ScanCodeHttpService } from './scan-code.service';

@Injectable()
export class ScanCodeEffect {
  constructor(
    private store: Store<AppState>,
    private service: ScanCodeHttpService,
  ) {}

  findAll() {
    return this.store.select(fromScanCode.selectLoaded).pipe(
      take(1),
      filter((loaded) => !loaded),
      switchMap(() => this.service.findAll()),
      map((scanCodes: RR.ScanCode[]) => ScanCodeActions.findAllSuccess({ scanCodes })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  create(scanCode: Partial<RR.ScanCode>) {
    return this.service.create(scanCode).pipe(
      map((scanCode: RR.ScanCode) => ScanCodeActions.createSuccess({ scanCode })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  update(scanCodeId: number, changes: Partial<RR.ScanCode>) {
    return this.service.update(scanCodeId, changes).pipe(
      map((scanCode) => ScanCodeActions.updateSuccess({ scanCode })),
      tap((action) => this.store.dispatch(action)),
    );
  }

  delete(scanCodeId: number) {
    return this.service.delete(scanCodeId).pipe(
      // Soft delete for scan code
      map((scanCode) => ScanCodeActions.updateSuccess({ scanCode })),
      tap((action) => this.store.dispatch(action)),
    );
  }
}
