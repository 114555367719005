import { props } from '@ngrx/store';

import { createBatchAction } from '../batching.action';
import { DefaultAttributeActions } from '../template/default-attribute/default-attribute.action';
import { TemplateBatchActions } from '../template/template/template.action';
import { CorrelatedStatement } from './correlated-statement-search.service';

const findCorrelatedSuccess = createBatchAction(
  '[Topic] Find Correlated Success Batch',
  props<{
    suggested_statements: CorrelatedStatement[];
    actions: {
      findTemplateDataSuccess: ReturnType<typeof TemplateBatchActions.findTemplateDataSuccess>;
      findDefaultAttributeSuccess: ReturnType<typeof DefaultAttributeActions.findAllSuccess>;
    };
  }>(),
);

export const CorrelatedSearchActions = {
  findCorrelatedSuccess,
};
