<ng-container *ngIf="!reportChoice">
  <div class="d-flex item-hover-parent">
    <div class="btn-link">
      <span
        *ngIf="getChoiceText(statementChoice) | async as choiceText"
        [linkify]="choiceText"
        class="form-check-label"
        (click)="onClick.emit()"
      >
      </span>
      <span class="fa fa-asterisk ms-1 text-danger" *ngIf="isKeyFinding"></span>
      <span class="fa fa-comment ms-1 text-danger" *ngIf="isComment"></span>
      <span class="fa fa-italic ms-1 text-danger" *ngIf="isImpression"></span>

      <!-- <span
      *ngIf="is_in_sc_mode$ | async"
      class="ms-1 fa fa-layer-group"
      role="button"
      [ngClass]="(checkSentenceCategories(statementChoice) | async) ? 'text-success' : 'text-danger'"
      [title]="getSentenceCategoriesTooltip(statementChoice) | async"
      (click)="categoriseSentence(statementChoice)"
    >
    </span> -->
    </div>
    <button
      class="ms-auto btn btn-sm btn-light item-hover text-nowrap align-self-start"
      permission
      [permission-only]="['template_manage']"
      (click)="editStatement()"
      rrTooltip="PREFILL_EDIT_STATEMENT"
    >
      <i class="fas fa-edit"></i> Edit
    </button>
  </div>
</ng-container>

<ng-container *ngIf="reportChoice && parents && parents.subsection">
  <rr-report-choice
    [ngbPopover]="parents.statement.tooltip"
    triggers="mouseenter:mouseleave"
    [disablePopover]="!parents.statement.tooltip"
    [choice]="reportChoice"
    [statement]="parents.statement"
    [topic]="topic"
    [element]="parents.element"
    [subsection]="parents.subsection"
    [section]="parents.section"
    [region]="parents.region"
    [parentComponent]="'PREFILL_COPY'"
    class="px-1 py-0 border-0 d-flex justify-content-between list-group-item"
    [ngClass]="parents.isSuggestion ? 'list-group-item-success' : 'list-group-item-info'"
  >
  </rr-report-choice>
</ng-container>
