import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import { TOOLTIPS } from 'app/app.constants';
import { filterDefined } from 'app/app.utils';
import { escapeRegex } from 'app/shared/utils/shared.utils';
import { fromAppSelector } from 'app/store/app.selector';
import { AppState } from 'app/store/app.state';
import { fromCategory } from 'app/store/category';
import { fromCategoryStatementCombo } from 'app/store/category-statement-combo';
import { fromTopic } from 'app/store/report/topic';
import { fromAttributeOption } from 'app/store/template/attribute-option';
import { fromAttributeSet } from 'app/store/template/attribute-set';
import { DefaultAttributeEffect, fromDefaultAttribute } from 'app/store/template/default-attribute';
import { ElementEffect, fromElement } from 'app/store/template/element';
import { fromRegion } from 'app/store/template/region';
import { fromRegionSet } from 'app/store/template/region-set';
import { fromStatement, StatementCreateEffect, StatementEffect } from 'app/store/template/statement';
import { fromStatementSet, StatementSetEffect } from 'app/store/template/statement-set';
import { fromSubsection } from 'app/store/template/subsection';
import { fromCurrentTemplate, fromTemplate } from 'app/store/template/template';
import { fromTitleOption } from 'app/store/title/title-option';
import { fromTitleSet } from 'app/store/title/title-set';
import { uniqBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of as observableOf, of } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mergeMap,
  shareReplay,
  switchMap,
  take,
} from 'rxjs/operators';

export interface ICategorisedAttribute {
  attribute_option_id: number;
  text_object_id: number;
}

export interface IElement {
  element: RR.Element;
  subsection: RR.Subsection;
  statement_set: RR.StatementSet;
  region_set: RR.RegionSet;
  section: RR.Section;
}

export interface SectionMeta {
  name: RR.ReportSection; // element enum lookup
  title: string; // displayed title
  abbrTitle: string; // short title
  position: number; // sort order in editor
}

const REPORT_SECTIONS: readonly SectionMeta[] = [
  { name: 'history', title: 'History', abbrTitle: 'History', position: 0 },
  { name: 'technique', title: 'Technique', abbrTitle: 'Technique', position: 1 },
  {
    name: 'findings',
    title: 'Findings in Detail',
    abbrTitle: 'Findings',
    position: 2,
  },
  {
    name: 'impression_recommendations',
    title: 'Impression and Recommendations',
    abbrTitle: 'Impression',
    position: 3,
  },
  { name: 'comment', title: 'Comment', abbrTitle: 'Comment', position: 4 },
] satisfies { name: RR.TemplateSection; [k: string]: unknown }[]; // because there's no name: 'key_finding' in this list

const KEY_FINDING: SectionMeta = {
  name: 'key_finding',
  title: 'Key Finding',
  abbrTitle: 'KF',
  position: -1,
};

export function getReportSections() {
  return [...REPORT_SECTIONS].sort((a, b) => a.position - b.position).map((s) => ({ ...s }));
}

export function getSectionAttr<T extends keyof SectionMeta>(name: SectionMeta['name'], attr: T) {
  if (name === 'key_finding') {
    return KEY_FINDING[attr] || null;
  }

  const section = getReportSections().find((s) => s.name === name);
  return section ? section[attr] : null;
}

export const sectionMap: {
  history: SectionMeta;
  technique: SectionMeta;
  findings: SectionMeta;
  comment: SectionMeta;
  impression_recommendations: SectionMeta;
} = REPORT_SECTIONS.reduce((memo, value) => {
  memo[value.name] = value;
  return memo;
}, {} as any);

type ESCardinalityAgg = {
  cardinality: {
    value: number;
  };
  doc_count: number;
};

type ESStatementSearchStatement = RR.Statement & {
  text: string; // TODO: change to _text
  section: RR.TemplateSection;
  subsection: string;
  subsection_id: number;
  region: boolean;
  element_id: number;
  statement_id: number;
};

export type ESStatementSearchHit = {
  _id: string;
  _source: ESStatementSearchStatement;
  highlight: {
    text: string[];
  };
};

export type ESStatementSearch = {
  hits: {
    hits: ESStatementSearchHit[];
  };
  aggregations: {
    comment_count: ESCardinalityAgg;
    findings_count: ESCardinalityAgg;
    history_count: ESCardinalityAgg;
    impression_recommendations_count: ESCardinalityAgg;
    technique_count: ESCardinalityAgg;
  };
};

export interface ESStatementSearchResponse {
  statement: ESStatementSearch;
  divider: ESStatementSearch;
  statement_set: {
    hits: {
      hits: {
        _id: string;
        _source: RR.StatementSet;
        highlight?: {
          name: string[];
        };
      }[];
    };
  };
}

@Injectable()
export class TemplateService {
  constructor(
    private store: Store<AppState>,
    private http: HttpClient,
    private statementEffect: StatementEffect,
    private statementSetEffect: StatementSetEffect,
    private defaultAttributeEffect: DefaultAttributeEffect,
    private elementEffect: ElementEffect,
  ) {}

  /**
   * @deprecated select and filter entity
   */
  getTemplate(templateId: number) {
    return this.store.select(fromTemplate.selectTemplate(templateId)).pipe(filter((template) => !!template));
  }

  /**
   * @deprecated select and filter entity
   */
  getSubsection(subsectionId: number) {
    return this.store.select(fromSubsection.selectSubsection(subsectionId)).pipe(filter((s) => !!s));
  }

  /**
   * @deprecated select and filter entity
   */
  getElement(elementId: number) {
    return this.store.select(fromElement.selectElement(elementId)).pipe(filter((e) => !!e));
  }

  /**
   * @deprecated select and filter entity
   */
  getRegion(regionId: number) {
    return this.store.select(fromRegion.selectRegion(regionId)).pipe(filter((r): r is RR.Region => !!r));
  }

  /**
   * @deprecated select and filter entity
   */
  getStatementSet(statementSetId: number) {
    return this.store
      .select(fromStatementSet.selectStatementSet(statementSetId))
      .pipe(filter((ss): ss is RR.StatementSet => !!ss));
  }

  /**
   * @deprecated select and filter entity
   */
  getStatement(statementId: number) {
    return this.store.select(fromStatement.selectStatement(statementId)).pipe(filter((s): s is RR.Statement => !!s));
  }

  /**
   * @deprecated select and filter entity
   */
  getAttributeSet(attr_set_id: number) {
    return this.store.select(fromAttributeSet.selectAttributeSet(attr_set_id)).pipe(filter((attrSet) => !!attrSet));
  }

  getStatementElements(statement_id: number, template_id: number): Observable<RR.Element[]> {
    return this.getStatement(statement_id).pipe(
      switchMap((statement) => this.getStatementSetElements(statement.statement_set_id, template_id)),
    );
  }

  getStatementSetElements(statement_set_id: number, template_id: number) {
    return this.getElements(template_id).pipe(
      map((elements) => elements.map((e) => e.element).filter((e) => e.statement_set_id === statement_set_id)),
    );
  }

  /**
   * @deprecated select entity from store
   */
  getElementParents(element_id: number) {
    return this.getElement(element_id).pipe(
      filterDefined(),
      switchMap((element) => this.getSubsection(element.subsection_id)),
    );
  }

  getElements(template_id: number): Observable<IElement[]> {
    return this.store.select(fromAppSelector.selectElementsByTemplateId(template_id));
  }

  /**
   * @deprecated use store and selector
   */
  getDividers(statement_set_id: number) {
    return this.store.select(fromStatementSet.selectDividers(statement_set_id));
  }

  /**
   * @deprecated use store and selector
   */
  getCategories(statement_set_id: number) {
    return this.store.select(fromStatementSet.selectCategories(statement_set_id));
  }

  getRegionSetInSubsection(subsection_id: number) {
    return combineLatest(
      this.getSubsection(subsection_id),
      this.store.select(fromRegionSet.selectEntities),
      (subsection, region_set_entities) => {
        // @ts-expect-error strictNullChecks
        return region_set_entities[subsection.region_set_id];
      },
    );
  }

  getAllRegionSets(): Observable<RR.RegionSet[]> {
    // @ts-expect-error strictNullChecks
    return this.store.select(fromRegionSet.selectEntities).pipe(
      map((region_sets) => {
        // @ts-expect-error strictNullChecks
        return Object.values(region_sets).filter((rs) => !rs.deleted);
      }),
    );
  }

  getRegions(region_set_id: number) {
    return this.store.select(fromRegion.selectEntities).pipe(
      map((region_entities) => {
        // @ts-expect-error strictNullChecks
        return Object.values(region_entities).filter((r) => r.region_set_id === region_set_id);
      }),
    );
  }

  getTitleSet(template_id: number) {
    return combineLatest(
      this.getTemplate(template_id),
      this.store.select(fromTitleSet.selectEntities),
      (template, title_set_entities) => {
        // @ts-expect-error strictNullChecks
        return title_set_entities[template.title_set_id];
      },
    ).pipe(filter((set) => !!set));
  }

  getTitleOptions(template_id: number) {
    return combineLatest(
      this.getTitleSet(template_id),
      this.store.select(fromTitleOption.selectEntities),
      (title_set, title_option_entities) => {
        // @ts-expect-error strictNullChecks
        return Object.values(title_option_entities).filter((o) => o.title_set_id === title_set.id);
      },
    );
  }

  /**
   * @deprecated use TemplateHeadlineComponent
   */
  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  getTemplateName = (template_id: number) => {
    // @ts-expect-error strictNullChecks
    return this.getTemplate(template_id).pipe(map((template) => `${template.modality.name} – ${template.name}`));
  };

  getTemplateModality(template_id: number) {
    // @ts-expect-error strictNullChecks
    return this.getTemplate(template_id).pipe(map((template) => template.modality.name));
  }

  /**
   * Check whether the current template is loaded
   *
   * Determine whether the current template has been loaded, including all the
   * dependent elements.
   *
   * @example
   * ```ts
   * this.isCurrentTemplateLoaded().pipe(
   *   skipWhile((isLoaded) => !isLoaded),
   *   switchMap(() => this.store.select(fromCurrentTemplate.selectTemplate)),
   * )
   * ```
   *
   */
  isCurrentTemplateLoaded(): Observable<boolean> {
    return this.store.select(fromCurrentTemplate.selectTemplateId).pipe(
      mergeMap((templateId: number | undefined) => {
        if (templateId !== undefined) {
          return this.isTemplateLoaded(templateId);
        } else {
          return of(false);
        }
      }),
      distinctUntilChanged(),
    );
  }

  /**
   * Check whether the full template has been loaded
   *
   * When loading templates, there  are two states that we load them into. An
   * initial minimal state which describes the medical aspect of the template.
   * The rest of the template describing the structural information is then
   * loaded later, taking a little longer with the additional information it
   * contains.
   *
   * This function checks whether a specific full template has been loaded,
   * ensuring that the template itself is loaded along with the contained
   * elements it relies on. The definition of the template for this function
   * extends to the Regions, Subsections and Elements. With the statements
   * being handled separately.
   *
   * @param templateId: The id of the template we want to query.
   *
   * @returns: An observable that is true when the template is completely
   * loaded
   *
   */
  isTemplateLoaded(templateId: number): Observable<boolean> {
    return this.store.select(fromTemplate.selectLoaded(templateId)).pipe(map((loaded) => loaded === 'loaded'));
  }

  getTopicTitleOption(topic_id: number) {
    return combineLatest([
      this.store.select(fromTopic.selectTopic(topic_id)).pipe(filter((t) => !!t)),
      this.store.select(fromTitleOption.selectEntities),
    ]).pipe(
      map(([topic, title_option_entities]) => {
        // @ts-expect-error strictNullChecks
        return title_option_entities[topic.title_option_id];
      }),
    );
  }

  getTitleOptionFromText(template_id: number, text: string) {
    return this.getTitleOptions(template_id).pipe(
      map((title_options) => {
        // @ts-expect-error strictNullChecks
        return title_options.find((option) => option.text === text);
      }),
    );
  }

  insertStatement(data: StatementCreateEffect, beforeStatementId: number) {
    // @ts-expect-error strictNullChecks
    return this.getStatementSet(data.statement.statement_set_id).pipe(
      take(1),
      switchMap((statementSet) => {
        // Find the position of the current statement within the statement set
        const index: number = statementSet.statements.indexOf(beforeStatementId);
        return this.statementEffect.createWithTextObjects({
          index,
          statement: data.statement,
          textObjects: data.textObjects,
        });
      }),
    );
  }

  autoAttributifyStatement(text?: string) {
    // @ts-expect-error strictNullChecks
    return this.http.get<string>('/api/attributify_statement', { params: new HttpParams().set('text', text) });
  }

  /**
   * Move a statement from to another location within a statement_set
   *
   * This assumes that we are moving statements within the same statement set.
   * Moving to a new statement set, requires modifying the statement first.
   *
   * @param statement: The statement we want to move somewhere else within its
   * own statement set.
   * @param moveToStatementId: The statement_id of the location we want to
   * move the statement to. When the value is null, the item is not found in
   * the list and so will be added at the end.
   */
  moveStatementBefore(statement: RR.Statement, moveToStatementId: number | null) {
    if (!statement.statement_set_id) return of(undefined);

    return this.store.select(fromStatementSet.selectStatementSet(statement.statement_set_id)).pipe(
      take(1),
      switchMap((statementSet) => {
        if (!statementSet || !statement.statement_set_id) return of(undefined);
        // Create a clone of the statements so it can be modified without affecting the state in the store
        const statements = [...statementSet.statements];

        // This is not for dragging. Dragging has a different behaviour depending on the direction the item was dragged.
        // Dragging essentially does this splice after finding the insertIndex. But now this is handled by cdkDrag.
        statements.splice(statements.indexOf(statement.id), 1);

        // @ts-expect-error strictNullChecks
        const insertIndex = statements.indexOf(moveToStatementId);
        if (insertIndex === -1) {
          // An index of -1 indicates the item wasn't found. In this case we want to append to the end of the statements
          statements.push(statement.id);
        } else {
          // Inserts the the statement at the insertIndex. 0 means delete nothing.
          statements.splice(insertIndex, 0, statement.id);
        }

        // This updates the statement set with the new location of the statement within the list.
        return this.statementSetEffect.update(statement.statement_set_id, { statements });
      }),
    );
  }

  /**
   * Move a statement to a different statement Set
   *
   * This provides the tools to move a statement between statement sets,
   * allowing for the statement to be placed anywhere within the new statement
   * set.
   *
   * @param statementId: The id of the statement being moved
   * @param statementSetId: The id of the statement set we are moving the statement to
   * @param moveToStatementId: The id of a statement within the new statement
   * set describing the new position of the statement. This is where we want
   * the statement to end up. By default the statement will be after this
   * selected statement.
   * @param forceBefore: Force putting the statement before the
   * moveToStatementId. This allows for the statement to be placed at the start
   * of the statementSet.
   */
  moveStatementToStatementSet(
    statementId: number,
    statementSetId: number,
    moveToStatementId: number,
    forceBefore: boolean,
  ) {
    return this.store.select(fromStatementSet.selectStatementSet(statementSetId)).pipe(
      take(1),
      switchMap((statementSet) => {
        if (!statementSet) return of(undefined);

        let position = statementSet.statements.indexOf(moveToStatementId);
        // When we find the position, it is to insert the statement before a statement, to insert it afterwards we need
        // to add 1 to the position.
        if (!forceBefore) {
          position += 1;
        }
        // TOOD(camelCase): When the API moves to using camelCase, the
        // statement_set_id needs to be converted to camelCase.
        return this.statementEffect.update(statementId, { statement_set_id: statementSetId, position });
      }),
    );
  }

  getAttributeSetOptions(attribute_set_id: number) {
    return this.store.select(fromAttributeSet.selectAttributeOptions(attribute_set_id));
  }

  replaceStatementTextTemplateWithRegion(text: string, region: RR.Region | undefined) {
    if (region) {
      const match = text.match(/\[\[(\w)*\]\]/g);
      if (match) {
        match.forEach((region_ref: string) => {
          const region_attr = region_ref.replace('[[', '').replace(']]', '');
          text = text.replace(
            region_ref,
            // @ts-expect-error noImplicitAny
            <string>region[region_attr] || 'INVALID REGION ATTRIBUTE, PLEASE EDIT STATEMENT',
          );
        });
      }
    }
    return text;
  }

  getAttributeSets() {
    return (
      this.store
        .select(fromAttributeSet.selectEntities)
        // @ts-expect-error strictNullChecks
        .pipe(map((attr) => Object.values(attr).sort((a, b) => a.id - b.id)))
    );
  }

  getAttributeShortlists() {
    return combineLatest(
      [this.store.select(fromAttributeOption.selectEntities), this.store.select(fromAttributeSet.selectAll)],
      (attributeOptions: Dictionary<RR.AttributeOption>, attributeSets: RR.AttributeSet[]) => {
        const shortListBySetId: { [key: number]: number[] | undefined } = {};

        attributeSets.forEach((attributeSet) => {
          attributeSet.attribute_option_ids
            .map((attributeOptionId) => {
              return attributeOptions[attributeOptionId];
            })
            .filter((a): a is RR.AttributeOption => !!a)
            .filter((attributeOption) => attributeOption.default_to_top_list)
            .forEach((attributeOption) => {
              if (shortListBySetId[attributeSet.id] === undefined) {
                shortListBySetId[attributeSet.id] = [];
              }
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              shortListBySetId[attributeSet.id]!.push(attributeOption.id);
            });
        });

        return shortListBySetId;
      },
    );
  }

  searchAttributes(term: string) {
    return combineLatest(
      [this.store.select(fromAttributeOption.selectEntities), this.store.select(fromAttributeSet.selectAll)],
      (attributeOptions: Dictionary<RR.AttributeOption>, attributeSets: RR.AttributeSet[]) => {
        if (!term) return [];
        const regex = new RegExp('[ ]?' + escapeRegex(term), 'i');
        const matchingOptions = attributeSets
          .map((attributeSet) => {
            return attributeSet.attribute_option_ids
              .map((attributeOptionId) => {
                return attributeOptions[attributeOptionId];
              })
              .filter((a): a is RR.AttributeOption => !!a)
              .map((attributeOption) => {
                let score = 0;
                if (regex.test(attributeOption.text)) {
                  score += 1;
                }
                if (attributeOption.text.toLowerCase() === term.toLowerCase()) {
                  score += 1;
                }
                if (regex.test(attributeSet.name)) {
                  score += 2;
                }
                return { option: attributeOption, set: attributeSet, score };
              });
          })
          .flat()
          .sort((a, b) => {
            return b.option.frequency - a.option.frequency;
          })
          .sort((a, b) => b.score - a.score);
        return uniqBy(matchingOptions, (o) => o.set.id);
      },
    );
  }

  getDefaultAttribute(
    templateId: number,
    statementTextObjectId: number,
    regionId: number | null,
  ): Observable<RR.DefaultAttribute | undefined> {
    return this.store.select(fromDefaultAttribute.selectByChoice(templateId, statementTextObjectId, regionId));
  }

  setDefaultAttribute(data: Omit<RR.DefaultAttribute, 'id'>) {
    this.getDefaultAttribute(data.template_id, data.text_object_id, data.region_id || null)
      .pipe(
        take(1),
        switchMap((tda: RR.DefaultAttribute | undefined) => {
          if (tda) {
            return this.defaultAttributeEffect.update(tda.id, data);
          } else {
            return this.defaultAttributeEffect.create(data);
          }
        }),
      )
      .subscribe();
  }

  /**
   * Create a new element, connecting a subsection to a statementSet.
   *
   * The element provides a many to many link between the subsection and the
   * statement set. This creates a new instance of the link, adding a statement set to a subsection.
   *
   * @param statementSetId: The id of the statement set we are adding
   * @param subsectionId: The subsection we are adding the statementSet to.
   * @param beforeElementId: Insert before this element. If undefined append to the end.
   */
  createElement(statementSetId: number, subsectionId: number, beforeElementId?: number) {
    this.getSubsection(subsectionId)
      .pipe(
        take(1),
        switchMap((subsection) => {
          // @ts-expect-error strictNullChecks
          let index: number | undefined = subsection.elements.indexOf(beforeElementId);
          if (index === -1) {
            index = undefined;
          }

          return this.elementEffect.create({
            element: {
              statement_set_id: statementSetId,
              subsection_id: subsectionId,
            },
            index,
          });
        }),
      )
      .subscribe();
  }

  enumerateTitleOptions(option_array: string[][], fixed: string[][], template_id: number) {
    return combineLatest(
      this.enumerate(option_array, fixed).map((option) => this.getTitleOptionFromText(template_id, option)),
      (...titles) => titles.filter((o) => !!o),
    );
  }

  private enumerate(option_array: string[][], fixed: string[][]): string[] {
    let results = [''];
    option_array.forEach((titles, i) => {
      const temp: string[] = [];
      titles.forEach((title) => {
        if (fixed[i].indexOf(title) !== -1 || fixed[i].length === 0)
          results.forEach((result) => {
            temp.push(result.length ? `${result} ${title}` : title);
          });
      });
      results = temp;
    });
    return results;
  }

  searchForSimilarStatement({
    text$,
    topic,
    activeSection$,
    statement_set_id,
    element_id,
    subsection_id,
    _loading$,
  }: {
    text$: Observable<string>;
    topic: RR.Topic;
    activeSection$?: Observable<RR.TemplateSection | 'all' | undefined>;
    statement_set_id?: number;
    element_id?: number;
    subsection_id?: number;
    _loading$?: BehaviorSubject<boolean>;
  }): Observable<{ textHasChanged: boolean; response: ESStatementSearchResponse } | null> {
    const loading$ = _loading$ || new BehaviorSubject(false);

    if (activeSection$ === undefined) {
      activeSection$ = observableOf(undefined);
    }

    // @ts-expect-error noImplicitAny
    let lastText;
    const defaultValue = null;
    return combineLatest([text$, activeSection$]).pipe(
      debounceTime(300),
      switchMap(([text, activeSection]) => {
        loading$.next(true);
        let textHasChanged = false;
        // @ts-expect-error noImplicitAny
        if (lastText !== text) {
          textHasChanged = true;
        }
        lastText = text;
        if (!text) return observableOf(defaultValue);
        return this.http
          .post<ESStatementSearchResponse>('/api/statement_search', {
            search: text,
            template_id: topic.template_id,
            section: activeSection || undefined,
            statement_set_id: statement_set_id,
            element_id,
            subsection_id,
          })
          .pipe(
            finalize(() => {
              loading$.next(false);
            }),
          )
          .pipe(
            map((response) => {
              return { textHasChanged, response };
            }),
            catchError(() => observableOf(defaultValue)),
          );
      }),
    );
  }

  findStatementSetUsage(statement_set_id: number) {
    return this.http
      .get<RR.Template[]>('/api/template/query', {
        params: new HttpParams().set('statement_set_id', String(statement_set_id)),
      })
      .pipe(
        map((response) => {
          return response.sort((a, b) => a.modality.name.localeCompare(b.modality.name));
        }),
      );
  }

  /**
   * Get categories for a combo of statement and attributes
   * @param statement_id
   * @param attributes
   */
  getSentenceCategories(statement_id: number, attributes: ICategorisedAttribute[]) {
    return combineLatest(
      this.store.select(fromCategory.selectEntities),
      this.store.select(fromCategoryStatementCombo.selectEntities),
      (category_entities, combo_entities) => {
        return (
          Object.values(combo_entities)
            .filter(
              (combo) =>
                // @ts-expect-error strictNullChecks
                combo.statement_id === statement_id && this.checkAttributesInCategoryStatementCombo(combo, attributes),
            )
            // @ts-expect-error strictNullChecks
            .map((combo) => category_entities[combo.category_id])
        );
      },
    );
  }

  /**
   * Check if a combo of statement and attributes was already classified under a category
   * @param statement_id
   * @param attributes
   */
  checkSentenceCategories(statement_id: number, attributes: ICategorisedAttribute[]) {
    // Check combo of statement and attributes
    return this.store.select(fromCategoryStatementCombo.selectEntities).pipe(
      map(
        (combo_entities) =>
          Object.values(combo_entities).filter(
            (combo) =>
              // @ts-expect-error strictNullChecks
              combo.statement_id === statement_id && this.checkAttributesInCategoryStatementCombo(combo, attributes),
          ).length > 0,
      ),
    );
  }

  getSentenceCategoriesTooltip(statement_id: number, attributes: ICategorisedAttribute[]) {
    return this.getSentenceCategories(statement_id, attributes).pipe(
      map((cats) => {
        if (cats.length === 0) return TOOLTIPS['CATEGORISE_TAG_SENTENCE'];
        return cats.map((c) => (c ? c.text : '')).join(', ');
      }),
    );
  }

  /**
   * Check if category statement combo has attributes in its attribute combo
   * @param category_statement_combo
   * @param attributes
   */
  checkAttributesInCategoryStatementCombo(
    category_statement_combo: RR.CategoryStatementCombo,
    attributes: ICategorisedAttribute[],
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!category_statement_combo) return false;

    // If there is no AttributeCombo, and attributes list is null or empty
    if (
      (!category_statement_combo.attribute_combos || category_statement_combo.attribute_combos.length === 0) &&
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (!attributes || attributes.length === 0)
    )
      return true;

    // Check if one attribute_combo in category_statement_combo match with attributes list
    // @ts-expect-error strictNullChecks
    return category_statement_combo.attribute_combos.some((attr_combo) =>
      this.checkAttributeCombo(attr_combo, attributes),
    );
  }
  /**
   * Check if attributes are in an attribute combo
   * @param attr_combo
   * @param attributes
   */
  checkAttributeCombo(attr_combo: RR.AttributeCombo, attributes: ICategorisedAttribute[]) {
    if (attr_combo.attributes.length !== attributes.length) return false;

    // Check if all of attributes exist in attr_combo.attributes list
    for (const attr of attributes) {
      if (
        !attr_combo.attributes.some(
          (a) => a.attribute_option_id === attr.attribute_option_id && a.text_object_id === attr.text_object_id,
        )
      )
        return false;
    }

    return true;
  }

  getStatementFrequencies(statement_set_id: number, template_id: number, topic_id: number, age?: number) {
    let params = new HttpParams()
      .set('statement_set_id', statement_set_id.toString())
      .set('template_id', template_id.toString())
      .set('topic_id', topic_id.toString());
    // Age can be 0 so can't used !age
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (age !== null && age !== undefined) {
      params = params.set('current_patient_age', age.toString());
    }
    return this.http
      .get<any>('/api/get_statement_frequencies', {
        params,
      })
      .pipe(map((response) => ({ statement_set_id, frequencies: response })));
  }

  getStatementFrequenciesLogisticRegression(statement_set_id: number, topic_id: number) {
    const params = new HttpParams()
      .set('statement_set_id', statement_set_id.toString())
      .set('topic_id', topic_id.toString());
    return this.http
      .get<any>('/api/ml/topic/get_logistic_regression_statement_set_filtering', {
        params,
      })
      .pipe(map((response) => ({ statement_set_id, frequencies: response })));
  }

  getTemplateCategories(template_id: number) {
    return this.getElements(template_id).pipe(
      switchMap((elements) => {
        return combineLatest(
          elements
            .filter((element) => element.statement_set.categories.length > 0)
            .map((element) => {
              return this.store.select(fromStatementSet.selectCategories(element.statement_set.id));
            }),
        );
      }),
      map((results) => {
        // Order is by Element position in Template, then by position within StatementSet.
        return results.flat();
      }),
    );
  }

  getNumberOfStatements(statement_set_id: number, type: 'DIVIDER' | 'STATEMENT') {
    return this.store.select(fromStatementSet.selectStatements(statement_set_id)).pipe(
      map((statements) => {
        if (type === 'DIVIDER') {
          return statements.filter((s) => s.is_divider && !s.legacy).length;
        }
        return statements.filter((s) => !s.is_divider && !s.legacy).length;
      }),
    );
  }

  getNumberOfCategorisedStatements(statement_set_id: number, type: 'DIVIDER' | 'STATEMENT') {
    return combineLatest(
      this.store.select(fromStatementSet.selectStatements(statement_set_id)),
      this.store.select(fromCategoryStatementCombo.selectEntities),
      (statements, combo_entities) => {
        const combos = Object.values(combo_entities);
        return statements.filter(
          (s) =>
            ((type === 'DIVIDER' && s.is_divider) || (type === 'STATEMENT' && !s.is_divider)) &&
            !s.legacy &&
            // @ts-expect-error strictNullChecks
            combos.some((c) => c.statement_id === s.id),
        ).length;
      },
    );
  }

  /**
   * Check to load statements and default attributes for statement set
   * @param statementSetId
   * @param templateId
   */

  loadStatementSet(statementSetId: number, templateId: number) {
    const statementLoaded$ = this.store
      .select(fromStatementSet.selectLoadingOrLoadedInStatementSet(statementSetId))
      .pipe(take(1));
    const attributeLoaded$ = this.store
      .select(fromDefaultAttribute.selectLoadingOrLoadedInStatementSet(templateId, statementSetId))
      .pipe(take(1));

    const statements$ = statementLoaded$.pipe(
      switchMap((loaded) => (loaded ? of(null) : this.statementEffect.findInStatementSet(statementSetId))),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    const attributes$ = attributeLoaded$.pipe(
      switchMap((loaded) =>
        loaded ? of(null) : this.defaultAttributeEffect.findInStatementSet(templateId, statementSetId),
      ),
      shareReplay({ bufferSize: 1, refCount: false }),
    );

    return combineLatest([statements$, attributes$]);
  }
}
