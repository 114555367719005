import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BookingPatientForm } from 'app/modules/booking/pages/booking/booking-page.service';

@Component({
  selector: 'rr-booking-patient-view',
  templateUrl: './booking-patient-view.component.html',
  styleUrls: ['./booking-patient-view.component.css'],
  standalone: true,
  imports: [CommonModule],
})
export class BookingPatientViewComponent {
  @Input() bookingPatientForm: BookingPatientForm;
}
