export const funders: {
  id: RR.FunderType;
  name: string;
}[] = [
  { id: 'medicare', name: 'Medicare' },
  { id: 'dva', name: 'DVA' },
  { id: 'bupa', name: 'BUPA or No medicare' },
  { id: 'private_patient', name: 'Private Fee (Medicare card holder)' },
  { id: 'other', name: 'Third party, CTP, work cover, or other' },
  // { id: 'unknown', name: 'Unknown' },
];

export const funderMap: Record<RR.FunderType, string> = {
  medicare: 'Medicare',
  dva: 'DVA',
  bupa: 'BUPA or No medicare',
  private_patient: 'Private Fee (Medicare card holder)',
  other: 'Third party, CTP, work cover, or other',
  unknown: 'Unknown (this option is legacy)',
};

export const invoiceStatusMap: Record<RR.InvoiceStatusType, string> = {
  outstanding: 'Outstanding',
  paid: 'Paid',
};

/* eslint quote-props: ["error", "as-needed"]*/
export const medipassClaimStatusMap: Record<RR.MedipassClaimStatusType, string> = {
  submitted: 'Submitted',
  'under-review': 'Under review',
  accepted: 'Accepted',
  approved: 'Approved',
  rejected: 'Rejected',
  paid: 'Paid',
};

export const medipassTransactionStatusMap: Record<RR.MedipassTransactionStatusType, string> = {
  pending: 'Pending',
  cancelled: 'Cancelled',
  completed: 'Completed',
};

export const paymentTypeMap: Record<RR.PaymentType, string> = {
  eftpos: 'EFTPOS',
  cash: 'Cash',
  credit_card: 'Credit card',
  medicare: 'Medicare',
};

/* eslint quote-props: ["error", "as-needed"]*/
export const invoiceItemStatusMap = {
  pending: 'Pending',
  'under-review': 'Under review',
  approved: 'Approved',
  rejected: 'Rejected',
};

/**
 * Hide some options that we are unsure about
 */
export const institutionTypeMap: Record<RR.InstitutionType, string> = {
  third_party: 'Third Party',
  employer: 'Employer',
  private: 'Private',
  solicitor: 'Solicitor',
  other_party: 'Other Party',
};
