<rr-slatement-mount
  *ngIf="initialValue"
  [props]="{
    onChange: slateOnChangeCallback,
    onEditor: slateOnEditorCallback,
    onKeyDown: slateOnKeyDownCallback,
    onClickAttribute: slateOnClickAttributeCallback,
    onClickNumberAttribute: slateOnClickAttributeNumberCallback,
    onClickDateAttribute: slateOnClickAttributeDateCallback,
    initialValue: initialValue,
    templateId: templateId,
    devMode: devMode,
  }"
></rr-slatement-mount>

<div ngbDropdown [open]="true" *ngIf="starStarRange">
  <div ngbDropdownMenu>
    <button (click)="insertNumberAttribute(starStarRange)" data-cy="number-match" ngbDropdownItem>
      Press Tab to add a number attribute
    </button>
  </div>
</div>

<div ngbDropdown [open]="true" *ngIf="region && formattedRegionMatch && formattedRegionMatch.length > 0">
  <div ngbDropdownMenu>
    <button
      data-cy="region-match"
      ngbDropdownItem
      *ngFor="let region of formattedRegionMatch"
      (click)="insertRegionText(region.regionKey)"
    >
      {{ region.regionSyntax }}
    </button>
  </div>
</div>

<ng-container *ngIf="!starStarRange && !focusedAttributeTextElement && !shouldDisplayRegionSuggestion">
  <div
    (openChange)="onDropdownOpenChange($event)"
    ngbDropdown
    [autoClose]="'outside'"
    placement="bottom"
    #attrDropdown="ngbDropdown"
    [class.will-search]="searchStatus === 'will-search'"
    [class.searched]="searchStatus === 'searched'"
  >
    <div ngbDropdownMenu data-cy="attribute-match-dropdown" [id]="ATTRIBUTE_MATCH_DROPDOWN">
      <!-- Scenario when attributeSetMatch is available -->
      <ng-container *ngIf="attributeSetMatch">
        <rr-attribute-match
          data-cy="attribute-match"
          ngbDropdownItem
          [match]="attributeSetMatch"
          (onMouseDown)="onMouseDownAttributeMatch($event, attributeSetMatch)"
        ></rr-attribute-match>
      </ng-container>
      <!-- Scenario when matches array is available -->
      <ng-container *ngIf="matches.length > 0">
        <rr-attribute-match
          data-cy="attribute-match"
          ngbDropdownItem
          *ngFor="let match of matches"
          [match]="match"
          (onMouseDown)="onMouseDownAttributeMatch($event, match)"
        ></rr-attribute-match>
      </ng-container>

      <!-- Scenario when neither is available -->
      <ng-container *ngIf="!attributeSetMatch && matches.length === 0 && formattedRegionMatch.length === 0">
        <div ngbDropdownItem>
          <i>No matches found</i>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="focusedAttributeTextElement">
  <rr-switch-attribute
    *ngIf="focusedAttributeTextElement.text_object_id | storeSelectPipe: selectTextObject | async as switchTextObject"
    [textObject]="switchTextObject"
    [templateId]="templateId"
    [regionId]="region?.id || null"
    (onSwitchAttributeSet)="switchAttributeSet($event, focusedAttributeTextElement)"
  ></rr-switch-attribute>
  <rr-switch-attribute
    *ngIf="!focusedAttributeTextElement.text_object_id"
    [defaultAttributeId]="focusedAttributeTextElement.default_option_id"
    [templateId]="templateId"
    [regionId]="region?.id || null"
    (onSwitchAttributeSet)="switchAttributeSet($event, focusedAttributeTextElement)"
  >
  </rr-switch-attribute>
</ng-container>

<button role="button" (click)="save()" *ngIf="devMode" class="btn btn-sm btn-success">Submit</button>
