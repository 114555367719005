<div class="modal-header">
  <h5 class="modal-title">Notices</h5>
  <button class="btn btn-success ms-auto" role="button" (click)="close()">Mark all as read</button>
  <button role="button" class="btn-close ms-0" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body-notice">
  <div
    class="alert"
    *ngFor="let notice of notices"
    [ngClass]="{
      'alert-info': seenNoticeID !== null && notice.id > seenNoticeID,
      'alert-secondary': seenNoticeID === null || notice.id <= seenNoticeID,
    }"
  >
    <strong>{{ notice.when | date: 'dd/MM/yyyy' }}</strong>
    {{ notice.text }}
  </div>
</div>
