<div class="alert alert-danger" *ngIf="form.controls.referrerId.errors?.required">
  (To continue with the registration, please either select a referrer or indicate that this registration has no
  referrer.)
</div>
<div *ngIf="!form.controls.referrerId.errors">
  <!-- Select a referrer and define the referrer_type before showing the form. The "To continue..." message above
    clarifies what to do -->
  <h6 class="text-danger" *ngIf="form.invalid">Incomplete</h6>
  <div
    *ngIf="viewMode === 'view'"
    class="alert alert-light"
    [class.border-danger]="form.invalid"
    data-cy="report-form-view"
  >
    <div *ngIf="viewMode === 'view'">
      <div class="grid" style="row-gap: 0">
        <div class="g-col-4">
          <strong>Scan Code:</strong>
          <p *ngIf="scanCode$ | async as scanCode">
            {{ scanCode?.code }}
            <span *ngIf="scanCode.has_side" class="text-capitalize ms-1">{{ report?.scan_code_side }}</span>
          </p>
        </div>
        <div class="g-col-4">
          <strong>Booking Code:</strong>
          <p>{{ (bookingCode$ | async)?.code }}</p>
        </div>
        <div class="g-col-4">
          <strong>Referral Date:</strong>
          <p>{{ report?.referral_date | date: 'dd/MM/yyyy' }}</p>
        </div>
        <div class="g-col-4">
          <strong>Site:</strong>
          <p>{{ (site$ | async)?.short_name }}</p>
        </div>
        <div class="g-col-4">
          <strong>Provider Number:</strong>
          <p>
            {{ (providerNumber$ | async)?.medicare_provider_number }} <span *ngIf="doctor">({{ doctor }})</span>
          </p>
        </div>
        <div class="g-col-4">
          <strong>Voyager Study Notes:</strong>
          <p class="text-truncate" *ngIf="report?.study_notes; else noNotes">{{ report?.study_notes }}</p>
          <ng-template #noNotes><p>No</p></ng-template>
        </div>
      </div>
      <ng-container *ngIf="bookingCode$ | async as bookingCode">
        <div *ngIf="bookingCode.booking_notes">
          <strong>Booking Code Notes:</strong>
          <p [innerHTML]="bookingCode.booking_notes"></p>
        </div>
      </ng-container>
    </div>
    <div>
      <button
        type="button"
        class="btn btn-outline-primary w-100"
        (click)="editBooking()"
        rrTooltip="REGISTRAION_EDIT_REPORT"
      >
        {{ report ? 'Edit Registration' : 'New Registration' }}
      </button>
    </div>
  </div>

  <div [hidden]="!(viewMode === 'edit')">
    <form class="bootstrap-validate">
      <div class="grid">
        <div class="form-group mb-2 g-col-4">
          <label class="control-label">Scan Code <span class="text-danger fa fa-asterisk fa-xs"></span></label>
          <div
            class="input-group"
            (click)="selectScanCode()"
            [formControl]="form.controls.scan_code_id"
            ngDefaultControl
            data-cy="scanCodeInput"
          >
            <div role="button" readonly class="form-control flex-grow-1">
              {{ (scanCode$ | async)?.code }}
            </div>
            <button class="btn btn-secondary" rrTooltip="SELECT_BOOKING_SCAN_CODE">Select</button>
          </div>
          <span class="invalid-feedback" *ngIf="form.controls.scan_code_id.errors?.required"
            >Scan code is required</span
          >
        </div>
        <div class="form-group mb-2 g-col-4" *ngIf="(scanCode$ | async)?.has_side">
          <label class="control-label">Scan Side <span class="text-danger fa fa-asterisk fa-xs"></span></label>
          <div class="d-flex flex-wrap" [formControl]="form.controls.scan_code_side" ngDefaultControl>
            <ng-container *ngFor="let side of ['left', 'right', 'bilateral']">
              <div class="form-check me-2">
                <input
                  id="{{ side }}"
                  class="form-check-input"
                  type="radio"
                  [formControl]="form.controls.scan_code_side"
                  [value]="side"
                />
                <label for="{{ side }}" class="text-capitalize">{{ side }}</label>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="form-group mb-2 g-col-4">
          <label class="control-label">Booking Code <span class="text-danger fa fa-asterisk fa-xs"></span></label>
          <div
            class="input-group"
            (click)="selectBookingCode()"
            [formControl]="bookingForm.controls.bookingCodeId"
            data-cy="bookingCodeIdInput"
            ngDefaultControl
          >
            <div role="button" readonly class="form-control flex-grow-1">
              {{ (bookingCode$ | async)?.code }}
            </div>
            <button class="btn btn-secondary" rrTooltip="SELECT_BOOKING_BOOKING_CODE">Select</button>
          </div>
          <span class="invalid-feedback" *ngIf="bookingForm.controls.bookingCodeId.errors?.required"
            >Booking code is required</span
          >
        </div>
      </div>

      <ng-container *ngIf="bookingCode$ | async as bookingCode">
        <div class="form-group mb-2" *ngIf="!!bookingCode.booking_notes">
          <span>Booking Code Notes:</span>
          <div [innerHTML]="bookingCode.booking_notes"></div>
        </div>
      </ng-container>

      <div class="grid">
        <div class="g-col-4 form-group mb-2">
          <label class="control-label">Referral Date</label>
          <input
            data-cy="referral-date"
            [formControl]="form.controls.referral_date"
            type="date"
            class="form-control"
            (change)="checkReferralDate()"
          />
          <ng-container>
            <div class="text-warning" *ngIf="isReferralDateInFuture">
              Referral date is in the future. Check date, then check procedure under Help.
            </div>
          </ng-container>
        </div>
        <div class="g-col-3 form-group mb-2">
          <label class="control-label">Site <span class="text-danger fa fa-asterisk fa-xs"></span></label>
          <div class="d-flex flex-wrap">
            <ng-container *ngFor="let s of sites">
              <div class="form-check me-2">
                <input
                  id="site-{{ s.id }}"
                  class="form-check-input"
                  type="radio"
                  [formControl]="bookingForm.controls.site_id"
                  [value]="s.id"
                />
                <label data-cy="site-label" for="site-{{ s.id }}">{{ s.short_name }}</label>
              </div>
            </ng-container>
          </div>
          <span class="invalid-feedback" *ngIf="bookingForm.controls.site_id.errors?.required"> Site is required </span>
        </div>
        <div class="g-col-5 form-group mb-2">
          <label class="control-label">Providing Doctor<span class="text-danger fa fa-asterisk fa-xs"></span></label>
          <rr-medicare-provider
            [site_id]="bookingForm.controls.site_id.value"
            [formControl]="form.controls.medicare_provider_id"
          ></rr-medicare-provider>
          <span
            class="invalid-feedback"
            *ngIf="
              form.controls.medicare_provider_id.invalid &&
              (form.controls.medicare_provider_id.touched || form.controls.medicare_provider_id.dirty)
            "
          >
            Provider number is required
          </span>
        </div>
      </div>

      <div *ngIf="bookingForm.controls.bookingCodeId.value">
        <div class="form-group">
          <rr-booking-code-detail
            [bookingCodeId]="bookingForm.controls.bookingCodeId.value"
            [referrerType]="referrer?.referrer_type"
            [booking]="booking"
            [funder]="funder"
            [selectedBillingItemIds]="selectedBillingItemIds"
            (onBillingItemChange)="onBillingItemChange($event)"
          ></rr-booking-code-detail>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center">
        <div *ngIf="!patient && !report" class="mb-2">
          Need a patient to register.
          <button
            class="btn btn-outline-danger btn-sm"
            rrTooltip="REGISTER_BOOKING_PATIENT"
            (click)="registrationService.focus('patient-form')"
          >
            Step 2
          </button>
        </div>
      </div>

      <div class="alert alert-danger" *ngIf="bookingForm.invalid || form.invalid">
        <ul class="mb-0">
          <li *ngIf="bookingForm.controls.bookingCodeId.errors?.required">Booking Code is required.</li>
          <li *ngIf="bookingForm.controls.billingItemIds.errors?.required">
            Need to select at least one billing item to register.
          </li>
          <li *ngIf="bookingForm.controls.site_id.errors?.required">Site is required.</li>
          <!-- TODO(reg): previously focused the step (click)="registrationService.focus('referrer-form')"  *ngIf="!referrer && !report && !noReferrer"-->
          <li *ngIf="form.controls.patientId.errors?.required">Patient is required.</li>
          <li *ngIf="form.controls.scan_code_id.errors?.required">Scan Code is required.</li>
          <li *ngIf="form.controls.medicare_provider_id.errors?.required">Provider Number is required.</li>
        </ul>
      </div>

      <div class="d-flex w-50 mx-auto justify-content-center">
        <!--Cannot create report without a patient-->
        <button *ngIf="!report" class="btn btn-primary" rrTooltip="REGISTER_BOOKING" (click)="submitReport()">
          Register
        </button>
        <button *ngIf="report" class="btn btn-primary" rrTooltip="REGISTER_BOOKING" (click)="submitReport()">
          Save Changes
        </button>
        <button class="btn btn-light ms-2" (click)="cancel()">Cancel</button>
      </div>
    </form>
  </div>
</div>
