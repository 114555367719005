<div class="alert alert-light" [class.border-danger]="invalid">
  <div class="d-flex">
    <div class="grid w-100 flex-grow-1" style="row-gap: 0">
      <div class="g-col-4">
        <strong>Patient:</strong>
        <p>{{ patient?.patient_first_name }} {{ patient?.patient_last_name }}</p>
      </div>
      <div class="g-col-4">
        <strong>DOB:</strong>
        <p>{{ patient?.patient_dob | date: 'dd/MM/yyyy' }}</p>
      </div>
      <div class="g-col-4">
        <strong>Sex:</strong>
        <p class="text-capitalize">{{ patient?.patient_sex }}</p>
      </div>
      <div class="g-col-4">
        <strong>Patient Number:</strong>
        <p>{{ patient?.patient_number }}</p>
      </div>
      <div class="g-col-4" *ngIf="patient?.medicare_number">
        <strong>Medicare:</strong>
        <p>
          {{ patient?.medicare_number
          }}{{ patient?.medicare_reference_number ? '-' + patient?.medicare_reference_number : '' }}
        </p>
      </div>
      <div class="g-col-4" *ngIf="patient?.medicare_expiry_month && patient?.medicare_expiry_year">
        <strong>Expiry:</strong>
        <p>{{ patient?.medicare_expiry_month }}/{{ patient?.medicare_expiry_year }}</p>
      </div>
      <div class="g-col-4">
        <strong>Address:</strong>
        <p>{{ patient?.address }} {{ patient?.city }} {{ patient?.zip }}</p>
      </div>

      <div class="g-col-8" *ngIf="patient?.email">
        <strong>Email:</strong>
        <p>{{ patient?.email }}</p>
      </div>
      <div class="g-col-4" *ngIf="patient?.phone_mobile">
        <strong>Mobile:</strong>
        <p>{{ patient?.phone_mobile }}</p>
      </div>
      <div class="g-col-4" *ngIf="patient?.phone_work">
        <strong>Work Phone:</strong>
        <p>{{ patient?.phone_work }}</p>
      </div>
      <div class="g-col-4" *ngIf="patient?.phone_home">
        <strong>Home Phone:</strong>
        <p>{{ patient?.phone_home }}</p>
      </div>
      <div class="g-col-4" *ngIf="patient?.veteran_file_number">
        <strong>VFN:</strong>
        <p>{{ patient?.veteran_file_number }}</p>
      </div>
      <div class="g-col-4" *ngIf="patient?.note">
        <strong>Patient Note:</strong>
        <p class="text-truncate" *ngIf="patient?.note; else noNotes">{{ patient?.note }}</p>
        <ng-template #noNotes><p>No</p></ng-template>
      </div>
      <div class="g-col-4">
        <strong>Medicare validation:</strong>
        <p>{{ (patient?.medipass_last_validated | date: 'dd/MM/yyyy') || 'Never/Failed validation' }}</p>
      </div>
    </div>

    <!-- Close button on the right -->
    <button
      *ngIf="onClearObserved"
      role="button"
      class="btn-close"
      (click)="onClear.emit()"
      rrTooltip="REGISTRAION_REMOVE_PATIENT"
    ></button>
  </div>
  <!-- Action buttons are passed in with Content Projection -->
  <div class="col d-flex flex-column">
    <ng-content select="[buttons]"></ng-content>
  </div>
</div>
