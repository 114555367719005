<div class="container alert alert-light align-items-center">
  <div class="row justify-content-start mb-2">
    <div *ngIf="reInvoice" class="col-4">
      <strong>Original Invoice: </strong>
      <div>#{{ invoiceId }}</div>
    </div>
    <div class="col-4">
      <strong>Accession No.: </strong>
      <ng-container *ngIf="report; else noReports">
        <div class="d-flex">
          <a class="me-2" target="_blank" [routerLink]="['/report', report.id, 'edit']">
            <rr-report-accession-number [report]="report"></rr-report-accession-number>
          </a>
          <rr-registration-link [btnStatus]="false" [btnClass]="undefined" [newTab]="true" [report]="report">
          </rr-registration-link>
        </div>
      </ng-container>
      <ng-template #noReports>
        <div>None</div>
      </ng-template>
    </div>
    <div class="col-4">
      <strong>Scan Code: </strong>
      <ng-container *ngIf="scanCode$ | async as scanCode; else noScanCode">
        <div>
          {{ scanCode?.code }}
        </div>
      </ng-container>
      <ng-template #noScanCode>
        <div>None</div>
      </ng-template>
    </div>
    <div class="col-4">
      <strong>Doctor: </strong>
      <ng-container *ngIf="user$ | async as user; else noDoctor">
        <div>
          <rr-user-headline *ngIf="user" [user]="user"></rr-user-headline>
          {{ providerNumber?.medicare_provider_number ?? '' }}
        </div>
      </ng-container>
      <ng-template #noDoctor>
        <div>None</div>
      </ng-template>
    </div>
  </div>

  <div class="row justify-content-start mb-2">
    <div class="col-4">
      <strong>Patient: </strong>
      <ng-container *ngIf="report.patient_id; else noPatient">
        <div>
          <rr-patient-name [patientId]="report.patient_id"></rr-patient-name>
        </div>
      </ng-container>
      <ng-template #noPatient>
        <div>None</div>
      </ng-template>
    </div>
    <div class="col-4">
      <strong>Referrer: </strong>
      <ng-container *ngIf="referrer; else noReferrer">
        <div>
          <rr-referrer-name [referrerId]="referrer.id"></rr-referrer-name>
          - {{ referrer.medicare_provider_number }}
        </div>
      </ng-container>
      <ng-template #noReferrer>
        <div>None</div>
      </ng-template>
    </div>
    <div class="col-4">
      <ng-container *ngIf="report?.referral_date; else noReferralDate">
        <strong>Referral Date: </strong>
        <div>
          {{ report.referral_date | date: 'dd/MM/yyyy' }}
        </div>
      </ng-container>
      <ng-template #noReferralDate>
        <div>None</div>
      </ng-template>
    </div>
  </div>

  <div class="row" *ngIf="linkReports">
    <div class="col-4">
      <strong>Date: </strong>
      <div class="" *ngIf="report?.created">
        <!--Report date-->
        <span
          [ngbTooltip]="tipContent"
          #t="ngbTooltip"
          triggers="mouseenter:mouseleave"
          container="body"
          placement="right"
        >
          {{ report.created | date: 'MMM d, y, H:mm' }}
        </span>
        <ng-template #tipContent>
          <small>
            Created: {{ report.created | date: 'short' }}<br />
            Modified: {{ report.modified | date: 'short' }}
          </small>
        </ng-template>
      </div>
    </div>

    <ng-container *ngIf="report?.topic_ids">
      <div class="col-4">
        <strong class="mt-2">Topic titles: </strong>
        <div class="d-flex flex-wrap flex-column align-items-start">
          <ng-container *ngFor="let topic_id of report?.topic_ids">
            <span class="badge bg-secondary text-wrap mb-1">
              <rr-topic-title-option-text [topic_id]="topic_id"></rr-topic-title-option-text>
            </span>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
