import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';

import { reducerProvider, reducerToken } from './app.reducer';
import { enableBatching } from './batching.action';
import { BillingGroupEffect } from './billing-group/billing-group.effect';
import { BillingGroupHttpService } from './billing-group/billing-group.service';
import { BillingItemReferrerTypeEffect } from './billing-item-referrer-type/billing-item-referrer-type.effect';
import { BillingItemReferrerTypeHttpService } from './billing-item-referrer-type/billing-item-referrer-type.service';
import { BillingItemEffect } from './billing-item/billing-item.effect';
import { BillingItemHttpService } from './billing-item/billing-item.service';
import { BookingCodeLocatorEffect } from './booking-code-locator/booking-code-locator.effect';
import { BookingCodeLocatorHttpService } from './booking-code-locator/booking-code-locator.service';
import { BookingCodeEffect } from './booking-code/booking-code.effect';
import { BookingCodeHttpService } from './booking-code/booking-code.service';
import { BookingEnquiryHttpService } from './booking-enquiry/booking-enquiry.service';
import { BookingRateEffect } from './booking-rate/booking-rate.effect';
import { BookingRateHttpService } from './booking-rate/booking-rate.service';
import { BookingEffect } from './booking/booking.effect';
import { BookingHttpService } from './booking/booking.service';
import { CategoryStatementComboEffect } from './category-statement-combo/category-statement-combo.effect';
import { CategoryStatementComboHttpService } from './category-statement-combo/category-statement-combo.service';
import { CategoryEffect } from './category/category.effect';
import { CategoryHttpService } from './category/category.service';
import { CorrelatedSearchEffect, CorrelatedSearchHttpService } from './correlated-statement-search';
import { DicomEffect } from './dicom/dicom.effect';
import { DicomHttpService } from './dicom/dicom.service';
import { DocumentationLinkEffect, DocumentationLinkHttpService } from './documentation-link';
import { ExactMatchTopicEffect } from './exact-match-topic/exact-match-topic.effect';
import { ExactMatchTopicHttpService } from './exact-match-topic/exact-match-topic.service';
import { GithubIssueEffect } from './github-issue/github-issue.effect';
import { ImgsimParamsEffect } from './imgsim-parameters/imgsim-parameters.effect';
import { ImgsimParamsHttpService } from './imgsim-parameters/imgsim-parameters.service';
import { InstitutionEffect } from './institution/institution.effect';
import { InstitutionHttpService } from './institution/institution.service';
import { InvoiceItemEffect } from './invoice-item/invoice-item.effect';
import { InvoiceItemHttpService } from './invoice-item/invoice-item.service';
import { InvoiceEffect } from './invoice/invoice.effect';
import { InvoiceHttpService } from './invoice/invoice.service';
import { IssueHttpService } from './issue/issue.service';
import { KeywordAbbreviationEffect } from './keyword-abbr/keyword-abbr.effect';
import { KeywordAbbreviationHttpService } from './keyword-abbr/keyword-abbr.service';
import { LandmarkLabelEffect } from './landmark-label';
import { LandmarkLabelHttpService } from './landmark-label/landmark-label.service';
import { MachineEffect } from './machine/machine.effect';
import { MachineHttpService } from './machine/machine.service';
import { MandatoryStatementEffect } from './mandatory-statement/mandatory-statement.effect';
import { MandatoryStatementHttpService } from './mandatory-statement/mandatory-statement.service';
import { NoticeEffect } from './notice/notice.effect';
import { NoticeHttpService } from './notice/notice.service';
import { UserNotificationsEffect } from './notifications/notifications.effect';
import { PathologyEffect } from './pathology/pathology.effect';
import { PathologyHttpService } from './pathology/pathology.service';
import { PatientMergeConflictService } from './patient-merge-conflict/patient-merge-conflict.service';
import { PatientNoteCategoryEffect, PatientNoteCategoryHttpService } from './patient-note-category';
import { PatientNoteEffect } from './patient-note/patient-note.effect';
import { PatientNoteHttpService } from './patient-note/patient-note.service';
import { PatientEffect } from './patient/patient.effect';
import { PatientHttpService } from './patient/patient.service';
import { PaymentEffect } from './payment/payment.effect';
import { PaymentHttpService } from './payment/payment.service';
import { PercentileBandHttpService, PercentileBandEffect } from './percentile-band';
import { PercentileBandGroupHttpService, PercentileBandGroupEffect } from './percentile-band-group';
import { PercentileBandRuleHttpService, PercentileBandRuleEffect } from './percentile-band-rule';
import { PermissionEffect } from './permission/permission.effect';
import { PermissionHttpService } from './permission/permission.service';
import { TagChoiceEffect } from './prefill/tag-choice/tag-choice.effect';
import { TagChoiceHttpService } from './prefill/tag-choice/tag-choice.service';
import { TagEffect } from './prefill/tag/tag.effect';
import { TagHttpService } from './prefill/tag/tag.service';
import { ProviderNumberEffect } from './provider-number/provider-number.effect';
import { ProviderNumberHttpService } from './provider-number/provider-number.service';
import { PatientQuestionAnswerEffect } from './questionnaire/patient-question-answer/patient-question-answer.effect';
import { PatientQuestionAnswerHttpService } from './questionnaire/patient-question-answer/patient-question-answer.service';
import { PatientQuestionSetEffect } from './questionnaire/patient-question-set/patient-question-set.effect';
import { PatientQuestionSetHttpService } from './questionnaire/patient-question-set/patient-question-set.service';
import { PatientQuestionEffect } from './questionnaire/patient-question/patient-question.effect';
import { PatientQuestionHttpService } from './questionnaire/patient-question/patient-question.service';
import { ReferralEnquiryEffect } from './referral-enquiry/referral-enquiry.effect';
import { ReferralEnquiryHttpService } from './referral-enquiry/referral-enquiry.service';
import { ReferrerContactMethodEffect } from './referrer-contact-method/referrer-contact-method.effect';
import { ReferrerContactMethodHttpService } from './referrer-contact-method/referrer-contact-method.service';
import { ReferrerMergeConflictService } from './referrer-merge-conflict/referrer-merge-conflict.service';
import { ReferrerNoteEffect, ReferrerNoteHttpService } from './referrer-note';
import { ReferrerNoteCategoryEffect, ReferrerNoteCategoryHttpService } from './referrer-note-category';
import { ReferrerEffect } from './referrer/referrer.effect';
import { ReferrerHttpService } from './referrer/referrer.service';
import { RegistrationFormEffect, RegistrationFormHttpService } from './registration-form';
import { ReportMergeConflictEffect } from './report-merge-conflict/report-merge-conflict.effect';
import { ReportMergeConflictService } from './report-merge-conflict/report-merge-conflict.service';
import { ReportAccessEventEffect } from './report/access-event/access-event.effect';
import { ReportAccessEventHttpService } from './report/access-event/access-event.service';
import { AuditEventEffect } from './report/audit-event/audit-event.effect';
import { AuditEventHttpService } from './report/audit-event/audit-event.service';
import { ChoiceErrorEffect } from './report/choice-error/choice-error.effect';
import { DoctorFollowupEffect } from './report/doctor-followup/doctor-followup.effect';
import { DocumentEffect } from './report/document/document.effect';
import { DocumentHttpService } from './report/document/document.service';
import { ElementChoiceEffect } from './report/element-choice/element-choice.effect';
import { ElementChoiceHttpService } from './report/element-choice/element-choice.service';
import { FavouriteEffect } from './report/favourite/favourite.effect';
import { FavouriteHttpService } from './report/favourite/favourite.service';
import { FeedbackCommentEffect } from './report/feedback-comment/feedback-comment.effect';
import { FeedbackCommentHttpService } from './report/feedback-comment/feedback-comment.service';
import { FeedbackEffect } from './report/feedback/feedback.effect';
import { FeedbackHttpService } from './report/feedback/feedback.service';
import { FollowupTaskEffect } from './report/followup-task/followup-task.effect';
import { FollowupTaskHttpService } from './report/followup-task/followup-task.service';
import { JustificationEffect, JustificationHttpService } from './report/justification';
import { LabelEffect } from './report/label/label.effect';
import { LabelHttpService } from './report/label/label.service';
import { PresetEffect } from './report/preset/preset.effect';
import { PresetHttpService } from './report/preset/preset.service';
import { RegionChoiceEffect } from './report/region-choice/region-choice.effect';
import { RegionChoiceHttpService } from './report/region-choice/region-choice.service';
import { ReportStatusEffect } from './report/report-status/report-status.effect';
import { ReportEffect } from './report/report/report.effect';
import { ReportHttpService } from './report/report/report.service';
import { SectionChoiceEffect } from './report/section-choice/section-choice.effect';
import { SectionChoiceHttpService } from './report/section-choice/section-choice.service';
import { SendEventEffect } from './report/send-event/send-event.effect';
import { SendEventHttpService } from './report/send-event/send-event.service';
import { StatementChoiceUsageAnalyticsEffect } from './report/statement-choice-usage-analytics/statement-choice-usage-analytics.effect';
import { StatementChoiceUsageAnalyticsHttpService } from './report/statement-choice-usage-analytics/statement-choice-usage-analytics.service';
import { StatementChoiceEffect } from './report/statement-choice/statement-choice.effect';
import { StatementChoiceHttpService } from './report/statement-choice/statement-choice.service';
import { SubsectionChoiceEffect } from './report/subsection-choice/subsection-choice.effect';
import { SubsectionChoiceHttpService } from './report/subsection-choice/subsection-choice.service';
import { TextObjectChoiceEffect } from './report/text-object-choice/text-object-choice.effect';
import { TextObjectChoiceHttpService } from './report/text-object-choice/text-object-choice.service';
import { TodoEffect } from './report/todo/todo.effect';
import { TodoHttpService } from './report/todo/todo.service';
import { TopicEffect } from './report/topic/topic.effect';
import { TopicHttpService } from './report/topic/topic.service';
import { UrgentNoteEffect } from './report/urgent-note/urgent-note.effect';
import { UrgentNoteHttpService } from './report/urgent-note/urgent-note.service';
import { VoiceNoteEffect } from './report/voice-note/voice-note.effect';
import { VoiceNoteHttpService } from './report/voice-note/voice-note.service';
import { ScanCodeEffect } from './scan-code/scan-code.effect';
import { ScanCodeHttpService } from './scan-code/scan-code.service';
import { SessionEffect } from './session/session.effect';
import { SessionHttpService } from './session/session.service';
import { SettingEffect } from './setting/setting.effect';
import { SexSpecificWordEffect } from './sex-specific-words/sex-specific-word.effect';
import { SexSpecificWordHttpService } from './sex-specific-words/sex-specific-word.service';
import { SignatureEffect } from './signature/signature.effect';
import { SignatureHttpService } from './signature/signature.service';
import { SiteEffect } from './site/site.effect';
import { SiteHttpService } from './site/site.service';
import { SoftwarePreferenceEffect, SoftwarePreferenceHttpService } from './software-preferences';
import { StatementParameterConditionEffect } from './statement-parameter-condition';
import { StatementParameterConditionHttpService } from './statement-parameter-condition';
import {
  StatementParameterConditionGroupEffect,
  StatementParameterConditionGroupHttpService,
} from './statement-parameter-condition-group';
import { TagSearchTermEffect } from './tag-search-term/tag-search-term.effect';
import { TagSearchTermHttpService } from './tag-search-term/tag-search-term.service';
import { AttributeOptionEffect } from './template/attribute-option/attribute-option.effect';
import { AttributeOptionHttpService } from './template/attribute-option/attribute-option.service';
import { AttributeSetEffect } from './template/attribute-set/attribute-set.effect';
import { AttributeSetHttpService } from './template/attribute-set/attribute-set.service';
import { BodyPartEffect } from './template/body-part/body-part.effect';
import { BodyPartHttpService } from './template/body-part/body-part.service';
import { DefaultAttributeEffect } from './template/default-attribute/default-attribute.effect';
import { DefaultAttributeHttpService } from './template/default-attribute/default-attribute.service';
import { ElementEffect } from './template/element/element.effect';
import { ElementHttpService } from './template/element/element.service';
import { RegionSetEffect } from './template/region-set/region-set.effect';
import { RegionSetHttpService } from './template/region-set/region-set.service';
import { RegionEffect } from './template/region/region.effect';
import { RegionHttpService } from './template/region/region.service';
import { SectionEffect } from './template/section/section.effect';
import { SectionHttpService } from './template/section/section.service';
import { StatementCoincidenceEffect } from './template/statement-coincidence/statement-coincidence.effect';
import { StatementCoincidenceHttpService } from './template/statement-coincidence/statement-coincidence.service';
import { StatementSetEffect } from './template/statement-set/statement-set.effect';
import { StatementSetHttpService } from './template/statement-set/statement-set.service';
import { StatementEffect } from './template/statement/statement.effect';
import { StatementHttpService } from './template/statement/statement.service';
import { SubsectionEffect } from './template/subsection/subsection.effect';
import { SubsectionHttpService } from './template/subsection/subsection.service';
import { TemplateEffect } from './template/template/template.effect';
import { TemplateHttpService } from './template/template/template.service';
import { TextObjectEffect } from './template/text-object/text-object.effect';
import { TextObjectHttpService } from './template/text-object/text-object.service';
import { TitleOptionEffect } from './title/title-option/title-option.effect';
import { TitleOptionHttpService } from './title/title-option/title-option.service';
import { TitleSetEffect } from './title/title-set/title-set.effect';
import { TitleSetHttpService } from './title/title-set/title-set.service';
import { CompanyRoleEffect } from './user/company-role/company-role.effect';
import { CompanyRoleHttpService } from './user/company-role/company-role.service';
import { UserSettingEffect } from './user/user-setting/user-setting.effect';
import { UserSettingHttpService } from './user/user-setting/user-setting.service';
import { UserEffect } from './user/user/user.effect';
import { UserHttpService } from './user/user/user.service';

@NgModule({
  imports: [
    StoreModule.forRoot(reducerToken, {
      metaReducers: [enableBatching],
      runtimeChecks: {
        // https://ngrx.io/guide/store/configuration/runtime-checks
        strictStateImmutability: true,
        strictActionImmutability: true,
        strictActionTypeUniqueness: true,
      },
    }),
    // Uncomment these when you want to see store in redux dev tool
    environment.production ? [] : StoreDevtoolsModule.instrument({ maxAge: 20 }),
  ],
  exports: [],
  declarations: [],
  /**
   * Some of the new effects like SubsectionEffect are now a kind of Service Effect,
   * so they should be included in the providers
   */
  providers: [
    reducerProvider,
    SectionEffect,
    SectionHttpService,
    SubsectionEffect,
    SubsectionHttpService,
    RegionEffect,
    RegionHttpService,
    RegionSetHttpService,
    RegionSetEffect,
    ElementHttpService,
    ElementEffect,
    StatementHttpService,
    StatementEffect,
    StatementSetEffect,
    StatementSetHttpService,
    TemplateEffect,
    TemplateHttpService,
    DefaultAttributeEffect,
    DefaultAttributeHttpService,
    TextObjectEffect,
    TextObjectHttpService,
    StatementCoincidenceEffect,
    StatementCoincidenceHttpService,
    AttributeSetEffect,
    AttributeSetHttpService,
    AttributeOptionEffect,
    AttributeOptionHttpService,
    UserEffect,
    UserHttpService,
    CompanyRoleEffect,
    CompanyRoleHttpService,
    UserSettingHttpService,
    UserSettingEffect,
    SignatureEffect,
    SignatureHttpService,
    SiteEffect,
    SiteHttpService,
    TagEffect,
    TagHttpService,
    TagChoiceEffect,
    TagChoiceHttpService,
    MachineEffect,
    MachineHttpService,
    BookingRateEffect,
    BookingRateHttpService,
    TitleOptionEffect,
    TitleOptionHttpService,
    TitleSetEffect,
    TitleSetHttpService,
    KeywordAbbreviationEffect,
    KeywordAbbreviationHttpService,
    NoticeEffect,
    NoticeHttpService,
    CategoryEffect,
    CategoryHttpService,
    CategoryStatementComboEffect,
    CategoryStatementComboHttpService,
    TodoEffect,
    TodoHttpService,
    UrgentNoteEffect,
    UrgentNoteHttpService,
    AuditEventEffect,
    AuditEventHttpService,
    ReportAccessEventEffect,
    ReportAccessEventHttpService,
    SendEventEffect,
    SendEventHttpService,
    FeedbackEffect,
    FeedbackHttpService,
    FeedbackCommentEffect,
    FeedbackCommentHttpService,
    FavouriteEffect,
    FavouriteHttpService,
    ReportEffect,
    ReportHttpService,
    DocumentHttpService,
    DocumentEffect,
    TextObjectChoiceEffect,
    TextObjectChoiceHttpService,
    SectionChoiceEffect,
    SectionChoiceHttpService,
    SubsectionChoiceEffect,
    SubsectionChoiceHttpService,
    RegionChoiceEffect,
    RegionChoiceHttpService,
    ElementChoiceEffect,
    ElementChoiceHttpService,
    StatementChoiceEffect,
    StatementChoiceHttpService,
    TopicEffect,
    TopicHttpService,
    DicomEffect,
    DicomHttpService,
    SettingEffect,
    ChoiceErrorEffect,
    PatientEffect,
    PatientHttpService,
    ReferrerEffect,
    ReferrerHttpService,
    BookingEffect,
    BookingHttpService,
    ReportMergeConflictEffect,
    ReportMergeConflictService,
    PatientQuestionAnswerEffect,
    PatientQuestionAnswerHttpService,
    PatientQuestionSetEffect,
    PatientQuestionSetHttpService,
    PatientQuestionEffect,
    PatientQuestionHttpService,
    SessionEffect,
    SessionHttpService,
    PermissionEffect,
    PermissionHttpService,
    ScanCodeEffect,
    ScanCodeHttpService,
    BillingItemEffect,
    BillingItemHttpService,
    ProviderNumberEffect,
    ProviderNumberHttpService,
    InvoiceEffect,
    InvoiceHttpService,
    InvoiceItemEffect,
    InvoiceItemHttpService,
    InstitutionEffect,
    InstitutionHttpService,
    BillingGroupEffect,
    BillingGroupHttpService,
    BookingCodeEffect,
    BookingCodeHttpService,
    VoiceNoteHttpService,
    VoiceNoteEffect,
    BookingCodeLocatorEffect,
    BookingCodeLocatorHttpService,
    PaymentEffect,
    PaymentHttpService,
    TagSearchTermEffect,
    TagSearchTermHttpService,
    ReferrerMergeConflictService,
    PatientMergeConflictService,
    FollowupTaskEffect,
    FollowupTaskHttpService,
    LandmarkLabelHttpService,
    LandmarkLabelEffect,
    IssueHttpService,
    ReferrerContactMethodEffect,
    ReferrerContactMethodHttpService,
    ReferrerNoteEffect,
    SoftwarePreferenceEffect,
    SoftwarePreferenceHttpService,
    ReferrerNoteHttpService,
    ReferrerNoteCategoryEffect,
    ReferrerNoteCategoryHttpService,
    PatientNoteEffect,
    PatientNoteHttpService,
    PatientNoteCategoryEffect,
    PatientNoteCategoryHttpService,
    BookingEnquiryHttpService,
    ReferralEnquiryHttpService,
    ReferralEnquiryEffect,
    ImgsimParamsEffect,
    ImgsimParamsHttpService,
    StatementParameterConditionEffect,
    StatementParameterConditionHttpService,
    StatementParameterConditionGroupEffect,
    StatementParameterConditionGroupHttpService,
    DocumentationLinkEffect,
    DocumentationLinkHttpService,
    CorrelatedSearchEffect,
    CorrelatedSearchHttpService,
    DoctorFollowupEffect,
    MandatoryStatementHttpService,
    MandatoryStatementEffect,
    JustificationHttpService,
    JustificationEffect,
    UserNotificationsEffect,
    PercentileBandHttpService,
    PercentileBandEffect,
    PercentileBandRuleHttpService,
    PercentileBandRuleEffect,
    PercentileBandGroupHttpService,
    PercentileBandGroupEffect,
    GithubIssueEffect,
    ReportStatusEffect,
    StatementChoiceUsageAnalyticsEffect,
    StatementChoiceUsageAnalyticsHttpService,
    BodyPartEffect,
    BodyPartHttpService,
    LabelEffect,
    LabelHttpService,
    BillingItemReferrerTypeEffect,
    BillingItemReferrerTypeHttpService,
    SexSpecificWordEffect,
    SexSpecificWordHttpService,
    ExactMatchTopicEffect,
    ExactMatchTopicHttpService,
    PresetEffect,
    PresetHttpService,
    PathologyHttpService,
    PathologyEffect,
    RegistrationFormEffect,
    RegistrationFormHttpService,
  ],
})
export class RRStoreModule {}
