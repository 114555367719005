import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filterDefined, strToNum } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { AppState } from 'app/store';
import { EditorActions } from 'app/store/editor';
import { fromTopic, TopicEffect } from 'app/store/report/topic';
import { BehaviorSubject, connectable, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';

import { TriggerUnion } from './prefill-search-metadata/prefill-search-metadata.component';
import { ESResult, PrefillSelectTopicType, PrefillSourceType } from './prefill.types';

@Injectable()
export class PrefillService {
  constructor(
    private store: Store<AppState>,
    private editorService: EditorService,
    private messageService: MessageService,
    private http: HttpClient,
    private reportService: ReportService,
    private topicEffect: TopicEffect,
  ) {}

  prefillPreviewLoading$ = new BehaviorSubject<boolean>(false);
  selectPrefillTopics$ = new Subject<{ type: PrefillSelectTopicType }>();
  refreshExactMatch$ = new Subject();

  exactMatchOpen$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  changePrefillSource$: Subject<PrefillSourceType> = new Subject();
  prefillBySubdivisions$ = new BehaviorSubject<{ subdivision: RR.Subdivision; topicIds: string[] }[]>([]);
  changeLandmarkFilter$ = new Subject<string>();
  showExactMatchResults$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isShowB3ResultsButtonVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isExactMatchResultsLoading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  searchResultsUpdated = new Subject<{
    result: ESResult | null;
    reason?: TriggerUnion;
  }>();
  manualSearchSubject = new Subject<null>();

  setPrefillPreviewTopics({
    openTopicId,
    topicIds: topicIds,
    forceReload = false,
  }: {
    openTopicId: number;
    topicIds: string[];
    forceReload?: boolean;
  }) {
    this.store.dispatch(
      EditorActions.setPrefillTopicPreview({
        openTopicId,
        prefillPreview: { topicIds },
      }),
    );

    // Check the store first
    this.store
      .select(fromTopic.selectEntities)
      .pipe(take(1))
      .subscribe((topic_entities) => {
        const unloadedTopicIds = topicIds.filter((topic_id) => {
          const topic = topic_entities[strToNum(topic_id)];
          return forceReload || !topic || !topic.section_choices;
        });

        if (unloadedTopicIds.length > 0) {
          this.loadTopics(unloadedTopicIds);
        }
      });
  }

  loadTopics(topic_ids: string[]) {
    if (!topic_ids.length) return;
    this.prefillPreviewLoading$.next(true);
    this.topicEffect
      .findMultipleTopics(topic_ids.map((id) => strToNum(id)))
      .pipe(take(1))
      .subscribe(() => {
        this.prefillPreviewLoading$.next(false);
      });
  }

  addPrefillPreviewTopic(open_topic_id: number, topic_id: string) {
    this.store.dispatch(EditorActions.addTopicToPrefillPreview({ openTopicId: open_topic_id, topicId: topic_id }));
    if (topic_id) {
      const t_id = strToNum(topic_id);
      this.store
        .select(fromTopic.selectEntities)
        .pipe(take(1))
        .subscribe((topic_entities) => {
          const topic = topic_entities[t_id];
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
          if (!topic || topic.section_choices == null) {
            this.prefillPreviewLoading$.next(true);
            this.topicEffect
              .find(t_id)
              .pipe(take(1))
              // eslint-disable-next-line rxjs/no-nested-subscribe -- 2
              .subscribe(() => {
                this.prefillPreviewLoading$.next(false);
              });
          }
        });
    }
  }

  removePrefillPreviewTopic(open_topic_id: number, topic_id: string) {
    this.store.dispatch(EditorActions.removeTopicFromPrefillPreview({ openTopicId: open_topic_id, topicId: topic_id }));
  }

  getPrefillPreviewObject(open_topic_id: number) {
    return this.editorService.getEditorTopics(open_topic_id).pipe(map((t) => t.prefillPreview));
  }

  getPrefillPreviewTopics(topic_id: number) {
    return this.getPrefillPreviewObject(topic_id).pipe(
      filterDefined(),
      map((preview) => preview.topicIds),
      distinctUntilChanged(),
      switchMap((topic_ids) => this.store.select(fromTopic.selectTopics(topic_ids.map((tid) => Number(tid))))),
    );
  }

  getPrefillLogisticRegressionFiltering(selected_topic_ids: string[], topic_id: number) {
    return this.http.get<any>('/api/ml/topic/get_logistic_regression_prefill_filtering', {
      params: {
        topic_id: topic_id.toString(),
        selected_topic_ids,
      },
    });
  }

  copyChoices(topicId: number, choiceIds: number[], keepNumbers: boolean, statement?: RR.Statement, reportId?: number) {
    const obs = connectable(this.topicEffect.copyChoices(topicId, choiceIds, keepNumbers));
    obs.pipe(take(1)).subscribe((action) => {
      const statementChoiceId = action.actions.statementChoiceAddMany.statementChoices[0].id;
      if (reportId && statement)
        this.editorService.createMedicalNotesFromStatementTooltip(statementChoiceId, reportId, statement);
      this.reportService.notifyEdit();
      this.messageService.add({
        id: action.type,
        title: 'Success',
        message: 'Study prefilled',
        type: 'success',
        timeout: 2000,
      });
    });
    obs.connect();
    return obs;
  }

  importTopicToPrefill(openTopicId: number, prefillTopicId: string, expandPrefill: boolean) {
    this.setPrefillPreviewTopics({
      openTopicId: openTopicId,
      topicIds: [prefillTopicId],
      forceReload: true,
    });
    if (expandPrefill) {
      this.store.dispatch(EditorActions.togglePrefill({ prefill: true }));
    }
  }

  selectPrefill({
    event,
    selected,
    openTopicId,
    topicId,
  }: {
    event: MouseEvent;
    selected: boolean;
    openTopicId: number;
    topicId: string;
  }) {
    if (!(event.target instanceof HTMLElement)) throw new Error('Invalid event target');

    if (event.target.closest('[data-no-bubble-select-prefill-row]')) {
      return;
    }

    if (event.shiftKey) {
      if (selected) {
        this.removePrefillPreviewTopic(openTopicId, topicId);
      } else {
        this.addPrefillPreviewTopic(openTopicId, topicId);
      }
    } else {
      this.selectPrefillTopics$.next({ type: 'SELECT_TOPIC' });

      this.setPrefillPreviewTopics({
        openTopicId: openTopicId,
        topicIds: [topicId],
      });
    }
  }
}
