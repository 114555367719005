import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { AppState } from 'app/store';
import { BookingEffect, PartialBooking, UpdateBookingBody } from 'app/store/booking';
import { ReportEffect } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { fromSite } from 'app/store/site';
import { fromUser } from 'app/store/user/user';
import { addMinutes, formatISO } from 'date-fns';
import { isEqual } from 'lodash-es';
import { Observable, of, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { TooltipDirective } from '../../../../shared/directives/tooltip.directive';
import { BookingCodeDetailComponent } from '../../../booking/components/booking-code/booking-code-detail/booking-code-detail.component';
import { BookingCodeSelectModalComponent } from '../../../booking/modals/booking-code-select-modal/booking-code-select-modal.component';
import { ScanCodeSelectModalComponent } from '../../modals/scan-code-select-modal/scan-code-select-modal.component';
import { BookingForm, RegistrationService, ReportForm, ReportFormValue } from '../../services/registration.service';
import { MedicareProviderComponent } from '../medicare-provider/medicare-provider.component';

export const REPORT_FORM_ID = 'report-form';

@Component({
  selector: 'rr-report-form',
  templateUrl: './report-form.component.html',
  styleUrls: ['./report-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TooltipDirective,
    FormsModule,
    ReactiveFormsModule,
    MedicareProviderComponent,
    BookingCodeDetailComponent,
  ],
  host: {
    id: REPORT_FORM_ID,
  },
})
export class ReportFormComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() report?: RR.Report;
  report$: Observable<RR.Report | undefined>;

  @Input() patient?: RR.Patient;
  @Input() referrer?: RR.Referrer;

  viewMode: 'view' | 'edit' = 'view';
  sites: RR.Site[] = [];
  subscription: Subscription = new Subscription();
  reportScanCode$: Observable<RR.ScanCode | undefined>;
  scanCode$: Observable<RR.ScanCode | undefined>;
  bookingCode$: Observable<RR.BookingCode | undefined>;
  booking: RR.Booking | undefined;
  providerNumber$: Observable<RR.ProviderNumber | undefined>;

  providerNumber: RR.ProviderNumber | undefined;
  doctor: string | undefined;
  site$: Observable<RR.Site | undefined>;

  selectedBillingItemIds: number[] = [];
  funder: RR.FunderType;

  isReferralDateInFuture: boolean;
  bookingForm: BookingForm;
  form: ReportForm;
  originalFormValue: ReportFormValue | undefined;

  // Method to check if the referral date is in the future
  checkReferralDate() {
    const referralDate = this.form.controls.referral_date.value;
    this.isReferralDateInFuture = new Date(referralDate as string) > new Date();
  }

  constructor(
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
    private messageService: MessageService,
    private bookingEffect: BookingEffect,
    private modalService: NgbModal,
    private router: Router,
    public registrationService: RegistrationService,
  ) {
    this.bookingForm = registrationService.bookingForm;
    this.form = registrationService.reportForm;
  }

  ngOnInit() {
    this.subscription.add(
      this.report$.subscribe((report) => {
        if (report) {
          this.viewMode = 'view';
        } else {
          this.viewMode = 'edit';
        }
      }),
    );

    this.subscription.add(
      this.store.select(fromSite.selectActiveSites).subscribe((allSites) => {
        this.sites = allSites;
      }),
    );

    this.subscription.add(
      this.registrationService.fromBooking$.subscribe((booking) => {
        this.booking = booking;
      }),
    );

    this.providerNumber$ = this.registrationService.providerNumber$;

    this.subscription.add(
      this.providerNumber$
        .pipe(
          switchMap((providerNumber) =>
            providerNumber ? this.store.select(fromUser.selectUser(providerNumber.user_id)) : of(undefined),
          ),
        )
        .subscribe((user) => {
          this.doctor = user?.name;
        }),
    );

    this.site$ = this.registrationService.site$;
    this.scanCode$ = this.registrationService.scanCode$;
    this.bookingCode$ = this.registrationService.bookingCode$;

    this.subscription.add(
      this.registrationService.billingItemIds$.subscribe((data) => {
        this.selectedBillingItemIds = data;
      }),
    );

    this.subscription.add(
      this.registrationService.funder$.subscribe((data) => {
        this.funder = data;
      }),
    );
  }

  formValidation() {
    // This shows the inline errors messages
    this.form.markAllAsTouched();
  }

  editBooking() {
    this.formValidation();
    this.viewMode = 'edit';
    this.originalFormValue = this.form.getRawValue();
  }

  cancel() {
    this.form.markAsPristine();
    this.viewMode = 'view';
    if (this.report && this.originalFormValue) {
      // TODO(reg): this is misleading because the booking form changes (like bookingCodeId and siteId) are not reverted
      // Reset to original values
      this.form.patchValue(this.originalFormValue);
      this.originalFormValue = undefined;
    }

    if (this.booking) {
      this.registrationService.bookingForm.controls.billingItemIds.setValue(this.booking.billing_items);
    }
  }

  submitReport() {
    this.formValidation();
    // Check input data
    if (!this.form.valid) {
      this.messageService.add({
        title: 'Error',
        message: 'Some fields are still invalid',
        type: 'danger',
      });
      return;
    }

    if (this.booking) {
      // Update report or register a new report from booking
      const changes: UpdateBookingBody = {};
      if (this.booking.booking_code_id !== this.bookingForm.controls.bookingCodeId.value) {
        changes.booking_code_id = this.bookingForm.controls.bookingCodeId.value || undefined;
      }
      if (this.booking.site_id !== this.bookingForm.controls.site_id.value) {
        changes.site_id = this.bookingForm.controls.site_id.value || undefined;
      }
      if (!!this.patient?.id && this.booking.patient_id !== this.patient.id) {
        changes.patient_id = this.patient.id;
      }
      if (!isEqual(this.booking.billing_items, this.selectedBillingItemIds)) {
        changes.billing_items = this.selectedBillingItemIds;
      }
      if (this.booking.funder !== this.funder) {
        changes.funder = this.funder;
      }

      if (Object.keys(changes).length > 0) {
        this.subscription.add(this.bookingEffect.update(this.booking.id, changes).subscribe());
      }
      this.createOrUpdateReport(this.booking.id);
    } else {
      // Third: No radresBooking or reportBooking => Create a walk-in booking
      const booking: PartialBooking = {
        booking_code_id: this.bookingForm.controls.bookingCodeId.value || undefined,
        patient_id: this.patient?.id,
        type: 'walkin',
        start_time: formatISO(new Date(), { representation: 'complete' }),
        end_time: formatISO(addMinutes(new Date(), 20), { representation: 'complete' }),
        site_id: this.bookingForm.controls.site_id.value || undefined,
        referrer_type: this.referrer?.referrer_type,
        funder: this.funder,
      };

      this.subscription.add(
        this.bookingEffect
          .create({
            booking,
            billing_items: this.selectedBillingItemIds,
          })
          .pipe(take(1))
          .subscribe((action) => {
            this.createOrUpdateReport(action.booking.id);
          }),
      );
    }
  }

  createOrUpdateReport(booking_id: number | undefined) {
    if (this.report) {
      const changes: Partial<RR.Report> = {
        referral_date: this.form.controls.referral_date.value || undefined,
        scan_code_id: this.form.controls.scan_code_id.value,
        scan_code_side: this.form.controls.scan_code_side.value,
        medicare_provider_id: this.form.controls.medicare_provider_id.value,
      };
      if (booking_id) changes.booking_id = booking_id;
      this.updateReport(this.report.id, changes);
    } else {
      const newReport: Partial<RR.Report> = {
        patient_id: this.patient?.id,
        referrer_id: this.referrer?.id,
        // Booking id either come form prefilling booking or the new walkin booking created
        booking_id: booking_id,
        referral_date: this.form.controls.referral_date.value || undefined,
        scan_code_id: this.form.controls.scan_code_id.value,
        scan_code_side: this.form.controls.scan_code_side.value,
        medicare_provider_id: this.form.controls.medicare_provider_id.value,
      };
      this.createReport(newReport);
    }
  }

  updateReport(id: number, changes: Partial<RR.Report>) {
    this.subscription.add(
      this.reportEffect
        .update(id, changes)
        .pipe(take(1))
        .subscribe({
          next: (action) => {
            const report = action.actions.updateReportSuccess.report;
            this.postSubmitReportSuccess(report, false);
          },
        }),
    );
  }

  createReport(newReport: Partial<RR.Report>) {
    this.subscription.add(
      this.reportEffect
        .create(newReport)
        .pipe(take(1))
        .subscribe({
          next: (action) => {
            const report = action.report;
            this.postSubmitReportSuccess(report, true);
          },
        }),
    );
  }

  postSubmitReportSuccess(report: RR.Report, created = true) {
    this.form.markAsPristine();
    this.messageService.add({
      title: 'Success',
      message: created ? 'Create new report successfully!' : 'Update report successfully!',
      type: 'success',
    });
    // document.getElementById('patient-form').scrollIntoView({ behavior: 'smooth', block: 'start' });
    // Emit report to parent component after registering
    this.subscription.add(
      this.registrationService.selectReport(report).subscribe(() => {
        if (created) {
          // This use queryParams to update the report.id in the URL without "navigating" and destroying the
          // RegistrationComponent
          this.router.navigate(['registration', 'report'], {
            queryParams: {
              reportId: report.id,
            },
          });
        }
        this.viewMode = 'view';
      }),
    );
  }

  selectBookingCode() {
    // Reset the billing item selection whenever the booking code is updated
    const modalRef = BookingCodeSelectModalComponent.open(this.modalService);
    modalRef.result.then(
      (id: number) => {
        this.registrationService.selectBookingCode({ bookingCodeId: id });
      },
      () => {
        // Do nothing
      },
    );
  }

  onBillingItemChange(billingItemIds: number[]) {
    this.registrationService.bookingForm.controls.billingItemIds.setValue(billingItemIds);
  }

  selectScanCode() {
    const modalRef = ScanCodeSelectModalComponent.open(this.modalService);
    modalRef.result.then(
      (id: number) => {
        this.subscription.add(
          this.store
            .select(fromScanCode.selectScanCode(id))
            .pipe(take(1), filterDefined())
            .subscribe((scanCode) => {
              this.registrationService.selectScanCode({ scanCode });
            }),
        );
      },
      () => {
        // Do nothing
      },
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
