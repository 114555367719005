import { NgTemplateOutlet, CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Dictionary } from '@ngrx/entity';
import { Store } from '@ngrx/store';
import {
  BindObservable,
  elementRelativeToParent,
  filterDefined,
  scrollIntoView,
  strToNum,
  trackById,
} from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { MandatoryStatementService } from 'app/core/services/mandatory-statement.service';
import { ReportService } from 'app/core/services/report.service';
import { IElement, TemplateService } from 'app/core/services/template.service';
import { CheckboxComponent } from 'app/shared/components/checkbox/checkbox.component';
import { AppState } from 'app/store';
import { fromLandmarkLabel } from 'app/store/landmark-label/landmark-label.selector';
import { fromTagChoice } from 'app/store/prefill/tag-choice';
import { StatementChoiceEffect } from 'app/store/report/statement-choice';
import { fromTopic } from 'app/store/report/topic';
import { fromSession } from 'app/store/session';
import { fromRegionSet } from 'app/store/template/region-set';
import { fromStatement } from 'app/store/template/statement';
import { fromCurrentTemplate } from 'app/store/template/template';
import { uniq, uniqBy, uniqWith } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  skipWhile,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';

import { AutoSelectGroupsButtonComponent } from '../../../../shared/components/auto-select-groups-button/auto-select-groups-button.component';
import { LinkifyDirective } from '../../../../shared/directives/linkify/linkify.directive';
import { SelectPipe } from '../../../../shared/pipes/select-pipe';
import { StoreSelectPipe } from '../../../../shared/pipes/store-select.pipe';
import { SharedModule } from '../../../../shared/shared.module';
import { TagHttpService } from '../../../../store/prefill/tag';
import { ChoiceComponent } from '../../choice/choice.component';
import { StatementPreviewComponent } from '../../statement-edit/statement-preview/statement-preview.component';
import { StatementTextRenderComponent } from '../../statement-edit/statement-text-render/statement-text-render.component';
import { RecommendedStatementComponent } from '../../statement-recommendation/recommended-statement/recommended-statement.component';
import { LandmarkLabelHeadlineComponent } from '../landmark-label-headline/landmark-label-headline.component';
import { PrefillTutorialComponent } from './../prefill-tutorial/prefill-tutorial.component';
import { PrefillService } from './../prefill.service';
import { PrefillCopyChoiceComponent } from './prefill-copy-choice/prefill-copy-choice.component';
import { PrefillCopyElementComponent } from './prefill-copy-element/prefill-copy-element.component';

/**
 * Add Template information to the TagChoice
 *
 * The TagChoice interface describes the information stored within the
 * database, however for working with tags in the context of the report, we also
 * need the template information. In this case it is the statement_set_id.
 *
 * @param tag_text: The display text of the tag. This is used within the
 * prefill copy module to display the tag and the statements associated with
 * it.
 * @param statement_set_id: Used for identifying the element to which the tag
 * belongs. This is hte header under which the tag sits.
 * @param position: The position of the divider within the element. This is
 * based on the position of the statements it is used with.
 *
 */
export interface TagChoiceTemplate extends RR.TagChoice {
  tag_text: string;
  statement_set_id: number;
  position: number;
  isNotepad: boolean;
}

export type PrefillCopyChoicesData = {
  choiceIds: number[];
  keepNumbers?: boolean;
  choice?: RR.FullStatementChoice;
};

@Component({
  selector: 'rr-prefill-copy',
  templateUrl: './prefill-copy.component.html',
  styleUrls: ['./prefill-copy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SharedModule,
    LinkifyDirective,
    ChoiceComponent,
    NgbPopover,
    NgTemplateOutlet,
    StatementTextRenderComponent,
    LandmarkLabelHeadlineComponent,
    StoreSelectPipe,
    SelectPipe,
    AutoSelectGroupsButtonComponent,
    StatementPreviewComponent,
    RecommendedStatementComponent,
    PrefillCopyChoiceComponent,
    PrefillCopyElementComponent,
    CheckboxComponent,
  ],
})
@LifecycleLogger
export class PrefillCopyComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() topic: RR.Topic;
  topic$: Observable<RR.Topic>;
  @Input() @BindObservable() threshold: number;
  threshold$: Observable<number>;
  @Input() @BindObservable() hideHistoryTechnique: boolean;
  hideHistoryTechnique$: Observable<boolean>;
  @Input() @BindObservable() moreComments: boolean;
  moreComments$: Observable<boolean>;

  // Filter choices with linear regression data returned from the api
  @Input() @BindObservable() logRegStatements: number[];
  logRegStatements$: Observable<number[]>;
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef<HTMLElement> | undefined;

  subscription = new Subscription();

  loading$: BehaviorSubject<boolean>;
  previewChoicesSubscription = new Subscription();
  trackById = trackById;
  selectedTopics: RR.Topic[] = [];

  sections: RR.Section[] = [];
  // Statements in template that are used to create choices in selected reports
  usedStatements: RR.Statement[] = [];
  usedDividers: TagChoiceTemplate[] = [];
  usedElements: IElement[] = [];
  usedSubsections: RR.Subsection[] = [];
  usedSections: RR.Section[];
  notepadStatements: RR.Statement[] = [];
  // All choices from selected prefill topics
  keyFindings: RR.FullStatementChoice[] = [];
  cloneComments: RR.FullStatementChoice[] = [];
  cloneImpressions: RR.FullStatementChoice[] = [];

  /**
   * Merged Prefill and Current Choices
   */
  prefillContextChoices: RR.CtxFullStatementChoice[] = [];
  prefillTopicsChoices$: Observable<RR.Ctx[]>;
  currentTopicChoices$: Observable<RR.Ctx[]>;
  currentContextChoices: RR.CtxFullStatementChoice[] = [];
  metric: { intersection: number; totalPrefills: number; totalCurrent: number };
  suggestionStatementIds: Set<number> = new Set();

  tagChoices$: Observable<RR.TagChoice[]>;
  statements$: Observable<Dictionary<RR.Statement>>;
  templateElements$: Observable<IElement[]>;
  notepads$: Observable<RR.Element[]>;

  // Prefill title/prediction sentences
  processingPrefillTitle = false;
  is_in_sc_mode$ = this.editorService.toggleSCModeEventListener();

  exclusiveTagMap: { [tagId: number]: boolean } = {};

  hideExcludedTags = false;

  // Landmark filter for ML landmark template
  mlLandmarkTemplate$: Observable<boolean>;

  statementGroupIds: number[] = [];
  statementGroupTags: string[] = [];

  sectionOrSubsectionEl: HTMLElement | null = null;
  incompleteMandatoryStatements: RR.MandatoryStatement[] | undefined;

  constructor(
    private templateService: TemplateService,
    public reportService: ReportService,
    public prefillService: PrefillService,
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private editorService: EditorService,
    private store: Store<AppState>,
    private statementChoiceEffect: StatementChoiceEffect,
    private tagHttpService: TagHttpService,
    protected mandatoryStatementService: MandatoryStatementService,
  ) {
    this.tagChoices$ = this.store.select(fromTagChoice.selectLoaded).pipe(
      skipWhile((loaded) => !loaded),
      switchMap(() => this.store.select(fromTagChoice.selectAll)),
    );
    this.statements$ = this.store.select(fromStatement.selectEntities);
  }

  ngOnInit() {
    this.loading$ = this.prefillService.prefillPreviewLoading$;
    const topicLoaded$ = this.topic$.pipe(
      switchMap((topic) => this.store.select(fromTopic.selectLoaded(topic.id))),
      skipWhile((loaded) => !loaded),
    );
    this.currentTopicChoices$ = topicLoaded$.pipe(switchMap(() => this.reportService.getChoicesWithCtx(this.topic.id)));

    this.prefillTopicsChoices$ = this.topic$
      .pipe(switchMap((topic) => this.prefillService.getPrefillPreviewTopics(topic.id)))
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        filter((topics) => topics && topics.length > 0),
        distinctUntilChanged(),
      )
      .pipe(
        switchMap((topics) => {
          // @ts-expect-error strictNullChecks
          this.selectedTopics = topics;
          // @ts-expect-error strictNullChecks
          return combineLatest(topics.map((t) => this.reportService.getChoicesWithCtx(t.id))).pipe(
            map((val) => val.flat()),
          );
        }),
      );

    this.templateElements$ = this.topic$.pipe(switchMap((t) => this.templateService.getElements(t.template_id)));
    this.notepads$ = this.store.select(fromCurrentTemplate.selectNotepads);

    this.filterPrefillChoices();

    this.findExclusiveTags();

    this.mlLandmarkTemplate$ = this.topic$.pipe(
      withLatestFrom(this.store.select(fromSession.selectRRConfig).pipe(filterDefined())),
      map(([topic, rrConfig]) => rrConfig.IMGSIM_LANDMARK_TEMPLATES.includes(topic.template_id)),
    );

    this.editorService.registerPrefillShortcuts = () => this.registerKeyboardShortcuts();
    this.editorService.unregisterPrefillShortcuts = () => this.editorService.unregisterKeyboardShortcuts();
    // TODO: This should be moved to a more appropriate place
    this.editorService.subscribeToKeyboardShortcuts();

    this.subscription.add(
      this.topic$
        .pipe(
          switchMap((topic) => {
            return this.reportService.getIncompleteMandatoryStatements(topic);
          }),
        )
        .subscribe((mandatoryStatements) => {
          this.incompleteMandatoryStatements = mandatoryStatements;
          this.cd.markForCheck();
        }),
    );
  }

  registerKeyboardShortcuts() {
    this.editorService.registerKeyboardShortcuts({
      right: () => this.scrollToNextSectionOrSubsection({ direction: 'down', data: '[data-subsection]' }),
      left: () => this.scrollToNextSectionOrSubsection({ direction: 'up', data: '[data-subsection]' }),
      up: () => this.scrollToNextSectionOrSubsection({ direction: 'up', data: '[data-section]' }),
      down: () => this.scrollToNextSectionOrSubsection({ direction: 'down', data: '[data-section]' }),
    });
  }

  /**
   * Find tags that are suggested in prefill but are unlikely to be added to current topic
   */
  findExclusiveTags() {
    const uniqueTagsFromChoices = (ctxChoices$: Observable<RR.Ctx[]>) => {
      return ctxChoices$.pipe(
        withLatestFrom(this.statements$),
        map(([ctxChoices, statements]) =>
          uniq(
            ctxChoices
              .map((ctx) => {
                const statement_id = ctx.statement_choice?.statement_id;
                if (!statement_id) return null;
                const statement = statements[statement_id];
                if (!statement?.divider_id) return null;
                return statements[statement.divider_id]?.id;
              })
              .filter((id) => !!id),
          ),
        ),
      );
    };
    const currentTopicTags$ = uniqueTagsFromChoices(this.currentTopicChoices$);

    const prefillTopicTags$ = uniqueTagsFromChoices(this.prefillTopicsChoices$);
    // Prefill exclusive tags should be checked whenever current topic tags or prefill suggested tags changed
    this.previewChoicesSubscription.add(
      this.store
        .select(fromSession.selectRRConfig)
        .pipe(
          filterDefined(),
          take(1),
          filter((rrConfig) => rrConfig.ANALYTICS),
          switchMap(() =>
            combineLatest([
              currentTopicTags$.pipe(distinctUntilChanged()),
              prefillTopicTags$.pipe(distinctUntilChanged()),
            ])
              .pipe(debounceTime(500))
              .pipe(
                switchMap(([_, prefillTopicTags]) =>
                  // @ts-expect-error strictNullChecks
                  this.tagHttpService.getExclusiveTags(this.topic.id, prefillTopicTags),
                ),
              ),
          ),
        )
        .subscribe((data) => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          this.exclusiveTagMap = (data.exclusive_tags || []).reduce((map, tagId) => {
            // @ts-expect-error noImplicitAny
            map[tagId] = true;
            return map;
          }, {});
          this.cd.markForCheck();
        }),
    );
  }

  /**
   * Construct the prefill choices, filter out duplicate choices
   */
  filterPrefillChoices() {
    this.previewChoicesSubscription.add(
      combineLatest([
        this.currentTopicChoices$,
        this.prefillTopicsChoices$,
        this.threshold$,
        this.hideHistoryTechnique$,
        this.moreComments$,
        this.logRegStatements$,
        this.tagChoices$,
        this.statements$,
        this.templateElements$,
        this.notepads$,
        this.prefillService.prefillBySubdivisions$,
      ]).subscribe(
        ([
          currentChoices,
          prefillChoices,
          threshold,
          hideHistoryTechnique,
          moreComments,
          logRegStatements,
          tagChoices,
          statement_entities,
          template_elements,
          notepads,
          prefillBySubdivisions,
        ]) => {
          // Get context choices from current topic and selected prefill topics, remove report specific choices
          this.currentContextChoices = currentChoices.filter((ctx): ctx is RR.CtxFullStatementChoice =>
            Boolean(ctx.statement_choice && ctx.statement_choice.statement_id !== null),
          );
          this.prefillContextChoices = prefillChoices.filter((ctx): ctx is RR.CtxFullStatementChoice =>
            Boolean(ctx.statement_choice && ctx.statement_choice.statement_id !== null),
          );

          if (prefillBySubdivisions.length > 0) {
            // If selecting prefill by subdivision, combine subdivisions from best matches topics
            // Only keep sentences that are under prefill subdivisions or are in current report
            this.prefillContextChoices = this.prefillContextChoices.filter((ctx) => {
              return (
                this.currentContextChoices.some(
                  (currentCtx) => ctx.statement_choice?.statement_id === currentCtx.statement_choice?.statement_id,
                ) ||
                prefillBySubdivisions.some(
                  (subdivision) =>
                    subdivision.topicIds.some((tid) => strToNum(tid) === ctx.topic?.id) &&
                    // @ts-expect-error strictNullChecks
                    subdivision.subdivision.section === ctx.section_choice.type &&
                    // @ts-expect-error strictNullChecks
                    subdivision.subdivision.subsection_id === ctx.subsection_choice.subsection_id &&
                    (!subdivision.subdivision.region_id ||
                      // @ts-expect-error strictNullChecks
                      subdivision.subdivision.region_id === ctx.region_choice.region_id),
                )
              );
            });
            // Filter duplicate choices and choices with statements that are no longer in the template
            // For prefill by subdivision, we filter these choices after filtering them by subdivisions
            // so that sentences in best matched topics don't mistakenly filtered out.
            this.prefillContextChoices = this.filterDuplicateAndLegacyChoices(
              this.prefillContextChoices,
              statement_entities,
              template_elements,
              notepads,
            );
          } else {
            // Filter duplicate choices and choices with statements that are no longer in the template
            this.prefillContextChoices = this.filterDuplicateAndLegacyChoices(
              this.prefillContextChoices,
              statement_entities,
              template_elements,
              notepads,
            );

            // If applying LogReg filtering, keep choices in LogReg suggestion list or under selected dividers
            if (logRegStatements.length > 0) {
              this.prefillContextChoices = this.prefillContextChoices.filter((ctx) => {
                // @ts-expect-error strictNullChecks
                if (logRegStatements.includes(ctx.statement_choice.statement_id)) return true;

                // Ignore LRA filtering for comment/I&R
                if (
                  // @ts-expect-error strictNullChecks
                  ctx.section_choice.type === 'comment' ||
                  // @ts-expect-error strictNullChecks
                  ctx.section_choice.type === 'impression_recommendations' ||
                  // @ts-expect-error strictNullChecks
                  ctx.statement_choice.clones.includes('comment') ||
                  // @ts-expect-error strictNullChecks
                  ctx.statement_choice.clones.includes('impression_recommendations')
                ) {
                  return true;
                }

                // @ts-expect-error strictNullChecks
                const statement = statement_entities[ctx.statement_choice.statement_id];
                return statement && tagChoices.some((tag) => tag.tag_id === statement.divider_id);
              });
            } else {
              // Otherwise, only show choices that meet certain threshold
              if (threshold > 1) {
                this.prefillContextChoices = this.filterByThreshold(
                  this.prefillContextChoices,
                  prefillChoices,
                  statement_entities,
                  tagChoices,
                  threshold,
                  moreComments,
                );
              }
            }
          }

          // To calculate the percentage of statements suggested by prefill that are in the final report
          // @ts-expect-error strictNullChecks
          const prefillStatementIds = this.prefillContextChoices.map((c) => c.statement_choice.statement_id);
          // @ts-expect-error strictNullChecks
          const currentStatementIds = this.currentContextChoices.map((c) => c.statement_choice.statement_id);
          const intersection = prefillStatementIds.filter((value) => currentStatementIds.includes(value));
          this.metric = {
            intersection: intersection.length,
            totalPrefills: prefillStatementIds.length,
            totalCurrent: currentStatementIds.length,
          };

          this.suggestionStatementIds = new Set(prefillStatementIds);
          // Merge current topic choices and prefill choices as we also want to show current topic choices in prefill
          // Firstly remove prefill choices that are already in current topic choices, then add all current topic choices to prefill choices
          this.prefillContextChoices = this.mergePrefillAndCurrentReportChoices(
            this.prefillContextChoices,
            this.currentContextChoices,
          );

          // Hide defaults/ Hide history and technique. Not hiding them when applying LogReg suggestion or prefilling by subdivisions
          if (!this.logRegStatements.length && !prefillBySubdivisions.length) {
            this.prefillContextChoices = this.filterChoicesBySectionOptions(
              this.prefillContextChoices,
              statement_entities,
              tagChoices,
              hideHistoryTechnique,
            );
          }

          // Get all unique keyfindings from selected topics
          // @ts-expect-error strictNullChecks
          this.keyFindings = this.getCloneChoices(this.prefillContextChoices, 'key_finding');
          // @ts-expect-error strictNullChecks
          this.cloneComments = this.getCloneChoices(this.currentContextChoices, 'comment');
          // @ts-expect-error strictNullChecks
          this.cloneImpressions = this.getCloneChoices(this.currentContextChoices, 'impression_recommendations');

          // @ts-expect-error strictNullChecks
          const statement_ids = this.prefillContextChoices.map((ctx) => ctx.statement_choice.statement_id);
          // Get statements, notepad statements, dividers, elements, subsections that are used to create choices in selected prefill topics
          this.usedStatements = statement_ids
            .map((statement_id) => statement_entities[statement_id])
            .filter((s): s is RR.Statement => !!s);

          this.notepadStatements = this.usedStatements.filter((s) =>
            notepads.find((np) => np.statement_set_id === s.statement_set_id),
          );

          this.usedDividers = this.findDividersInUse({
            ctx_choices: this.prefillContextChoices,
            usedStatements: this.usedStatements,
            notepadStatements: this.notepadStatements,
            statement_entities,
            tagChoices,
          });

          // Notepad copied elements
          const notepad_element_statement_set_ids = new Set(
            this.prefillContextChoices
              // @ts-expect-error strictNullChecks
              .filter((ctx) => this.notepadStatements.some((nps) => nps.id === ctx.statement_choice.statement_id))
              .map((ctx) => ctx.element_choice?.statement_set_id),
          );
          this.usedElements = this.findElementsInUse(
            this.usedStatements,
            this.usedDividers,
            template_elements,
            // @ts-expect-error strictNullChecks
            notepad_element_statement_set_ids,
          );
          this.usedSubsections = uniqBy(
            this.usedElements.map((e: IElement) => e.subsection),
            (s) => s.id,
          );
          this.usedSections = uniqBy(
            this.usedElements.map((e: IElement) => e.section),
            (s) => s.id,
          );

          this.cd.markForCheck();
        },
      ),
    );
  }

  /**
   * Find elements in template that contain subelements.
   *
   * An element is considered used, and therefore included within the display
   * when it contains subelements. This function takes the subelements as
   * input, building a list of elements for inclusion in the User Interface.
   *
   * @param usedStatements: The statements that have already been determined to be used.
   * @param usedDividers: The dividers (also known as tags) that have been selected for inclusion.
   * @param template_elements: All the template elements we have to choose
   * from. This is the starting component and will get filtered down to the
   * final view.
   */
  findElementsInUse(
    usedStatements: RR.Statement[],
    usedDividers: TagChoiceTemplate[],
    template_elements: IElement[],
    notepad_element_statement_set_ids: Set<number>,
  ): IElement[] {
    const from_choices_statement_set_ids = usedStatements.map((s) => s.statement_set_id);
    const from_tags_statement_set_ids = usedDividers.map((d) => d.statement_set_id);
    const statement_set_ids = new Set([...from_choices_statement_set_ids, ...from_tags_statement_set_ids]);
    return template_elements.filter(
      (e: IElement) =>
        statement_set_ids.has(e.element.statement_set_id) ||
        // Notepad statement link to notepad statement set, but when they are copied to an element,
        // we have to check their element_choice.element.statement_id
        notepad_element_statement_set_ids.has(e.element.statement_set_id),
    );
  }

  /**
   * Determine the dividers to be included within the prefill output.
   *
   * Within the prefill panel there is a list of dividers that will be
   * included. This function is used to determine the list of dividers to
   * include, taking into account the statements predicted along with the tags
   * that have been chosen.
   *
   * @param ctx_choices
   * @param usedStatements
   * @param statement_entities
   * @param tagChoices: These are the tags that have been chosen within the
   * prefill-tags modal.
   */
  findDividersInUse({
    ctx_choices,
    usedStatements,
    notepadStatements,
    statement_entities,
    tagChoices,
  }: {
    ctx_choices: RR.Ctx[];
    usedStatements: RR.Statement[];
    notepadStatements: RR.Statement[];
    statement_entities: Dictionary<RR.Statement>;
    tagChoices: RR.TagChoice[];
  }): TagChoiceTemplate[] {
    const dividers: TagChoiceTemplate[] = [];

    // Add all the tags that have been chosen to the list of Tags to be
    // displayed in the prefill. This means that any tag selected will be in
    // the prefill window.
    //
    // We can't directly add the tags, since we need to get the
    // statement_set_id from the list of statements.
    //
    // By adding the tagChoices first, we are getting the tags that have been
    // chosen in the prefill. Since these tags have the tagId set, they can
    // be compared with the tags that have been selected. These get chosen over
    // previous values, since the unique function returns the first value it
    // sees.
    tagChoices.forEach((tag) => {
      const divider = statement_entities[tag.tag_id];
      if (divider) {
        dividers.push({
          ...tag,
          statement_set_id: divider.statement_set_id,
          tag_text: divider._text,
          position: divider.position,
          isNotepad: false,
        });
      }
    });

    // For each of the dividers within the collection of used statements,
    // convert the statement to a TagChoiceTemplate. We don't define all the
    // fields since we can't easily get this information. The extra tag
    // information is only used to check whether the tag has been chosen in
    // the prefill. We get around needing this information by putting the
    // chosen tags first.
    usedStatements
      .filter((s) => !!s.divider_id)
      .forEach((s) => {
        const isNotepad = !!notepadStatements.find((np) => np.id === s.id);
        // @ts-expect-error strictNullChecks
        const divider: RR.Statement = statement_entities[s.divider_id];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (divider) {
          const region_ids = new Set(
            ctx_choices
              // @ts-expect-error strictNullChecks
              .filter((ctx) => ctx.statement_choice.statement_id === s.id)
              // @ts-expect-error strictNullChecks
              .map((ctx) => ctx.region_choice.region_id),
          );
          region_ids.forEach((region_id) => {
            dividers.push({
              // @ts-expect-error strictNullChecks
              id: undefined,
              tag_id: divider.id,
              tag_text: divider._text,
              region_id,
              // @ts-expect-error strictNullChecks
              section: undefined,
              // @ts-expect-error strictNullChecks
              subsection_id: undefined,
              statement_set_id: divider.statement_set_id,
              position: divider.position,
              isNotepad,
            });
          });
        }
      });

    // We could have added Tags from both the prefill and the statements that
    // have been used. So we use this function to ensure that the resulting
    // tags are unique based on the tag_id and the region_id.
    return uniqWith(dividers, (d1, d2) => {
      return d1.tag_id === d2.tag_id && d1.region_id === d2.region_id;
    });
  }

  /**
   * Get clones from prefill choices
   * @param ctx_choices
   */
  getCloneChoices(ctx_choices: RR.CtxFullStatementChoice[], clone: RR.ReportSection) {
    return ctx_choices
      .filter((ctx) => {
        const c = ctx.statement_choice;
        // @ts-expect-error strictNullChecks
        return !!c.clones && c.clones.includes(clone);
      })
      .map((ctx) => ctx.statement_choice);
  }

  /**
   * Filter choices if user select to hide defaults/hide history technique
   * @param ctx_choices
   * @param statement_entities
   * @param searchingTags
   * @param hideHistoryTechnique
   */
  filterChoicesBySectionOptions(
    ctx_choices: RR.CtxFullStatementChoice[],
    statement_entities: Dictionary<RR.Statement>,
    tagChoices: RR.TagChoice[],
    hideHistoryTechnique: boolean,
  ) {
    let ret_ctx_choices = ctx_choices;

    if (hideHistoryTechnique) {
      // Filter choices in history/technique (still show them if they are under searching tags)
      ret_ctx_choices = ret_ctx_choices.filter((ctx) => {
        // @ts-expect-error strictNullChecks
        if (ctx.section_choice.type !== 'history' && ctx.section_choice.type !== 'technique') {
          return true;
        }
        // @ts-expect-error strictNullChecks
        const statement = statement_entities[ctx.statement_choice.statement_id];
        return (
          statement &&
          statement.divider_id &&
          // @ts-expect-error strictNullChecks
          tagChoices.some((tag) => tag.tag_id === statement.divider_id && tag.region_id === ctx.region_choice.region_id)
        );
      });
    }

    return ret_ctx_choices;
  }

  /**
   * Merge prefill report choices and current report choice as we want to show current report choices in prefill
   * @param prefillContextChoices
   * @param currentContextChoices
   */
  mergePrefillAndCurrentReportChoices(
    prefillContextChoices: RR.CtxFullStatementChoice[],
    currentContextChoices: RR.CtxFullStatementChoice[],
  ): RR.CtxFullStatementChoice[] {
    const ctx_choices = prefillContextChoices.filter((ctx) => {
      // @ts-expect-error strictNullChecks
      const statement_id = ctx.statement_choice.statement_id;
      // @ts-expect-error strictNullChecks
      const region_id = ctx.region_choice.region_id;
      return !currentContextChoices.some(
        (currentCtx) =>
          // @ts-expect-error strictNullChecks
          currentCtx.statement_choice.statement_id === statement_id && currentCtx.region_choice.region_id === region_id,
      );
    });
    return ctx_choices.concat(currentContextChoices);
  }

  /**
   * Remove duplicate choices and choices that are no longer in the template
   * @param ctxChoices
   */
  filterDuplicateAndLegacyChoices(
    ctx_choices: RR.CtxFullStatementChoice[],
    statement_entities: Dictionary<RR.Statement>,
    template_elements: IElement[],
    notepads: RR.Element[],
  ) {
    // Remove duplicate choices (based on statement_id and region_id)
    const uniqueChoices = uniqWith(ctx_choices, (ctx1, ctx2) => {
      return (
        // @ts-expect-error strictNullChecks
        ctx1.statement_choice.statement_id === ctx2.statement_choice.statement_id &&
        // @ts-expect-error strictNullChecks
        ctx1.region_choice.region_id === ctx2.region_choice.region_id
      );
    });

    // Filter choices that are not in the template
    return uniqueChoices.filter((ctx) => {
      // @ts-expect-error strictNullChecks
      const statement = statement_entities[ctx.statement_choice.statement_id];
      // @ts-expect-error strictNullChecks
      const region_id = ctx.region_choice.region_id;

      return (
        statement &&
        // Statement is in the template
        (!!template_elements.find(
          (e: IElement) =>
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
            e.statement_set &&
            e.statement_set.id === statement.statement_set_id &&
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
            (!region_id || (e.region_set && e.region_set.region_ids && e.region_set.region_ids.includes(region_id))),
        ) ||
          // Statement is a global sentence and was selected under template element
          (!!notepads.find((np) => np.statement_set_id === statement.statement_set_id) &&
            !!template_elements.find((e) => e.element.statement_set_id === ctx.element_choice?.statement_set_id)))
      );
    });
  }

  /**
   * Apply threshold to filter common choices in selected reports
   * @param ctx_choices
   * @param threshold
   */
  filterByThreshold(
    unique_prefill_ctx_choices: RR.CtxFullStatementChoice[],
    original_prefill_ctx_choices: RR.Ctx[],
    statement_entities: Dictionary<RR.Statement>,
    tagChoices: RR.TagChoice[],
    threshold: number,
    moreComments: boolean,
  ) {
    // Filter out choices that don't meet the threshold
    return unique_prefill_ctx_choices.filter((ctx) => {
      // Don't apply threshold to comment and I&R choices if moreComments is checked
      if (
        moreComments &&
        // @ts-expect-error strictNullChecks
        (ctx.section_choice.type === 'comment' ||
          // @ts-expect-error strictNullChecks
          ctx.section_choice.type === 'impression_recommendations' ||
          // @ts-expect-error strictNullChecks
          ctx.statement_choice.clones.includes('comment') ||
          // @ts-expect-error strictNullChecks
          ctx.statement_choice.clones.includes('impression_recommendations'))
      ) {
        return true;
      }

      // Ignore threshold for choices under searching tags
      // @ts-expect-error strictNullChecks
      const statement = statement_entities[ctx.statement_choice.statement_id];
      if (statement?.divider_id) {
        const searchTag = tagChoices.find(
          // @ts-expect-error strictNullChecks
          (tag) => tag.tag_id === statement.divider_id && tag.region_id === ctx.region_choice.region_id,
        );
        if (searchTag) {
          return true;
        }
      }

      // Note: this will count the same statement chosen multiple times in a topic
      const majorityChoices = original_prefill_ctx_choices.filter(
        (prefillCtx) =>
          // @ts-expect-error strictNullChecks
          prefillCtx.statement_choice.statement_id === ctx.statement_choice.statement_id &&
          // @ts-expect-error strictNullChecks
          prefillCtx.region_choice.region_id === ctx.region_choice.region_id,
      );
      return majorityChoices.length >= threshold;
    });
  }

  ngOnDestroy() {
    this.previewChoicesSubscription.unsubscribe();
    this.subscription.unsubscribe();
    this.editorService.unregisterKeyboardShortcuts();
  }

  checkSectionInUse(section: RR.Section) {
    return (
      this.usedSections.some((s) => s === section) ||
      this.prefillContextChoices.some((ctx) => ctx.statement_choice?.clones?.includes(section.type))
    );
  }

  getSubsections(section: RR.Section) {
    return section.subsection_ids
      .map((id) => this.usedSubsections.find((s) => s.id === id))
      .filter((s): s is RR.Subsection => !!s);
  }

  getSubsectionElements(subsectionId: number) {
    return this.usedElements.filter((e) => e.element.subsection_id === subsectionId).map((e) => e.element);
  }

  getRegions(regionSetId: number): Observable<RR.Region[]> {
    // @ts-expect-error strictNullChecks
    return this.store.select(fromRegionSet.selectRegions(regionSetId));
  }

  checkExistRegionChoice(regionId: number) {
    // @ts-expect-error strictNullChecks
    return !!this.prefillContextChoices.find((ctx) => ctx.region_choice.region_id === regionId);
  }

  checkElementInRegionIsUsed(element: RR.Element, region: RR.Region) {
    const statements = this.usedStatements.filter(
      (s) => s.statement_set_id === element.statement_set_id || this.checkNotepadStatementInElement(s, element),
    );
    if (statements.length === 0) return false;
    return !!this.prefillContextChoices.find(
      (ctx) =>
        // @ts-expect-error strictNullChecks
        statements.some((statement) => ctx.statement_choice.statement_id === statement.id) &&
        // @ts-expect-error strictNullChecks
        ctx.region_choice.region_id === region.id,
    );
  }
  copyChoices({ choiceIds, keepNumbers = false, choice }: PrefillCopyChoicesData) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!choiceIds || choiceIds.length === 0) return;

    this.subscription.add(
      this.statements$
        .pipe(
          take(1),
          map((statements) => choice && statements[choice.statement_id]),
          switchMap((statement) => {
            this.mandatoryStatementService.mandatoryStatementChecker(statement, this.incompleteMandatoryStatements);

            // Filter out choices that are already in the current reports
            const prefillChoiceCtxs = this.prefillContextChoices.filter((ctx) =>
              // @ts-expect-error strictNullChecks
              choiceIds.includes(ctx.statement_choice.id),
            );
            const from_choices = prefillChoiceCtxs
              .filter(
                (ctx) =>
                  !this.currentContextChoices.find(
                    (reportCtx) =>
                      // @ts-expect-error strictNullChecks
                      reportCtx.statement_choice.statement_id === ctx.statement_choice.statement_id &&
                      // @ts-expect-error strictNullChecks
                      reportCtx.region_choice.region_id === ctx.region_choice.region_id,
                  ),
              )
              .map((ctx) => ctx.statement_choice);

            if (from_choices.length === 0) return of(null);
            // @ts-expect-error strictNullChecks
            const from_choice_ids = from_choices.map((c) => c.id);
            return this.prefillService.copyChoices(
              this.topic.id,
              from_choice_ids,
              keepNumbers,
              statement,
              this.topic.report_id,
            );
          }),
        )
        .subscribe(() => {
          this.cd.markForCheck();
        }),
    );
  }

  copyAllKeyFindingChoices() {
    this.copyChoices({ choiceIds: this.keyFindings.map((c) => c.id) });
  }

  copySectionChoices(section: RR.Section) {
    // Find all subsections in a section
    const subsections = this.getSubsections(section);
    const elements = this.usedElements.filter((e) => subsections.some((s) => s.id === e.subsection.id));
    // Statements inside these elements that were used to create choices in prefill report
    const elementStatements = this.usedStatements.filter((s) =>
      elements.some(
        (e) => e.element.statement_set_id === s.statement_set_id || this.checkNotepadStatementInElement(s, e.element),
      ),
    );
    const statementChoices = this.prefillContextChoices
      // @ts-expect-error strictNullChecks
      .filter((ctx) => !!elementStatements.find((s) => s.id === ctx.statement_choice.statement_id))
      .map((ctx) => ctx.statement_choice);

    // @ts-expect-error strictNullChecks
    this.copyChoices({ choiceIds: statementChoices.map((sc) => sc.id) });
  }

  checkNotepadStatementInElement(statement: RR.Statement, element: RR.Element) {
    const notepadStatement = this.notepadStatements.find((nps) => nps.id === statement.id);
    if (!notepadStatement) return false;
    return this.prefillContextChoices.find(
      (ctx) =>
        // @ts-expect-error strictNullChecks
        ctx.statement_choice.statement_id === notepadStatement.id &&
        ctx.element_choice?.statement_set_id === element.statement_set_id,
    );
  }

  copySubsectionChoices(subsection: RR.Subsection) {
    const elements = this.getSubsectionElements(subsection.id);
    const elementStatements = this.usedStatements.filter((s) =>
      elements.some((e) => e.statement_set_id === s.statement_set_id || this.checkNotepadStatementInElement(s, e)),
    );
    const statementChoices = this.prefillContextChoices
      // @ts-expect-error strictNullChecks
      .filter((ctx) => !!elementStatements.find((s) => s.id === ctx.statement_choice.statement_id))
      .map((ctx) => ctx.statement_choice);

    // @ts-expect-error strictNullChecks
    this.copyChoices({ choiceIds: statementChoices.map((sc) => sc.id) });
  }

  copyRegionChoices(region: RR.Region) {
    const statementChoices = this.prefillContextChoices
      // @ts-expect-error strictNullChecks
      .filter((ctx) => ctx.region_choice.region_id === region.id)
      .map((ctx) => ctx.statement_choice);

    // @ts-expect-error strictNullChecks
    this.copyChoices({ choiceIds: statementChoices.map((c) => c.id) });
  }

  copyAll(keepNumbers: boolean) {
    const choices = this.prefillContextChoices.map((ctx) => ctx.statement_choice);
    this.copyChoices({
      // @ts-expect-error strictNullChecks
      choiceIds: choices.map((c) => c.id),
      keepNumbers,
    });
  }

  openPrefillTutorialModal() {
    PrefillTutorialComponent.open(this.modalService);
  }

  prefillScrollTo(to: 'TOP' | 'BOTTOM') {
    if (!this.scrollContainer) return;

    const nativeElement = this.scrollContainer.nativeElement;

    nativeElement.scrollTop = to === 'TOP' ? 0 : nativeElement.scrollHeight;
  }

  scrollToNextSectionOrSubsection({
    direction = 'down',
    data = '[data-section]',
  }: { direction?: 'up' | 'down'; data?: '[data-section]' | '[data-subsection]' } = {}) {
    if (!this.scrollContainer) return;

    const nativeElement = this.scrollContainer.nativeElement;

    // The subsection.id in the value [data-subsection] is not used yet. It's helpful for debugging.
    const subsections: HTMLElement[] = Array.from(nativeElement.querySelectorAll(data));

    if (direction === 'down') {
      const subsectionAtTopOfViewportIndex = subsections.findIndex(
        (subsectionEl) => elementRelativeToParent({ element: subsectionEl, parentElement: nativeElement }).isNearTop,
      );
      const subsectionInViewportIndex = subsections.findIndex(
        (subsectionEl) => elementRelativeToParent({ element: subsectionEl, parentElement: nativeElement }).isBetween,
      );
      const subsectionBelowViewportIndex = subsections.findIndex(
        (subsectionEl) => elementRelativeToParent({ element: subsectionEl, parentElement: nativeElement }).isBelow,
      );

      if (subsectionAtTopOfViewportIndex !== -1) {
        // When a subsection heading is at the top of the screen, scroll to the next one.
        // cast to undefined because tsconfig `noUncheckedIndexedAccess` is off
        const targetEl = subsections[subsectionAtTopOfViewportIndex + 1] as HTMLElement | undefined;
        // scrollIntoView was used here over Element.scrollIntoView because of ~1px discrepancies when scrolling up. It
        // would say that the element was above the screen, even though it was on the screen by less than a pixel.
        if (targetEl) {
          this.highlightSectionOrSubsection(targetEl);
          scrollIntoView({
            element: targetEl,
            parent: nativeElement,
            scrollToTop: true,
          });
        }
      } else if (subsectionInViewportIndex !== -1) {
        // Otherwise, if a subsection heading is on the screen, scroll to it.
        const targetEl = subsections[subsectionInViewportIndex];
        this.highlightSectionOrSubsection(targetEl);
        scrollIntoView({
          element: targetEl,
          parent: nativeElement,
          scrollToTop: true,
        });
      } else if (subsectionBelowViewportIndex !== -1) {
        // Otherwise, if no heading on the screen, scroll to the first one below the screen.
        const targetEl = subsections[subsectionBelowViewportIndex];
        this.highlightSectionOrSubsection(targetEl);
        scrollIntoView({
          element: targetEl,
          parent: nativeElement,
          scrollToTop: true,
        });
      }
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (direction === 'up') {
      // Reverse the list to search in the opposite direction
      subsections.reverse();
      const subsectionAboveViewportIndex = subsections.findIndex(
        (subsectionEl) => elementRelativeToParent({ element: subsectionEl, parentElement: nativeElement }).isAbove,
      );

      if (subsectionAboveViewportIndex !== -1) {
        // Scroll to the first element above the screen.
        const targetEl = subsections[subsectionAboveViewportIndex];
        this.highlightSectionOrSubsection(targetEl);
        scrollIntoView({
          element: targetEl,
          parent: nativeElement,
          scrollToTop: true,
        });
      }
    } else {
      throw new TypeError(`Invalid value for 'direction' ${direction}`);
    }
  }

  highlightSectionOrSubsection(target: HTMLElement) {
    if (this.sectionOrSubsectionEl !== target) {
      this.sectionOrSubsectionEl = target;
      this.cd.detectChanges();
      setTimeout(() => {
        this.sectionOrSubsectionEl = null;
        this.cd.detectChanges();
      }, 300);
    }
  }

  prefillSentences(type: 'TITLE' | 'PREDICTION') {
    this.processingPrefillTitle = true;

    this.statementChoiceEffect
      .createPredictionChoices(this.topic.id, type)
      .pipe(
        take(1),
        finalize(() => {
          this.processingPrefillTitle = false;
        }),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
      .subscribe((action) => {
        // If there are prefilled choices, toggle underline prefill to be able to delete them
        const newChoices = action.actions.statementChoiceAddMany.statementChoices;
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- 2
        if (newChoices && newChoices.length > 0) {
          // Toggle underline prefill with new created choices
          const choice_ids = newChoices.map((k) => k.id);
          this.editorService.toggleUnderlinePrefilled({ underline: true, choice_ids: new Set(choice_ids) });
        }
        this.cd.detectChanges();
      });
  }

  toggleHideExcludedTags() {
    this.hideExcludedTags = !this.hideExcludedTags;
  }

  filterLandmark(landmark: string) {
    this.prefillService.changeLandmarkFilter$.next(landmark);
  }

  selectLandmarkLabel = fromLandmarkLabel.selectLandmarkLabel;

  showExactMatchResults() {
    this.prefillService.showExactMatchResults$.next(true);
  }

  impressionAndCommentsPrediction() {
    this.editorService.searchAndToggleCorrelatedStatement({
      statementId: undefined,
      region: undefined,
      templateId: undefined,
      source: 'BUTTON',
    });
  }
}
