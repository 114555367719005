import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { SharedModule } from 'app/shared/shared.module';
import { referrerTypeMap } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store';
import { fromBookingCode } from 'app/store/booking-code';
import { fromScanCode } from 'app/store/scan-code';
import { Observable, of, Subscription, switchMap } from 'rxjs';

import { BillingItemEligibilityComponent } from '../../billing-item-eligibility/billing-item-eligibility.component';
import { BillingItemLinkComponent } from '../../billing-item-link/billing-item-link.component';

/**
 * This component is used to select Billing Items for both Registration and Booking pages
 */
@Component({
  standalone: true,
  selector: 'rr-booking-code-detail',
  templateUrl: './booking-code-detail.component.html',
  styleUrls: ['./booking-code-detail.component.scss'],
  imports: [CommonModule, BillingItemLinkComponent, NgbModule, SharedModule, BillingItemEligibilityComponent],
})
export class BookingCodeDetailComponent implements OnInit, OnDestroy {
  @Input() @BindObservable() bookingCodeId: number;
  bookingCodeId$: Observable<number>;
  @Input() referrerType: RR.ReferrerType | null | undefined;
  @Input() booking: RR.Booking | undefined;
  @Input() funder: RR.FunderType;

  @Input() selectedBillingItemIds: number[];
  @Output() onBillingItemChange = new EventEmitter<number[]>();

  billingItems$: Observable<RR.BillingItem[]>;
  referrerTypes = referrerTypeMap;

  subscription: Subscription = new Subscription();

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    const bookingCode$ = this.bookingCodeId$.pipe(
      switchMap((id) => this.store.select(fromBookingCode.selectBookingCode(id))),
    );
    this.billingItems$ = bookingCode$.pipe(
      filterDefined(),
      switchMap((bookingCode) =>
        bookingCode.scan_code_id
          ? this.store.select(fromScanCode.selectBillingItems(bookingCode.scan_code_id))
          : of([]),
      ),
    );

    if (this.booking) {
      this.selectedBillingItemIds = this.booking.billing_items;
      this.onBillingItemChange.emit(this.selectedBillingItemIds);
    }
  }

  onClickBillingItem(itemId: number): void {
    this.selectedBillingItemIds = [itemId];
    this.onBillingItemChange.emit(this.selectedBillingItemIds);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
