<ng-container
  *ngIf="
    booking.booking_code_id && (booking.booking_code_id | storeSelectPipe: selectBookingCode | async) as bookingCode;
    else loading
  "
>
  <div class="d-flex mb-2">
    <div class="col-7">
      <div class="mb-1">
        <i class="fas fa-notes-medical me-1"></i>
        <rr-booking-code-headline [bookingCode]="bookingCode"></rr-booking-code-headline>
      </div>
      <div *ngIf="booking.site_id && (booking.site_id | storeSelectPipe: selectSite | async) as site">
        <i class="fas fa-map-marker-alt me-1"></i>{{ site.name }}
      </div>
      <div *ngIf="bookingCode.doctor_required" class="text-danger">
        <i class="fas fa-exclamation-circle"></i>
        This is a doctor required exam
      </div>
      <div *ngIf="booking.injection" class="text-danger">
        <i class="fas fa-exclamation-circle"></i>
        Injection included
      </div>
      <div class="mt-4" *ngIf="displayBookingNotes && bookingCode.booking_notes">
        <i class="fas fa-sticky-note"></i>
        Notes:
        <div>
          <small [innerHTML]="bookingCode.booking_notes"></small>
        </div>
      </div>
    </div>

    <div class="ms-auto text-end col">
      <div *ngIf="selectedDate">
        <i class="fas fa-calendar"></i>
        <strong>
          {{ selectedDate | date: 'd/M/yy' }}
        </strong>
      </div>
      <div *ngIf="!selectedDate && booking.start_time">
        <i class="fas fa-calendar"></i>
        <strong>
          {{ booking.start_time | date: 'd/M/yy' }}
        </strong>
      </div>
      <div *ngIf="booking?.start_time">
        <i class="fas fa-clock"></i>
        <strong> {{ booking.start_time | date: 'HH:mm' }} - {{ booking.end_time | date: 'HH:mm' }} </strong>
      </div>
      <div *ngIf="booking.rr_user_id && (booking.rr_user_id | storeSelectPipe: selectUser | async) as user">
        <i class="fas fa-user-md"></i>
        <strong>
          {{ user.name }}
        </strong>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #loading>
  <span *ngIf="parent === 'REVIEW'">No Booking Code Selected</span>
  <p *ngIf="parent !== 'REVIEW'" class="placeholder-glow">
    <span class="placeholder bg-secondary col-12"></span>
    <span class="placeholder bg-secondary col-6"></span>
  </p>
</ng-template>
