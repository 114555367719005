import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit, ChangeDetectorRef, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { AppState } from 'app/store';
import { CorrelatedSearchEffect, CorrelatedStatement } from 'app/store/correlated-statement-search';
import { ReportHttpService } from 'app/store/report/report';
import { ChoiceCreationObject, StatementChoiceEffect } from 'app/store/report/statement-choice';
import { fromCurrentTopic } from 'app/store/report/topic';
import { Observable, Subscription, switchMap, tap } from 'rxjs';

import { SharedModule } from '../../../shared/shared.module';
import { RecommendedStatementComponent } from '../statement-recommendation/recommended-statement/recommended-statement.component';

@Component({
  selector: 'rr-correlated-statement-search',
  templateUrl: './correlated-statement-search.component.html',
  styleUrls: ['./correlated-statement-search.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedModule, FormsModule, ReactiveFormsModule, RecommendedStatementComponent],
})
export class CorrelatedSearchComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  @ViewChildren(RecommendedStatementComponent) recommendedStatementComponents: RecommendedStatementComponent[];

  topic$: Observable<RR.Topic>;
  topic: RR.Topic;
  correlatedStatements: CorrelatedStatement[] | undefined = undefined;
  allCorrelatedStatements: CorrelatedStatement[] | undefined = undefined;

  region: RR.Region | undefined;
  templateId: number | undefined;
  statementId: number | undefined;
  source: 'BUTTON' | 'STATEMENT';

  loading = false;
  rightPaneViewMode: string;

  isExpanded = false;

  form = new FormGroup({
    includeTechniqueSentences: new FormControl(false, { nonNullable: true }),
    voiceMode: new FormControl(true, { nonNullable: true }),
    excludeOtherSubsections: new FormControl(false, { nonNullable: true }),
    filterBySimilarAgeGroup: new FormControl(true, { nonNullable: true }),
    selectedFilter: new FormControl('all', { nonNullable: true }),
  });

  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private statementChoiceEffect: StatementChoiceEffect,
    private correlatedSearchEffect: CorrelatedSearchEffect,
    private cd: ChangeDetectorRef,
    private messageService: MessageService,
    public editorService: EditorService,
    private voiceService: VoiceRecognitionService,
    private reportService: ReportHttpService,
  ) {}

  ngOnInit() {
    this.topic$ = this.store.select(fromCurrentTopic.selectTopic).pipe(filterDefined());
    this.subscription.add(
      this.topic$.subscribe((topic) => {
        this.topic = topic;
      }),
    );

    this.subscription.add(
      this.editorService.selectedStatement$
        .pipe(
          filterDefined(),
          tap(() => {
            this.loading = true;
          }),
          switchMap(({ statementId, templateId, region, source }) => {
            this.templateId = templateId;
            this.region = region;
            this.statementId = statementId;
            this.source = source;

            if (statementId) {
              return this.correlatedSearchEffect.correlatedSearch(this.topic.id, statementId);
            }

            return this.correlatedSearchEffect.statisticalStatementsPrediction(this.topic.id);
          }),
        )
        .subscribe({
          next: (action) => {
            const { suggested_statements } = action;

            if (suggested_statements.length === 0) {
              if (this.source === 'BUTTON') {
                this.noCorrelatedStatementsMessage();
              }
              this.editorService.toggleRightPaneViewMode('REPORT_PREVIEW');
              return;
            }
            this.loading = false;

            if (this.rightPaneViewMode !== 'SUGGESTIONS') {
              this.editorService.toggleRightPaneViewMode('SUGGESTIONS');
            }

            this.correlatedStatements = suggested_statements;
            this.allCorrelatedStatements = suggested_statements;

            if (this.form.controls.voiceMode.value) {
              this.readTopStatement(this.correlatedStatements[0]);
            } else {
              this.correlatedStatementsSound();
            }

            this.cd.detectChanges();
          },
          error: (err: unknown) => {
            this.loading = false;
            this.messageService.httpErrorMessage(err);
            this.cd.detectChanges();
          },
        }),
    );

    this.subscription.add(
      this.store
        .select((s) => s.editor.rightPaneViewMode)
        .subscribe((viewMode) => {
          this.rightPaneViewMode = viewMode;
        }),
    );

    this.subscription.add(
      this.form.valueChanges.subscribe((value) => {
        let filteredStatements = this.allCorrelatedStatements;

        if (value.selectedFilter === 'finding') {
          filteredStatements = filteredStatements?.filter((statement) => statement.statement.default_key_finding);
        } else if (value.selectedFilter === 'comment') {
          filteredStatements = filteredStatements?.filter((statement) => statement.statement.default_comment);
        } else if (value.selectedFilter === 'impression') {
          filteredStatements = filteredStatements?.filter(
            (statement) => statement.statement.default_impression_recommendation,
          );
        }
        // filter by excludeOtherSubsections if checked
        if (value.excludeOtherSubsections) {
          filteredStatements = filteredStatements?.filter((statement) => statement.filtered_by_subsection === true);
        }
        // filter by filterBySimilarAgeGroup if checked
        if (!value.filterBySimilarAgeGroup) {
          filteredStatements = filteredStatements?.filter((statement) => statement.filtered_by_age === false);
        }
        this.correlatedStatements = filteredStatements;
      }),
    );
  }

  noCorrelatedStatementsMessage() {
    return this.messageService.add({
      title: '',
      message: 'No correlated statements found',
      type: 'info',
    });
  }

  close() {
    this.editorService.toggleRightPaneViewMode('REPORT_PREVIEW');
    this.form.controls.selectedFilter.reset();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addChoices(choices: ChoiceCreationObject[]) {
    return this.statementChoiceEffect.createMany(this.topic.id, choices);
  }

  selectAll() {
    // TODO(unused)
    const data = this.recommendedStatementComponents.map((component) => {
      // Reach into the component a pull out the element, rather than repeating the complex selector here.
      return {
        statement_id: component.statement.id,
        element_id: component.element.id,
        region_id: null, // TODO: regions not supported
      };
    });

    if (data.length > 0) {
      this.subscription.add(this.addChoices(data).subscribe());
    }
  }

  selectTopThree() {
    // TODO: Implement this method
  }

  // TODO Fix
  deselectAll() {
    const statementChoiceIds =
      this.correlatedStatements &&
      this.correlatedStatements.map((correlatedStatement) => correlatedStatement.statement.id);

    if (statementChoiceIds) {
      this.subscription.add(this.statementChoiceEffect.deleteMany(statementChoiceIds).subscribe());
    }
  }

  correlatedStatementsSound() {
    const audio = new Audio();
    audio.src = '/assets/effects/notification.mp3';
    audio.load();
    audio.play();
  }

  readTopStatement(correlatedStatement: CorrelatedStatement) {
    const firstStatementId = correlatedStatement.statement.id;

    this.reportService
      .findStatementTextWithAttributes(firstStatementId)
      .pipe(
        tap((payload) => {
          this.voiceService.startSpeechSynth('Correlated finding: ' + payload.sentence_text);
        }),
      )
      // eslint-disable-next-line rxjs-angular/prefer-composition
      .subscribe();
  }

  checkVoiceMode(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const isChecked: boolean = inputElement.checked;
    this.form.controls.voiceMode.setValue(isChecked);
  }

  getReportCorrelatedSearch() {
    this.subscription.add(
      this.correlatedSearchEffect.reportCorrelatedSearch(this.report.id).subscribe({
        next: (action) => {
          const { suggested_statements } = action;

          if (suggested_statements.length === 0) {
            this.noCorrelatedStatementsMessage();
            return;
          }
          this.loading = false;
          if (this.rightPaneViewMode !== 'SUGGESTIONS') {
            this.editorService.toggleRightPaneViewMode('SUGGESTIONS');
          }

          this.correlatedStatements = suggested_statements;
          this.allCorrelatedStatements = suggested_statements;

          this.cd.detectChanges();
        },
        error: (err: unknown) => {
          this.loading = false;
          this.messageService.httpErrorMessage(err);
          this.cd.detectChanges();
        },
      }),
    );
  }

  toggleContainerSize() {
    this.isExpanded = !this.isExpanded;
  }
}
