<div class="w-25 d-flex flex-column gap-1 ps-2">
  <rr-document-attachment-button
    *ngIf="report"
    [bookingId]="report.booking_id"
    [size]="'lg'"
    [btnClassName]="'btn-primary'"
    [dismissActiveModal]="true"
    [expand]="true"
  ></rr-document-attachment-button>

  <ng-container *ngIf="report">
    <rr-report-notes-button
      *ngIf="report"
      [btnClass]="'btn-primary'"
      [small]="false"
      class="w-100"
      [report_id]="report.id"
      [expand]="true"
    ></rr-report-notes-button>
  </ng-container>
  <a
    *ngIf="report"
    class="btn btn-primary"
    routerLink="/registration/booking"
    target="_blank"
    [queryParams]="{ bookingId: report.booking_id }"
    title="Open a new window to see booking information"
    rrTooltip="VIEW_BOOKING"
  >
    View booking info
  </a>

  <rr-db4-form *ngIf="!!report" [disabled]="patient && !patient.medicare_number" [reportId]="report.id">
    DB4 Form
  </rr-db4-form>
  <div *ngIf="report">
    <rr-all-form [availableForms]="availableForms" [reportId]="report.id"></rr-all-form>
  </div>

  <!--Registration events-->
  <rr-registration-events *ngIf="report" [report_id]="report.id"></rr-registration-events>
</div>

<div class="w-75 d-flex flex-grow-1 flex-column gap-4 overflow-auto pe-2">
  <div class="d-flex align-items-center alert alert-light">
    <div class="mx-2"><strong>Help: </strong></div>
    <rr-documentation-link-button [buttonId]="10"></rr-documentation-link-button>
    <rr-documentation-link-button [buttonId]="11"></rr-documentation-link-button>
    <rr-documentation-link-button [buttonId]="12"></rr-documentation-link-button>
    <rr-documentation-link-button [buttonId]="13"></rr-documentation-link-button>
  </div>
  <div class="alert alert-light">
    <div data-cy="registrationMetadata" class="d-flex align-items-center">
      <div class="col">
        <rr-report-accession-number *ngIf="report" [report]="report"></rr-report-accession-number>
        <p *ngIf="!report">Accession Number: None</p>
      </div>
      <div class="col">
        <strong>Patient:</strong>
        <p>{{ patient ? patient.patient_first_name + ' ' + patient.patient_last_name : 'None' }}</p>
      </div>
      <div class="col">
        <strong>Referrer:</strong>
        <p *ngIf="referrer">
          <rr-referrer-name [referrerId]="referrer.id" [referrerType]="true"></rr-referrer-name>
        </p>
        <p *ngIf="!referrer">None</p>
      </div>
    </div>
  </div>

  <div>
    <div class="d-flex flex-column mb-3" *ngIf="sameDayBookingIds.length > 1">
      <!-- If there's other sameDayBookings, warn and show user those bookings -->
      <!-- Or, warn if a report has been registered for the booking already - to prevent multiple reports for a single booking -->
      <ng-container>
        <h5 *ngIf="booking && booking.reports.length > 0">
          <strong>
            <span class="fa fa-lg fa-exclamation-triangle text-danger"></span>
            This booking has been registered.
          </strong>
        </h5>
        <rr-same-day-bookings
          (onSelectBooking)="changeSameDayBooking($event)"
          [sameDayBookingIds]="sameDayBookingIds"
          [bookingId]="booking?.id || null"
        ></rr-same-day-bookings>
      </ng-container>
    </div>

    <rr-booking-view-summary
      *ngIf="booking"
      [context]="'REGISTRATION'"
      [bookingId]="booking.id"
    ></rr-booking-view-summary>
  </div>

  <div>
    <h2 class="mb-2">
      <strong>Step 1 &ndash; Funder</strong>
    </h2>

    <select class="form-select" [formControl]="bookingForm.controls.funder">
      <option *ngFor="let funder of funders" [value]="funder.id">
        {{ funder.name }}
      </option>
      <!-- Only display unknown if set, otherwise don't provide the option -->
      <option *ngIf="bookingForm.controls.funder.value === 'unknown'" [value]="'unknown'">Unknown</option>
    </select>
  </div>

  <div>
    <h2>
      <strong>Step 2 &ndash; Patient Details</strong>
    </h2>
    <rr-patient-form [patient]="patient" [parent]="'REGISTRATION'"></rr-patient-form>
    <rr-notes *ngIf="patient" [patientId]="patient.id" [reportId]="report?.id" [type]="'patient'" [minimalView]="true">
      <button role="button" class="btn btn-outline-success" (click)="openCreateNoteModal({ patientId: patient.id })">
        <i class="fas fa-plus"></i>
        Add Note
      </button>
    </rr-notes>
  </div>

  <div *ngIf="!report && patient">
    <rr-same-day-registrations [patientId]="patient.id"></rr-same-day-registrations>
  </div>

  <div>
    <h2 class="mb-2">
      <strong>Step 3 &ndash; Referrer Form</strong>
    </h2>
    <rr-checkbox class="my-2" *ngIf="!referrer" [formControl]="reportForm.controls.noReferrerType"
      >This registration has no referrer.</rr-checkbox
    >
    <rr-referrer-form
      [referrer]="referrer"
      [parent]="'REPORT'"
      [reportId]="report?.id"
      [noReferrer]="reportForm.controls.noReferrerType.value"
    ></rr-referrer-form>
  </div>

  <div>
    <h2 class="mb-2">
      <strong>Step 4 &ndash; Patient Scan Registration</strong>
    </h2>
    <rr-report-form [report]="report" [patient]="patient" [referrer]="referrer"></rr-report-form>
  </div>

  <!-- If existing registration -->
  <div class="d-flex">
    <ng-container *ngIf="report">
      <a
        type="button"
        class="btn btn-outline-primary ms-auto"
        routerLink="/registration/report"
        [queryParams]="{
          patientId: report.patient_id,
          referrerId: report.referrer_id,
        }"
        rrTooltip="REGISTER_NEW_PATIENT_SCAN"
      >
        Create Walk-in Booking and Register New Scan for this Patient
      </a>
    </ng-container>
  </div>

  <ng-container *ngIf="report">
    <div class="my-2">
      <h2 class="text-center">
        <strong>Invoices</strong>
      </h2>
      <div class="bg-light p-2">
        <rr-report-invoices [reportId]="report.id" header="Invoices"></rr-report-invoices>
      </div>
    </div>
  </ng-container>

  <div class="d-flex justify-content-center">
    <button
      role="button"
      class="btn btn-secondary"
      title="Please make sure you have saved all your changes before going back to worklist."
      data-cy="back-to-worklist"
    >
      <rr-worklist-link>Go back to worklist</rr-worklist-link>
    </button>
  </div>
</div>
