import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { formValueChanges } from 'app/shared/utils/form-validation.utils';
import { AppState } from 'app/store';
import { BookingEffect, fromBooking } from 'app/store/booking';
import { fromBookingCode } from 'app/store/booking-code';
import { InvoiceEffect } from 'app/store/invoice';
import { PatientEffect, fromPatient } from 'app/store/patient';
import { fromProviderNumber } from 'app/store/provider-number';
import { ReferralEnquiryEffect } from 'app/store/referral-enquiry';
import { ReferrerEffect, fromReferrer } from 'app/store/referrer';
import { AuditEventEffect } from 'app/store/report/audit-event';
import { ReportEffect, fromReport } from 'app/store/report/report';
import { fromScanCode } from 'app/store/scan-code';
import { fromSite } from 'app/store/site';
import {
  BehaviorSubject,
  Subscription,
  distinctUntilChanged,
  map,
  of,
  shareReplay,
  switchMap,
  finalize,
  combineLatest,
  Observable,
  tap,
  take,
} from 'rxjs';

import { PATIENT_FORM_ID } from '../components/patient-form/patient-form.component';
import { REFERRER_FORM_ID } from '../components/referrer-form/referrer-form.component';
import { REPORT_FORM_ID } from '../components/report-form/report-form.component';

export type BookingForm = RegistrationService['bookingForm'];
export type ReportForm = RegistrationService['reportForm'];
export type ReportFormValue = ReturnType<ReportForm['getRawValue']>;

export type RegistrationState = {
  pageLoading: undefined | 'loading' | 'loaded';
};

const initialState: RegistrationState = {
  pageLoading: undefined,
};

@Injectable({ providedIn: 'root' })
export class RegistrationService implements OnDestroy {
  bookingForm = new FormGroup(
    {
      bookingCodeId: new FormControl<number | null>(null, {
        validators: Validators.required,
        nonNullable: true,
      }),
      billingItemIds: new FormControl<number[]>([], { nonNullable: true }),
      /**
       * The length of the available billing items is stored here for validation. When there are none, we can't force
       * the user to select one.
       */
      billingItemsLength: new FormControl<number>(0, { nonNullable: true }),
      funder: new FormControl<RR.FunderType>('medicare', { nonNullable: true }),
      site_id: new FormControl<number | null>(null, { validators: Validators.required, nonNullable: true }),
    },
    { validators: [this.crossValidate()] },
  );
  reportForm = new FormGroup(
    {
      referral_date: new FormControl('', { nonNullable: true }),
      scan_code_id: new FormControl<number | null>(null, {
        validators: Validators.required,
        nonNullable: true,
      }),
      scan_code_side: new FormControl<RR.ScanCodeSide | null>(null, { nonNullable: true }),
      medicare_provider_id: new FormControl<number | null>(null, {
        validators: Validators.required,
        nonNullable: true,
      }),
      referrerId: new FormControl<number | null>(null, { nonNullable: true }),
      patientId: new FormControl<number | null>(null, { nonNullable: true }),
      bookingId: new FormControl<number | null>(null, { nonNullable: true }),
      reportId: new FormControl<number | null>(null, { nonNullable: true }),
      referralEnquiryId: new FormControl<number | null>(null, { nonNullable: true }),
      // filters
      noReferrerType: new FormControl(false, { nonNullable: true }),
    },
    {
      validators: [this.crossValidateReport()],
    },
  );
  subscription = new Subscription();

  constructor(
    private store: Store<AppState>,
    private reportEffect: ReportEffect,
    private patientEffect: PatientEffect,
    private referrerEffect: ReferrerEffect,
    private invoiceEffect: InvoiceEffect,
    private bookingEffect: BookingEffect,
    private referralEnquiryEffect: ReferralEnquiryEffect,
    private auditEventEffect: AuditEventEffect,
  ) {
    // // TODO(reg): remove debug code
    // (window as any).bookingForm = this.bookingForm;
    // (window as any).reportForm = this.reportForm;
    // this.bookingFormValue$.subscribe((value) => {
    //   console.log(value);
    // });
    // this.reportFormValue$.subscribe((value) => {
    //   console.log(value);
    // });
    // this.state.subscribe((state) => {
    //   console.log(state);
    // });

    this.subscription.add(
      this.billingItems$.subscribe((billingItems) => {
        const billingItemsLength = billingItems.length;
        this.bookingForm.patchValue({ billingItemsLength });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Cross validation for the booking form. This is used to validate fields that depend on each other.
   */
  crossValidate(): ValidatorFn {
    return (_form: AbstractControl) => {
      const form: BookingForm = _form as BookingForm;
      // billing items are not required, if there are no billing items
      if (form.controls.billingItemsLength.value === 0) {
        form.controls.billingItemIds.setErrors(null);
      } else if (form.controls.billingItemIds.value.length === 0) {
        form.controls.billingItemIds.setErrors({ required: true });
      }
      return null;
    };
  }

  crossValidateReport(): ValidatorFn {
    return (_form: AbstractControl) => {
      const form: ReportForm = _form as ReportForm;
      // validate referrerId if noReferrerType
      if (form.controls.noReferrerType.value) {
        form.controls.referrerId.setErrors(null);
      } else if (form.controls.referrerId.value === null) {
        form.controls.referrerId.setErrors({ required: true });
      }
      return null;
    };
  }

  bookingFormValue$ = formValueChanges(this.bookingForm);

  /**
   * @deprecated
   */
  funder$ = this.bookingFormValue$.pipe(
    map(({ funder }) => funder),
    distinctUntilChanged(),
  );
  billingItemIds$ = this.bookingFormValue$.pipe(
    map(({ billingItemIds }) => billingItemIds),
    distinctUntilChanged(),
  );
  reportFormValue$ = formValueChanges(this.reportForm);
  referrerId$ = this.reportFormValue$.pipe(
    map(({ referrerId }) => referrerId),
    distinctUntilChanged(),
  );
  patientId$ = this.reportFormValue$.pipe(
    map(({ patientId }) => patientId),
    distinctUntilChanged(),
  );
  bookingId$ = this.reportFormValue$.pipe(
    map(({ bookingId }) => bookingId),
    distinctUntilChanged(),
  );
  reportId$ = this.reportFormValue$.pipe(
    map(({ reportId }) => reportId),
    distinctUntilChanged(),
  );

  report$ = this.reportId$.pipe(
    switchMap((reportId) => {
      if (reportId === null) {
        return of(undefined);
      }
      return this.store.select(fromReport.selectReport(reportId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  fromBooking$ = this.bookingId$.pipe(
    switchMap((bookingId) => {
      if (bookingId === null) {
        return of(undefined);
      }
      return this.store.select(fromBooking.selectBooking(bookingId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  referrer$ = this.referrerId$.pipe(
    switchMap((referrerId) => {
      if (referrerId === null) {
        return of(undefined);
      }
      return this.store.select(fromReferrer.selectReferrer(referrerId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  patient$ = this.patientId$.pipe(
    switchMap((patientId) => {
      if (patientId === null) {
        return of(undefined);
      }
      return this.store.select(fromPatient.selectPatient(patientId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  // Some of these are duplicated in BookingPageService
  bookingCode$ = this.bookingFormValue$.pipe(
    map((booking) => booking.bookingCodeId),
    distinctUntilChanged(),
    switchMap((bookingCodeId) => {
      if (bookingCodeId === null) {
        return of(undefined);
      }
      return this.store.select(fromBookingCode.selectBookingCode(bookingCodeId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  billingItems$ = this.bookingCode$.pipe(
    switchMap((bookingCode) => {
      if (!bookingCode) {
        return of([]);
      }
      return this.store.select(fromScanCode.selectBillingItems(bookingCode.scan_code_id));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  site$ = this.bookingFormValue$.pipe(
    map(({ site_id }) => site_id),
    distinctUntilChanged(),
    switchMap((siteId) => {
      if (siteId === null) {
        return of(undefined);
      }
      return this.store.select(fromSite.selectSite(siteId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  scanCode$ = this.reportFormValue$.pipe(
    map(({ scan_code_id }) => scan_code_id),
    distinctUntilChanged(),
    switchMap((scanCodeId) => {
      if (scanCodeId === null) {
        return of(undefined);
      }
      return this.store.select(fromScanCode.selectScanCode(scanCodeId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  providerNumber$ = this.reportFormValue$.pipe(
    map(({ medicare_provider_id }) => medicare_provider_id),
    distinctUntilChanged(),
    switchMap((medicareProviderId) => {
      if (medicareProviderId === null) {
        return of(undefined);
      }
      return this.store.select(fromProviderNumber.selectProviderNumber(medicareProviderId));
    }),
  );

  focus(id: typeof PATIENT_FORM_ID | typeof REFERRER_FORM_ID | typeof REPORT_FORM_ID) {
    document.getElementById(id)?.scrollIntoView(true);
  }

  selectBookingCode({ bookingCodeId }: { bookingCodeId: number }) {
    // Reset the billing item selection whenever the booking code is changed
    this.bookingForm.patchValue({
      bookingCodeId,
      billingItemIds: [],
    });
  }

  selectScanCode({ scanCode }: { scanCode: RR.ScanCode }) {
    let scanCodeSide: RR.ScanCodeSide | undefined = undefined;
    if (scanCode.has_side) {
      // Default to left if scan code has side and no side selected for the report
      scanCodeSide = 'left';
    }
    this.reportForm.patchValue({
      scan_code_id: scanCode.id,
      scan_code_side: scanCodeSide,
    });
    // Default to first booking code that linked to scan code
    const firstBookingCodeId: number | undefined = scanCode.booking_codes[0];
    if (firstBookingCodeId) {
      this.selectBookingCode({ bookingCodeId: firstBookingCodeId });
    }
  }

  selectPatient(patient: RR.Patient | null) {
    if (!patient) {
      this.reportForm.controls.patientId.setValue(null);
      return of(undefined);
    }
    // Load the patient into the store before selecting it in the form.
    return this.patientEffect.findById(patient.id).pipe(
      tap(() => {
        this.reportForm.controls.patientId.setValue(patient.id);
      }),
      switchMap(() =>
        combineLatest({
          report: this.report$.pipe(take(1)),
          booking: this.fromBooking$.pipe(take(1)),
        }),
      ),
      switchMap(({ report, booking }) => {
        const observables: Observable<any>[] = [];
        // If there is a report, update the patient_id
        if (report) {
          observables.push(this.createAuditEvent(report, 'REGISTER_PATIENT'));
          observables.push(this.reportEffect.update(report.id, { patient_id: patient.id }));
        }
        // If there is a booking, update the patient_id
        if (booking) {
          observables.push(this.bookingEffect.update(booking.id, { patient_id: patient.id }));
        }
        return observables.length > 0 ? combineLatest(observables) : of(undefined);
      }),
    );
  }

  selectReferrer(referrer: RR.Referrer | null) {
    if (!referrer) {
      this.reportForm.controls.referrerId.setValue(null);
      return of(undefined);
    }
    // Load the referrer into the store before selecting it in the form.
    return this.referrerEffect.findById(referrer.id).pipe(
      tap(() => {
        this.reportForm.controls.referrerId.setValue(referrer.id);
      }),
      switchMap(() => this.report$.pipe(take(1))),
      switchMap((report) => {
        const observables: Observable<any>[] = [];
        if (report) {
          observables.push(this.createAuditEvent(report, 'REGISTER_REFERRER'));
          observables.push(this.reportEffect.update(report.id, { referrer_id: referrer.id }));
        }
        return observables.length > 0 ? combineLatest(observables) : of(undefined);
      }),
    );
  }

  selectReport(report: RR.Report) {
    return this.createAuditEvent(report, 'REGISTER_REPORT').pipe(
      tap(() => {
        this.reportForm.controls.reportId.setValue(report.id);
      }),
    );
  }

  createAuditEvent(report: RR.Report, type: RR.AuditEventType) {
    // Create audit event for registration
    const event: Partial<RR.AuditEvent> = { report_id: report.id, type };
    return this.auditEventEffect.createRegistrationEvent(event);
  }

  loadBooking({ bookingId, patch }: { bookingId: number; patch: boolean }) {
    return this.bookingEffect.find(bookingId).pipe(
      map((action) => action.actions.findBookingSuccess.booking),
      switchMap((booking) => {
        if (!booking.referral_enquiry_id) {
          return of({ booking, referralEnquiry: undefined } as const);
        }
        // Also load related ReferralEnquiry if there is one
        return this.referralEnquiryEffect
          .get(booking.referral_enquiry_id)
          .pipe(map((actions) => ({ booking, referralEnquiry: actions.enquiry }) as const));
      }),
      tap(({ booking }) => {
        if (patch) {
          this.bookingForm.patchValue({
            bookingCodeId: booking.booking_code_id,
            billingItemIds: booking.billing_items,
            funder: booking.funder,
            site_id: booking.site_id,
            // Mirror changes in findReport
          });
          this.setState({ pageLoading: 'loaded' });
        }
      }),
    );
  }

  loadReport(reportId: number) {
    return this.reportEffect.find(reportId).pipe(
      map((action) => action.actions.findReportSuccess.report),
      switchMap((report) => {
        const observables: {
          report: Observable<RR.Report>;
          referrer: Observable<RR.Referrer> | Observable<null>;
          patient: Observable<RR.Patient> | Observable<null>;
          invoice: ReturnType<InvoiceEffect['findInReport']>;
          booking: Observable<RR.Booking>;
        } = {
          report: of(report),
          referrer: of(null),
          patient: of(null),
          invoice: this.invoiceEffect.findInReport(report.id),
          booking: this.loadBooking({
            bookingId: report.booking_id,
            // We patch manually at the end of this observable chain
            patch: false,
          }).pipe(map(({ booking }) => booking)),
        };
        if (report.referrer_id)
          observables.referrer = this.referrerEffect
            .findById(report.referrer_id)
            .pipe(map((action) => action.actions.findReferrerSuccess.referrer));
        if (report.patient_id)
          observables.patient = this.patientEffect
            .findById(report.patient_id)
            .pipe(map((action) => action.actions.findPatientSuccess.patient));
        return combineLatest(observables);
      }),
      switchMap(({ report, booking }) => {
        return this.store.select(fromBookingCode.selectBookingCode(booking.booking_code_id)).pipe(
          take(1),
          map((bookingCode) => ({ report, booking, bookingCode }) as const),
        );
      }),
      tap(({ report, booking, bookingCode }) => {
        let scanCodeId = report.scan_code_id ?? undefined;
        if (!scanCodeId && bookingCode) {
          // If not defined, default to the Booking's scan code
          scanCodeId = bookingCode.scan_code_id;
        }
        this.reportForm.patchValue({
          referrerId: report.referrer_id,
          patientId: report.patient_id,
          referral_date: report.referral_date || '',
          scan_code_id: scanCodeId,
          scan_code_side: report.scan_code_side || undefined,
          medicare_provider_id: report.medicare_provider_id ?? undefined,
          noReferrerType: report.referrer_id === null,
        });
        this.bookingForm.patchValue({
          funder: booking.funder,
          billingItemIds: booking.billing_items,
          bookingCodeId: booking.booking_code_id,
          site_id: booking.site_id,
          // Mirror changes in findBooking
        });
      }),
      finalize(() => {
        this.setState({ pageLoading: 'loaded' });
      }),
    );
  }

  loadAll({
    reportId,
    bookingId,
    referrerId,
    patientId,
  }: {
    reportId: number | null;
    bookingId: number | null;
    referrerId: number | null;
    patientId: number | null;
  }) {
    this.reset();
    // When there is a reportId param, load report and associated entities
    // Otherwise if there's a bookingId param, load booking and associated entities
    this.reportForm.patchValue({ reportId, bookingId, referrerId, patientId });
    this.setState({ pageLoading: 'loading' });

    if (reportId) {
      return this.loadReport(reportId);
    } else if (bookingId) {
      return this.loadBooking({ bookingId, patch: true });
    } else {
      const observables: Observable<any>[] = [];
      if (patientId) {
        observables.push(this.patientEffect.findById(patientId));
      }
      if (referrerId) {
        observables.push(this.referrerEffect.findById(referrerId));
      }
      return combineLatest(observables).pipe(
        // We use `finalize` rather than `tap`, because `combineLatest([])` completes immediately so there's no
        // option to chain off it (like `EMPTY`)
        finalize(() => {
          this.setState({
            pageLoading: 'loaded',
          });
        }),
      );
    }
  }

  state = new BehaviorSubject<RegistrationState>(initialState);

  setState(state: Partial<RegistrationState>): void {
    this.state.next({
      ...this.state.value,
      ...state,
    });
  }

  select<K>(mapFn: (state: RegistrationState) => K): Observable<K> {
    return this.state.pipe(map(mapFn), distinctUntilChanged());
  }
  pageLoading$ = this.select((s) => s.pageLoading);

  reset() {
    this.bookingForm.reset();
    this.reportForm.reset();
    this.setState(initialState);
  }
}
