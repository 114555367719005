import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { fixMaxHeight, trackById } from 'app/app.utils';
import { EditorService } from 'app/core/services/editor.service';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { DividerStatementsModalComponent } from 'app/modules/editor/divider/divider-statements-modal/divider-statements-modal.component';
import { StatementChoiceUsageAnalyticsModalComponent } from 'app/modules/editor/modals/statement-choice-usage-analytics-modal/statement-choice-usage-analytics-modal.component';
import { NotepadModalComponent } from 'app/modules/editor/notepad/notepad-modal.component';
import { StatementTextRenderComponent } from 'app/modules/editor/statement-edit/statement-text-render/statement-text-render.component';
import { RecommendedStatementComponent } from 'app/modules/editor/statement-recommendation/recommended-statement/recommended-statement.component';
import { StatementEditModalComponent } from 'app/modules/editor/statement/statement-edit-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromTagChoice } from 'app/store/prefill/tag-choice';
import { fromElement } from 'app/store/template/element';
import { fromStatement } from 'app/store/template/statement';
import { Observable, Subscription, map, take } from 'rxjs';

import { PrefillCopyChoiceComponent } from '../prefill-copy-choice/prefill-copy-choice.component';
import { PrefillCopyChoicesData, PrefillCopyComponent, TagChoiceTemplate } from '../prefill-copy.component';

@Component({
  selector: 'rr-prefill-copy-element',
  standalone: true,
  imports: [SharedModule, RecommendedStatementComponent, PrefillCopyChoiceComponent, StatementTextRenderComponent],
  templateUrl: './prefill-copy-element.component.html',
  styleUrl: './prefill-copy-element.component.css',
})
export class PrefillCopyElementComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren('popoverGroupStatements') popoverGroupStatements: QueryList<NgbPopover>;
  @ViewChildren('popoverGroupStatementsButtons') popoverGroupStatementsButtons: QueryList<ElementRef>;

  @Input() element: RR.Element;
  @Input() region: RR.Region | undefined;
  @Input() subsection: RR.Subsection;
  @Input() section: RR.Section;
  @Input() topic: RR.Topic;

  @Input() usedStatements: RR.Statement[];
  @Input() notepadStatements: RR.Statement[];
  @Input() prefillContextChoices: RR.CtxFullStatementChoice[];
  @Input() currentContextChoices: RR.CtxFullStatementChoice[] = [];
  @Input() exclusiveTagMap: PrefillCopyComponent['exclusiveTagMap'];
  @Input() usedDividers: TagChoiceTemplate[];
  @Input() statementGroupIds: number[];
  @Input() statementGroupTags: string[];
  @Input() suggestionStatementIds: Set<number>;
  @Input() hideExcludedTags: boolean;

  @Output() onCopyChoices = new EventEmitter<PrefillCopyChoicesData>();

  prefillExpanded$: Observable<boolean>;

  subscription = new Subscription();
  trackById = trackById;

  constructor(
    private store: Store<AppState>,
    private modalService: NgbModal,
    private templateService: TemplateService,
    private reportService: ReportService,
    private editorService: EditorService,
  ) {}

  ngOnInit(): void {
    this.prefillExpanded$ = this.editorService.prefill;
  }

  ngAfterViewInit(): void {
    // When switching from 'prefill' to 'index' and back to 'prefill', if the popover is open, focus it.
    // Making easy to close the popover using the space bar, for example.
    this.subscription.add(
      this.prefillExpanded$.subscribe((isPrefillOpen) => {
        if (isPrefillOpen) {
          // Check which popover is opened and stop as soon as the first one is found
          const element = this.popoverGroupStatements.toArray().find((popover) => popover.isOpen());

          if (element) {
            const elementIndex = this.popoverGroupStatements.toArray().indexOf(element);
            const button = this.popoverGroupStatementsButtons.toArray()[elementIndex];
            // Focus the opened popover button
            requestAnimationFrame(() => {
              button.nativeElement.focus();
            });
          }
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  copyElementChoices(element: RR.Element, region: RR.Region | undefined) {
    const statementChoices = this.getStatementChoicesInElement(element, region);
    // @ts-expect-error strictNullChecks
    this.copyChoices(statementChoices.map((sc) => sc.id));
  }

  getStatementChoicesInElement(element: RR.Element, region: RR.Region | undefined) {
    // Find all statements in that element that were used to create choices in prefill reports
    const elementNotepadStatementIds = this.prefillContextChoices
      .filter(
        (ctx) =>
          this.notepadStatements.some((nps) => nps.id === ctx.statement_choice?.statement_id) &&
          ctx.element_choice?.statement_set_id === element.statement_set_id,
      )
      // @ts-expect-error strictNullChecks
      .map((ctx) => ctx.statement_choice.statement_id);

    const elementStatementIds = this.usedStatements
      .filter(
        (s) => s.statement_set_id === element.statement_set_id || elementNotepadStatementIds.some((id) => id === s.id),
      )
      .map((s) => s.id);
    if (region) {
      return this.prefillContextChoices
        .filter(
          (ctx) =>
            // @ts-expect-error strictNullChecks
            elementStatementIds.includes(ctx.statement_choice.statement_id) &&
            // @ts-expect-error strictNullChecks
            ctx.region_choice.region_id === region.id,
        )
        .map((ctx) => ctx.statement_choice);
    }

    return (
      this.prefillContextChoices
        // @ts-expect-error strictNullChecks
        .filter((ctx) => elementStatementIds.includes(ctx.statement_choice.statement_id))
        .map((ctx) => ctx.statement_choice)
    );
  }

  getStatementSet(element_id: number) {
    return this.store.select(fromElement.selectStatementSet(element_id));
  }

  getElementDividers(element: RR.Element, region: RR.Region | undefined) {
    const region_id = region ? region.id : null;
    const filteredDividers = this.usedDividers
      .filter(
        (s) =>
          (s.statement_set_id === element.statement_set_id ||
            this.checkNotepadDividerInElement(s, element, region_id)) &&
          s.region_id === region_id,
      )
      .sort((a, b) => a.position - b.position)
      .map((group) => {
        this.updateTagIdAndText(group);
        return group;
      });

    return filteredDividers;
  }

  checkNotepadDividerInElement(tag: TagChoiceTemplate, element: RR.Element, region_id: number | null) {
    const notepadStatements = this.notepadStatements.filter((nps) => nps.divider_id === tag.tag_id);
    if (!notepadStatements.length) return false;
    return this.prefillContextChoices.find(
      (ctx) =>
        // @ts-expect-error strictNullChecks
        notepadStatements.some((nps) => nps.id === ctx.statement_choice.statement_id) &&
        ctx.element_choice?.statement_set_id === element.statement_set_id &&
        // @ts-expect-error strictNullChecks
        ctx.region_choice.region_id === region_id,
    );
  }

  /**
   * Get all template statement under a divider
   * @param divider
   */
  getStatementsUnderDivider(tag: TagChoiceTemplate): Observable<RR.Statement[]> {
    return this.store
      .select(fromStatement.selectAll)
      .pipe(
        map((statement_entities) =>
          statement_entities
            .filter((s) => s.divider_id === tag.tag_id && !s.is_divider)
            .sort((a, b) => a.position - b.position),
        ),
      );
  }

  /**
   * Statement choices that don't have any divider
   */
  getOrphanStatementChoices(element: RR.Element, region: RR.Region | undefined) {
    return this.getStatementChoicesUnderDivider(element, region, null);
  }

  /**
   * Get all choices under divider from selected topics
   * @param element
   * @param region
   * @param divider
   */
  getStatementChoicesUnderDivider(
    element: RR.Element,
    region: RR.Region | undefined,
    tag: TagChoiceTemplate | null,
  ): RR.FullStatementChoice[] {
    // Find all statements in that element that were used to create choices in prefill reports
    const divider_id = tag ? tag.tag_id : null;
    const statementIds = new Set([
      ...this.usedStatements
        .filter((s) => s.statement_set_id === element.statement_set_id && s.divider_id === divider_id)
        .map((s) => s.id),
      ...this.notepadStatements
        .filter(
          (nps) =>
            nps.divider_id === divider_id &&
            this.prefillContextChoices.some(
              (ctx) =>
                // @ts-expect-error strictNullChecks
                ctx.statement_choice.statement_id === nps.id &&
                // @ts-expect-error strictNullChecks
                ctx.element_choice.statement_set_id === element.statement_set_id &&
                // @ts-expect-error strictNullChecks
                (!region || ctx.region_choice.region_id === region.id),
            ),
        )
        .map((nps) => nps.id),
    ]);
    if (region) {
      return this.prefillContextChoices
        .filter(
          (
            ctx,
          ): ctx is RR.CtxFullStatementChoice & {
            statement_choice: RR.FullStatementChoice;
            region_choice: RR.RegionChoice;
          } => !!ctx.statement_choice && !!ctx.region_choice,
        )
        .filter(
          (ctx) => statementIds.has(ctx.statement_choice.statement_id) && ctx.region_choice.region_id === region.id,
        )
        .map((ctx) => ctx.statement_choice)
        .sort((a, b) => this.sort(a, b));
    }

    return this.prefillContextChoices
      .map((ctx) => ctx.statement_choice)
      .filter((sc): sc is RR.FullStatementChoice => !!sc)
      .filter((sc) => statementIds.has(sc.statement_id))
      .sort((a, b) => this.sort(a, b));
  }

  /**
   * Sort statement choice by their statement position
   * @param statementChoice1
   * @param statementChoice2
   */
  sort(statementChoice1: RR.StatementChoice, statementChoice2: RR.StatementChoice) {
    const s1 = this.usedStatements.find((s) => s.id === statementChoice1.statement_id);
    const s2 = this.usedStatements.find((s) => s.id === statementChoice2.statement_id);
    if (!s1 && !s2) return 0;
    if (!s1) return 1;
    if (!s2) return -1;
    return s1.position - s2.position;
  }

  /**
   * Check if the divider does not have any choices under it
   * @param divider
   */
  isEmptyDivider(tag: TagChoiceTemplate): boolean {
    return !this.prefillContextChoices.some((ctx) => {
      // @ts-expect-error strictNullChecks
      const statement = this.usedStatements.find((s) => s.id === ctx.statement_choice.statement_id);
      // @ts-expect-error strictNullChecks
      return statement?.divider_id === tag.tag_id && ctx.region_choice.region_id === tag.region_id;
    });
  }

  updateTagIdAndText(group: TagChoiceTemplate) {
    requestAnimationFrame(() => {
      if (!this.statementGroupIds.includes(group.tag_id)) {
        this.statementGroupIds = [...this.statementGroupIds, group.tag_id];
        this.statementGroupTags = [...this.statementGroupTags, group.tag_text];
      }
    });
  }

  isSearchingDivider(tag: TagChoiceTemplate): boolean {
    let result = false;
    this.subscription.add(
      this.store
        .select(fromTagChoice.isSelected(tag.id))
        .pipe(take(1))
        .subscribe((value) => {
          if (value) {
            result = true;
          }
        }),
    );
    return result;
  }

  showDividerSentences(
    tag: TagChoiceTemplate,
    element: RR.Element,
    region: RR.Region | undefined,
    subsection: RR.Subsection,
    section: RR.Section,
  ) {
    // Check if it is notepad divider
    if (tag.isNotepad) {
      this.openNotepadModal(element, region, subsection, section);
      return;
    }
    DividerStatementsModalComponent.open({
      modalService: this.modalService,
      topic_id: this.topic.id,
      divider_id: tag.tag_id,
      region_id: tag.region_id,
      parent: 'PREFILL',
    });
  }

  openNotepadModal(element: RR.Element, region: RR.Region | undefined, subsection: RR.Subsection, section: RR.Section) {
    NotepadModalComponent.open({
      modal: this.modalService,
      template_id: this.topic.template_id,
      topic: this.topic,
      element,
      subsection,
      section,
      region,
    });
  }

  editTag(tag: TagChoiceTemplate, element: RR.Element, region: RR.Region | undefined, subsection: RR.Subsection) {
    if (tag.isNotepad) return;
    this.openStatementEditModal(tag.tag_id, element, region, subsection);
  }

  openStatementEditModal(
    statement_id: number,
    element: RR.Element,
    region: RR.Region | undefined,
    subsection: RR.Subsection,
  ) {
    const { componentInstance } = StatementEditModalComponent.open(this.modalService, {
      windowClass: 'statement-edit-modal',
    });
    componentInstance.statement_id = statement_id;
    componentInstance.region = region;
    componentInstance.topic = this.topic;
    componentInstance.subsection = subsection;
    componentInstance.element = element;
    componentInstance.editType = 'editStatement';
    componentInstance.source = 'PREFILL';
  }

  openStatementUsageAnalytics(tag: TagChoiceTemplate) {
    StatementChoiceUsageAnalyticsModalComponent.open(
      this.modalService,
      tag.tag_id,
      this.topic,
      'statement',
      true, // prefill groups
      this.statementGroupIds,
      this.statementGroupTags,
    );
  }

  onClickPopoverGroupStatements(popoverGroupStatements: NgbPopover, tag: TagChoiceTemplate) {
    popoverGroupStatements.toggle();
    this.subscription.add(
      this.templateService.loadStatementSet(tag.statement_set_id, this.topic.template_id).subscribe(() => {
        this.popoverGroupStatementsFixHeight();
      }),
    );
  }

  popoverGroupStatementsFixHeight() {
    requestAnimationFrame(() => {
      const el: HTMLElement | null = document.querySelector(`.group-statements-popover .popover-body`);
      if (el) {
        fixMaxHeight(el);
      }
    });
  }

  /**
   * Create choice from statement
   * @param statement
   * @param element
   * @param region
   */
  selectStatement(statement: RR.Statement, element: RR.Element, region: RR.Region | undefined) {
    this.subscription.add(
      this.reportService
        .chooseStatement({
          topic_id: this.topic.id,
          element_id: element.id,
          statement_id: statement.id,
          region_id: region ? region.id : undefined,
        })
        .subscribe(),
    );
  }
}
