<div class="d-flex flex-column w-100">
  <div class="w-100">
    <div hidden data-cy="hidden-text">
      <!-- Due to formatting selecting from the styled text is too difficult -->
      <ng-container *ngFor="let obj of text_object_choices">
        <ng-container *ngIf="obj.type === 'literal'">{{ obj.text }}</ng-container>
        <ng-container *ngIf="obj.type === 'set'">{{ obj.text }}</ng-container>
        <ng-container *ngIf="obj.type === 'number' && obj.formula">{{ obj.formula }}</ng-container>
        <ng-container *ngIf="obj.type === 'number'">**</ng-container>
      </ng-container>
    </div>

    <ng-container *ngFor="let obj of text_object_choices; trackBy: trackBy">
      <span class="me-1" *ngIf="obj.type === 'literal'">{{ obj.text }}</span>
      <div
        ngbDropdown
        class="d-inline"
        container="body"
        #dropdown="ngbDropdown"
        (openChange)="onOpenChange(dropdown, $event)"
        [autoClose]="'outside'"
        [attr.data-type]="obj.type"
        [attr.data-id]="obj.id"
        *ngIf="obj.type === 'set' || obj.type === 'number' || obj.type === 'date'"
      >
        <button
          class="attribute-toggle btn btn-sm dropdown-toggle"
          title="Click to select a different text option. For voice mode, speak the desired term, then follow with the verbal command 'comma' to initiate the search."
          *ngIf="obj.type === 'set'"
          data-no-bubble-choice
          ngbDropdownToggle
        >
          {{ obj.text }}
        </button>
        <button
          class="number-toggle btn btn-sm dropdown-toggle"
          *ngIf="obj.type === 'number'"
          data-no-bubble-choice
          ngbDropdownToggle
        >
          <ng-container *ngIf="obj.text">{{ obj.text }}</ng-container
          ><ng-container *ngIf="!obj.text && obj.formula">{{ obj.formula }}</ng-container
          ><ng-container *ngIf="!obj.text && !obj.formula">**</ng-container>
          <span
            *ngIf="
              proposed &&
              proposed.proposed &&
              proposed.proposed[obj.text_object_id] &&
              proposed.proposed[obj.text_object_id].length > 1
            "
            class="ms-1 fa fa-exclamation-circle"
            rrTooltip="NUMBER_MULTIPLE_DICOM_MEASUREMENT"
          ></span>
        </button>
        <button
          class="date-toggle btn btn-sm dropdown-toggle"
          *ngIf="obj.type === 'date'"
          data-no-bubble-choice
          ngbDropdownToggle
        >
          <ng-container *ngIf="obj.date">{{ obj.text }}</ng-container>
          <ng-container *ngIf="!obj.date"><rr-date-type [dateType]="obj.date_type"></rr-date-type></ng-container>
        </button>
        <div *ngIf="obj.type === 'number'" ngbDropdownMenu>
          <rr-statement-number
            *ngIf="dropdown.isOpen()"
            class="d-block"
            [choice_object]="obj"
            [choice]="choice"
            [statement]="statement"
            (enter)="onEnter(dropdown, 'number')"
            (tabNumbers)="onkeydown($event)"
            [topic]="topic"
            [proposed_measurements]="proposed && proposed.proposed && proposed.proposed[obj.text_object_id]"
          >
          </rr-statement-number>
        </div>
        <div *ngIf="obj.type === 'set'" ngbDropdownMenu>
          <!-- eslint-disable @angular-eslint/template/no-any -->
          <rr-statement-attribute
            *ngIf="dropdown.isOpen()"
            class="d-block"
            [choice_object]="$any(obj)"
            [choice]="choice"
            [statement]="statement"
            (enter)="onEnter(dropdown, 'set')"
            (tabAttributes)="onkeydown($event)"
          >
          </rr-statement-attribute>
          <!-- eslint-enable @angular-eslint/template/no-any -->
        </div>
        <div *ngIf="obj.type === 'date'" ngbDropdownMenu>
          <rr-statement-date
            *ngIf="dropdown.isOpen()"
            class="d-block"
            [choice_object]="obj"
            (enter)="onEnter(dropdown, 'date')"
            (tabDate)="onkeydown($event)"
          >
          </rr-statement-date>
        </div>
      </div>
    </ng-container>
    <span
      *ngIf="filterType === 'LOGISTIC_REGRESSION' && statementFrequency"
      class="fw-bold text-dark ms-2 badge bg-light text-dark"
    >
      {{ statementFrequency | number: '1.0-2' }}%
    </span>
    <span
      *ngIf="filterType !== 'LOGISTIC_REGRESSION' && filterType !== 'NONE' && statementFrequency"
      class="fw-bold text-dark ms-2 badge bg-light text-dark"
    >
      {{ statementFrequency }}
    </span>
  </div>
  <div class="d-flex btn-group btn-group-sm ms-auto" data-no-bubble-choice>
    <button
      class="btn btn-sm btn-info"
      (click)="openStatementUsageAnalytics()"
      permission
      [permission-only]="['report_manage']"
      rrTooltip="FIND_PREVIOUS_REPORT_BY_STATEMENT_ATTRIBUTE"
    >
      <i class="fa fa-file-medical"></i>
    </button>
    <button
      role="button"
      (click)="openEditor('editStatement')"
      class="btn btn-sm btn-info"
      id="edit"
      rrTrack
      rrTooltip="EDIT"
      permission
      [permission-only]="['template_manage']"
    >
      <span class="fa fa-edit"></span>
    </button>

    <button
      role="button"
      *ngIf="parentComponent === 'STATEMENT'"
      (click)="openEditor(element.type === 'notepad' ? 'insertNotepadStatement' : 'insertStatement')"
      class="btn btn-sm btn-info"
      rrTooltip="INSERT_STATEMENT_BEFORE"
      permission
      [permission-only]="['template_manage']"
    >
      <span class="fa fa-arrow-up"></span>
    </button>

    <button
      *ngIf="subsection"
      class="btn btn-sm"
      [class.btn-info]="!choice.excluded"
      [class.btn-danger]="choice.excluded"
      (click)="toggleExcluded()"
      [rrTooltip]="choice.excluded ? 'UNEXCLUDE_SENTENCE' : 'EXCLUDE_SENTENCE'"
    >
      <i class="fa fa-eye-slash"></i>
    </button>
    <button
      role="button"
      (click)="onToggleClone('key_finding')"
      class="btn btn-sm btn-info"
      *ngIf="isFindingsOrTechnique"
      [ngClass]="!cloneMap['key_finding'] ? 'btn-primary' : 'btn-danger'"
      permission
      [permission-only]="['report_manage']"
      rrTooltip="KEY_FINDING"
      tabindex="-1"
    >
      <span class="fa fa-asterisk"></span>
    </button>
    <button
      role="button"
      (click)="onToggleClone('comment')"
      class="btn btn-sm btn-info"
      *ngIf="isFindingsOrImpressionRecommendations"
      [ngClass]="!cloneMap['comment'] ? 'btn-primary' : 'btn-danger'"
      permission
      [permission-only]="['report_manage']"
      rrTooltip="COMMENT"
      tabindex="-1"
    >
      <span class="fa fa-comment"></span>
    </button>
    <!--Clone impression and recommendations-->
    <button
      role="button"
      (click)="onToggleClone('impression_recommendations')"
      class="btn btn-sm btn-info"
      *ngIf="isFindingsOrComment"
      [ngClass]="!cloneMap['impression_recommendations'] ? 'btn-primary' : 'btn-danger'"
      permission
      [permission-only]="['report_manage']"
      rrTooltip="CLONE_IMPRESSION_RECOMMENDATIONS"
      tabindex="-1"
    >
      <span class="fa fa-italic"></span>
    </button>
    <button
      role="button"
      (click)="repeatChoice()"
      class="btn btn-sm btn-info"
      rrTooltip="CHOOSE_AGAIN"
      permission
      [permission-only]="['report_manage']"
    >
      <span class="fas fa-copy"></span>
    </button>

    <div
      *ngIf="region && parentComponent === 'STATEMENT'"
      ngbDropdown
      container="body"
      display="dynamic"
      placement="bottom-right"
    >
      <button
        ngbDropdownToggle
        role="button"
        class="btn btn-sm btn-info rounded-0"
        rrTooltip="ADD_TO_OTHER_REGIONS"
        permission
        [permission-only]="['report_manage']"
      >
        <i class="fas fa-paste"></i>
      </button>
      <div ngbDropdownMenu>
        <table>
          <tr>
            <th>
              Default
              <span class="text-primary" rrTooltip="DEFAULT_COPY_REGION"><i class="fas fa-question-circle"></i></span>
            </th>
            <th>
              Current
              <span class="text-primary" rrTooltip="CURRENT_COPY_REGION"><i class="fas fa-question-circle"></i></span>
            </th>
            <th>Copy to</th>
          </tr>
          <tr *ngFor="let otherRegion of otherRegions$ | async">
            <ng-container *ngIf="region.id !== otherRegion.id">
              <td>
                <input
                  type="checkbox"
                  role="button"
                  id="check-region-{{ otherRegion.id }}"
                  (click)="addRegionStatement({ region_id: otherRegion.id, copyDefaultAttributes: true })"
                  [checked]="otherRegion.isCheckedDefault"
                  class="ms-2"
                  [disabled]="otherRegion.disabled"
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  role="button"
                  id="check-region-{{ otherRegion.id }}"
                  (click)="addRegionStatement({ region_id: otherRegion.id, copyDefaultAttributes: false })"
                  [checked]="otherRegion.isCheckedNonDefault"
                  class="ms-2"
                  [disabled]="otherRegion.disabled"
                />
              </td>
              <td>{{ otherRegion.name }}</td>
            </ng-container>
          </tr>
        </table>
      </div>
    </div>

    <button
      class="btn btn-sm btn-info"
      (click)="popoverSearch.open()"
      [ngbPopover]="popSearchContent"
      #popoverSearch="ngbPopover"
      container="body"
      triggers="manual"
      [autoClose]="'outside'"
      [placement]="'bottom'"
      rrTooltip="GLOBAL_PREFILL_SEARCH"
    >
      <ng-template #popSearchContent>
        <ng-container>
          <button
            class="btn btn-primary w-100"
            (click)="copyToGlobalSearch(); popoverSearch.close()"
            rrTooltip="GLOBAL_STATEMENT_SEARCH"
          >
            Global Search
          </button>
          <button
            class="btn btn-secondary w-100 mt-1"
            (click)="copyToFullTextSearch(); popoverSearch.close()"
            rrTooltip="COPY_TO_FULL_TEXT_SEARCH"
          >
            Prefill Search
          </button>
        </ng-container>
      </ng-template>
      <i class="fa fa-search"></i>
    </button>

    <button
      class="btn btn-sm btn-info"
      (click)="searchAndOpenSuggestionsPanel()"
      rrTooltip="CORRELATED_SENTENCE_SEARCH"
    >
      <i class="fas fa-lightbulb"></i>
    </button>
    <button
      role="button"
      *ngIf="choicesCount < 3 && choicesCount > 1 && parentComponent === 'STATEMENT'"
      id="button-select"
      (click)="singleStatementSelect()"
      class="btn btn-sm btn-danger"
      permission
      [permission-only]="['report_manage']"
      rrTooltip="CHOOSE_ONLY_THIS_STATEMENT"
    >
      <span class="fa fa-plus-circle"></span>
    </button>
    <button
      role="button"
      (click)="removeChoice()"
      class="button-minus btn btn-sm btn-danger"
      permission
      [permission-only]="['report_manage']"
      rrTooltip="REMOVE_FROM_REPORT"
      tabindex="-1"
    >
      <span class="fa fa-minus"></span>
    </button>

    <!--Categorise sentence-->
    <button
      *ngIf="is_in_sc_mode$ | async"
      role="button"
      (click)="categoriseSentence()"
      class="btn btn-sm"
      [ngClass]="(isSentenceCategorised | async) ? 'btn-success' : 'btn-info'"
      [title]="sentenceCategoriesTooltip | async"
      tabindex="-1"
    >
      <span class="fa fa-layer-group"></span>
    </button>
  </div>
</div>
