import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { AppState } from 'app/store';
import { fromBookingCode } from 'app/store/booking-code';
import { Dictionary } from 'lodash';
import { groupBy } from 'lodash-es';
import { Observable, Subscription } from 'rxjs';

import { BookingCodeHeadlineComponent } from '../../components/booking-code-headline/booking-code-headline.component';

@Component({
  selector: 'rr-booking-code-select-modal',
  templateUrl: './booking-code-select-modal.component.html',
  styleUrls: ['./booking-code-select-modal.component.css'],
  standalone: true,
  imports: [CommonModule, BookingCodeHeadlineComponent],
})
export class BookingCodeSelectModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  activeBookingCodes: RR.BookingCode[] = [];
  filteredBookingCodes: RR.BookingCode[] = [];
  bookingCodesLoaded$: Observable<boolean>;
  groupedBookingCodes: Dictionary<RR.BookingCode[]> = {};
  modalities: string[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
  ) {}

  selectBookingCode(bookingCode: RR.BookingCode) {
    this.activeModal.close(bookingCode.id);
  }

  ngOnInit(): void {
    this.bookingCodesLoaded$ = this.store.select(fromBookingCode.selectLoaded);

    this.subscription.add(
      this.store.select(fromBookingCode.selectActiveBookingCodes).subscribe((codes) => {
        this.activeBookingCodes = codes;
        this.filteredBookingCodes = codes;
        this.groupedBookingCodes = groupBy(this.filteredBookingCodes, 'modality');
        this.modalities = Object.keys(this.groupedBookingCodes);
      }),
    );
  }

  onSearchTextChanged(text: string) {
    this.filteredBookingCodes = this.activeBookingCodes.filter(
      (code) =>
        code.code.toLowerCase().includes(text.toLowerCase()) || code.name.toLowerCase().includes(text.toLowerCase()),
    );
    this.groupedBookingCodes = groupBy(this.filteredBookingCodes, 'modality');
    this.modalities = Object.keys(this.groupedBookingCodes);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.searchInput.nativeElement.focus();
  }

  static open(modalService: NgbModal) {
    const modalRef = modalService.open(BookingCodeSelectModalComponent, {
      size: 'lg',
    });
    return modalRef;
  }
}
