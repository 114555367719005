import { Dictionary } from '@ngrx/entity';
import { Observable, combineLatest, map } from 'rxjs';
import { BasePoint, BaseRange, Descendant, Path, Point } from 'slate';
import { Editor, Node, Range, Text, Transforms } from 'slate';
import { Element } from 'slate';

import { DenormalisedAttribute } from '../slatement-attributes/bruteForceAttributeSearch';
import { AttributeTextObjectElement, NumberTextObjectElement, CustomText, DateTextObjectElement } from './slate-types';

function createAttributeTextObjectElement(textObject: RR.TextObjectSet) {
  const a: AttributeTextObjectElement = {
    type: 'rr-attribute',
    children: [{ text: '' }],
    attribute_set_id: textObject.attribute_set_id,
    shortlist: textObject.shortlist,
    text_object_id: textObject.id,
  };
  return a;
}

function createNumberTextObjectElement(textObject: RR.TextObjectNumber) {
  const a: NumberTextObjectElement = {
    type: 'rr-number',
    children: [{ text: '' }],
    text_object_id: textObject.id,
    upper: textObject.upper,
    lower: textObject.lower,
    formula: textObject.formula,
  };
  return a;
}

function createDateTextObjectElement(textObject: RR.TextObjectDate) {
  const a: DateTextObjectElement = {
    type: 'rr-date',
    children: [{ text: '' }],
    text_object_id: textObject.id,
    date_type: textObject.date_type,
  };
  return a;
}

export function textObjectsToEditorValue(textObjects: RR.TextObject[]): Descendant[] {
  return textObjects.map((textObject) => {
    if (textObject.type === 'set') {
      return createAttributeTextObjectElement(textObject);
    } else if (textObject.type === 'number') {
      return createNumberTextObjectElement(textObject);
    } else if (textObject.type === 'date') {
      return createDateTextObjectElement(textObject);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (textObject.type === 'literal') {
      const text = textObject.value || '';
      const a: CustomText = {
        text,
      };
      return a;
      // TODO(slate-literal): do we need to do this? Should we just use Text nodes?
      // const a: LiteralTextObject = {
      //   type: 'rr-literal',
      //   children: [{ text }],
      // TODO(slate-literal): Editor.normalize doesn't collapse adjacent Text nodes with differing `text_object_id`s
      //   text_object_id: textObject.id,
      // };
    } else {
      throw new Error(`Unknown text object type ${textObject}`);
    }
  });
  // .filter((x): x is Exclude<typeof x, null> => !!x);
}

export function getTextFromSelection(editor: Editor) {
  const { selection } = editor;

  if (!selection) {
    throw new Error('No selection');
  }

  const range = Editor.range(editor, selection);
  return Editor.string(editor, range);
}

export function getTextBeforeCursorUntilVoid(editor: Editor) {
  const { selection } = editor;

  if (!selection) {
    throw new Error('No selection');
  }

  let cursorPoint: BasePoint | undefined = selection.anchor;

  // Scan backwards from the cursor until we find a void node
  while (cursorPoint) {
    // const [node] = Editor.node(editor, beforePoint);

    // Check if we're at a void node. The node itself is { text: "" }. This gets the parent of that.
    const [voidNode] = Editor.void(editor, { at: cursorPoint }) || [];

    if (voidNode) {
      // If we're at a void node, return the text from the void node to the cursor
      const range = Editor.range(editor, cursorPoint, selection.anchor);
      return Editor.string(editor, range);
    }

    // If we're not at a void node, continue looking backwards
    cursorPoint = Editor.before(editor, cursorPoint);
  }

  // If there's no void node before the cursor, return the whole text
  const range = Editor.range(editor, [0], selection.anchor);
  return Editor.string(editor, range);
}

export function rangeBeforeCursorIsStarStar(editor: Editor): BaseRange | undefined {
  if (editor.selection) {
    const startPoint = Editor.before(editor, editor.selection, { distance: 2 });
    if (startPoint) {
      const range = Editor.range(editor, startPoint, editor.selection);
      const stringTwoBefore = Editor.string(editor, range);
      if (stringTwoBefore === '**') {
        return range;
      }
    }
  }
  return undefined;
}

export function slateValueToTextObjects(slateValue: Descendant[]) {
  const firstDescendant: Descendant = slateValue[0];
  if (!Element.isElement(firstDescendant)) {
    throw new Error(`First descendant is not an element: ${firstDescendant}`);
  }
  return firstDescendant.children.map((node: Descendant) => {
    if (Element.isElement(node)) {
      if (node.type === 'rr-attribute') {
        return {
          id: node.text_object_id,
          type: 'set',
          default_option_id: node.default_option_id,
          attribute_set_id: node.attribute_set_id,
          shortlist: node.shortlist,
        } as const;
      } else if (node.type === 'rr-number') {
        return {
          id: node.text_object_id,
          type: 'number',
          upper: node.upper,
          lower: node.lower,
          formula: node.formula,
        } as const;
        // TODO(slate-literal)
        // } else if (node.type === 'rr-literal') {
        //   return {
        //     id: node.text_object_id,
        //     type: 'literal',
        //   };
      } else if (node.type === 'rr-date') {
        return {
          id: node.text_object_id,
          type: 'date',
          date_type: node.date_type,
        } as const;
      } else {
        throw new Error(`Unknown element type: ${node.type}`);
      }
    } else {
      // Text.isText(node)
      // throw new Error(`Text node should be "rr-literal": "${node.text}"`);
      return {
        // TODO(slate-literal): remove this
        type: 'literal',
        value: node.text,
        // Old behaviour was to always create a new 'literal' TextObject and delete the old. If we pass the
        // `text_object_id`, it doesn't need to do this.
        // TODO: when inserting an attribute it splits the text into two Text nodes and the second one has the same id
        // id: node.text_object_id,
      } as const;
    }
  });
}

export function findPathOfNode(editor: Editor, targetNode: Node): Path | undefined {
  // Traverse nodes tree
  for (const [node, path] of Editor.nodes(editor, { at: [], universal: true })) {
    // Check if the current node matches the target node
    if (node === targetNode) {
      return path;
    }
  }
  return undefined;
}

export function handleDoubleClickOnWindows(editor: Editor): (event: React.MouseEvent) => void {
  // This removes the whitespace from the selection that occurs after double-clicking a word on Windows.

  return () => {
    const { selection } = editor;

    if (!selection || Range.isCollapsed(selection)) return;

    const [node] = Editor.node(editor, selection.focus.path);

    if (!Text.isText(node)) return;

    // You can double click and then drag. This is the case where you drag from right to left. focus < anchor.
    // Do nothing to the selection in this case.
    if (Point.isAfter(selection.anchor, selection.focus)) return;

    if (node.text[selection.focus.offset - 1] === ' ') {
      // left to right. handle the windows double clicking bug
      const newSelection = {
        ...selection,
        focus: {
          ...selection.focus,
          offset: selection.focus.offset - 1,
        },
      };
      Transforms.select(editor, newSelection);
    }
  };
}

export function findAttributeSetNameById(options: { attributes: DenormalisedAttribute[] | undefined; typeId: number }) {
  const { attributes, typeId } = options;

  if (attributes) {
    attributes.sort((a, b) => {
      return b.attributeOption.frequency - a.attributeOption.frequency;
    });

    return attributes.find((attributeSet) => attributeSet.attributeSet.id === typeId);
  }
  return undefined;

  // Sort the denormalisedAttributes by frequency in descending order
}

export function fixSpacingAround(editor: Editor, element: Element) {
  const path = findPathOfNode(editor, element);
  const [nextNode, nextPath] = Editor.next(editor, { at: path }) || [];
  if (nextNode && nextPath) {
    // Even if there was an Void originally. After normalization, the next node would be `{text: ''}`
    const [lastNode] = Editor.last(editor, []);
    if (Text.isText(nextNode) && nextNode !== lastNode) {
      if (nextNode.text[0] !== ' ') {
        Transforms.insertText(editor, ' ' + nextNode.text, { at: nextPath });
      }
    }
  }
  const [prevNode, prevPath] = Editor.previous(editor, { at: path }) || [];
  if (prevNode) {
    const [firstNode] = Editor.first(editor, []);
    if (Text.isText(prevNode) && prevNode !== firstNode) {
      if (prevNode.text[prevNode.text.length - 1] !== ' ') {
        Transforms.insertText(editor, prevNode.text + ' ', { at: prevPath });
      }
    }
  }
}

/**
 * Fix the spacing after pasting in text
 */
export function handleInsertData(editor: Editor, insertData: Editor['insertData'], data: DataTransfer) {
  const selection1 = editor.selection;
  // After inserting the data, the selection changes. So we need to save the selection before and after.
  insertData(data);
  const selection2 = editor.selection;
  if (!selection1 || !selection2) return;

  const [node, path] = Editor.node(editor, selection2);
  if (!Text.isText(node)) return;
  const originalText = node.text;

  // "<textBefore><textMiddle><textAfter>"
  // textMiddle is the pasted text
  const startPoint = Range.start(selection1); // Use the start because the selection might not be collapsed
  const endPoint = Range.start(selection2);
  const textBefore = originalText.slice(0, startPoint.offset);
  const textMiddle = originalText.slice(startPoint.offset, endPoint.offset);
  const textAfter = originalText.slice(endPoint.offset);

  const [prevNode] = Editor.previous(editor, { at: path }) || [];
  const [nextNode] = Editor.next(editor, { at: path }) || [];

  let paddingBefore = '';
  if (
    // If there is no space, between `<textBefore><textMiddle>` then insert a space
    (textBefore !== '' && !textBefore.endsWith(' ') && !textMiddle.startsWith(' ')) ||
    // If there is no space before, and the previous node is an attribute, then insert a space
    (textBefore === '' && !textMiddle.startsWith(' ') && Element.isElement(prevNode))
  ) {
    paddingBefore = ' ';
  }

  let paddingAfter = '';
  if (
    // if textAfter starts with punctuation, don't add a space
    !['.', ',', '!', '?', ':', ';'].includes(textAfter[0]) &&
    // If there is no space, between `<textMiddle><textAfter>` then insert a space
    ((textAfter !== '' && !textMiddle.endsWith(' ') && !textAfter.startsWith(' ')) ||
      // If there is no space after, and the next node is an attribute, then insert a space
      (textAfter === '' && !textMiddle.endsWith(' ') && Element.isElement(nextNode)))
  ) {
    paddingAfter = ' ';
  }

  let newText = textBefore + paddingBefore + textMiddle + paddingAfter + textAfter;
  // Replace double spaces. `g` means replace all occurrences
  newText = newText.replace(/ {2,}/g, ' ');

  Transforms.insertText(editor, newText, { at: path });

  // Adjust the selection based on the new text length
  const newAnchor = selection2.anchor.offset + newText.length - originalText.length;
  const newSelection = {
    path: selection2.focus.path,
    offset: newAnchor,
  };
  Transforms.setSelection(editor, {
    anchor: newSelection,
    focus: newSelection,
  });
}

export function moveCursorAfter(editor: Editor, element: Element) {
  const nodePath = findPathOfNode(editor, element);
  if (!nodePath) {
    throw new Error('Could not find path of node');
  }
  const afterPath = Editor.after(editor, nodePath);
  // put the cursor after the inserted node
  Transforms.setSelection(editor, {
    anchor: afterPath,
    focus: afterPath,
  });
}

export function getDenormalisedAttributes(
  attributeSetSelector: Observable<RR.AttributeSet[]>,
  attributeOptionSelector: Observable<Dictionary<RR.AttributeOption>>,
): Observable<DenormalisedAttribute[]> {
  return combineLatest([attributeSetSelector, attributeOptionSelector]).pipe(
    map(([attributeSets, attributeOptions]) => {
      return attributeSets
        .map((attributeSet) => {
          return attributeSet.attribute_option_ids
            .map((attributeOptionId) => attributeOptions[attributeOptionId])
            .filter((attributeOption): attributeOption is RR.AttributeOption => attributeOption !== undefined)
            .map((attributeOption) => {
              return {
                attributeOption,
                // Finding the parent AttributeSet of an AttributeOption is hard/slow, so just store it here.
                attributeSet,
              };
            });
        })
        .flat();
    }),
  );
}
