import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filterDefined } from 'app/app.utils';
import { MessageService } from 'app/core/services/message.service';
import { atLeastOneRequired, forbiddenDOB, formValueChanges } from 'app/shared/utils/form-validation.utils';
import { getEmailValidatorRegex } from 'app/shared/utils/shared.utils';
import { AppState } from 'app/store';
import { BookingEffect, FetchSlotParams } from 'app/store/booking';
import { fromBookingCode } from 'app/store/booking-code';
import { fromPatient } from 'app/store/patient';
import { fromScanCode } from 'app/store/scan-code';
import { fromSite } from 'app/store/site';
import { add, formatISO } from 'date-fns';
import {
  BehaviorSubject,
  Subscription,
  combineLatest,
  distinctUntilChanged,
  finalize,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

// `bookingForm` is an Input to child components `[bookingForm]="bookingForm"`

export type BookingForm = BookingPageService['bookingForm'];
export type BookingFormType = ReturnType<BookingForm['getRawValue']>;

export type BookingPatientForm = BookingPageService['bookingPatientForm'];
export type BookingPatientFormType = ReturnType<BookingPatientForm['getRawValue']>;

export type BookingSlotFiltersForm = BookingPageService['slotFiltersForm'];
export type BookingSlotFiltersFormType = ReturnType<BookingSlotFiltersForm['getRawValue']>;

export type BookingPageViewMode = 'view' | 'edit' | 'create';
/**
 * Page state for the Booking page. There are many different components that need to share state. It's easier to manage
 * the state in one place rather than prop drilling.
 */
export interface BookingPageState {
  /**
   * The Selected Date for
   */
  date: Date;
  /**
   * The Booking being edited or viewed, undefined if creating a new booking.
   */
  booking: RR.Booking | undefined;
  bookingLoading: boolean;
  viewMode: BookingPageViewMode;
  /**
   * "booking" enquiry id (Web booking enquiries)
   */
  enquiryId: number | undefined;
  /**
   * The available Slot that is selected
   */
  slot: RR.Slot | undefined;
  /**
   * Slots returned by /api/available_slots
   */
  availableSlots: RR.SlotGroup[] | undefined;
  availableSlotsLoading: boolean;
}

const initialState: BookingPageState = {
  date: new Date(),
  booking: undefined,
  bookingLoading: false,
  viewMode: 'create',
  enquiryId: undefined,
  slot: undefined,
  availableSlots: undefined,
  availableSlotsLoading: false,
};

@Injectable({ providedIn: 'root' })
export class BookingPageService implements OnDestroy {
  subscription = new Subscription();

  bookingForm = new FormGroup(
    {
      patientId: new FormControl<number | null>(null, { nonNullable: true }),
      bookingCodeId: new FormControl<number | null>(null, {
        validators: Validators.required,
        nonNullable: true,
      }),
      startTime: new FormControl<Date | null>(null, { nonNullable: true, validators: Validators.required }),
      endTime: new FormControl<Date | null>(null, { nonNullable: true }),
      siteId: new FormControl<number | null>(null, { nonNullable: true }),
      notes: new FormControl<string | null>(null, { nonNullable: true }),
      injection: new FormControl(false, { nonNullable: true }),
      userId: new FormControl<number | null>(null, { nonNullable: true }),
      referrerType: new FormControl<RR.ReferrerType | null>(null, { nonNullable: true }),
      billingItemIds: new FormControl<number[]>([], { nonNullable: true }),
      /**
       * The length of the available billing items is stored here for validation. When there are none, we can't force
       * the user to select one.
       */
      billingItemsLength: new FormControl<number>(0, { nonNullable: true }),
      funder: new FormControl<RR.FunderType>('medicare', { nonNullable: true }),
      referralEnquiryId: new FormControl<number | null>(null, { nonNullable: true }),

      // filters
      existingPatient: new FormControl(true, { nonNullable: true }),
      noReferrerType: new FormControl(false, { nonNullable: true }),
    },
    { validators: [this.crossValidate()] },
  );
  bookingFormValue$ = formValueChanges(this.bookingForm);

  bookingPatientForm = new FormGroup(
    {
      first_name: new FormControl('', { nonNullable: true, validators: Validators.required }),
      last_name: new FormControl('', { nonNullable: true, validators: Validators.required }),
      dob: new FormControl<string | null>(null, { nonNullable: true, validators: forbiddenDOB() }),
      gender: new FormControl<RR.Sex | null>(null, { nonNullable: true, validators: Validators.required }),
      phone: new FormControl<string>('', {
        validators: Validators.pattern('[0-9]{10}'),
        nonNullable: true,
      }),
      email: new FormControl<string>('', {
        validators: [Validators.pattern(getEmailValidatorRegex())],
        nonNullable: true,
      }),
    },
    Validators.compose([atLeastOneRequired(Validators.required, ['phone', 'email'], 'phoneOrEmailRequired')]),
  );
  booking: RR.Booking | undefined;

  slotFiltersForm = new FormGroup({
    doctor_preferred: new FormControl<'INJECTION_TIME' | 'DOCTOR_ONSITE' | null>(null, { nonNullable: true }),
    gender_preferred: new FormControl<'male' | 'female' | 'any'>('any', { nonNullable: true }),
    doctor_id: new FormControl<number | null>(null, { nonNullable: true }),
  });
  slotFiltersFormValue$ = formValueChanges(this.slotFiltersForm);

  constructor(
    private store: Store<AppState>,
    private bookingEffect: BookingEffect,
    private message: MessageService,
    private router: Router,
  ) {
    this.subscription.add(
      this.booking$.subscribe((booking) => {
        this.booking = booking;
      }),
    );

    this.subscription.add(
      this.billingItems$.subscribe((billingItems) => {
        const billingItemsLength = billingItems.length;
        this.bookingForm.patchValue({ billingItemsLength });
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Cross validation for the booking form. This is used to validate fields that depend on each other.
   */
  crossValidate(): ValidatorFn {
    return (_form: AbstractControl) => {
      const form: BookingForm = _form as BookingForm;
      // patientId is required, if we checked the "Existing Patient" checkbox
      if (form.controls.existingPatient.value && form.controls.patientId.value === null) {
        form.controls.patientId.setErrors({ required: true });
      } else {
        // Clear the error when unchecking "Existing Patient" checkbox.
        // For below, I think it's not needed because those fields clear themselves as they're updated, but here it
        // responds to a different field.
        form.controls.patientId.setErrors(null);
      }
      // billing items are not required, if there are no billing items
      if (form.controls.billingItemsLength.value === 0) {
        form.controls.billingItemIds.setErrors(null);
      } else if (form.controls.billingItemIds.value.length === 0) {
        form.controls.billingItemIds.setErrors({ required: true });
      }
      return null;
    };
  }

  bookingFormToJson(override?: Pick<RR.Booking, 'type'>): Partial<RR.Booking> {
    const value = this.bookingForm.getRawValue();
    return {
      patient_id: value.patientId ?? undefined,
      booking_code_id: value.bookingCodeId ?? undefined,
      start_time: value.startTime ? formatISO(value.startTime) : undefined,
      end_time: value.endTime ? formatISO(value.endTime) : undefined,
      site_id: value.siteId ?? undefined,
      notes: value.notes,
      injection: value.injection,
      rr_user_id: value.userId,
      referrer_type: value.referrerType,
      funder: value.funder,
      referral_enquiry_id: value.referralEnquiryId ?? undefined,
      ...override,
    };
  }

  bookingPatientFormJson(): Partial<RR.BookingPatient> | undefined {
    const bookingValue = this.bookingForm.getRawValue();
    if (bookingValue.patientId) {
      // We don't need to send BookingPatient data if we've linked a Patient already
      return undefined;
    }
    const value = this.bookingPatientForm.getRawValue();

    // When you backspace the date input it converts to an empty string, which is invalid for the API
    const dob = value.dob !== null && value.dob !== '' ? value.dob : null;

    return {
      first_name: value.first_name,
      last_name: value.last_name,
      dob,
      gender: value.gender ?? undefined,
      phone: value.phone,
      email: value.email,
    };
  }

  bookingPatientFormFromBookingPatient(bookingPatient: RR.BookingPatient) {
    this.bookingPatientForm.patchValue({
      first_name: bookingPatient.first_name,
      last_name: bookingPatient.last_name,
      gender: bookingPatient.gender,
      dob: bookingPatient.dob ?? null,
      email: bookingPatient.email ?? '',
      phone: bookingPatient.phone ?? '',
    });
  }

  bookingFormFromBooking(booking: RR.Booking) {
    this.bookingForm.patchValue({
      bookingCodeId: booking.booking_code_id,
      injection: booking.injection,
      billingItemIds: booking.billing_items,
      funder: booking.funder,
      referrerType: booking.referrer_type,
      patientId: booking.patient_id,
      siteId: booking.site_id,
      referralEnquiryId: booking.referral_enquiry_id ?? null,
      notes: booking.notes,
      startTime: booking.start_time ? new Date(booking.start_time) : null,
      endTime: booking.end_time ? new Date(booking.end_time) : null,
      noReferrerType: !booking.referrer_type,
      existingPatient: booking.patient_id !== null,
    });
  }

  validateForms({ draft }: { draft?: boolean } = {}): boolean {
    Object.values(this.bookingForm.controls).forEach((control) => {
      // We do this to trigger the validation again. Because if we clicked "Draft" first, that runs `setErrors(null)`
      control.updateValueAndValidity();
    });
    if (draft) {
      // We don't have a checkbox for draft, so we patch the form here and revalidate. Some of the error messages no
      // longer apply and will disappear when you do this.
      this.bookingForm.controls.startTime.setErrors(null);
      this.bookingForm.controls.billingItemIds.setErrors(null);
      // Ignore these errors for drafts, and restore them if the form still doesn't validate.
    }
    // Mark all the forms as touched to show the validation errors (css classes only show when .ng-touched)
    this.bookingForm.markAllAsTouched();
    this.bookingPatientForm.markAllAsTouched();
    const value = this.bookingForm.getRawValue();
    const valid = this.bookingForm.valid && (value.existingPatient || this.bookingPatientForm.valid);
    if (!valid) {
      this.message.add({
        title: 'Error',
        message: 'Some fields are still invalid',
        type: 'danger',
      });
    }
    return valid;
  }

  bookingCode$ = this.bookingFormValue$.pipe(
    map((booking) => booking.bookingCodeId),
    distinctUntilChanged(),
    switchMap((bookingCodeId) => {
      if (bookingCodeId === null) {
        return of(undefined);
      }
      return this.store.select(fromBookingCode.selectBookingCode(bookingCodeId));
    }),
    // shareReplay makes sure that we only have one subscription, even if many components subscribe. If a component
    // subscribes late, it will get the last value.
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  billingItems$ = this.bookingCode$.pipe(
    switchMap((bookingCode) => {
      if (!bookingCode) {
        return of([]);
      }
      return this.store.select(fromScanCode.selectBillingItems(bookingCode.scan_code_id));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  sites$ = this.bookingCode$.pipe(
    switchMap((bookingCode) => {
      if (!bookingCode) {
        return of(undefined);
      }
      return this.store.select(fromSite.selectSites(bookingCode.sites));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  patient$ = this.bookingFormValue$.pipe(
    map((booking) => booking.patientId),
    distinctUntilChanged(),
    switchMap((patientId) => {
      if (!patientId) {
        return of(undefined);
      }
      return this.store.select(fromPatient.selectPatient(patientId));
    }),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  findAvailableSlots() {
    return combineLatest({
      selectedDate: this.date$,
      slotFilters: this.slotFiltersFormValue$,
      booking: this.bookingFormValue$.pipe(
        distinctUntilChanged((prev, curr) => prev.bookingCodeId === curr.bookingCodeId && prev.siteId === curr.siteId),
      ),
      // Wait until after booking is loaded because the form gets patched multiple times
      bookingLoading: this.bookingLoading$,
    }).pipe(
      map(({ selectedDate, slotFilters, booking, bookingLoading }) => {
        if (booking.siteId === null || booking.bookingCodeId === null || bookingLoading) {
          return null;
        }
        return {
          selectedDate,
          slotFilters,
          // This code is to narrow the types
          bookingCodeId: booking.bookingCodeId,
          siteId: booking.siteId,
          userId: booking.userId,
        } as const;
      }),
      filterDefined(), // filter out null
      switchMap(({ selectedDate, slotFilters, bookingCodeId, siteId, userId }) => {
        const data: FetchSlotParams = {
          booking_code_id: bookingCodeId,
          site_id: siteId,
          start_date: formatISO(selectedDate, { representation: 'date' }),
          end_date: formatISO(add(selectedDate, { days: 1 }), {
            representation: 'date',
          }),
          doctor_preferred: slotFilters.doctor_preferred ?? undefined,
          gender_preferred: slotFilters.gender_preferred === 'any' ? undefined : slotFilters.gender_preferred,
          doctor_id: slotFilters.doctor_id ?? undefined,
          user_id: userId ?? undefined,
          ignore_booking_id: this.booking?.id,
        };
        this.setState({ availableSlotsLoading: true });
        return this.bookingEffect.findAvailableSlots(data).pipe(
          this.message.handleHttpErrorPipe,
          finalize(() => {
            this.setState({ availableSlotsLoading: false });
          }),
          map((response) => response.slots),
        );
      }),
      tap((slots) => {
        this.setState({ availableSlots: slots });
      }),
    );
  }

  selectSlot(slot: RR.Slot) {
    this.setState({ slot });
    this.bookingForm.patchValue({
      startTime: new Date(slot.start),
      endTime: new Date(slot.end),
      userId: slot.user_id,
    });
  }

  selectBookingCode({ bookingCodeId, navigate }: { bookingCodeId: number; navigate: boolean }) {
    // Reset the billing item selection whenever the booking code is changed
    this.bookingForm.patchValue({
      bookingCodeId,
      billingItemIds: [],
      startTime: null,
      endTime: null,
      userId: null,
    });
    this.setState({
      slot: undefined,
    });
    if (navigate) {
      this.router.navigate([], {
        queryParams: {
          bookingCodeId,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * Ideally we'd use the the store, but we need multiple instances of this state (for Check Availability modal).
   * Another reason this makes sense is that the page is really one big form, that you save at the end.
   */
  state = new BehaviorSubject<BookingPageState>(initialState);

  setState(state: Partial<BookingPageState>): void {
    this.state.next({
      ...this.state.value,
      ...state,
    });
  }

  resetState() {
    this.state.next(initialState);
    this.bookingForm.reset();
    this.bookingPatientForm.reset();
    this.slotFiltersForm.reset();
  }

  select<T extends keyof BookingPageState>(field: T) {
    return this.state.pipe(
      map((state) => state[field]),
      distinctUntilChanged(),
    );
  }

  slot$ = this.select('slot');
  date$ = this.select('date');
  booking$ = this.select('booking');
  bookingLoading$ = this.select('bookingLoading');
  viewMode$ = this.select('viewMode');
  enquiryId$ = this.select('enquiryId');
  availableSlots$ = this.select('availableSlots');
  availableSlotsLoading$ = this.select('availableSlotsLoading');
}
