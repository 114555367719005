<div class="d-flex flex-column my-2 mx-auto" [ngClass]="parent === 'MODAL' ? 'w-75' : 'align-items-start'">
  <h4 *ngIf="header">
    <strong>{{ header }}</strong>
  </h4>

  <ul class="list-group w-100" *ngIf="!!documents?.length">
    <li
      *ngFor="let document of documents"
      class="list-group-item d-flex item-hover-parent align-items-center text-break gap-1"
    >
      <ng-container *ngIf="document">
        <span
          class="fas me-1 text-info"
          [ngClass]="document.original_filename.endsWith('.pdf') ? 'fa-file-pdf' : 'fa-file-image'"
        ></span>
        <a
          class="overflow-hidden col"
          *ngIf="!document.web_booking_id"
          [href]="'/api/document/' + document.filename + '/content'"
          target="_blank"
          rel="noreferrer"
        >
          <span class="">{{ document.original_filename }}</span>
        </a>
        <a
          class="overflow-hidden col"
          *ngIf="document.web_booking_id"
          [href]="'/api/enquiry/' + document.web_booking_id + '/file'"
          target="_blank"
          rel="noreferrer"
        >
          <span class="">{{ document.original_filename }}</span>
        </a>
        <span
          *ngIf="!isEditingType || currentDocumentId !== document.filename"
          class="badge"
          [ngClass]="{
            'bg-secondary': document.type === 'unclassified',
            'bg-info': document.type !== 'unclassified',
          }"
          >{{ document.type }}</span
        >
        <rr-document-type-select
          *ngIf="isEditingType && currentDocumentId === document.filename"
          [control]="form.controls.type"
          [documentType]="document.type"
        ></rr-document-type-select>
        <div *ngIf="!isEditingType || currentDocumentId !== document.filename" class="ms-auto">
          <button
            (click)="editDocumentTypeMode(document.filename)"
            class="btn btn-sm btn-secondary item-hover"
            rrTooltip="EDIT_DOCUMENT_TYPE"
          >
            <i class="fas fa-edit"></i>
          </button>
          <button
            (click)="deleteDocument(document.filename)"
            class="btn btn-sm btn-danger item-hover ms-1"
            rrTooltip="DELETE_DOCUMENT"
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
        <div *ngIf="isEditingType && currentDocumentId === document.filename" class="ms-auto">
          <button
            (click)="updateDocumentType(document.filename)"
            class="btn btn-sm btn-success"
            rrTooltip="UPDATE_DOCUMENT_TYPE"
          >
            <i class="fas fa-check"></i>
          </button>
          <button (click)="cancelEdit()" class="btn btn-sm btn-secondary ms-1" rrTooltip="EXIT_EDIT_DOCUMENT_TYPE">
            <i class="fas fa-times"></i>
          </button>
        </div>
      </ng-container>
    </li>
  </ul>

  <div class="form-group mb-2 w-100 mt-2">
    <div class="input-group" *ngIf="!isEditingType">
      <input
        #fileInput
        type="file"
        class="form-control"
        id="file"
        accept=".png, .jpg, .jpeg, .gif, .pdf"
        (change)="onFileChange()"
        required
      />
      <rr-document-type-select [control]="form.controls.type"></rr-document-type-select>
    </div>
    <div class="d-flex mt-2" [ngClass]="parent === 'MODAL' ? 'justify-content-center' : ''">
      <button [disabled]="!currentFile || submitting" class="btn btn-primary" (click)="submit()">Upload</button>
      <button *ngIf="!!currentFile" [disabled]="submitting" class="btn btn-secondary ms-2" (click)="currentFile = null">
        Cancel
      </button>
    </div>
  </div>
</div>
