import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDropdown, NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filterDefined, fixMaxHeight } from 'app/app.utils';
import { LifecycleLogger } from 'app/core/loggers/lifecycle.logger';
import { EditorService } from 'app/core/services/editor.service';
import { MessageService } from 'app/core/services/message.service';
import { ReportService } from 'app/core/services/report.service';
import { SelectorService } from 'app/core/services/selector.service';
import {
  ESStatementSearch,
  ESStatementSearchHit,
  getSectionAttr,
  TemplateService,
} from 'app/core/services/template.service';
import { VoiceInputTerm, VoiceRecognitionService } from 'app/core/services/voice-recognition.service';
import { SectionFilterString } from 'app/core/toolbar-navbar/components/global-search/global-search.component';
import { VoiceRecognitionTextComponent } from 'app/shared/components/voice-recognition/voice-recognition-text/voice-recognition-text.component';
import { AutoFocusDirective } from 'app/shared/directives/auto-focus.directive';
import { VoiceDirective, VoiceInputDetail } from 'app/shared/directives/voice.directive';
import { ConfirmMessageModalComponent } from 'app/shared/modals/confirm-message-modal/confirm-message-modal.component';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { EditorActions, LastClickedBreadcrumb, fromBreadcrumb } from 'app/store/editor';
import { MandatoryStatementEffect, fromMandatoryStatement } from 'app/store/mandatory-statement';
import { VoiceNoteEffect } from 'app/store/report/voice-note';
import { AttributeGroup, fromDefaultAttribute } from 'app/store/template/default-attribute';
import { fromSection } from 'app/store/template/section';
import { PatchTextObject, StatementEffect, fromStatement } from 'app/store/template/statement';
import { fromUserSetting } from 'app/store/user/user-setting';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { Descendant, Editor, Transforms, Text, Node, Range } from 'slate';
import { ReactEditor } from 'slate-react';

import { DividerStatementsModalComponent } from '../divider/divider-statements-modal/divider-statements-modal.component';
import { SlatementAngularComponent } from '../slatement/slatement-angular/slatement-angular.component';
import { fixSpacingAround, moveCursorAfter, slateValueToTextObjects } from '../slatement/slatement-react/helpers';
import {
  AttributeTextObjectElement,
  DateTextObjectElement,
  NumberTextObjectElement,
} from '../slatement/slatement-react/slate-types';
import { LastClickedBreadcrumbComponent } from './last-clicked-breadcrumb/last-clicked-breadcrumb.component';
import { StatementPreviewComponent } from './statement-preview/statement-preview.component';

export type StatementEditComponentOnSubmit = {
  statement: ReturnType<StatementEditComponent['form']['getRawValue']>;
  textObjects: PatchTextObject[];
  focus: boolean;
  dismissModal: boolean;
};

export type CreateAndCloneStatementData = Pick<StatementEditComponentOnSubmit, 'statement' | 'textObjects'>;

export type EditType =
  | 'createStatement'
  | 'insertStatement'
  | 'createElement'
  | 'createNotepadStatement'
  | 'insertNotepadStatement'
  | 'editStatement'
  | 'editNotepadStatement'
  | 'createBlueStatement'
  | 'editBlueStatement'
  | 'moveMultipleStatement';

const upperRe = /[A-Z]/g;
const lowerRe = /[a-z]/g;

@Component({
  standalone: true,
  selector: 'rr-statement-edit',
  templateUrl: './statement-edit.component.html',
  styleUrls: ['./statement-edit.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SharedModule,
    VoiceRecognitionTextComponent,
    LastClickedBreadcrumbComponent,
    SlatementAngularComponent,
    VoiceDirective,
    StatementPreviewComponent,
    AutoFocusDirective,
  ],
})
@LifecycleLogger
export class StatementEditComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() region: RR.Region | undefined;
  @Input() statement: RR.Statement | undefined;
  @Input() blueChoice: RR.StatementChoice | undefined;
  form = new FormGroup({
    default_key_finding: new FormControl(false, { nonNullable: true }),
    default_comment: new FormControl(false, { nonNullable: true }),
    is_divider: new FormControl(false, { nonNullable: true }),
    default_impression_recommendation: new FormControl(false, { nonNullable: true }),
  });

  // topic and statementSetId are only required for create/editBlueStatement to search and select similar statements
  @Input() topic: RR.Topic;
  @Input() statementSetId: number | undefined;
  @Input() templateId: number;
  @Input() editType: Exclude<EditType, 'moveMultipleStatement'>;
  @Input() attributeGroup: AttributeGroup | undefined;

  @Output() onSubmitBlue = new EventEmitter<string>();
  @Output() onSubmit = new EventEmitter<StatementEditComponentOnSubmit>();
  @Output() onCancel = new EventEmitter<string>();
  @Output() onDelete = new EventEmitter<void>();
  @Output() onApprove = new EventEmitter<string>();
  @Output() onAttributify = new EventEmitter<string>();
  @Output() onClone = new EventEmitter<string>();
  @Output() onCreateAndClone = new EventEmitter<CreateAndCloneStatementData>();
  @Output() onCopy = new EventEmitter<string>(); // Copy notepad statement to template
  @Output() dismissModal = new EventEmitter<void>();
  @Output() focusStatement = new EventEmitter<RR.Statement>();

  // Statement tooltip popover
  @ViewChild('popoverTooltip') popoverTooltip: NgbPopover;
  @ViewChild('popoverMandatory') popoverMandatory: NgbPopover;
  @ViewChild('popoverStatementBuilder') popoverStatementBuilder: NgbPopover;
  statementBuilderPopoverClass = 'statement-builder-popover';

  // Statement suggestions is only applied for create/editBlueStatement
  @ViewChild('suggestedDropdown', { static: true }) suggestedDropdown: NgbDropdown;
  suggested_statements: ESStatementSearchHit[] = [];

  @ViewChild('blueTextInput') blueTextInput: ElementRef<HTMLTextAreaElement>;

  editable = true;
  subscription = new Subscription();

  @ViewChild('slatementAngularComponent') slatementAngularComponent: SlatementAngularComponent | undefined;

  // Auto capitalise first letter and add full stop to the end of the sentence
  autoDecorate = true;

  @ViewChild('attributeOneDropdown', { static: false }) attributeOneDropdown: NgbDropdown;
  mandatoryStatement: RR.MandatoryStatement | undefined;
  statementCount: ESStatementSearch['aggregations'] | undefined;
  searchSection = new FormControl<SectionFilterString>('all', { nonNullable: true });
  private activeSection = new BehaviorSubject<SectionFilterString>('all');
  activeSection$ = this.activeSection.asObservable();
  selectedSection$: Observable<RR.Section | undefined>;
  selectedSubsection$: Observable<RR.Subsection | undefined>;
  selectedElement$: Observable<RR.Element | undefined>;
  lastClickedBreadcrumb$: Observable<LastClickedBreadcrumb | null | undefined>;

  // Slate
  slateValue: Descendant[] | undefined;
  slateValue$ = new Subject<Descendant[] | undefined>();
  editor: Editor | undefined;
  editor$ = new Subject<Editor | undefined>();
  voiceMode: boolean;

  statementBuilderSetId: number | undefined;
  statementBuilderTemplateId: number | undefined;
  statementBuilderStatements: RR.Statement[] | undefined = [];

  constructor(
    private templateService: TemplateService,
    private cd: ChangeDetectorRef,
    private editorService: EditorService,
    private statementEffect: StatementEffect,
    private modal: NgbModal,
    protected el: ElementRef,
    private voiceService: VoiceRecognitionService,
    private mandatoryStatementEffect: MandatoryStatementEffect,
    private store: Store<AppState>,
    private voiceNoteEffect: VoiceNoteEffect,
    private messageService: MessageService,
    private reportService: ReportService,
    private selectorService: SelectorService,
  ) {}

  ngOnInit() {
    const currentUser$ = this.selectorService.selectLoadedCurrentUser();

    this.subscription.add(
      currentUser$
        .pipe(filterDefined())
        .pipe(switchMap((user) => this.store.select(fromUserSetting.selectUserSetting(user.id))))
        .subscribe((userSetting) => {
          if (userSetting) {
            this.voiceMode = userSetting.voice_recognition_features;
          }
        }),
    );

    this.subscription.add(
      this.voiceService.voiceTermSubject$
        .pipe(filter((voiceInput: VoiceInputTerm) => voiceInput.source === 'STATEMENT_EDIT'))
        .subscribe((voiceInput) => {
          // TODO(slatement): Add `rrVoice` to the textbox
          this.editor?.insertText(voiceInput.term + ' ');
        }),
    );

    if (this.statement && this.templateId) {
      this.subscription.add(
        this.store
          .select(fromMandatoryStatement.selectMandatoryStatementByStatementId(this.statement.id, this.templateId))
          .subscribe((mandatoryStatement) => {
            this.mandatoryStatement = mandatoryStatement;
          }),
      );
    }

    this.subscription.add(
      this.store.select(fromStatement.selectStatementsFromStatementBuilder).subscribe((statements) => {
        if (statements) {
          this.statementBuilderStatements = statements.filter((s): s is RR.Statement => !!s);
        }
      }),
    );

    this.subscription.add(
      this.store.select(fromStatement.selectStatementBuilderSetId).subscribe((statementBuilderId) => {
        this.statementBuilderSetId = statementBuilderId;
      }),
    );

    this.subscription.add(
      this.store.select(fromStatement.selectStatementBuilderTemplateId).subscribe((statementBuilderTemplateId) => {
        this.statementBuilderTemplateId = statementBuilderTemplateId;
      }),
    );

    this.selectedSection$ = this.store.select(fromBreadcrumb.selectCurrentSection);
    this.selectedSubsection$ = this.store.select(fromBreadcrumb.selectCurrentSubsection);
    this.selectedElement$ = this.store.select(fromBreadcrumb.selectCurrentElement);
    this.lastClickedBreadcrumb$ = this.store.select(fromBreadcrumb.selectLastClickedBreadcrumb);
  }

  ngAfterViewInit() {
    this.subscription.add(
      // Delay to force to end of call stack
      this.editorService.soabsTextSearch$.pipe(delay(0), filterDefined()).subscribe((searchTerm) => {
        this.editorService.searchTextInSoabs(null);
        const textInputElement = this.blueTextInput.nativeElement;
        textInputElement.innerText = searchTerm;
        textInputElement.dispatchEvent(new Event('input', { bubbles: true }));
        this.setCaretAtEnd(textInputElement);
      }),
    );

    if (this.editType === 'editBlueStatement' && this.blueChoice) {
      this.subscription.add(
        this.reportService.getChoiceText(this.blueChoice.id).subscribe((text) => {
          this.blueTextInput.nativeElement.value = text;
        }),
      );
    }

    if (this.statement) {
      this.form.setValue({
        default_key_finding: this.statement.default_key_finding,
        default_comment: this.statement.default_comment,
        is_divider: this.statement.is_divider,
        default_impression_recommendation: this.statement.default_impression_recommendation,
      });
    } else {
      this.form.setValue({
        default_key_finding: false,
        default_comment: false,
        is_divider: false,
        default_impression_recommendation: false,
      });
    }

    // Subscribe to input changed and suggest similar statements when creating or editing blue statement
    if (this.editType === 'createBlueStatement' || this.editType === 'editBlueStatement') {
      const text$ = fromEvent(this.blueTextInput.nativeElement, 'input').pipe(
        debounceTime(150),
        map(() => this.getBlueText()),
        // Without replay, nothing would happen in searchForSimilarStatement until you change the text input again.
        shareReplay({ bufferSize: 1, refCount: false }),
      );

      this.subscription.add(
        this.lastClickedBreadcrumb$
          .pipe(
            switchMap((lastClickedBreadcrumb) => {
              if (lastClickedBreadcrumb) {
                // This searches for similar statements based on the last clicked breadcrumb
                if (lastClickedBreadcrumb.type === 'element') {
                  return this.templateService.searchForSimilarStatement({
                    text$,
                    topic: this.topic,
                    element_id: lastClickedBreadcrumb.element_id,
                  });
                } else if (lastClickedBreadcrumb.type === 'subsection') {
                  return this.templateService.searchForSimilarStatement({
                    text$,
                    topic: this.topic,
                    subsection_id: lastClickedBreadcrumb.subsection_id,
                  });
                } else {
                  // lastClickedBreadcrumb.type === 'section'
                  const section$ = this.store
                    .select(fromSection.selectSection(lastClickedBreadcrumb.section_id))
                    .pipe(map((s) => s?.type));
                  return this.templateService.searchForSimilarStatement({
                    text$,
                    topic: this.topic,
                    activeSection$: section$,
                  });
                }
              } else {
                return this.templateService.searchForSimilarStatement({
                  text$,
                  topic: this.topic,
                  activeSection$: this.activeSection$,
                });
              }
            }),
          )
          .subscribe((result) => {
            if (!result) return;
            const { textHasChanged, response } = result;
            this.suggested_statements = response.statement.hits.hits;
            this.statementCount = response.statement.aggregations;
            this.cd.markForCheck();

            if (textHasChanged) {
              this.suggestedDropdown.open();
            }
          }),
      );
    }

    this.subscription.add(
      this.editor$.subscribe(() => {
        const editTypes: EditType[] = [
          'createStatement',
          'insertStatement',
          'createElement',
          'createNotepadStatement',
          'insertNotepadStatement',
          'editStatement',
          'editNotepadStatement',
        ];
        if (editTypes.includes(this.editType)) {
          requestAnimationFrame(() => {
            this.focus();
          });
        }
      }),
    );

    if (this.editType === 'createBlueStatement') {
      // Otherwise, when editing blue statement, focus on approve button
      requestAnimationFrame(() => {
        this.focus();
      });
    }

    this.subscription.add(
      this.slateValue$.pipe(debounceTime(100)).subscribe(() => {
        if (
          this.editType === 'createElement' ||
          this.editType === 'createStatement' ||
          this.editType === 'insertStatement'
        ) {
          const finalText = this.getSlateText();
          // When creating a new statement, if the text is uppercase then make it a divider.
          // This is for convenience and legacy reasons.
          this.form.controls.is_divider.setValue(
            // has at least 1 uppercase char
            upperRe.test(finalText) &&
              // has no lowercase chars
              !lowerRe.test(finalText),
          );
        }
      }),
    );

    this.startListeningVoice();
  }

  startListeningVoice() {
    if (!this.voiceMode) return;
    this.voiceService.startListening('STATEMENT_EDIT');
  }

  // Uses an anonymous function because have to bind `this` because otherwise it will refer to the React component
  // eslint-disable-next-line no-restricted-syntax
  slateOnChangeCallback = (value: Descendant[]) => {
    this.slateValue = value;
    this.slateValue$.next(value);
  };

  // Uses an anonymous function because have to bind `this` because otherwise it will refer to the React component
  // eslint-disable-next-line no-restricted-syntax
  slateOnEditorCallback = (editor: Editor) => {
    this.editor = editor;
    this.editor$.next(editor);
  };

  focus() {
    const slateEditTypes: EditType[] = [
      // Focuses the text box
      'createStatement',
      'insertStatement',
      'createElement',
      'createNotepadStatement',
      'insertNotepadStatement',
      // Focuses the close button: https://github.com/fluidsolar/radreport-feedback/issues/5655
      // 'editStatement',
      // 'editNotepadStatement',
    ];
    const blueEditTypes: EditType[] = [
      // Focuses the text box
      'createBlueStatement',
      // Actually focuses the approve button anyway with ngbAutoFocus
      'editBlueStatement',
    ];

    if (slateEditTypes.includes(this.editType)) {
      this.assertEditorDefined();
      ReactEditor.focus(this.editor);

      // Move cursor to the end
      const end = Editor.end(this.editor, this.editor.selection || []);
      Transforms.select(this.editor, { anchor: end, focus: end });
    } else if (blueEditTypes.includes(this.editType)) {
      this.blueTextInput.nativeElement.focus();
    }
  }

  /**
   * Used to narrow the type of StatementEditComponent to one that has an editor.
   */
  assertEditorDefined(): asserts this is StatementEditComponent & { editor: Editor } {
    if (!this.editor) {
      throw new Error('editor is undefined');
    }
  }

  slateValueToTextObjects() {
    if (!this.slateValue) {
      throw new Error('slateValue is undefined');
    }
    return slateValueToTextObjects(this.slateValue);
  }

  @HostListener('voiceInput', ['$event'])
  voiceEvent(event: CustomEvent<VoiceInputDetail>) {
    if (this.isBlueEditType()) {
      this.blueTextInput.nativeElement.append(event.detail.term + ' ');
    }
  }

  setCaretAtEnd(contentEditableElement: HTMLElement) {
    const range = document.createRange();
    range.selectNodeContents(contentEditableElement);
    range.collapse(false); // Collapse the range to the end point. false means collapse to end rather than the start.
    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
  triggerSearch(section: SectionFilterString) {
    this.activeSection.next(section);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  insertGlobalSearchText() {
    this.subscription.add(
      this.editorService
        .getGlobalSearch(this.topic.id)
        .pipe(take(1))
        .subscribe((searchTerm) => {
          this.blueTextInput.nativeElement.innerText = searchTerm;
        }),
    );
  }

  resetStatement() {
    this.slatementAngularComponent?.initialiseFromStatement();
  }

  isBlueEditType() {
    return this.editType === 'createBlueStatement' || this.editType === 'editBlueStatement';
  }

  isStatementEditType() {
    return (
      this.editType === 'createElement' ||
      this.editType === 'createStatement' ||
      this.editType === 'editStatement' ||
      this.editType === 'insertStatement'
    );
  }

  handleGlobalStatementSearch(statementText: string) {
    this.editorService.globalSearchTerm$.next({ source: 'PREFILL', term: statementText });
    setTimeout(() => {
      this.cd.detectChanges();
    }, 0);
  }

  getSlateText(): string {
    if (this.isStatementEditType()) {
      this.assertEditorDefined();
      // [] selects the whole editor
      return Editor.string(this.editor, []);
    } else {
      throw new Error(`Unknown edit type ${this.editType}`);
    }
  }

  // declared return type as `string` because of this error:
  // `TS7023: 'getSlateText' implicitly has return type 'any' because it does not have a return type annotation and is
  // referenced directly or indirectly in one of its return expressions.`
  getBlueText(): string {
    if (this.isBlueEditType()) {
      return this.blueTextInput.nativeElement.value.trim();
    } else {
      throw new Error(`Unknown edit type ${this.editType}`);
    }
  }

  getFinalText(): string {
    if (this.isBlueEditType()) {
      return this.getBlueText();
    } else if (this.isStatementEditType()) {
      this.assertEditorDefined();
      return this.getSlateText();
    } else {
      throw new Error(`Unknown edit type ${this.editType}`);
    }
  }

  _onSubmitBlue() {
    if (!(this.editType === 'createBlueStatement' || this.editType === 'editBlueStatement')) {
      throw new Error('Not in a blue statement ');
    }
    const text = this.getFinalText();
    this.onSubmitBlue.emit(text);
  }

  onEnterBlue() {
    this._onSubmitBlue();
  }

  doSubmit(options: { focus?: boolean; dismissModal?: boolean } = {}) {
    if (this.editType === 'createBlueStatement' || this.editType === 'editBlueStatement') {
      this._onSubmitBlue();
    } else {
      const { focus = true, dismissModal = true } = options;
      const textObjects = this.slateValueToTextObjects();
      this.onSubmit.emit({
        focus,
        dismissModal,
        statement: this.form.getRawValue(),
        textObjects,
      });
    }
  }

  doCancel() {
    this.onCancel.emit();
  }

  doClone() {
    this.onClone.emit();
  }

  doCreateAndClone() {
    const textObjects = this.slateValueToTextObjects();
    this.onCreateAndClone.emit({
      statement: this.form.getRawValue(),
      textObjects,
    });
  }

  // eslint-disable-next-line no-restricted-syntax -- prefer class method
  setDefaultStatement = (
    type: 'KEY_FINDING' | 'COMMENT' | 'DIVIDER' | 'IMPRESSION_RECOMMENDATION',
    isDefault: boolean,
  ) => {
    if (type === 'KEY_FINDING') {
      this.form.controls.default_key_finding.setValue(isDefault);
    } else if (type === 'COMMENT') {
      this.form.controls.default_comment.setValue(isDefault);
    } else if (type === 'DIVIDER') {
      this.form.controls.is_divider.setValue(isDefault);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (type === 'IMPRESSION_RECOMMENDATION') {
      this.form.controls.default_impression_recommendation.setValue(isDefault);
    }
  };

  autoAttributifyStatement() {
    this.editable = false;
    // eslint-disable-next-line rxjs-angular/prefer-composition -- 2
    this.templateService.autoAttributifyStatement(this.getSlateText()).subscribe((_response) => {
      // TODO(slatement)
      // this.textInput.nativeElement.innerText = response;
      this.editable = true;
    });
  }

  editorSelector() {
    this.assertEditorDefined();

    const { selection } = this.editor;
    if (!selection || Range.isCollapsed(selection)) return;

    const start = selection.anchor.offset;
    const end = selection.focus.offset;

    const range = {
      anchor: { offset: start, path: selection.anchor.path },
      focus: { offset: end, path: selection.focus.path },
    };

    const text = Editor.string(this.editor, range);
    ReactEditor.focus(this.editor);
    return { range, text };
  }

  upperTitle() {
    // upper case the first letter of a word
    this.assertEditorDefined();

    const selectionData = this.editorSelector();

    if (selectionData) {
      const { range, text } = selectionData;
      const words = text.split(' ');

      const upperCaseText = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

      Transforms.insertText(this.editor, upperCaseText, { at: range });
    }
  }

  lowerSentence() {
    this.assertEditorDefined();

    const selectionData = this.editorSelector();

    if (selectionData) {
      const { range, text } = selectionData;
      const upperCaseText = text.toLocaleLowerCase();

      Transforms.insertText(this.editor, upperCaseText, { at: range });
    }
  }

  capitaliseWholeSentence() {
    this.assertEditorDefined();
    const editor = this.editor;

    const start = Editor.start(editor, []);
    const end = Editor.end(editor, []);

    const range = { anchor: start, focus: end };

    const entries = Editor.nodes(editor, { at: range });

    for (const [node, path] of entries) {
      if (Text.isText(node)) {
        // Capitalize the text
        const capitalizedText = node.text.toUpperCase();

        // Replace the text
        Transforms.insertText(editor, capitalizedText, { at: path });
      }
    }
  }

  decorateSentence() {
    this.assertEditorDefined();
    const editor = this.editor;

    // Because of normalisation, the first and last node should always be a text node (not void).
    // Uppercase the first character
    const startPoint = Editor.start(editor, []);
    const [startNode] = Editor.node(editor, startPoint);
    if (Text.isText(startNode)) {
      const text = Node.string(startNode);
      if (text.length) {
        const endPoint = Editor.after(editor, startPoint, { distance: 1 });
        if (!endPoint) {
          return;
        }
        // If you pass a range to `insertText` it will delete the range and insert the text.
        Transforms.insertText(editor, text[0].toLocaleUpperCase(), {
          at: Editor.range(editor, startPoint, endPoint),
        });
      }
    }

    // Add a period at the end if there isn't one
    const endPoint = Editor.end(editor, []);
    const [endNode] = Editor.node(editor, endPoint);
    if (Text.isText(endNode)) {
      const text = Node.string(endNode);
      if (text[text.length - 1] !== '.') {
        // If the last character is a space, remove it first
        if (text[text.length - 1] === ' ') {
          const prevEndPoint = Editor.before(editor, endPoint);
          if (prevEndPoint === undefined) {
            throw new Error('Unexpected undefined prevEndPoint');
          }
          Transforms.delete(editor, {
            at: prevEndPoint,
          });
          Transforms.insertText(editor, '.', { at: prevEndPoint });
        } else {
          Transforms.insertText(editor, '.', { at: endPoint });
        }
      }
    }
  }

  clickStatementSuggestion(search_option: obj_literal) {
    this.doCancel();
    // If divider is selected, only focus on that divider
    if (search_option._source.is_divider) {
      this.editorService.publishFocus({
        statement_id: search_option._source.statement_id,
        element_id: search_option._source.element_id,
      });
      return;
    }

    let region_id = undefined;
    // @ts-expect-error noImplicitAny
    const attribute_option_objects = search_option._source.attribute_option_objects.map((object) => {
      return {
        attribute_option_id: object.attribute_option_id,
        text: object.text,
      };
    });
    if (search_option._source.region) {
      if (this.region) {
        region_id = this.region.id;
      }
    }

    this.editorService.chooseAndFocusStatement({
      topic_id: this.topic.id,
      element_id: search_option._source.element_id,
      statement_id: search_option._source.statement_id,
      region_id,
      attribute_option_objects,
    });
  }

  openChange(open: boolean) {
    if (open) {
      requestAnimationFrame(() => {
        const element: HTMLElement | null = document.querySelector('.scrollable-dropdown.show');
        if (element) {
          fixMaxHeight(element);
        }
      });
    }
  }

  getSectionTitle(name: RR.TemplateSection) {
    return getSectionAttr(name, 'abbrTitle');
  }

  isNotepadEditType() {
    return (
      this.editType === 'createNotepadStatement' ||
      this.editType === 'insertNotepadStatement' ||
      this.editType === 'editNotepadStatement'
    );
  }

  saveStatementTooltip(tooltip: string) {
    if (!this.statement) return;
    this.subscription.add(
      this.statementEffect.update(this.statement.id, { tooltip }).subscribe(() => {
        this.popoverTooltip.close();
      }),
    );
  }

  openDividerStatementsModal(statement_index: number) {
    const statement = this.suggested_statements[statement_index]._source;
    if (!statement.divider_id) {
      return;
    }
    DividerStatementsModalComponent.open({
      modalService: this.modal,
      divider_id: statement.divider_id,
      topic_id: this.topic.id,
      parent: 'PREFILL_TAG_MODAL',
    });
  }

  onClickMandatory({ statement, reason }: { statement: RR.Statement; reason: string }) {
    const data = {
      template_id: this.templateId,
      type: 'statement',
      statement_id: statement.id,
      reason: reason,
    };

    const observer = {
      next: () => {
        this.popoverMandatory.close();
      },
    };

    if (this.mandatoryStatement) {
      // If there is a mandatory statement, delete it first
      this.subscription.add(
        this.mandatoryStatementEffect.replace(this.mandatoryStatement.id, data).subscribe(observer),
      );
    } else {
      this.subscription.add(
        this.mandatoryStatementEffect
          .create({
            template_id: this.templateId,
            type: 'statement',
            statement_id: statement.id,
            reason: reason,
          })
          .subscribe(observer),
      );
    }
  }

  onClickDeleteMandatory() {
    const observer = {
      next: () => {
        this.popoverMandatory.close();
      },
    };

    if (this.mandatoryStatement) {
      this.subscription.add(this.mandatoryStatementEffect.delete(this.mandatoryStatement.id).subscribe(observer));
    }
  }

  saveSearch() {
    const text = this.getFinalText().trim();
    if (!text) return;
    this.store.dispatch(EditorActions.addVoiceSearchResult({ term: text, note_type: 'search_clipboard' }));
    this.subscription.add(
      this.voiceNoteEffect
        .create({
          report_id: this.topic.report_id,
          text,
          note_type: 'search_clipboard',
        })
        .subscribe(() => {
          this.messageService.add({
            message: 'Search term saved',
            title: 'Success',
            type: 'success',
            timeout: 1000,
          });
        }),
    );
  }

  createStatementToStatementBuilder() {
    const textObjects = this.slateValueToTextObjects();

    this.subscription.add(
      this.statementEffect
        .createWithTextObjects({
          statement: {
            ...this.form.getRawValue(),
            statement_set_id: this.statementBuilderSetId,
          },
          textObjects: textObjects,
        })
        .subscribe({
          next: () => {
            this.messageService.add({
              title: 'Success',
              type: 'success',
              message: 'Statement has been saved',
            });
          },
        }),
    );
  }

  insertIntoStatementBuilder(statementId: number) {
    this.subscription.add(
      this.store
        .select(fromStatement.selectTextObjects(statementId))
        .pipe(
          take(1),
          tap((textObjects) => {
            textObjects.forEach((textObject) => {
              if (textObject.type === 'set') {
                if (this.statementBuilderTemplateId) {
                  this.subscription.add(
                    this.store
                      .select(fromDefaultAttribute.selectByChoice(this.statementBuilderTemplateId, textObject.id, null))
                      .pipe(
                        map((res) => ({
                          defaultOptionId: res?.default_option_id,
                          textObject,
                        })),
                      )
                      .subscribe(({ defaultOptionId, textObject }) => {
                        if (defaultOptionId && textObject.attribute_set_id) {
                          this.insertAttribute(textObject.attribute_set_id, defaultOptionId);
                        }
                      }),
                  );
                }
              } else if (textObject.type === 'number') {
                this.insertNumberAttribute(textObject.lower, textObject.upper, textObject.formula);
              } else if (textObject.type === 'date') {
                this.insertDateAttribute('day_month_year');
              } else {
                // textObject.type === 'literal'
                this.insertInitialText(textObject.value);
              }
            });
          }),
        )
        .subscribe(),
    );
  }

  deleteStatementFromStatementBuilder(statementId: number) {
    const confirmModalRef = ConfirmMessageModalComponent.open({
      modalService: this.modal,
      header: 'Confirm',
      message: 'Do you want to delete this statement template?',
      btnConfirmText: 'Yes',
    });

    confirmModalRef.result
      .then(() => {
        this.subscription.add(this.statementEffect.delete(statementId).subscribe());
      })
      .catch(() => {
        // Do nothing if the user cancels the confirmation
      });
  }

  insertInitialText(text: string) {
    this.assertEditorDefined();
    const editor = this.editor;
    Transforms.insertText(editor, text);
    ReactEditor.focus(editor);
  }

  insertNumberAttribute(lower: number | null, upper: number | null, formula: string | null) {
    this.assertEditorDefined();
    const editor = this.editor;
    const node: NumberTextObjectElement = {
      type: 'rr-number',
      children: [{ text: '' }],
      text_object_id: undefined,
      upper: upper,
      lower: lower,
      formula: formula,
    };

    Transforms.insertNodes(editor, node);

    fixSpacingAround(editor, node);
    moveCursorAfter(editor, node);
  }

  insertAttribute(attributeSetId: number, defaultOptionId: number) {
    this.assertEditorDefined();
    const editor = this.editor;
    const node: AttributeTextObjectElement = {
      type: 'rr-attribute',
      children: [{ text: '' }],
      text_object_id: undefined,
      attribute_set_id: attributeSetId,
      shortlist: [],
      default_option_id: defaultOptionId,
    };

    Transforms.insertNodes(editor, node);

    fixSpacingAround(editor, node);
    moveCursorAfter(editor, node);
  }

  insertDateAttribute(date_type: RR.DateType) {
    this.assertEditorDefined();
    const editor = this.editor;
    const node: DateTextObjectElement = {
      type: 'rr-date',
      children: [{ text: '' }],
      date_type,
    };

    Transforms.insertNodes(editor, node);

    fixSpacingAround(editor, node);
    moveCursorAfter(editor, node);
  }

  onStatementBuilderPopoverShown() {
    requestAnimationFrame(() => {
      const el: HTMLElement | null = document.querySelector(`.${this.statementBuilderPopoverClass} .popover-body`);
      if (el) {
        fixMaxHeight(el);
      }
    });
  }
}
