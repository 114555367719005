import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { BindObservable, filterDefined } from 'app/app.utils';
import { ReportService } from 'app/core/services/report.service';
import { TemplateService } from 'app/core/services/template.service';
import { ChoiceComponent } from 'app/modules/editor/choice/choice.component';
import { StatementEditModalComponent } from 'app/modules/editor/statement/statement-edit-modal.component';
import { LinkifyDirective } from 'app/shared/directives/linkify/linkify.directive';
import { SharedModule } from 'app/shared/shared.module';
import { AppState } from 'app/store';
import { fromRegion } from 'app/store/template/region';
import { switchMap, combineLatest, map, Observable, filter, of, zip, take, Subscription } from 'rxjs';

@Component({
  selector: 'rr-prefill-copy-choice',
  standalone: true,
  imports: [SharedModule, LinkifyDirective, ChoiceComponent],
  templateUrl: './prefill-copy-choice.component.html',
  styleUrl: './prefill-copy-choice.component.css',
})
export class PrefillCopyChoiceComponent implements OnInit, OnDestroy {
  @Input() statementChoice: RR.FullStatementChoice;
  reportChoice: RR.FullStatementChoice | undefined;
  // undefined for cloned choices, undefined for keyfindings
  @Input() element: RR.Element | undefined;
  parents:
    | {
        element: RR.Element;
        subsection: RR.Subsection;
        section: RR.Section;
        region: RR.Region | undefined;
        isSuggestion: boolean;
        statement: RR.Statement;
      }
    | undefined;
  @Input() topic: RR.Topic;
  @Input() type: 'keyfinding' | 'clone' | 'choice' = 'choice';

  @Input() suggestionStatementIds: Set<number>;
  @Input() notepadStatements: RR.Statement[];
  @Input() @BindObservable() currentContextChoices: RR.CtxFullStatementChoice[];
  currentContextChoices$: Observable<RR.CtxFullStatementChoice[]>;
  @Input() prefillContextChoices: RR.CtxFullStatementChoice[];

  @Output() onClick = new EventEmitter();
  subscription = new Subscription();

  isKeyFinding: boolean;
  isImpression: boolean;
  isComment: boolean;

  constructor(
    private reportService: ReportService,
    private templateService: TemplateService,
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    const reportChoice$ = this.getCorrespondingChoiceInReport(this.statementChoice);

    this.subscription.add(
      reportChoice$.subscribe((reportChoice) => {
        this.reportChoice = reportChoice;
      }),
    );

    this.subscription.add(
      reportChoice$
        .pipe(
          switchMap((reportChoice) => {
            if (!reportChoice) return of(undefined);
            return this.getElementDetailsFn(reportChoice);
          }),
        )
        .subscribe((parents) => {
          this.parents = parents;
        }),
    );

    if (this.type === 'keyfinding') {
      this.isKeyFinding = true;
    } else {
      this.subscription.add(
        this.getStatement(this.statementChoice.statement_id).subscribe((statement) => {
          this.isKeyFinding = statement.default_key_finding;
          this.isImpression = statement.default_impression_recommendation;
          this.isComment = statement.default_comment;
        }),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getChoiceText(choice: RR.StatementChoice) {
    return this.reportService.getChoiceText(choice.id);
  }

  getCorrespondingChoiceInReport(choice: RR.StatementChoice) {
    return this.currentContextChoices$.pipe(
      map((ctx) =>
        ctx.find((ctx) => {
          // @ts-expect-error ctx.statement_choice undefined
          return ctx.statement_choice.id === choice.id;
        }),
      ),
      filterDefined(),
      map((ctx) => ctx.statement_choice),
    );
  }

  // eslint-disable-next-line no-restricted-syntax
  getElementDetailsFn = (statementChoice: RR.StatementChoice) => {
    if (!statementChoice.statement_id) throw new Error('statement_id is undefined');

    return this.loadStatementParents(statementChoice.statement_id).pipe(
      filterDefined(),
      switchMap((element) => {
        return combineLatest({
          region: this.getRegion(statementChoice.id),
          statement: this.getStatement(statementChoice.statement_id),
        }).pipe(
          map(({ region, statement }) => ({
            element: element.element,
            subsection: element.subsection,
            section: element.section,
            region,
            isSuggestion: this.checkSuggestionStatement(statementChoice.statement_id),
            statement,
          })),
        );
      }),
    );
  };

  loadStatementParents(statementId: number) {
    const element$ = this.getStatementElement(statementId);
    return element$.pipe(
      filterDefined(),
      switchMap((element) => {
        return this.templateService.getElements(this.topic.template_id).pipe(
          map((elements) => {
            return elements.find((el) => el.element.id === element.id);
          }),
        );
      }),
    );
  }

  isDefaultKeyFinding(statement_id: number) {
    return this.getStatement(statement_id).pipe(map((s) => s.default_key_finding));
  }

  isDefaultComment(statement_id: number) {
    return this.getStatement(statement_id).pipe(map((s) => s.default_comment));
  }

  isDefaultImpression(statement_id: number) {
    return this.getStatement(statement_id).pipe(map((s) => s.default_impression_recommendation));
  }

  getStatement(statementId: number) {
    return this.templateService.getStatement(statementId);
  }

  getStatementElement(statementId: number): Observable<RR.Element | undefined> {
    return this.templateService
      .getStatementElements(statementId, this.topic.template_id)
      .pipe(map((elements) => elements[0]));
  }

  getSubsection(statementId: number) {
    return this.getStatementElement(statementId).pipe(
      filter((element): element is RR.Element => !!element),
      switchMap((element) => this.templateService.getSubsection(element.subsection_id)),
    );
  }

  getRegion(statementChoiceId: number) {
    const ctxChoice = this.prefillContextChoices.find((ctx) => {
      // @ts-expect-error ctx.statement_choice undefined
      return ctx.statement_choice.id === statementChoiceId;
    });
    if (!ctxChoice || !ctxChoice.region_choice || !ctxChoice.region_choice.region_id) {
      return of(undefined);
    }
    const region_id = ctxChoice.region_choice.region_id;
    return this.store.select(fromRegion.selectRegion(region_id));
  }

  checkSuggestionStatement(statementId: number) {
    return this.suggestionStatementIds.has(statementId);
  }

  editStatement() {
    if (!this.statementChoice.statement_id) return;
    if (this.notepadStatements.find((nps) => nps.id === this.statementChoice.statement_id)) return;
    this.subscription.add(
      zip(
        this.getStatement(this.statementChoice.statement_id),
        this.getStatementElement(this.statementChoice.statement_id),
        this.getRegion(this.statementChoice.id),
        this.getSubsection(this.statementChoice.statement_id),
      )
        .pipe(take(1))
        .subscribe(([statement, element, region, subsection]) => {
          this.openStatementEditModal(
            statement.id,
            // @ts-expect-error element is undefined
            element,
            region,
            subsection,
          );
        }),
    );
  }

  openStatementEditModal(
    statement_id: number,
    element: RR.Element,
    region: RR.Region | undefined,
    subsection: RR.Subsection,
  ) {
    const { componentInstance } = StatementEditModalComponent.open(this.modalService, {
      windowClass: 'statement-edit-modal',
    });
    componentInstance.statement_id = statement_id;
    componentInstance.region = region;
    componentInstance.topic = this.topic;
    componentInstance.subsection = subsection;
    componentInstance.element = element;
    componentInstance.editType = 'editStatement';
    componentInstance.source = 'PREFILL';
  }
}
