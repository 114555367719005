<nav
  class="navbar fixed-top p-0"
  [ngClass]="{
    'bg-warning': rrConfig.environment === 'development' || rrConfig.environment === 'testing',
    'navbar-dark bg-dark': rrConfig.environment === 'production',
    'bg-light border-bottom': rrConfig.environment === 'staging',
  }"
>
  <div class="container-fluid d-flex align-items-center">
    <div class="d-flex gap-1">
      <button
        class="btn btn-outline-secondary"
        (click)="toggleSideNavigation()"
        [rrTooltip]="'NAVIGATION_SIDEBAR'"
        rrTrack
      >
        <i class="fas fa-bars"></i>
      </button>
      <a class="navbar-brand" routerLink="/start">RadReport</a>
    </div>
    <rr-navbar-buttons class="navbar-nav ms-auto"></rr-navbar-buttons>
  </div>
</nav>
