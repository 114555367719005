<div class="fw-bold item-hover-parent">
  <i class="fa fa-stream me-1"></i>
  <label
    title="Click to copy all statements in this element to the report"
    class="mb-2"
    (click)="copyElementChoices(element, region)"
  >
    {{ (getStatementSet(element.id) | async)?.name || 'Unnamed Element' }}
  </label>
  <button class="btn btn-sm btn-light item-hover ms-1 btns-right" (click)="copyElementChoices(element, region)">
    <i class="fas fa-exchange-alt"></i> Copy element
  </button>
</div>

<!--Dividers and Statement choices inside element-->
<div *ngFor="let divider of getElementDividers(element, region); trackBy: trackById">
  <div *ngIf="!hideExcludedTags || !exclusiveTagMap[divider.tag_id]">
    <div class="d-flex align-items-center item-hover-parent text-info mb-1">
      <label
        class="form-check-label"
        [ngClass]="{
          'text-success fw-bold': isSearchingDivider(divider) && !exclusiveTagMap[divider.tag_id],
        }"
        [class.text-warning]="!!exclusiveTagMap[divider.tag_id]"
        (click)="showDividerSentences(divider, element, region, subsection, section)"
        rrTooltip="SHOW_DIVIDER_SENTENCES"
      >
        <i class="fa fa-tag"></i>
        {{ divider.tag_text }}
        <span
          rrTooltip="EXCLUSIVE_DIVIDER"
          [class.invisible]="!exclusiveTagMap[divider.tag_id]"
          class="badge bg-warning ms-2"
          >Excluded Group</span
        >
      </label>
      <button
        class="btn btn-sm btn-outline-info mx-2"
        (click)="onClickPopoverGroupStatements(popoverGroupStatements, divider)"
        [ngbPopover]="popoverGroupStatementsContent"
        #popoverGroupStatements="ngbPopover"
        #popoverGroupStatementsButtons
        triggers="manual"
        [autoClose]="false"
        [placement]="'bottom'"
        [popoverClass]="'group-statements-popover'"
        rrTooltip="SHOW_DIVIDER_SENTENCES"
        [disabled]="divider.isNotepad"
      >
        <ng-template #popoverGroupStatementsContent>
          <!-- TODO: This button is disabled for Global Notepads. To fix this, we need a way to pass element, region, etc to `<rr-recommended-statement>`. For an example, see the custom Notepad handling in `this.showDividerSentences(...)` -->
          <ng-container *ngIf="!divider.isNotepad">
            <ng-container *ngFor="let statement of getStatementsUnderDivider(divider) | async; trackBy: trackById">
              <rr-recommended-statement
                [statement]="statement"
                [topic]="topic"
                [region]="region"
              ></rr-recommended-statement>
            </ng-container>
          </ng-container>
        </ng-template>
        <span><i class="fa fa-list"></i></span>
      </button>
      <button
        class="btn btn-sm btn-light"
        (click)="openStatementUsageAnalytics(divider)"
        rrTooltip="FIND_PREVIOUS_REPORT_BY_STATEMENT"
      >
        <i class="fa fa-file-medical"></i> Statement Usages
      </button>
      <button
        permission
        [permission-only]="['report_manage']"
        class="btn btn-sm btn-light item-hover"
        (click)="editTag(divider, element, region, subsection)"
        rrTooltip="PREFILL_EDIT_STATEMENT"
      >
        <i class="fas fa-edit text-primary"></i> Edit
      </button>

      <button
        class="btn btn-sm btn-light item-hover"
        (click)="showDividerSentences(divider, element, region, subsection, section)"
        rrTooltip="SHOW_DIVIDER_SENTENCES"
      >
        <i class="fas fa-eye"></i> Show
      </button>
      <button class="btn btn-sm btn-light item-hover" (click)="openNotepadModal(element, region, subsection, section)">
        <i class="fas fa-file-alt"></i> Global statements
      </button>
    </div>
    <!--Divider is not empty, show choices under it-->
    <ng-container *ngIf="!isEmptyDivider(divider)">
      <div
        *ngFor="let statementChoice of getStatementChoicesUnderDivider(element, region, divider); trackBy: trackById"
      >
        <rr-prefill-copy-choice
          [type]="'choice'"
          [statementChoice]="statementChoice"
          [element]="element"
          [topic]="topic"
          [suggestionStatementIds]="suggestionStatementIds"
          [currentContextChoices]="currentContextChoices"
          [prefillContextChoices]="prefillContextChoices"
          [notepadStatements]="notepadStatements"
          (onClick)="onCopyChoices.emit({ choiceIds: [statementChoice.id], keepNumbers: false, choice: undefined })"
        ></rr-prefill-copy-choice>
      </div>
    </ng-container>
    <!-- No choices under divider, show its statements in template-->
    <ng-container *ngIf="isEmptyDivider(divider)">
      <div *ngFor="let statement of getStatementsUnderDivider(divider) | async; trackBy: trackById">
        <div class="btn-link">
          <rr-statement-text-render
            [statement_id]="statement.id"
            [region]="region ?? undefined"
            [template_id]="topic.template_id"
            class="text-danger"
            (click)="selectStatement(statement, element, region)"
          ></rr-statement-text-render>
          <span class="fa fa-asterisk ms-1 text-danger" *ngIf="statement.default_key_finding"></span>
          <span class="fa fa-comment ms-1 text-danger" *ngIf="statement.default_comment"></span>
          <span class="fa fa-italic ms-1 text-danger" *ngIf="statement.default_impression_recommendation"></span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

<!--Statement choices that doesn't belong to any divider-->
<div *ngFor="let statementChoice of getOrphanStatementChoices(element, region); trackBy: trackById">
  <rr-prefill-copy-choice
    [type]="'choice'"
    [statementChoice]="statementChoice"
    [element]="element"
    [topic]="topic"
    [suggestionStatementIds]="suggestionStatementIds"
    [currentContextChoices]="currentContextChoices"
    [prefillContextChoices]="prefillContextChoices"
    [notepadStatements]="notepadStatements"
    (onClick)="onCopyChoices.emit({ choiceIds: [statementChoice.id], keepNumbers: false, choice: undefined })"
  ></rr-prefill-copy-choice>
</div>
