<ng-container *ngIf="billingItems$ | async as billingItems">
  <ng-container *ngIf="billingItems.length; else noItems">
    <table class="table" style="height: fit-content">
      <thead>
        <tr>
          <th [style.width.%]="15">Item</th>
          <th *ngIf="referrerType" [style.width.%]="15">
            {{ referrerTypes[referrerType] }} - Eligible For Bulk Billing
          </th>
          <th *ngIf="!referrerType" [style.width.%]="15">Eligible Referrer Types</th>
          <th [style.width.%]="20">Notes</th>
          <th [style.width.%]="10" [class.funder-outline]="funder === 'medicare'">Medicare (100%)</th>
          <th [style.width.%]="15" [class.funder-outline]="funder === 'private_patient'">
            Private Fee (Medicare card holder)
          </th>
          <th [style.width.%]="5" [class.funder-outline]="funder === 'dva'">DVA</th>
          <th [style.width.%]="5" [class.funder-outline]="funder === 'bupa'">BUPA</th>
          <th [style.width.%]="15" [class.funder-outline]="funder === 'other'">Third party, CTP, work cover</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let item of billingItems">
          <tr *ngIf="item">
            <td container="body" placement="start" ngbTooltip="{{ item.mbs_description }}">
              <div class="d-flex align-items-center flex-column position-relative">
                <!-- position relative because the absolute position of btn-check was wrong -->
                <input
                  type="radio"
                  class="btn-check"
                  [name]="'billingItemSelection'"
                  [id]="'btn-check-' + item.id"
                  (change)="onClickBillingItem(item.id)"
                  [checked]="selectedBillingItemIds && selectedBillingItemIds[0] === item.id"
                  autocomplete="off"
                />
                <label class="btn btn-outline-secondary" [for]="'btn-check-' + item.id">Choose</label>
                <div class="mt-1">
                  <rr-billing-item-link [item_code]="item.item_code"></rr-billing-item-link>
                </div>
              </div>
            </td>
            <td>
              <rr-billing-item-eligibility
                [billingItem]="item"
                [referrerType]="referrerType"
              ></rr-billing-item-eligibility>
            </td>
            <td>
              <ng-container *ngIf="item.notes">{{ item.notes }}</ng-container>
              <ng-container *ngIf="!item.notes">No notes</ng-container>
            </td>
            <td
              [class.funder-outline]="funder === 'medicare'"
              container="body"
              placement="top"
              [ngbTooltip]="item.medicare_fee <= 0 ? 'Not eligible' : null"
            >
              <ng-container *ngIf="item.medicare_fee > 0">${{ item.medicare_fee }}</ng-container>
              <ng-container *ngIf="item.medicare_fee <= 0">
                <i class="fas fa-times fa-2x" style="color: red"></i>
              </ng-container>
            </td>
            <td [class.funder-outline]="funder === 'private_patient'">${{ item.private_fee }}</td>
            <td [class.funder-outline]="funder === 'dva'">${{ item.dva_fee }}</td>
            <td [class.funder-outline]="funder === 'bupa'">${{ item.bupa_fee }}</td>
            <td [class.funder-outline]="funder === 'other'">${{ item.other_fee }}</td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </ng-container>
  <ng-template #noItems>
    <div>No billing items</div>
  </ng-template>
</ng-container>
