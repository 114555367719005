import { CommonModule } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { PresetEffect } from 'app/store/report/preset';
import { Subscription } from 'rxjs';

import { PrefillService } from '../../prefill.service';

@Component({
  selector: 'rr-prefill-row-preset',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './prefill-row-preset.component.html',
  styleUrl: './prefill-row-preset.component.css',
})
export class PrefillRowPresetComponent implements OnInit, OnDestroy {
  @Input() report: RR.Report;
  @Input() presetTitle: RR.PresetTitle;
  @Input() openTopicId: number;
  @Input() count: number;
  @ViewChild('template', { static: true }) template: TemplateRef<ElementRef>;
  subscription = new Subscription();

  selected: boolean = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private prefillService: PrefillService,
    private presetEffect: PresetEffect,
  ) {}

  ngOnInit(): void {
    // This replaces the parent component `rr-prefill-row-preset` with the button, so that the .list-item class
    // doesn't get confused by extra DOM levels
    this.viewContainerRef.createEmbeddedView(this.template);

    this.subscription.add(
      this.prefillService.getPrefillPreviewTopics(this.openTopicId).subscribe((topics) => {
        const topicIds = new Set(topics.filter((t): t is RR.Topic => !!t).map((t) => t.id));
        this.selected = this.report.topic_ids.some((id) => topicIds.has(id));
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  selectPrefill(event: MouseEvent) {
    const topicIds = this.report.topic_ids.map((id) => id.toString());
    const topicId = topicIds[0];

    this.prefillService.selectPrefill({ event, selected: this.selected, openTopicId: this.openTopicId, topicId });
  }

  onClickUsePreset() {
    this.subscription.add(
      this.presetEffect
        .usePreset({
          from_report_id: this.report.id,
          to_topic_id: this.openTopicId,
        })
        .subscribe(),
    );
  }
}
